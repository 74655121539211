.DocTabs_container {
    padding: 0 8px;

    .window-controls-overlay & {
        margin-top: 24px;
    }
}

.DocTabs_main_content {
    border-top: 1px solid var(--grey-2);
    overflow: hidden;
}

.DocTab {
    margin: 0;
    padding: 0 9px 0 12px;

    width: 160px;
    overflow: hidden;

    display: flex;
    flex-direction: row;
    align-items: center;

    color: var(--spectrum-global-color-gray-600);
    background: transparent;
    border: 0;
    border-bottom: 2px solid transparent;

    user-select: none;

    &:not(:last-child) {
        border-right: 1px solid var(--spectrum-global-color-gray-100);
    }

    &.active-true {
        color: var(--spectrum-global-color-gray-900);
        //background: var(--grey-0-alpha-50);
        border-bottom-color: var(--spectrum-global-color-gray-900);
        //backdrop-filter: blur(4px);
    }

    > span {
        flex: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }

    .DocTab_Close {
        margin: 0;
        padding: 0;

        width: 18px;
        height: 18px;

        color: var(--spectrum-global-color-gray-600);
        background: transparent;
        border: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 4px;

        cursor: pointer;

        &:hover {
            color: var(--spectrum-global-color-gray-900);
            background: var(--spectrum-global-color-gray-200);

            .DocTab_Close_UnsavedIcon {
                display: none;
            }
        }

        &:not(:hover):has(.DocTab_Close_UnsavedIcon) {
            .DocTab_Close_CloseIcon {
                display: none;
            }
        }

        .DocTab_Close_CloseIcon {
            width: 14px;
            height: 14px;
            opacity: 0;
        }

        .DocTab_Close_UnsavedIcon {
            width: 12px;
            height: 12px;
            color: var(--spectrum-global-color-gray-800);
        }
    }

    &:hover,
    &.active-true {
        .DocTab_Close_CloseIcon {
            opacity: 1;
        }
    }
}
