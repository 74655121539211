:root {
  --grey-9: #efefef;
  --grey-8: #c8c8c8;
  --grey-7: #a2a2a2;
  --grey-6: #7c7c7c;
  --grey-5: #5c5c5c;
  --grey-4: #494949;
  --grey-3: #393939;
  --grey-2: #2c2c2c;
  --grey-1: #1e1e1e;
  --grey-0: #111;
  --grey-0-alpha-50: #11111180;
  --yellow: #ffc639;
  --pink: #ea7aa1;
}

@media (prefers-color-scheme: light) {
  :root {
    --grey-9: #080808;
    --grey-8: #1e1e1e;
    --grey-7: #2c2c2c;
    --grey-6: #393939;
    --grey-5: #494949;
    --grey-4: #5c5c5c;
    --grey-3: #7c7c7c;
    --grey-2: #a2a2a2;
    --grey-1: #c8c8c8;
    --grey-0: #fff;
    --grey-0-alpha-50: #ffffff80;
  }
}

* {
  box-sizing: border-box;
}

html {
  font-family: SF Pro, system-ui, sans-serif;
  font-size: 14px;
}

body {
  color: var(--grey-9);
  background: var(--grey-0);
  margin: 0;
  padding: 0;
}

.no-scroll {
  overflow: hidden;
}

::selection {
  color: #000;
  background: var(--yellow);
}

.icon {
  width: 1.25em;
  height: 1.25em;
  vertical-align: top;
  display: inline-block;
}

.initial-load-container {
  text-align: center;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.initial-load-container h1 {
  font-weight: 500;
}

.initial-load-container h1 img {
  filter: grayscale();
}

.initial-load-container p {
  max-width: 60ch;
  width: calc(100vw - 1em);
}

.initial-load-container p a:any-link {
  color: var(--yellow);
}

.initial-load-container .error-details {
  color: var(--grey-6);
  text-align: left;
  font-family: monospace;
}

#canvas {
  display: none;
}

.flex-grow {
  flex-grow: 1;
}

html, .App {
  background: var(--grey-0);
}

.initial-load-container, #splash-page {
  background: linear-gradient(180deg, transparent 0, var(--grey-0) 600px), fixed 0 0 / 20px 20px radial-gradient(var(--grey-2) 1px, transparent 0), fixed 10px 10px / 20px 20px radial-gradient(var(--grey-2) 1px, transparent 0), var(--grey-0);
}

#splash-page header {
  height: 3.25em;
  background: var(--grey-0-alpha-50);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid var(--grey-2);
  width: 100%;
  height: 40px;
  z-index: 1;
  padding: .3em 1em;
  position: sticky;
  top: 0;
}

#splash-page header h1 {
  margin: 0;
  padding: 0;
  font-size: 1.25em;
  font-weight: 400;
  line-height: 1.73077em;
  display: inline-block;
}

#splash-page header h1 .icon {
  vertical-align: sub;
}

#splash-page header .author {
  color: var(--grey-8);
  margin: 0 .5em;
}

#splash-page header .author a {
  color: var(--grey-9);
  border-bottom: 1.5px solid;
  text-decoration: none;
}

#splash-page header .right {
  float: right;
  height: 2.25em;
  align-items: center;
  gap: 1em;
  display: flex;
}

#splash-page header .button {
  height: 2.25em;
  text-decoration: none;
}

#splash-page .hero {
  text-align: center;
  padding: 6em 2em 0;
}

#splash-page .hero-logo {
  width: 24em;
  max-width: 100%;
  aspect-ratio: 56 / 15;
  margin: 0;
  padding: 0;
}

#splash-page .hero-description {
  font-family: SF Pro Rounded, system-ui, sans-serif;
  font-size: 2.1em;
}

@media (max-width: 600px) {
  #splash-page .hero-description {
    font-size: 24px;
  }
}

#splash-page a {
  color: inherit;
}

#splash-page .link, #splash-page p a {
  color: inherit;
  font-weight: 500;
  display: inline-block;
}

#splash-page .link:hover, #splash-page p a:hover {
  color: var(--yellow);
}

#splash-page .link:active, #splash-page p a:active {
  transform: translateY(1px);
}

#splash-page main, #splash-page .section-container {
  max-width: 36em;
  margin: 0 auto;
  padding: 1em;
}

#splash-page main p, #splash-page main ul, #splash-page main hr, #splash-page .section-container p, #splash-page .section-container ul, #splash-page .section-container hr {
  max-width: 30em;
  margin-inline: auto;
  font-size: 16px;
  line-height: 1.6;
}

#splash-page main hr, #splash-page .section-container hr {
  border: 0;
  border-top: 1px solid var(--grey-2);
  margin-block: 2em;
}

#splash-page main small, #splash-page .section-container small {
  color: var(--grey-8);
}

#splash-page main section:not(:first-child), #splash-page .section-container section:not(:first-child) {
  margin: 4em 0;
}

#splash-page .button {
  border: 1px solid var(--grey-3);
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border-bottom-width: 2px;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  padding: .5em 1.25em;
  font-weight: 600;
  text-decoration: none;
  display: inline-flex;
}

#splash-page .button:hover {
  background: var(--grey-1);
}

#splash-page .button:active {
  transform: translateY(1px);
}

#splash-page .big {
  padding: .75em 2em;
}

#splash-page .cta {
  color: #000;
  background: var(--pink);
  border-color: #ac5372;
}

#splash-page .cta:hover {
  background: #d36e91;
}

#splash-page .buttons {
  align-items: center;
  gap: 1em;
  display: flex;
}

#splash-page .center {
  text-align: center;
}

#splash-page .screenshot {
  width: 100%;
  max-width: 1115px;
  aspect-ratio: 1115 / 769;
  background: url("screenshot.e3f98c35.png") top / cover no-repeat;
  margin: 0 auto;
  display: block;
}

@media (max-width: 800px) {
  #splash-page .screenshot {
    background-size: 780.5px 538.3px;
    position: relative;
  }

  #splash-page .screenshot:after {
    content: "";
    width: 100%;
    height: 100px;
    background: linear-gradient(transparent, var(--grey-0));
    position: absolute;
    bottom: 0;
  }
}

.spectrum-Button_e2d99e, .spectrum-ActionButton_e2d99e, .spectrum-LogicButton_e2d99e, .spectrum-FieldButton_e2d99e, .spectrum-ClearButton_e2d99e {
  font-family: adobe-clean-ux, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e:lang(ar), .spectrum-ActionButton_e2d99e:lang(ar), .spectrum-LogicButton_e2d99e:lang(ar), .spectrum-FieldButton_e2d99e:lang(ar), .spectrum-ClearButton_e2d99e:lang(ar) {
  font-family: adobe-arabic, myriad-arabic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e:lang(he), .spectrum-ActionButton_e2d99e:lang(he), .spectrum-LogicButton_e2d99e:lang(he), .spectrum-FieldButton_e2d99e:lang(he), .spectrum-ClearButton_e2d99e:lang(he) {
  font-family: adobe-hebrew, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e:lang(zh-Hans), .spectrum-ActionButton_e2d99e:lang(zh-Hans), .spectrum-LogicButton_e2d99e:lang(zh-Hans), .spectrum-FieldButton_e2d99e:lang(zh-Hans), .spectrum-ClearButton_e2d99e:lang(zh-Hans), .spectrum-Button_e2d99e:lang(zh), .spectrum-ActionButton_e2d99e:lang(zh), .spectrum-LogicButton_e2d99e:lang(zh), .spectrum-FieldButton_e2d99e:lang(zh), .spectrum-ClearButton_e2d99e:lang(zh) {
  font-family: adobe-clean-han-simplified-c, SimSun, Heiti SC Light, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e:lang(ko), .spectrum-ActionButton_e2d99e:lang(ko), .spectrum-LogicButton_e2d99e:lang(ko), .spectrum-FieldButton_e2d99e:lang(ko), .spectrum-ClearButton_e2d99e:lang(ko) {
  font-family: adobe-clean-han-korean, Malgun Gothic, Apple Gothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e:lang(ja), .spectrum-ActionButton_e2d99e:lang(ja), .spectrum-LogicButton_e2d99e:lang(ja), .spectrum-FieldButton_e2d99e:lang(ja), .spectrum-ClearButton_e2d99e:lang(ja) {
  font-family: adobe-clean-han-japanese, Yu Gothic, メイリオ, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro W3, Osaka, ＭＳＰゴシック, MS PGothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e, .spectrum-ActionButton_e2d99e, .spectrum-LogicButton_e2d99e, .spectrum-FieldButton_e2d99e, .spectrum-ClearButton_e2d99e {
  box-sizing: border-box;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  transition: background var(--spectrum-global-animation-duration-100, 130ms) ease-out, border-color var(--spectrum-global-animation-duration-100, 130ms) ease-out, color var(--spectrum-global-animation-duration-100, 130ms) ease-out, box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  cursor: default;
  isolation: isolate;
  border-style: solid;
  justify-content: center;
  align-items: center;
  margin: 0;
  line-height: 1.3;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: visible;
}

button.spectrum-Button_e2d99e, button.spectrum-ActionButton_e2d99e, button.spectrum-LogicButton_e2d99e, button.spectrum-FieldButton_e2d99e, button.spectrum-ClearButton_e2d99e {
  -webkit-appearance: button;
}

.spectrum-Button_e2d99e:focus, .spectrum-ActionButton_e2d99e:focus, .spectrum-LogicButton_e2d99e:focus, .spectrum-FieldButton_e2d99e:focus, .spectrum-ClearButton_e2d99e:focus {
  outline: 0;
}

.spectrum-Button_e2d99e.focus-ring_e2d99e, .spectrum-ActionButton_e2d99e.focus-ring_e2d99e, .spectrum-LogicButton_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton_e2d99e.focus-ring_e2d99e, .spectrum-ClearButton_e2d99e.focus-ring_e2d99e {
  z-index: 3;
}

.spectrum-Button_e2d99e::-moz-focus-inner, .spectrum-ActionButton_e2d99e::-moz-focus-inner, .spectrum-LogicButton_e2d99e::-moz-focus-inner, .spectrum-FieldButton_e2d99e::-moz-focus-inner, .spectrum-ClearButton_e2d99e::-moz-focus-inner {
  border: 0;
  margin-top: -2px;
  margin-bottom: -2px;
  padding: 0;
}

.spectrum-Button_e2d99e:disabled, .spectrum-ActionButton_e2d99e:disabled, .spectrum-LogicButton_e2d99e:disabled, .spectrum-FieldButton_e2d99e:disabled, .spectrum-ClearButton_e2d99e:disabled {
  cursor: default;
}

.spectrum-Button_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e, .spectrum-LogicButton_e2d99e .spectrum-Icon_e2d99e, .spectrum-FieldButton_e2d99e .spectrum-Icon_e2d99e, .spectrum-ClearButton_e2d99e .spectrum-Icon_e2d99e {
  max-height: 100%;
  transition: background var(--spectrum-global-animation-duration-100, 130ms) ease-out, fill var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  box-sizing: initial;
  flex-shrink: 0;
  order: 0;
}

.spectrum-Button_e2d99e:after, .spectrum-ActionButton--emphasized_e2d99e:after, .spectrum-ActionButton--staticColor_e2d99e:after, .spectrum-LogicButton_e2d99e:after, .spectrum-ClearButton_e2d99e:after {
  border-radius: calc(var(--spectrum-button-primary-border-radius, var(--spectrum-alias-border-radius-large))  + var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)));
  content: "";
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -1);
  transition: box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-out, margin var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  display: block;
  position: absolute;
  inset: 0;
  transform: translate(0);
}

.spectrum-Button_e2d99e.focus-ring_e2d99e:after, .spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e:after, .spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e:after, .spectrum-LogicButton_e2d99e.focus-ring_e2d99e:after, .spectrum-ClearButton_e2d99e.focus-ring_e2d99e:after {
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -2);
}

.spectrum-Button_e2d99e {
  border-width: var(--spectrum-button-primary-border-size, var(--spectrum-alias-border-size-thick));
  border-radius: var(--spectrum-button-primary-border-radius, var(--spectrum-alias-border-radius-large));
  min-height: var(--spectrum-button-primary-height, var(--spectrum-alias-single-line-height));
  height: 0%;
  min-width: var(--spectrum-button-primary-min-width);
  padding: var(--spectrum-global-dimension-size-50) calc(var(--spectrum-button-primary-padding-x, var(--spectrum-global-dimension-size-200))  - var(--spectrum-button-primary-border-size, var(--spectrum-alias-border-size-thick)));
  padding-bottom: calc(var(--spectrum-global-dimension-size-50)  + 1px);
  padding-top: calc(var(--spectrum-global-dimension-size-50)  - 1px);
  font-size: var(--spectrum-button-primary-text-size, var(--spectrum-alias-pill-button-text-size));
  font-weight: var(--spectrum-button-primary-text-font-weight, var(--spectrum-global-font-weight-bold));
  border-style: solid;
}

.spectrum-Button_e2d99e.is-hovered_e2d99e, .spectrum-Button_e2d99e:active {
  box-shadow: none;
}

[dir="ltr"] .spectrum-Button_e2d99e .spectrum-Icon_e2d99e + .spectrum-Button-label_e2d99e {
  margin-left: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-Button_e2d99e .spectrum-Icon_e2d99e + .spectrum-Button-label_e2d99e, [dir="ltr"] .spectrum-Button_e2d99e .spectrum-Button-label_e2d99e + .spectrum-Icon_e2d99e {
  margin-right: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-Button_e2d99e .spectrum-Button-label_e2d99e + .spectrum-Icon_e2d99e {
  margin-left: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

a.spectrum-Button_e2d99e, a.spectrum-ActionButton_e2d99e {
  -webkit-appearance: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.spectrum-ActionButton_e2d99e {
  height: var(--spectrum-actionbutton-height, var(--spectrum-alias-single-line-height));
  min-width: var(--spectrum-actionbutton-min-width, var(--spectrum-global-dimension-size-400));
  border-width: var(--spectrum-actionbutton-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-actionbutton-border-radius, var(--spectrum-alias-border-radius-regular));
  font-size: var(--spectrum-actionbutton-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-actionbutton-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  padding: 0;
  position: relative;
}

[dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e {
  padding-right: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e {
  padding-left: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e + .spectrum-ActionButton-label_e2d99e {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e + .spectrum-ActionButton-label_e2d99e, [dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e:not([hidden]) + .spectrum-Icon_e2d99e {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e:not([hidden]) + .spectrum-Icon_e2d99e {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e:only-child, [dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-hold_e2d99e + .spectrum-ActionButton-label_e2d99e:last-child {
  padding-left: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e:only-child, [dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-hold_e2d99e + .spectrum-ActionButton-label_e2d99e:last-child {
  padding-right: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e:only-child, [dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-hold_e2d99e + .spectrum-Icon_e2d99e:last-child {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e:only-child, [dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-hold_e2d99e + .spectrum-Icon_e2d99e:last-child {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="ltr"] .spectrum-ActionButton-hold_e2d99e {
  right: var(--spectrum-actionbutton-hold-icon-padding-right, var(--spectrum-global-dimension-size-40));
}

[dir="rtl"] .spectrum-ActionButton-hold_e2d99e {
  left: var(--spectrum-actionbutton-hold-icon-padding-right, var(--spectrum-global-dimension-size-40));
}

.spectrum-ActionButton-hold_e2d99e {
  bottom: var(--spectrum-actionbutton-hold-icon-padding-bottom, var(--spectrum-global-dimension-size-40));
  position: absolute;
}

[dir="rtl"] .spectrum-ActionButton-hold_e2d99e {
  transform: rotate(90deg);
}

.spectrum-ActionButton-label_e2d99e, .spectrum-Button-label_e2d99e {
  -ms-grid-row-align: center;
  text-align: center;
  width: 100%;
  order: 1;
  place-self: center;
}

.spectrum-ActionButton-label_e2d99e:empty, .spectrum-Button-label_e2d99e:empty {
  display: none;
}

.spectrum-ActionButton-label_e2d99e {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spectrum-ActionButton--quiet_e2d99e {
  border-width: var(--spectrum-actionbutton-quiet-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-actionbutton-quiet-border-radius, var(--spectrum-alias-border-radius-regular));
  font-size: var(--spectrum-actionbutton-quiet-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-actionbutton-quiet-text-font-weight, var(--spectrum-alias-body-text-font-weight));
}

.spectrum-ActionButton--emphasized_e2d99e:after, .spectrum-ActionButton--staticColor_e2d99e:after {
  border-radius: calc(var(--spectrum-actionbutton-border-radius, var(--spectrum-alias-border-radius-regular))  + var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)));
}

.spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e:after, .spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e:after {
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -1 - var(--spectrum-actionbutton-quiet-border-size, var(--spectrum-alias-border-size-thin)));
}

.spectrum-LogicButton_e2d99e {
  height: var(--spectrum-logicbutton-and-height, 24px);
  padding: var(--spectrum-logicbutton-and-padding-x, var(--spectrum-global-dimension-size-100));
  border-width: var(--spectrum-logicbutton-and-border-size, var(--spectrum-alias-border-size-thick));
  border-radius: var(--spectrum-logicbutton-and-border-radius, var(--spectrum-alias-border-radius-regular));
  font-size: var(--spectrum-logicbutton-and-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-logicbutton-and-text-font-weight, var(--spectrum-global-font-weight-bold));
  line-height: 0;
}

.spectrum-LogicButton_e2d99e:after {
  border-radius: calc(var(--spectrum-logicbutton-and-border-radius, var(--spectrum-alias-border-radius-regular))  + var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)));
}

.spectrum-FieldButton_e2d99e {
  height: var(--spectrum-dropdown-height, var(--spectrum-global-dimension-size-400));
  padding: 0 var(--spectrum-dropdown-padding-x, var(--spectrum-global-dimension-size-150));
  font-family: inherit;
  font-weight: 400;
  font-size: var(--spectrum-dropdown-text-size, var(--spectrum-alias-font-size-default));
  -webkit-font-smoothing: initial;
  cursor: default;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: var(--spectrum-dropdown-padding-x, var(--spectrum-global-dimension-size-150));
  padding-right: var(--spectrum-dropdown-padding-x, var(--spectrum-global-dimension-size-150));
  border-width: var(--spectrum-dropdown-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-global-dimension-size-50);
  transition: background-color var(--spectrum-global-animation-duration-100, 130ms), box-shadow var(--spectrum-global-animation-duration-100, 130ms), border-color var(--spectrum-global-animation-duration-100, 130ms);
  border-style: solid;
  outline: 0;
  margin: 0;
  line-height: normal;
}

.spectrum-FieldButton_e2d99e:disabled, .spectrum-FieldButton_e2d99e.is-disabled_e2d99e {
  cursor: default;
  border-width: 0;
}

.spectrum-FieldButton_e2d99e.is-open_e2d99e {
  border-width: var(--spectrum-dropdown-border-size, var(--spectrum-alias-border-size-thin));
}

.spectrum-FieldButton--quiet_e2d99e {
  border-radius: var(--spectrum-fieldbutton-quiet-border-radius, 0px);
  border-width: 0;
  margin: 0;
  padding: 0;
}

.spectrum-FieldButton--quiet_e2d99e:disabled.focus-ring_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-disabled_e2d99e.focus-ring_e2d99e {
  box-shadow: none;
}

.spectrum-ClearButton_e2d99e {
  width: var(--spectrum-clearbutton-medium-width, var(--spectrum-alias-single-line-height));
  height: var(--spectrum-clearbutton-medium-height, var(--spectrum-alias-single-line-height));
  border: 0;
  border-radius: 100%;
  margin: 0;
  padding: 0;
}

.spectrum-ClearButton_e2d99e > .spectrum-Icon_e2d99e {
  margin: 0 auto;
}

.spectrum-ClearButton--overBackground_e2d99e.focus-ring_e2d99e:after {
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -1);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .spectrum-ClearButton_e2d99e > .spectrum-Icon_e2d99e {
    margin: 0;
  }
}

.spectrum-ClearButton--small_e2d99e {
  width: var(--spectrum-clearbutton-small-width, var(--spectrum-global-dimension-size-300));
  height: var(--spectrum-clearbutton-small-height, var(--spectrum-global-dimension-size-300));
}

.spectrum-LogicButton_e2d99e.focus-ring_e2d99e:after, .spectrum-LogicButton_e2d99e.is-focused_e2d99e:after, .spectrum-Button_e2d99e.focus-ring_e2d99e:after, .spectrum-Button_e2d99e.is-focused_e2d99e:after, .spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e:after, .spectrum-ActionButton--emphasized_e2d99e.is-focused_e2d99e:after {
  box-shadow: 0 0 0 var(--spectrum-button-primary-focus-ring-size-key-focus, var(--spectrum-alias-focus-ring-size)) var(--spectrum-button-primary-focus-ring-color-key-focus, var(--spectrum-alias-focus-ring-color));
}

.spectrum-ClearButton_e2d99e {
  background-color: var(--spectrum-clearbutton-medium-background-color, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ClearButton_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-clearbutton-medium-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ClearButton_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-clearbutton-medium-background-color-hover, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ClearButton_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-clearbutton-medium-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ClearButton_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-clearbutton-medium-background-color-down, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-ClearButton_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-clearbutton-medium-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-ClearButton_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-clearbutton-medium-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.spectrum-ClearButton_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-clearbutton-medium-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.spectrum-ClearButton_e2d99e:disabled, .spectrum-ClearButton_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-clearbutton-medium-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ClearButton_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ClearButton_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-clearbutton-medium-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-Button--cta_e2d99e {
  background-color: var(--spectrum-button-cta-background-color, var(--spectrum-semantic-cta-color-background-default));
  border-color: var(--spectrum-button-cta-border-color, var(--spectrum-semantic-cta-color-background-default));
  color: var(--spectrum-button-cta-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-Button--cta_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-cta-background-color-hover, var(--spectrum-semantic-cta-color-background-hover));
  border-color: var(--spectrum-button-cta-border-color-hover, var(--spectrum-semantic-cta-color-background-hover));
  color: var(--spectrum-button-cta-text-color-hover, var(--spectrum-global-color-static-white));
}

.spectrum-Button--cta_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-cta-background-color-key-focus, var(--spectrum-semantic-cta-color-background-hover));
  border-color: var(--spectrum-button-cta-border-color-key-focus, var(--spectrum-semantic-cta-color-background-hover));
  color: var(--spectrum-button-cta-text-color-key-focus, var(--spectrum-global-color-static-white));
}

.spectrum-Button--cta_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-cta-background-color-down, var(--spectrum-semantic-cta-color-background-down));
  border-color: var(--spectrum-button-cta-border-color-down, var(--spectrum-semantic-cta-color-background-down));
  color: var(--spectrum-button-cta-text-color-down, var(--spectrum-global-color-static-white));
}

.spectrum-Button--cta_e2d99e:disabled, .spectrum-Button--cta_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-cta-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-cta-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-cta-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Button--primary_e2d99e {
  background-color: var(--spectrum-button-primary-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-primary-border-color, var(--spectrum-global-color-gray-800));
  color: var(--spectrum-button-primary-text-color, var(--spectrum-global-color-gray-800));
}

.spectrum-Button--primary_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-primary-background-color-hover, var(--spectrum-global-color-gray-800));
  border-color: var(--spectrum-button-primary-border-color-hover, var(--spectrum-global-color-gray-800));
  color: var(--spectrum-button-primary-text-color-hover, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--primary_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-primary-background-color-key-focus, var(--spectrum-global-color-gray-800));
  border-color: var(--spectrum-button-primary-border-color-key-focus, var(--spectrum-global-color-gray-800));
  color: var(--spectrum-button-primary-text-color-key-focus, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--primary_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-primary-background-color-down, var(--spectrum-global-color-gray-900));
  border-color: var(--spectrum-button-primary-border-color-down, var(--spectrum-global-color-gray-900));
  color: var(--spectrum-button-primary-text-color-down, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--primary_e2d99e:disabled, .spectrum-Button--primary_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-primary-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-primary-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-primary-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Button--secondary_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-secondary-border-color, var(--spectrum-global-color-gray-700));
  color: var(--spectrum-button-secondary-text-color, var(--spectrum-global-color-gray-700));
}

.spectrum-Button--secondary_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color-hover, var(--spectrum-global-color-gray-700));
  border-color: var(--spectrum-button-secondary-border-color-hover, var(--spectrum-global-color-gray-700));
  color: var(--spectrum-button-secondary-text-color-hover, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--secondary_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color-key-focus, var(--spectrum-global-color-gray-700));
  border-color: var(--spectrum-button-secondary-border-color-key-focus, var(--spectrum-global-color-gray-700));
  color: var(--spectrum-button-secondary-text-color-key-focus, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--secondary_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color-down, var(--spectrum-global-color-gray-800));
  border-color: var(--spectrum-button-secondary-border-color-down, var(--spectrum-global-color-gray-800));
  color: var(--spectrum-button-secondary-text-color-down, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--secondary_e2d99e:disabled, .spectrum-Button--secondary_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-secondary-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-secondary-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Button--warning_e2d99e {
  background-color: var(--spectrum-button-warning-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-warning-border-color, var(--spectrum-semantic-negative-color-text-small));
  color: var(--spectrum-button-warning-text-color, var(--spectrum-semantic-negative-color-text-small));
}

.spectrum-Button--warning_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-warning-background-color-hover, var(--spectrum-semantic-negative-color-text-small));
  border-color: var(--spectrum-button-warning-border-color-hover, var(--spectrum-semantic-negative-color-text-small));
  color: var(--spectrum-button-warning-text-color-hover, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--warning_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-warning-background-color-key-focus, var(--spectrum-semantic-negative-color-text-small));
  border-color: var(--spectrum-button-warning-border-color-key-focus, var(--spectrum-semantic-negative-color-text-small));
  color: var(--spectrum-button-warning-text-color-key-focus, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--warning_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-warning-background-color-down, var(--spectrum-global-color-red-700));
  border-color: var(--spectrum-button-warning-border-color-down, var(--spectrum-global-color-red-700));
  color: var(--spectrum-button-warning-text-color-down, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--warning_e2d99e:disabled, .spectrum-Button--warning_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-warning-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-warning-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-warning-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Button--overBackground_e2d99e {
  background-color: var(--spectrum-button-over-background-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-over-background-border-color, var(--spectrum-global-color-static-white));
  color: var(--spectrum-button-over-background-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.is-hovered_e2d99e, .spectrum-Button--overBackground_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-over-background-background-color-hover, var(--spectrum-global-color-static-white));
  border-color: var(--spectrum-button-over-background-border-color-hover, var(--spectrum-global-color-static-white));
  color: inherit;
}

.spectrum-Button--overBackground_e2d99e.focus-ring_e2d99e:after {
  box-shadow: 0 0 0 var(--spectrum-alias-focus-ring-size, var(--spectrum-global-dimension-static-size-25)) var(--spectrum-button-over-background-border-color-key-focus, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-over-background-background-color-down, var(--spectrum-global-color-static-white));
  border-color: var(--spectrum-button-over-background-border-color-down, var(--spectrum-global-color-static-white));
  color: inherit;
}

.spectrum-Button--overBackground_e2d99e:disabled, .spectrum-Button--overBackground_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-over-background-background-color-disabled, #ffffff1a);
  border-color: var(--spectrum-button-over-background-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-over-background-text-color-disabled, #ffffff59);
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e, .spectrum-ClearButton--overBackground_e2d99e {
  background-color: var(--spectrum-button-quiet-over-background-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-over-background-border-color, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-over-background-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e.is-hovered_e2d99e, .spectrum-ClearButton--overBackground_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-quiet-over-background-background-color-hover, #ffffff1a);
  border-color: var(--spectrum-button-quiet-over-background-border-color-hover, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-over-background-text-color-hover, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e.focus-ring_e2d99e, .spectrum-ClearButton--overBackground_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-quiet-over-background-background-color-hover, #ffffff1a);
  border-color: var(--spectrum-button-quiet-over-background-border-color-hover, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-over-background-text-color-hover, var(--spectrum-global-color-static-white));
  box-shadow: none;
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e.focus-ring_e2d99e:after, .spectrum-ClearButton--overBackground_e2d99e.focus-ring_e2d99e:after {
  box-shadow: 0 0 0 var(--spectrum-alias-focus-ring-size, var(--spectrum-global-dimension-static-size-25)) var(--spectrum-button-over-background-border-color-key-focus, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e.is-active_e2d99e, .spectrum-ClearButton--overBackground_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-quiet-over-background-background-color-down, #ffffff26);
  border-color: var(--spectrum-button-quiet-over-background-border-color-down, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-over-background-text-color-down, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e:disabled, .spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e.is-disabled_e2d99e, .spectrum-ClearButton--overBackground_e2d99e:disabled, .spectrum-ClearButton--overBackground_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-quiet-over-background-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-over-background-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-over-background-text-color-disabled, #ffffff26);
}

.spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e {
  background-color: var(--spectrum-button-quiet-primary-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-primary-border-color, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-primary-text-color, var(--spectrum-global-color-gray-800));
}

.spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-quiet-primary-background-color-hover, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-primary-border-color-hover, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-primary-text-color-hover, var(--spectrum-global-color-gray-900));
}

.spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-quiet-primary-background-color-key-focus, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-primary-border-color-key-focus, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-primary-text-color-key-focus, var(--spectrum-global-color-gray-900));
}

.spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-quiet-primary-background-color-down, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-button-quiet-primary-border-color-down, var(--spectrum-global-color-gray-300));
  color: var(--spectrum-button-quiet-primary-text-color-down, var(--spectrum-global-color-gray-900));
}

.spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e:disabled, .spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-quiet-primary-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-primary-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-primary-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e {
  background-color: var(--spectrum-button-quiet-secondary-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-secondary-border-color, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-secondary-text-color, var(--spectrum-global-color-gray-700));
}

.spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-quiet-secondary-background-color-hover, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-secondary-border-color-hover, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-secondary-text-color-hover, var(--spectrum-global-color-gray-800));
}

.spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-quiet-secondary-background-color-key-focus, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-secondary-border-color-key-focus, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-secondary-text-color-key-focus, var(--spectrum-global-color-gray-800));
}

.spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-quiet-secondary-background-color-down, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-button-quiet-secondary-border-color-down, var(--spectrum-global-color-gray-300));
  color: var(--spectrum-button-quiet-secondary-text-color-down, var(--spectrum-global-color-gray-800));
}

.spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e:disabled, .spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-quiet-secondary-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-secondary-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-secondary-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-ActionButton_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color, var(--spectrum-global-color-gray-75));
  border-color: var(--spectrum-actionbutton-border-color, var(--spectrum-alias-border-color));
  color: var(--spectrum-actionbutton-text-color, var(--spectrum-alias-text-color));
}

.spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ActionButton_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-hold-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ActionButton_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-hover, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-actionbutton-border-color-hover, var(--spectrum-alias-border-color-hover));
  color: var(--spectrum-actionbutton-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton_e2d99e.is-hovered_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-hold-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-key-focus, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-actionbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 var(--spectrum-button-primary-border-size-increase-key-focus, 1px) var(--spectrum-actionbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-actionbutton-text-color-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  border-color: var(--spectrum-actionbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-ActionButton_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.spectrum-ActionButton_e2d99e.focus-ring_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-hold-icon-color-key-focus, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-down, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-down, var(--spectrum-alias-border-color-down));
  color: var(--spectrum-actionbutton-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-ActionButton_e2d99e.is-active_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-hold-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-ActionButton_e2d99e:disabled, .spectrum-ActionButton_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-disabled, var(--spectrum-alias-border-color-disabled));
  color: var(--spectrum-actionbutton-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton_e2d99e:disabled .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton_e2d99e.is-disabled_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-hold-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-selected, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-selected, var(--spectrum-alias-border-color));
  color: var(--spectrum-actionbutton-text-color-selected, var(--spectrum-alias-text-color));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-selected, var(--spectrum-alias-icon-color));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-selected-hover, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-selected-hover, var(--spectrum-alias-border-color-hover));
  color: var(--spectrum-actionbutton-text-color-selected-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-selected-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-selected-key-focus, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-selected-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-actionbutton-text-color-selected-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  border-color: var(--spectrum-actionbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-selected-key-focus, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-selected-down, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-selected-down, var(--spectrum-alias-border-color-down));
  color: var(--spectrum-actionbutton-text-color-selected-down, var(--spectrum-alias-text-color-down));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-selected-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-selected-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled));
  color: var(--spectrum-actionbutton-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-selected-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton--emphasized_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color, var(--spectrum-global-color-gray-75));
  border-color: var(--spectrum-actionbutton-emphasized-border-color, var(--spectrum-alias-border-color));
  color: var(--spectrum-actionbutton-emphasized-text-color, var(--spectrum-alias-text-color));
}

.spectrum-ActionButton--emphasized_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ActionButton--emphasized_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color-selected, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-text-color-selected-hover, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-hover, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-hover, var(--spectrum-alias-border-color-hover));
  box-shadow: none;
  color: var(--spectrum-actionbutton-emphasized-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--emphasized_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton--emphasized_e2d99e.is-hovered_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-key-focus, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-actionbutton-emphasized-border-color, var(--spectrum-alias-border-color));
  box-shadow: none;
  color: var(--spectrum-actionbutton-emphasized-text-color-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  border-color: var(--spectrum-actionbutton-emphasized-border-color-down, var(--spectrum-alias-border-color-down));
}

.spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color-key-focus, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton--emphasized_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-down, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-down, var(--spectrum-alias-border-color-down));
  color: var(--spectrum-actionbutton-emphasized-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-ActionButton--emphasized_e2d99e.is-active_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-ActionButton--emphasized_e2d99e:disabled, .spectrum-ActionButton--emphasized_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-disabled, var(--spectrum-alias-border-color-disabled));
  color: var(--spectrum-actionbutton-emphasized-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton--emphasized_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton--emphasized_e2d99e:disabled .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-disabled_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.focus-ring_e2d99e {
  border-color: var(--spectrum-actionbutton-quiet-border-color, var(--spectrum-alias-border-color-transparent));
  box-shadow: none;
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-selected, var(--spectrum-semantic-cta-color-background-default));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected, var(--spectrum-semantic-cta-color-background-default));
  color: var(--spectrum-actionbutton-emphasized-text-color-selected, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-selected, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-selected-key-focus, var(--spectrum-semantic-cta-color-background-key-focus));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected, var(--spectrum-semantic-cta-color-background-default));
  box-shadow: none;
  color: var(--spectrum-actionbutton-emphasized-text-color-selected-key-focus, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e.is-active_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected-down, var(--spectrum-semantic-cta-color-background-down));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-selected-key-focus, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-hovered_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-selected-hover, var(--spectrum-semantic-cta-color-background-hover));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected-hover, var(--spectrum-semantic-cta-color-background-hover));
  color: var(--spectrum-actionbutton-emphasized-text-color-selected-hover, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-selected-hover, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-active_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-selected-down, var(--spectrum-semantic-cta-color-background-down));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected-down, var(--spectrum-semantic-cta-color-background-down));
  color: var(--spectrum-actionbutton-emphasized-text-color-selected-down, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-selected-down, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-selected-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled));
  color: var(--spectrum-actionbutton-emphasized-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-selected-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton--quiet_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-actionbutton-quiet-border-color, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-actionbutton-quiet-text-color, var(--spectrum-alias-text-color));
}

.spectrum-ActionButton--quiet_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-hover, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-actionbutton-quiet-border-color-hover, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-actionbutton-quiet-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--quiet_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
  box-shadow: 0 0 0 1px var(--spectrum-actionbutton-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  border-color: var(--spectrum-actionbutton-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-actionbutton-quiet-text-color-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--quiet_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-down, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-actionbutton-quiet-border-color-down, var(--spectrum-global-color-gray-300));
  color: var(--spectrum-actionbutton-quiet-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-ActionButton--quiet_e2d99e:disabled, .spectrum-ActionButton--quiet_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-actionbutton-quiet-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-actionbutton-quiet-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-selected, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-actionbutton-quiet-border-color-selected, var(--spectrum-alias-border-color));
  color: var(--spectrum-actionbutton-quiet-text-color-selected, var(--spectrum-alias-text-color));
}

.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-selected-hover, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-actionbutton-quiet-border-color-selected-hover, var(--spectrum-alias-border-color-hover));
  color: var(--spectrum-actionbutton-quiet-text-color-selected-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-selected-key-focus, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-actionbutton-quiet-border-color-selected-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-actionbutton-quiet-text-color-selected-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-selected-down, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-actionbutton-quiet-border-color-selected-down, var(--spectrum-alias-border-color-down));
  color: var(--spectrum-actionbutton-quiet-text-color-selected-down, var(--spectrum-alias-text-color-down));
}

.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-selected-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-quiet-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled));
  color: var(--spectrum-actionbutton-quiet-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton--staticWhite_e2d99e {
  mix-blend-mode: screen;
  --spectrum-actionbutton-static-background-color-hover: #ffffff1a;
  --spectrum-actionbutton-static-background-color-focus: #ffffff1a;
  --spectrum-actionbutton-static-background-color-active: #fff3;
  --spectrum-actionbutton-static-border-color: #ffffff4d;
  --spectrum-actionbutton-static-border-color-hover: white;
  --spectrum-actionbutton-static-border-color-active: white;
  --spectrum-actionbutton-static-border-color-focus: white;
  --spectrum-actionbutton-static-border-disabled: #ffffff26;
  --spectrum-actionbutton-static-color: white;
  --spectrum-actionbutton-static-color-selected: black;
  --spectrum-actionbutton-static-color-disabled: #ffffff26;
}

.spectrum-ActionButton--staticWhite_e2d99e.spectrum-ActionButton--quiet_e2d99e {
  --spectrum-actionbutton-static-border-color: transparent;
  --spectrum-actionbutton-static-border-color-hover: transparent;
  --spectrum-actionbutton-static-border-color-active: transparent;
}

.spectrum-ActionButton--staticBlack_e2d99e {
  mix-blend-mode: multiply;
  --spectrum-actionbutton-static-background-color-hover: #0000001a;
  --spectrum-actionbutton-static-background-color-focus: #0000001a;
  --spectrum-actionbutton-static-background-color-active: #0003;
  --spectrum-actionbutton-static-border-color: #0000004d;
  --spectrum-actionbutton-static-border-color-hover: black;
  --spectrum-actionbutton-static-border-color-active: black;
  --spectrum-actionbutton-static-border-color-focus: black;
  --spectrum-actionbutton-static-border-disabled: #00000026;
  --spectrum-actionbutton-static-color: black;
  --spectrum-actionbutton-static-color-selected: white;
  --spectrum-actionbutton-static-color-disabled: #00000026;
}

.spectrum-ActionButton--staticBlack_e2d99e.spectrum-ActionButton--quiet_e2d99e {
  --spectrum-actionbutton-static-border-color: transparent;
  --spectrum-actionbutton-static-border-color-hover: transparent;
  --spectrum-actionbutton-static-border-color-active: transparent;
}

.spectrum-ActionButton--staticColor_e2d99e {
  border-color: var(--spectrum-actionbutton-static-border-color);
  color: var(--spectrum-actionbutton-static-color);
  background-color: #0000;
}

.spectrum-ActionButton--staticColor_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-static-background-color-hover);
  border-color: var(--spectrum-actionbutton-static-border-color-hover);
  color: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-hovered_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-static-background-color-focus);
  border-color: var(--spectrum-actionbutton-static-border-color-focus);
  box-shadow: none;
  color: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e.is-hovered_e2d99e {
  border-color: var(--spectrum-actionbutton-static-border-color-focus);
}

.spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  border-color: var(--spectrum-actionbutton-static-color-focus);
}

.spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e:after {
  box-shadow: 0 0 0 var(--spectrum-button-primary-focus-ring-size-key-focus, var(--spectrum-alias-focus-ring-size)) var(--spectrum-actionbutton-static-border-color-focus);
}

.spectrum-ActionButton--staticColor_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-static-background-color-active);
  border-color: var(--spectrum-actionbutton-static-border-color-active);
  color: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.is-active_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e:disabled, .spectrum-ActionButton--staticColor_e2d99e.is-disabled_e2d99e {
  border-color: var(--spectrum-actionbutton-static-border-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
  background-color: #0000;
}

.spectrum-ActionButton--staticColor_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e:disabled .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-disabled_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-actionbutton-static-color);
  border-color: var(--spectrum-actionbutton-static-color);
  color: var(--spectrum-actionbutton-static-color-selected);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-static-color);
  border-color: var(--spectrum-actionbutton-static-color);
  color: var(--spectrum-actionbutton-static-color-selected);
  box-shadow: none;
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e.is-active_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  box-shadow: none;
  border-color: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-hovered_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-active_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-hovered_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-static-color);
  border-color: var(--spectrum-actionbutton-static-color);
  color: var(--spectrum-actionbutton-static-color-selected);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-disabled_e2d99e {
  border-color: var(--spectrum-actionbutton-static-border-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
  background-color: #0000;
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e:disabled .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

.spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e {
  background-color: var(--spectrum-button-quiet-warning-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-warning-border-color, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-warning-text-color, var(--spectrum-semantic-negative-color-text-small));
}

.spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-quiet-warning-background-color-hover, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-warning-border-color-hover, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-warning-text-color-hover, var(--spectrum-global-color-red-700));
}

.spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-quiet-warning-background-color-key-focus, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-warning-border-color-key-focus, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-warning-text-color-key-focus, var(--spectrum-global-color-red-700));
}

.spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-quiet-warning-background-color-down, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-button-quiet-warning-border-color-down, var(--spectrum-global-color-gray-300));
  color: var(--spectrum-button-quiet-warning-text-color-down, var(--spectrum-global-color-red-700));
}

.spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e:disabled, .spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-quiet-warning-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-warning-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-warning-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-LogicButton--and_e2d99e {
  background-color: var(--spectrum-logicbutton-and-background-color, var(--spectrum-global-color-blue-500));
  border-color: var(--spectrum-logicbutton-and-border-color, var(--spectrum-global-color-blue-500));
  color: var(--spectrum-logicbutton-and-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-LogicButton--and_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-logicbutton-and-background-color-hover, var(--spectrum-global-color-blue-700));
  border-color: var(--spectrum-logicbutton-and-border-color-hover, var(--spectrum-global-color-blue-700));
  color: var(--spectrum-logicbutton-and-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-LogicButton--and_e2d99e:disabled, .spectrum-LogicButton--and_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-logicbutton-and-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-logicbutton-and-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-logicbutton-and-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-LogicButton--or_e2d99e {
  background-color: var(--spectrum-logicbutton-or-background-color, var(--spectrum-global-color-magenta-500));
  border-color: var(--spectrum-logicbutton-or-border-color, var(--spectrum-global-color-magenta-500));
  color: var(--spectrum-logicbutton-or-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-LogicButton--or_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-logicbutton-or-background-color-hover, var(--spectrum-global-color-magenta-700));
  border-color: var(--spectrum-logicbutton-or-border-color-hover, var(--spectrum-global-color-magenta-700));
  color: var(--spectrum-logicbutton-or-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-LogicButton--or_e2d99e:disabled, .spectrum-LogicButton--or_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-secondary-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-logicbutton-and-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldButton_e2d99e {
  color: var(--spectrum-fieldbutton-text-color, var(--spectrum-alias-text-color));
  background-color: var(--spectrum-fieldbutton-background-color, var(--spectrum-global-color-gray-75));
  border-color: var(--spectrum-fieldbutton-border-color, var(--spectrum-alias-border-color));
}

.spectrum-FieldButton_e2d99e.is-hovered_e2d99e {
  color: var(--spectrum-fieldbutton-text-color-hover, var(--spectrum-alias-text-color-hover));
  background-color: var(--spectrum-fieldbutton-background-color-hover, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-fieldbutton-border-color-hover, var(--spectrum-alias-border-color-hover));
}

.spectrum-FieldButton_e2d99e.is-active_e2d99e, .spectrum-FieldButton_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-fieldbutton-background-color-down, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-fieldbutton-border-color-down, var(--spectrum-alias-border-color-down));
}

.spectrum-FieldButton_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton_e2d99e.is-focused_e2d99e {
  background-color: var(--spectrum-fieldbutton-background-color-key-focus, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-fieldbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 var(--spectrum-button-primary-border-size-increase-key-focus, 1px) var(--spectrum-fieldbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-fieldbutton-text-color-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-FieldButton_e2d99e.focus-ring_e2d99e.is-placeholder_e2d99e, .spectrum-FieldButton_e2d99e.is-focused_e2d99e.is-placeholder_e2d99e {
  color: var(--spectrum-fieldbutton-placeholder-text-color-key-focus, var(--spectrum-alias-placeholder-text-color-hover));
}

.spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e {
  border-color: var(--spectrum-fieldbutton-border-color-error, var(--spectrum-global-color-red-500));
}

.spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e.is-hovered_e2d99e {
  border-color: var(--spectrum-fieldbutton-border-color-error-hover, var(--spectrum-global-color-red-600));
}

.spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e.is-active_e2d99e, .spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e.is-selected_e2d99e {
  border-color: var(--spectrum-fieldbutton-border-color-error-down, var(--spectrum-global-color-red-600));
}

.spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e.is-focused_e2d99e {
  border-color: var(--spectrum-fieldbutton-border-color-error-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 var(--spectrum-button-primary-border-size-increase-key-focus, 1px) var(--spectrum-fieldbutton-border-color-error-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-FieldButton_e2d99e:disabled, .spectrum-FieldButton_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-fieldbutton-background-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-fieldbutton-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldButton_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-FieldButton_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-fieldbutton-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-FieldButton--quiet_e2d99e {
  color: var(--spectrum-fieldbutton-text-color, var(--spectrum-alias-text-color));
  border-color: var(--spectrum-fieldbutton-quiet-border-color, var(--spectrum-alias-border-color-transparent));
  background-color: var(--spectrum-fieldbutton-quiet-background-color, var(--spectrum-alias-background-color-transparent));
}

.spectrum-FieldButton--quiet_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-hover, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-fieldbutton-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-FieldButton--quiet_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-focused_e2d99e {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
  box-shadow: 0 2px 0 0 var(--spectrum-fieldbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-FieldButton--quiet_e2d99e.focus-ring_e2d99e.is-placeholder_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-focused_e2d99e.is-placeholder_e2d99e {
  color: var(--spectrum-fieldbutton-quiet-placeholder-text-color-key-focus, var(--spectrum-alias-placeholder-text-color-hover));
}

.spectrum-FieldButton--quiet_e2d99e.is-active_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-down, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-fieldbutton-quiet-border-color-down, var(--spectrum-alias-border-color-transparent));
}

.spectrum-FieldButton--quiet_e2d99e.is-active_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-active_e2d99e.is-focused_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-selected_e2d99e.is-focused_e2d99e {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
  box-shadow: 0 2px 0 0 var(--spectrum-fieldbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-FieldButton--quiet_e2d99e.spectrum-FieldButton--invalid_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton--quiet_e2d99e.spectrum-FieldButton--invalid_e2d99e.is-focused_e2d99e {
  box-shadow: 0 2px 0 0 var(--spectrum-fieldbutton-border-color-error-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-FieldButton--quiet_e2d99e:disabled, .spectrum-FieldButton--quiet_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-fieldbutton-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

@media (forced-colors: active) {
  .spectrum-ActionButton_e2d99e, .spectrum-Button_e2d99e, .spectrum-ClearButton_e2d99e, .spectrum-LogicButton_e2d99e, .spectrum-FieldButton_e2d99e {
    forced-color-adjust: none;
    --spectrum-actionbutton-background-color: ButtonFace;
    --spectrum-actionbutton-background-color-disabled: ButtonFace;
    --spectrum-actionbutton-background-color-down: ButtonFace;
    --spectrum-actionbutton-background-color-hover: ButtonFace;
    --spectrum-actionbutton-background-color-key-focus: ButtonFace;
    --spectrum-actionbutton-background-color-selected: Highlight;
    --spectrum-actionbutton-background-color-selected-disabled: ButtonFace;
    --spectrum-actionbutton-background-color-selected-down: Highlight;
    --spectrum-actionbutton-background-color-selected-hover: Highlight;
    --spectrum-actionbutton-background-color-selected-key-focus: Highlight;
    --spectrum-actionbutton-border-color: ButtonText;
    --spectrum-actionbutton-border-color-disabled: GrayText;
    --spectrum-actionbutton-border-color-down: Highlight;
    --spectrum-actionbutton-border-color-hover: Highlight;
    --spectrum-actionbutton-border-color-key-focus: CanvasText;
    --spectrum-actionbutton-border-color-selected: HighlightText;
    --spectrum-actionbutton-border-color-selected-disabled: GrayText;
    --spectrum-actionbutton-border-color-selected-down: HighlightText;
    --spectrum-actionbutton-border-color-selected-hover: HighlightText;
    --spectrum-actionbutton-border-color-selected-key-focus: CanvasText;
    --spectrum-actionbutton-emphasized-background-color: ButtonFace;
    --spectrum-actionbutton-emphasized-background-color-disabled: ButtonFace;
    --spectrum-actionbutton-emphasized-background-color-down: Highlight;
    --spectrum-actionbutton-emphasized-background-color-hover: Highlight;
    --spectrum-actionbutton-emphasized-background-color-key-focus: ButtonFace;
    --spectrum-actionbutton-emphasized-background-color-selected: Highlight;
    --spectrum-actionbutton-emphasized-background-color-selected-disabled: ButtonFace;
    --spectrum-actionbutton-emphasized-background-color-selected-down: Highlight;
    --spectrum-actionbutton-emphasized-background-color-selected-hover: Highlight;
    --spectrum-actionbutton-emphasized-background-color-selected-key-focus: Highlight;
    --spectrum-actionbutton-emphasized-border-color: ButtonText;
    --spectrum-actionbutton-emphasized-border-color-disabled: GrayText;
    --spectrum-actionbutton-emphasized-border-color-down: ButtonText;
    --spectrum-actionbutton-emphasized-border-color-hover: ButtonText;
    --spectrum-actionbutton-emphasized-border-color-selected: HighlightText;
    --spectrum-actionbutton-emphasized-border-color-selected-disabled: GrayText;
    --spectrum-actionbutton-emphasized-border-color-selected-downed: HighlightText;
    --spectrum-actionbutton-emphasized-border-color-selected-hover: HighlightText;
    --spectrum-actionbutton-emphasized-hold-icon-color: ButtonText;
    --spectrum-actionbutton-emphasized-hold-icon-color-disabled: GrayText;
    --spectrum-actionbutton-emphasized-hold-icon-color-down: HighlightText;
    --spectrum-actionbutton-emphasized-hold-icon-color-hover: HighlightText;
    --spectrum-actionbutton-emphasized-hold-icon-color-key-focus: ButtonText;
    --spectrum-actionbutton-emphasized-hold-icon-color-selected: ButtonFace;
    --spectrum-actionbutton-emphasized-icon-color: ButtonText;
    --spectrum-actionbutton-emphasized-icon-color-disabled: GrayText;
    --spectrum-actionbutton-emphasized-icon-color-hover: HighlightText;
    --spectrum-actionbutton-emphasized-icon-color-key-focus: ButtonText;
    --spectrum-actionbutton-emphasized-icon-color-selected: HighlightText;
    --spectrum-actionbutton-emphasized-icon-color-selected-disabled: GrayText;
    --spectrum-actionbutton-emphasized-icon-color-selected-down: HighlightText;
    --spectrum-actionbutton-emphasized-icon-color-selected-hover: HighlightText;
    --spectrum-actionbutton-emphasized-icon-color-selected-key-focus: HighlightText;
    --spectrum-actionbutton-emphasized-text-color: ButtonText;
    --spectrum-actionbutton-emphasized-text-color-disabled: GrayText;
    --spectrum-actionbutton-emphasized-text-color-down: HighlightText;
    --spectrum-actionbutton-emphasized-text-color-hover: HighlightText;
    --spectrum-actionbutton-emphasized-text-color-key-focus: ButtonText;
    --spectrum-actionbutton-emphasized-text-color-selected: HighlightText;
    --spectrum-actionbutton-emphasized-text-color-selected-disabled: GrayText;
    --spectrum-actionbutton-emphasized-text-color-selected-down: HighlightText;
    --spectrum-actionbutton-emphasized-text-color-selected-hover: HighlightText;
    --spectrum-actionbutton-emphasized-text-color-selected-key-focus: HighlightText;
    --spectrum-actionbutton-hold-icon-color: ButtonText;
    --spectrum-actionbutton-hold-icon-color-disabled: GrayText;
    --spectrum-actionbutton-hold-icon-color-down: ButtonText;
    --spectrum-actionbutton-hold-icon-color-hover: ButtonText;
    --spectrum-actionbutton-hold-icon-color-key-focus: ButtonText;
    --spectrum-actionbutton-icon-color: ButtonText;
    --spectrum-actionbutton-icon-color-disabled: GrayText;
    --spectrum-actionbutton-icon-color-hover: ButtonText;
    --spectrum-actionbutton-icon-color-key-focus: ButtonText;
    --spectrum-actionbutton-icon-color-selected: HighlightText;
    --spectrum-actionbutton-icon-color-selected-disabled: GrayText;
    --spectrum-actionbutton-icon-color-selected-down: HighlightText;
    --spectrum-actionbutton-icon-color-selected-hover: HighlightText;
    --spectrum-actionbutton-icon-color-selected-key-focus: HighlightText;
    --spectrum-actionbutton-quiet-background-color: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-disabled: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-down: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-hover: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-key-focus: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-selected: Highlight;
    --spectrum-actionbutton-quiet-background-color-selected-disabled: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-selected-down: Highlight;
    --spectrum-actionbutton-quiet-background-color-selected-hover: Highlight;
    --spectrum-actionbutton-quiet-background-color-selected-key-focus: Highlight;
    --spectrum-actionbutton-quiet-border-color: ButtonFace;
    --spectrum-actionbutton-quiet-border-color-disabled: ButtonFace;
    --spectrum-actionbutton-quiet-border-color-down: Highlight;
    --spectrum-actionbutton-quiet-border-color-hover: Highlight;
    --spectrum-actionbutton-quiet-border-color-key-focus: CanvasText;
    --spectrum-actionbutton-quiet-border-color-selected: HighlightText;
    --spectrum-actionbutton-quiet-border-color-selected-disabled: Canvas;
    --spectrum-actionbutton-quiet-border-color-selected-down: HighlightText;
    --spectrum-actionbutton-quiet-border-color-selected-hover: HighlightText;
    --spectrum-actionbutton-quiet-border-color-selected-key-focus: HighlightText;
    --spectrum-actionbutton-quiet-text-color: ButtonText;
    --spectrum-actionbutton-quiet-text-color-disabled: GrayText;
    --spectrum-actionbutton-quiet-text-color-down: ButtonText;
    --spectrum-actionbutton-quiet-text-color-hover: ButtonText;
    --spectrum-actionbutton-quiet-text-color-key-focus: ButtonText;
    --spectrum-actionbutton-quiet-text-color-selected: HighlightText;
    --spectrum-actionbutton-quiet-text-color-selected-disabled: GrayText;
    --spectrum-actionbutton-quiet-text-color-selected-down: HighlightText;
    --spectrum-actionbutton-quiet-text-color-selected-hover: HighlightText;
    --spectrum-actionbutton-quiet-text-color-selected-key-focus: HighlightText;
    --spectrum-actionbutton-static-background-color-active: ButtonFace;
    --spectrum-actionbutton-static-background-color-focus: ButtonFace;
    --spectrum-actionbutton-static-background-color-hover: ButtonFace;
    --spectrum-actionbutton-static-border-color: ButtonText;
    --spectrum-actionbutton-static-border-color-active: ButtonText;
    --spectrum-actionbutton-static-border-color-focus: Highlight;
    --spectrum-actionbutton-static-border-color-hover: ButtonText;
    --spectrum-actionbutton-static-color: Highlight;
    --spectrum-actionbutton-static-color-disabled: GrayText;
    --spectrum-actionbutton-static-color-focus: Highlight;
    --spectrum-actionbutton-static-color-selected: ButtonFace;
    --spectrum-actionbutton-text-color: ButtonText;
    --spectrum-actionbutton-text-color-disabled: GrayText;
    --spectrum-actionbutton-text-color-down: ButtonText;
    --spectrum-actionbutton-text-color-hover: ButtonText;
    --spectrum-actionbutton-text-color-key-focus: ButtonText;
    --spectrum-actionbutton-text-color-selected: HighlightText;
    --spectrum-actionbutton-text-color-selected-disabled: GrayText;
    --spectrum-actionbutton-text-color-selected-down: HighlightText;
    --spectrum-actionbutton-text-color-selected-hover: HighlightText;
    --spectrum-actionbutton-text-color-selected-key-focus: HighlightText;
    --spectrum-button-cta-background-color: ButtonText;
    --spectrum-button-cta-background-color-disabled: ButtonFace;
    --spectrum-button-cta-background-color-down: Highlight;
    --spectrum-button-cta-background-color-hover: Highlight;
    --spectrum-button-cta-background-color-key-focus: Highlight;
    --spectrum-button-cta-border-color: ButtonFace;
    --spectrum-button-cta-border-color-disabled: GrayText;
    --spectrum-button-cta-border-color-down: Highlight;
    --spectrum-button-cta-border-color-hover: Highlight;
    --spectrum-button-cta-border-color-key-focus: Highlight;
    --spectrum-button-cta-text-color: ButtonFace;
    --spectrum-button-cta-text-color-disabled: GrayText;
    --spectrum-button-cta-text-color-down: ButtonFace;
    --spectrum-button-cta-text-color-hover: ButtonFace;
    --spectrum-button-cta-text-color-key-focus: ButtonFace;
    --spectrum-button-over-background-background-color: ButtonFace;
    --spectrum-button-over-background-background-color-disabled: ButtonFace;
    --spectrum-button-over-background-background-color-down: ButtonFace;
    --spectrum-button-over-background-background-color-hover: ButtonFace;
    --spectrum-button-over-background-border-color: ButtonText;
    --spectrum-button-over-background-border-color-disabled: GrayText;
    --spectrum-button-over-background-border-color-down: Highlight;
    --spectrum-button-over-background-border-color-hover: Highlight;
    --spectrum-button-over-background-border-color-key-focus: Highlight;
    --spectrum-button-over-background-text-color: ButtonText;
    --spectrum-button-over-background-text-color-disabled: GrayText;
    --spectrum-button-primary-background-color: ButtonFace;
    --spectrum-button-primary-background-color-disabled: ButtonFace;
    --spectrum-button-primary-background-color-down: ButtonFace;
    --spectrum-button-primary-background-color-hover: ButtonFace;
    --spectrum-button-primary-background-color-key-focus: ButtonFace;
    --spectrum-button-primary-border-color: ButtonText;
    --spectrum-button-primary-border-color-disabled: GrayText;
    --spectrum-button-primary-border-color-down: Highlight;
    --spectrum-button-primary-border-color-hover: Highlight;
    --spectrum-button-primary-border-color-key-focus: Highlight;
    --spectrum-button-primary-text-color: ButtonText;
    --spectrum-button-primary-text-color-disabled: GrayText;
    --spectrum-button-primary-text-color-down: ButtonText;
    --spectrum-button-primary-text-color-hover: ButtonText;
    --spectrum-button-primary-text-color-key-focus: ButtonText;
    --spectrum-button-quiet-over-background-background-color: ButtonFace;
    --spectrum-button-quiet-over-background-background-color-disabled: ButtonFace;
    --spectrum-button-quiet-over-background-background-color-down: ButtonFace;
    --spectrum-button-quiet-over-background-background-color-hover: ButtonFace;
    --spectrum-button-quiet-over-background-border-color: ButtonFace;
    --spectrum-button-quiet-over-background-border-color-disabled: ButtonFace;
    --spectrum-button-quiet-over-background-border-color-down: Highlight;
    --spectrum-button-quiet-over-background-border-color-hover: Highlight;
    --spectrum-button-quiet-over-background-text-color: ButtonText;
    --spectrum-button-quiet-over-background-text-color-disabled: GrayText;
    --spectrum-button-quiet-over-background-text-color-down: ButtonText;
    --spectrum-button-quiet-over-background-text-color-hover: ButtonText;
    --spectrum-button-quiet-primary-background-color: ButtonFace;
    --spectrum-button-quiet-primary-background-color-disabled: ButtonFace;
    --spectrum-button-quiet-primary-background-color-down: ButtonFace;
    --spectrum-button-quiet-primary-background-color-hover: ButtonFace;
    --spectrum-button-quiet-primary-background-color-key-focus: ButtonFace;
    --spectrum-button-quiet-primary-border-color: ButtonFace;
    --spectrum-button-quiet-primary-border-color-disabled: ButtonFace;
    --spectrum-button-quiet-primary-border-color-down: Highlight;
    --spectrum-button-quiet-primary-border-color-hover: Highlight;
    --spectrum-button-quiet-primary-border-color-key-focus: Highlight;
    --spectrum-button-quiet-primary-text-color: ButtonText;
    --spectrum-button-quiet-primary-text-color-disabled: GrayText;
    --spectrum-button-quiet-primary-text-color-down: ButtonText;
    --spectrum-button-quiet-primary-text-color-hover: ButtonText;
    --spectrum-button-quiet-primary-text-color-key-focus: ButtonText;
    --spectrum-button-quiet-secondary-background-color: ButtonFace;
    --spectrum-button-quiet-secondary-background-color-disabled: ButtonFace;
    --spectrum-button-quiet-secondary-background-color-down: ButtonFace;
    --spectrum-button-quiet-secondary-background-color-hover: ButtonFace;
    --spectrum-button-quiet-secondary-background-color-key-focus: ButtonFace;
    --spectrum-button-quiet-secondary-border-color: ButtonFace;
    --spectrum-button-quiet-secondary-border-color-disabled: ButtonFace;
    --spectrum-button-quiet-secondary-border-color-down: Highlight;
    --spectrum-button-quiet-secondary-border-color-hover: Highlight;
    --spectrum-button-quiet-secondary-border-color-key-focus: Highlight;
    --spectrum-button-quiet-secondary-text-color: ButtonText;
    --spectrum-button-quiet-secondary-text-color-disabled: GrayText;
    --spectrum-button-quiet-secondary-text-color-down: ButtonText;
    --spectrum-button-quiet-secondary-text-color-hover: ButtonText;
    --spectrum-button-quiet-secondary-text-color-key-focus: ButtonText;
    --spectrum-button-quiet-warning-background-color: ButtonFace;
    --spectrum-button-quiet-warning-background-color-disabled: ButtonFace;
    --spectrum-button-quiet-warning-background-color-down: ButtonFace;
    --spectrum-button-quiet-warning-background-color-hover: ButtonFace;
    --spectrum-button-quiet-warning-background-color-key-focus: ButtonFace;
    --spectrum-button-quiet-warning-border-color: ButtonFace;
    --spectrum-button-quiet-warning-border-color-disabled: ButtonFace;
    --spectrum-button-quiet-warning-border-color-down: Highlight;
    --spectrum-button-quiet-warning-border-color-hover: Highlight;
    --spectrum-button-quiet-warning-border-color-key-focus: Highlight;
    --spectrum-button-quiet-warning-text-color: ButtonText;
    --spectrum-button-quiet-warning-text-color-disabled: GrayText;
    --spectrum-button-quiet-warning-text-color-down: ButtonText;
    --spectrum-button-quiet-warning-text-color-hover: ButtonText;
    --spectrum-button-quiet-warning-text-color-key-focus: ButtonText;
    --spectrum-button-secondary-background-color: ButtonFace;
    --spectrum-button-secondary-background-color-disabled: ButtonFace;
    --spectrum-button-secondary-background-color-down: ButtonFace;
    --spectrum-button-secondary-background-color-hover: ButtonFace;
    --spectrum-button-secondary-background-color-key-focus: ButtonFace;
    --spectrum-button-secondary-border-color: ButtonText;
    --spectrum-button-secondary-border-color-disabled: GrayText;
    --spectrum-button-secondary-border-color-down: Highlight;
    --spectrum-button-secondary-border-color-hover: Highlight;
    --spectrum-button-secondary-border-color-key-focus: Highlight;
    --spectrum-button-secondary-text-color: ButtonText;
    --spectrum-button-secondary-text-color-disabled: GrayText;
    --spectrum-button-secondary-text-color-down: ButtonText;
    --spectrum-button-secondary-text-color-hover: ButtonText;
    --spectrum-button-secondary-text-color-key-focus: ButtonText;
    --spectrum-button-warning-background-color: ButtonFace;
    --spectrum-button-warning-background-color-disabled: ButtonFace;
    --spectrum-button-warning-background-color-down: ButtonFace;
    --spectrum-button-warning-background-color-hover: ButtonFace;
    --spectrum-button-warning-background-color-key-focus: ButtonFace;
    --spectrum-button-warning-border-color: ButtonText;
    --spectrum-button-warning-border-color-disabled: GrayText;
    --spectrum-button-warning-border-color-down: Highlight;
    --spectrum-button-warning-border-color-hover: Highlight;
    --spectrum-button-warning-border-color-key-focus: Highlight;
    --spectrum-button-warning-text-color: ButtonText;
    --spectrum-button-warning-text-color-disabled: GrayText;
    --spectrum-button-warning-text-color-down: ButtonText;
    --spectrum-button-warning-text-color-hover: ButtonText;
    --spectrum-button-warning-text-color-key-focus: ButtonText;
    --spectrum-clearbutton-medium-background-color: ButtonFace;
    --spectrum-clearbutton-medium-background-color-disabled: ButtonFace;
    --spectrum-clearbutton-medium-background-color-down: ButtonFace;
    --spectrum-clearbutton-medium-background-color-hover: ButtonFace;
    --spectrum-clearbutton-medium-background-color-key-focus: ButtonFace;
    --spectrum-clearbutton-medium-icon-color: ButtonText;
    --spectrum-clearbutton-medium-icon-color-disabled: GrayText;
    --spectrum-clearbutton-medium-icon-color-down: Highlight;
    --spectrum-clearbutton-medium-icon-color-hover: Highlight;
    --spectrum-clearbutton-medium-icon-color-key-focus: Highlight;
    --spectrum-fieldbutton-background-color: ButtonFace;
    --spectrum-fieldbutton-background-color-disabled: ButtonFace;
    --spectrum-fieldbutton-background-color-down: ButtonFace;
    --spectrum-fieldbutton-background-color-hover: ButtonFace;
    --spectrum-fieldbutton-background-color-key-focus: ButtonFace;
    --spectrum-fieldbutton-border-color: ButtonText;
    --spectrum-fieldbutton-border-color-down: Highlight;
    --spectrum-fieldbutton-border-color-error: ButtonText;
    --spectrum-fieldbutton-border-color-error-down: Highlight;
    --spectrum-fieldbutton-border-color-error-hover: Highlight;
    --spectrum-fieldbutton-border-color-error-key-focus: Highlight;
    --spectrum-fieldbutton-border-color-hover: Highlight;
    --spectrum-fieldbutton-border-color-key-focus: Highlight;
    --spectrum-fieldbutton-icon-color-disabled: GrayText;
    --spectrum-fieldbutton-placeholder-text-color-key-focus: ButtonText;
    --spectrum-fieldbutton-quiet-background-color: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-disabled: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-down: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-hover: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-key-focus: ButtonFace;
    --spectrum-fieldbutton-quiet-border-color: ButtonFace;
    --spectrum-fieldbutton-quiet-border-color-down: Highlight;
    --spectrum-fieldbutton-quiet-placeholder-text-color-key-focus: ButtonText;
    --spectrum-fieldbutton-text-color: ButtonText;
    --spectrum-fieldbutton-text-color-disabled: GrayText;
    --spectrum-fieldbutton-text-color-hover: ButtonText;
    --spectrum-fieldbutton-text-color-key-focus: ButtonText;
    --spectrum-logicbutton-and-background-color: ButtonFace;
    --spectrum-logicbutton-and-background-color-disabled: ButtonFace;
    --spectrum-logicbutton-and-background-color-hover: ButtonFace;
    --spectrum-logicbutton-and-border-color: ButtonText;
    --spectrum-logicbutton-and-border-color-disabled: GrayText;
    --spectrum-logicbutton-and-border-color-hover: Highlight;
    --spectrum-logicbutton-and-text-color: ButtonText;
    --spectrum-logicbutton-and-text-color-disabled: GrayText;
    --spectrum-logicbutton-or-background-color: ButtonFace;
    --spectrum-logicbutton-or-background-color-hover: ButtonFace;
    --spectrum-logicbutton-or-border-color: ButtonText;
    --spectrum-logicbutton-or-border-color-hover: Highlight;
    --spectrum-logicbutton-or-text-color: ButtonText;
    --spectrum-button-primary-focus-ring-color-key-focus: CanvasText;
    --spectrum-button-primary-focus-ring-size-key-focus: 3px;
    --spectrum-dropdown-border-color-key-focus: Highlight;
  }

  .spectrum-Button--overBackground_e2d99e {
    --spectrum-button-over-background-color: ButtonText;
  }

  .spectrum-ActionButton--staticWhite_e2d99e, .spectrum-ActionButton--staticBlack_e2d99e {
    mix-blend-mode: normal;
    --spectrum-actionbutton-static-background-color-hover: ButtonFace;
    --spectrum-actionbutton-static-background-color-focus: ButtonFace;
    --spectrum-actionbutton-static-background-color-active: ButtonFace;
    --spectrum-actionbutton-static-border-color: ButtonText;
    --spectrum-actionbutton-static-border-color-hover: ButtonText;
    --spectrum-actionbutton-static-border-color-active: ButtonText;
    --spectrum-actionbutton-static-border-color-focus: CanvasText;
    --spectrum-actionbutton-static-border-disabled: GrayText;
    --spectrum-actionbutton-static-color: ButtonText;
    --spectrum-actionbutton-static-color-selected: HighlightText;
    --spectrum-actionbutton-static-color-disabled: GrayText;
  }

  .spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e {
    --spectrum-actionbutton-emphasized-border-color-selected-disabled: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-hover: Highlight;
    --spectrum-actionbutton-quiet-text-color-hover: HighlightText;
    --spectrum-actionbutton-quiet-background-color-down: Highlight;
    --spectrum-actionbutton-quiet-text-color-down: HighlightText;
  }

  .spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-active_e2d99e {
    --spectrum-actionbutton-emphasized-icon-color-key-focus: HighlightText;
  }

  .spectrum-FieldButton_e2d99e.focus-ring_e2d99e:not(.spectrum-FieldButton--quiet_e2d99e), .spectrum-FieldButton_e2d99e.is-focused_e2d99e:not(.spectrum-FieldButton--quiet_e2d99e) {
    outline: 2px solid Highlight;
  }

  .spectrum-FieldButton_e2d99e.focus-ring_e2d99e.spectrum-FieldButton--quiet_e2d99e, .spectrum-FieldButton_e2d99e.is-focused_e2d99e.spectrum-FieldButton--quiet_e2d99e {
    forced-color-adjust: none;
    box-shadow: 0 2px 0 0 var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  }
}

.spectrum_b37d53 {
  background-color: var(--spectrum-alias-background-color-default, var(--spectrum-global-color-gray-100));
  -webkit-tap-highlight-color: #0000;
}

.spectrum_2a241c {
  font-family: adobe-clean-ux, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c:lang(ar) {
  font-family: adobe-arabic, myriad-arabic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c:lang(he) {
  font-family: adobe-hebrew, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c:lang(zh-Hans), .spectrum_2a241c:lang(zh) {
  font-family: adobe-clean-han-simplified-c, SimSun, Heiti SC Light, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c:lang(ko) {
  font-family: adobe-clean-han-korean, Malgun Gothic, Apple Gothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c:lang(ja) {
  font-family: adobe-clean-han-japanese, Yu Gothic, メイリオ, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro W3, Osaka, ＭＳＰゴシック, MS PGothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum_2a241c {
  font-size: var(--spectrum-alias-font-size-default, var(--spectrum-global-dimension-font-size-100));
  color: var(--spectrum-global-color-gray-800);
}

.spectrum_2a241c, .spectrum_2a241c.spectrum-Body_2a241c, .spectrum-Body_2a241c {
  font-size: var(--spectrum-body-4-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-body-4-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  line-height: var(--spectrum-body-4-text-line-height, var(--spectrum-alias-body-text-line-height));
  font-style: var(--spectrum-body-4-text-font-style, var(--spectrum-global-font-style-regular));
}

.spectrum-Body--italic_2a241c {
  font-style: var(--spectrum-body-4-emphasis-text-font-style, var(--spectrum-global-font-style-italic));
}

.spectrum-Icon_368b34, .spectrum-UIIcon_368b34 {
  color: inherit;
  fill: currentColor;
  pointer-events: none;
  display: inline-block;
}

.spectrum-Icon_368b34:not(:root), .spectrum-UIIcon_368b34:not(:root) {
  overflow: hidden;
}

@media (forced-colors: active) {
  .spectrum-Icon_368b34, .spectrum-UIIcon_368b34 {
    forced-color-adjust: auto;
  }
}

.spectrum-Icon--sizeXXS_368b34, .spectrum-Icon--sizeXXS_368b34 img, .spectrum-Icon--sizeXXS_368b34 svg {
  height: calc(var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225)) / 2);
  width: calc(var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225)) / 2);
}

.spectrum-Icon--sizeXS_368b34, .spectrum-Icon--sizeXS_368b34 img, .spectrum-Icon--sizeXS_368b34 svg {
  height: calc(var(--spectrum-global-dimension-size-300) / 2);
  width: calc(var(--spectrum-global-dimension-size-300) / 2);
}

.spectrum-Icon--sizeS_368b34, .spectrum-Icon--sizeS_368b34 img, .spectrum-Icon--sizeS_368b34 svg {
  height: var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225));
  width: var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225));
}

.spectrum-Icon--sizeM_368b34, .spectrum-Icon--sizeM_368b34 img, .spectrum-Icon--sizeM_368b34 svg {
  height: var(--spectrum-global-dimension-size-300);
  width: var(--spectrum-global-dimension-size-300);
}

.spectrum-Icon--sizeL_368b34, .spectrum-Icon--sizeL_368b34 img, .spectrum-Icon--sizeL_368b34 svg {
  height: calc(var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225)) * 2);
  width: calc(var(--spectrum-alias-workflow-icon-size, var(--spectrum-global-dimension-size-225)) * 2);
}

.spectrum-Icon--sizeXL_368b34, .spectrum-Icon--sizeXL_368b34 img, .spectrum-Icon--sizeXL_368b34 svg {
  height: calc(var(--spectrum-global-dimension-size-300) * 2);
  width: calc(var(--spectrum-global-dimension-size-300) * 2);
}

.spectrum-Icon--sizeXXL_368b34, .spectrum-Icon--sizeXXL_368b34 img, .spectrum-Icon--sizeXXL_368b34 svg {
  height: calc(var(--spectrum-global-dimension-size-300) * 3);
  width: calc(var(--spectrum-global-dimension-size-300) * 3);
}

.spectrum--medium_368b34 .spectrum-UIIcon--large_368b34 {
  display: none;
}

.spectrum--medium_368b34 .spectrum-UIIcon--medium_368b34 {
  display: inline;
}

.spectrum--large_368b34 .spectrum-UIIcon--medium_368b34 {
  display: none;
}

.spectrum--large_368b34 .spectrum-UIIcon--large_368b34 {
  display: inline;
}

.spectrum--large_368b34 {
  --ui-icon-large-display: block;
  --ui-icon-medium-display: none;
}

.spectrum--medium_368b34 {
  --ui-icon-medium-display: block;
  --ui-icon-large-display: none;
}

.spectrum-UIIcon--large_368b34 {
  display: var(--ui-icon-large-display);
}

.spectrum-UIIcon--medium_368b34 {
  display: var(--ui-icon-medium-display);
}

.spectrum-UIIcon-AlertMedium_368b34 {
  width: var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225));
  height: var(--spectrum-icon-alert-medium-height, var(--spectrum-global-dimension-size-225));
}

.spectrum-UIIcon-AlertSmall_368b34 {
  width: var(--spectrum-icon-alert-small-width, var(--spectrum-global-dimension-size-175));
  height: var(--spectrum-icon-alert-small-height, var(--spectrum-global-dimension-size-175));
}

.spectrum-UIIcon-ArrowDownSmall_368b34 {
  width: var(--spectrum-icon-arrow-down-small-width, var(--spectrum-global-dimension-size-100));
  height: var(--spectrum-icon-arrow-down-small-height);
}

.spectrum-UIIcon-ArrowLeftMedium_368b34 {
  width: var(--spectrum-icon-arrow-left-medium-width, var(--spectrum-global-dimension-size-175));
  height: var(--spectrum-icon-arrow-left-medium-height);
}

.spectrum-UIIcon-Asterisk_368b34 {
  width: var(--spectrum-fieldlabel-asterisk-size, var(--spectrum-global-dimension-size-100));
  height: var(--spectrum-fieldlabel-asterisk-size, var(--spectrum-global-dimension-size-100));
}

.spectrum-UIIcon-CheckmarkMedium_368b34 {
  width: var(--spectrum-icon-checkmark-medium-width);
  height: var(--spectrum-icon-checkmark-medium-height);
}

.spectrum-UIIcon-CheckmarkSmall_368b34 {
  width: var(--spectrum-icon-checkmark-small-width);
  height: var(--spectrum-icon-checkmark-small-height);
}

.spectrum-UIIcon-ChevronDownMedium_368b34 {
  width: var(--spectrum-icon-chevron-down-medium-width);
  height: var(--spectrum-icon-chevron-down-medium-height, var(--spectrum-global-dimension-size-75));
}

.spectrum-UIIcon-ChevronDownSmall_368b34 {
  width: var(--spectrum-icon-chevron-down-small-width, var(--spectrum-global-dimension-size-100));
  height: var(--spectrum-icon-chevron-down-small-height, var(--spectrum-global-dimension-size-75));
}

.spectrum-UIIcon-ChevronLeftLarge_368b34 {
  width: var(--spectrum-icon-chevron-left-large-width);
  height: var(--spectrum-icon-chevron-left-large-height, var(--spectrum-global-dimension-size-200));
}

.spectrum-UIIcon-ChevronLeftMedium_368b34 {
  width: var(--spectrum-icon-chevron-left-medium-width, var(--spectrum-global-dimension-size-75));
  height: var(--spectrum-icon-chevron-left-medium-height);
}

.spectrum-UIIcon-ChevronRightLarge_368b34 {
  width: var(--spectrum-icon-chevron-right-large-width);
  height: var(--spectrum-icon-chevron-right-large-height, var(--spectrum-global-dimension-size-200));
}

.spectrum-UIIcon-ChevronRightMedium_368b34 {
  width: var(--spectrum-icon-chevron-right-medium-width, var(--spectrum-global-dimension-size-75));
  height: var(--spectrum-icon-chevron-right-medium-height);
}

.spectrum-UIIcon-ChevronRightSmall_368b34 {
  width: var(--spectrum-icon-chevron-right-small-width, var(--spectrum-global-dimension-size-75));
  height: var(--spectrum-icon-chevron-right-small-height, var(--spectrum-global-dimension-size-100));
}

.spectrum-UIIcon-ChevronUpSmall_368b34 {
  width: var(--spectrum-icon-chevron-up-small-width, var(--spectrum-global-dimension-size-100));
  height: var(--spectrum-icon-chevron-up-small-height, var(--spectrum-global-dimension-size-75));
}

.spectrum-UIIcon-CornerTriangle_368b34 {
  width: var(--spectrum-icon-cornertriangle-width, var(--spectrum-global-dimension-size-65));
  height: var(--spectrum-icon-cornertriangle-height, var(--spectrum-global-dimension-size-65));
}

.spectrum-UIIcon-CrossLarge_368b34 {
  width: var(--spectrum-icon-cross-large-width);
  height: var(--spectrum-icon-cross-large-height);
}

.spectrum-UIIcon-CrossMedium_368b34 {
  width: var(--spectrum-icon-cross-medium-width, var(--spectrum-global-dimension-size-100));
  height: var(--spectrum-icon-cross-medium-height, var(--spectrum-global-dimension-size-100));
}

.spectrum-UIIcon-CrossSmall_368b34 {
  width: var(--spectrum-icon-cross-small-width, var(--spectrum-global-dimension-size-100));
  height: var(--spectrum-icon-cross-small-height, var(--spectrum-global-dimension-size-100));
}

.spectrum-UIIcon-DashSmall_368b34 {
  width: var(--spectrum-icon-dash-small-width);
  height: var(--spectrum-icon-dash-small-height);
}

.spectrum-UIIcon-DoubleGripper_368b34 {
  width: var(--spectrum-icon-doublegripper-width, var(--spectrum-global-dimension-size-200));
  height: var(--spectrum-icon-doublegripper-height, var(--spectrum-global-dimension-size-50));
}

.spectrum-UIIcon-FolderBreadcrumb_368b34 {
  width: var(--spectrum-icon-folderbreadcrumb-width, var(--spectrum-global-dimension-size-225));
  height: var(--spectrum-icon-folderbreadcrumb-height, var(--spectrum-global-dimension-size-225));
}

.spectrum-UIIcon-HelpMedium_368b34 {
  width: var(--spectrum-icon-info-medium-width, var(--spectrum-global-dimension-size-225));
  height: var(--spectrum-icon-info-medium-height, var(--spectrum-global-dimension-size-225));
}

.spectrum-UIIcon-HelpSmall_368b34 {
  width: var(--spectrum-icon-info-small-width, var(--spectrum-global-dimension-size-175));
  height: var(--spectrum-icon-info-small-height, var(--spectrum-global-dimension-size-175));
}

.spectrum-UIIcon-InfoMedium_368b34 {
  width: var(--spectrum-icon-info-medium-width, var(--spectrum-global-dimension-size-225));
  height: var(--spectrum-icon-info-medium-height, var(--spectrum-global-dimension-size-225));
}

.spectrum-UIIcon-InfoSmall_368b34 {
  width: var(--spectrum-icon-info-small-width, var(--spectrum-global-dimension-size-175));
  height: var(--spectrum-icon-info-small-height, var(--spectrum-global-dimension-size-175));
}

.spectrum-UIIcon-ListGripper_368b34 {
  width: var(--spectrum-global-dimension-size-75);
  height: var(--spectrum-global-dimension-size-150);
}

.spectrum-UIIcon-Magnifier_368b34 {
  width: var(--spectrum-icon-magnifier-width, var(--spectrum-global-dimension-size-200));
  height: var(--spectrum-icon-magnifier-height, var(--spectrum-global-dimension-size-200));
}

.spectrum-UIIcon-SkipLeft_368b34 {
  width: var(--spectrum-icon-skip-left-width);
  height: var(--spectrum-icon-skip-left-height);
}

.spectrum-UIIcon-SkipRight_368b34 {
  width: var(--spectrum-icon-skip-right-width);
  height: var(--spectrum-icon-skip-right-height);
}

.spectrum-UIIcon-Star_368b34 {
  width: var(--spectrum-icon-star-width, var(--spectrum-global-dimension-size-225));
  height: var(--spectrum-icon-star-height, var(--spectrum-global-dimension-size-225));
}

.spectrum-UIIcon-StarOutline_368b34 {
  width: var(--spectrum-icon-star-outline-width, var(--spectrum-global-dimension-size-225));
  height: var(--spectrum-icon-star-outline-height, var(--spectrum-global-dimension-size-225));
}

.spectrum-UIIcon-SuccessMedium_368b34 {
  width: var(--spectrum-icon-success-medium-width, var(--spectrum-global-dimension-size-225));
  height: var(--spectrum-icon-success-medium-height, var(--spectrum-global-dimension-size-225));
}

.spectrum-UIIcon-SuccessSmall_368b34 {
  width: var(--spectrum-icon-success-small-width, var(--spectrum-global-dimension-size-175));
  height: var(--spectrum-icon-success-small-height, var(--spectrum-global-dimension-size-175));
}

.spectrum-UIIcon-TripleGripper_368b34 {
  width: var(--spectrum-icon-triplegripper-width);
  height: var(--spectrum-icon-triplegripper-height, var(--spectrum-global-dimension-size-85));
}

[dir="ltr"] .no-results_743018 {
  padding-left: var(--spectrum-selectlist-option-padding, var(--spectrum-global-dimension-static-size-150));
}

[dir="rtl"] .no-results_743018 {
  padding-right: var(--spectrum-selectlist-option-padding, var(--spectrum-global-dimension-static-size-150));
}

.no-results_743018 {
  padding-top: var(--spectrum-selectlist-option-padding-height);
  font-size: var(--spectrum-selectlist-option-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-selectlist-option-text-font-weight, var(--spectrum-global-font-weight-regular));
  font-style: italic;
  display: block;
}

.mobile-combobox_743018 {
  outline: 0;
}

.mobile-input_743018 {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mobile-value_743018 {
  vertical-align: middle;
}

.tray-dialog_743018 {
  height: 100%;
  outline: 0;
  flex-direction: column;
  display: flex;
}

.tray-textfield_743018 {
  margin: var(--spectrum-global-dimension-size-150);
  margin-bottom: var(--spectrum-global-dimension-size-50);
  flex-shrink: 0;
  width: initial !important;
}

.tray-textfield_743018.has-label_743018 {
  margin-top: var(--spectrum-global-dimension-size-50);
}

[dir="ltr"] .tray-textfield_743018 .tray-textfield-input_743018 {
  padding-left: var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .tray-textfield_743018 .tray-textfield-input_743018 {
  padding-right: var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150));
}

.tray-listbox_743018 {
  width: 100%;
  flex: 1;
}

.spectrum-Button_e2d99e, .spectrum-ActionButton_e2d99e, .spectrum-LogicButton_e2d99e, .spectrum-FieldButton_e2d99e, .spectrum-ClearButton_e2d99e {
  font-family: adobe-clean-ux, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e:lang(ar), .spectrum-ActionButton_e2d99e:lang(ar), .spectrum-LogicButton_e2d99e:lang(ar), .spectrum-FieldButton_e2d99e:lang(ar), .spectrum-ClearButton_e2d99e:lang(ar) {
  font-family: adobe-arabic, myriad-arabic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e:lang(he), .spectrum-ActionButton_e2d99e:lang(he), .spectrum-LogicButton_e2d99e:lang(he), .spectrum-FieldButton_e2d99e:lang(he), .spectrum-ClearButton_e2d99e:lang(he) {
  font-family: adobe-hebrew, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e:lang(zh-Hans), .spectrum-ActionButton_e2d99e:lang(zh-Hans), .spectrum-LogicButton_e2d99e:lang(zh-Hans), .spectrum-FieldButton_e2d99e:lang(zh-Hans), .spectrum-ClearButton_e2d99e:lang(zh-Hans), .spectrum-Button_e2d99e:lang(zh), .spectrum-ActionButton_e2d99e:lang(zh), .spectrum-LogicButton_e2d99e:lang(zh), .spectrum-FieldButton_e2d99e:lang(zh), .spectrum-ClearButton_e2d99e:lang(zh) {
  font-family: adobe-clean-han-simplified-c, SimSun, Heiti SC Light, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e:lang(ko), .spectrum-ActionButton_e2d99e:lang(ko), .spectrum-LogicButton_e2d99e:lang(ko), .spectrum-FieldButton_e2d99e:lang(ko), .spectrum-ClearButton_e2d99e:lang(ko) {
  font-family: adobe-clean-han-korean, Malgun Gothic, Apple Gothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e:lang(ja), .spectrum-ActionButton_e2d99e:lang(ja), .spectrum-LogicButton_e2d99e:lang(ja), .spectrum-FieldButton_e2d99e:lang(ja), .spectrum-ClearButton_e2d99e:lang(ja) {
  font-family: adobe-clean-han-japanese, Yu Gothic, メイリオ, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro W3, Osaka, ＭＳＰゴシック, MS PGothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_e2d99e, .spectrum-ActionButton_e2d99e, .spectrum-LogicButton_e2d99e, .spectrum-FieldButton_e2d99e, .spectrum-ClearButton_e2d99e {
  box-sizing: border-box;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  transition: background var(--spectrum-global-animation-duration-100, 130ms) ease-out, border-color var(--spectrum-global-animation-duration-100, 130ms) ease-out, color var(--spectrum-global-animation-duration-100, 130ms) ease-out, box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  cursor: default;
  isolation: isolate;
  border-style: solid;
  justify-content: center;
  align-items: center;
  margin: 0;
  line-height: 1.3;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: visible;
}

button.spectrum-Button_e2d99e, button.spectrum-ActionButton_e2d99e, button.spectrum-LogicButton_e2d99e, button.spectrum-FieldButton_e2d99e, button.spectrum-ClearButton_e2d99e {
  -webkit-appearance: button;
}

.spectrum-Button_e2d99e:focus, .spectrum-ActionButton_e2d99e:focus, .spectrum-LogicButton_e2d99e:focus, .spectrum-FieldButton_e2d99e:focus, .spectrum-ClearButton_e2d99e:focus {
  outline: 0;
}

.spectrum-Button_e2d99e.focus-ring_e2d99e, .spectrum-ActionButton_e2d99e.focus-ring_e2d99e, .spectrum-LogicButton_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton_e2d99e.focus-ring_e2d99e, .spectrum-ClearButton_e2d99e.focus-ring_e2d99e {
  z-index: 3;
}

.spectrum-Button_e2d99e::-moz-focus-inner, .spectrum-ActionButton_e2d99e::-moz-focus-inner, .spectrum-LogicButton_e2d99e::-moz-focus-inner, .spectrum-FieldButton_e2d99e::-moz-focus-inner, .spectrum-ClearButton_e2d99e::-moz-focus-inner {
  border: 0;
  margin-top: -2px;
  margin-bottom: -2px;
  padding: 0;
}

.spectrum-Button_e2d99e:disabled, .spectrum-ActionButton_e2d99e:disabled, .spectrum-LogicButton_e2d99e:disabled, .spectrum-FieldButton_e2d99e:disabled, .spectrum-ClearButton_e2d99e:disabled {
  cursor: default;
}

.spectrum-Button_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e, .spectrum-LogicButton_e2d99e .spectrum-Icon_e2d99e, .spectrum-FieldButton_e2d99e .spectrum-Icon_e2d99e, .spectrum-ClearButton_e2d99e .spectrum-Icon_e2d99e {
  max-height: 100%;
  transition: background var(--spectrum-global-animation-duration-100, 130ms) ease-out, fill var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  box-sizing: initial;
  flex-shrink: 0;
  order: 0;
}

.spectrum-Button_e2d99e:after, .spectrum-ActionButton--emphasized_e2d99e:after, .spectrum-ActionButton--staticColor_e2d99e:after, .spectrum-LogicButton_e2d99e:after, .spectrum-ClearButton_e2d99e:after {
  border-radius: calc(var(--spectrum-button-primary-border-radius, var(--spectrum-alias-border-radius-large))  + var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)));
  content: "";
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -1);
  transition: box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-out, margin var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  display: block;
  position: absolute;
  inset: 0;
  transform: translate(0);
}

.spectrum-Button_e2d99e.focus-ring_e2d99e:after, .spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e:after, .spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e:after, .spectrum-LogicButton_e2d99e.focus-ring_e2d99e:after, .spectrum-ClearButton_e2d99e.focus-ring_e2d99e:after {
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -2);
}

.spectrum-Button_e2d99e {
  border-width: var(--spectrum-button-primary-border-size, var(--spectrum-alias-border-size-thick));
  border-radius: var(--spectrum-button-primary-border-radius, var(--spectrum-alias-border-radius-large));
  min-height: var(--spectrum-button-primary-height, var(--spectrum-alias-single-line-height));
  height: 0%;
  min-width: var(--spectrum-button-primary-min-width);
  padding: var(--spectrum-global-dimension-size-50) calc(var(--spectrum-button-primary-padding-x, var(--spectrum-global-dimension-size-200))  - var(--spectrum-button-primary-border-size, var(--spectrum-alias-border-size-thick)));
  padding-bottom: calc(var(--spectrum-global-dimension-size-50)  + 1px);
  padding-top: calc(var(--spectrum-global-dimension-size-50)  - 1px);
  font-size: var(--spectrum-button-primary-text-size, var(--spectrum-alias-pill-button-text-size));
  font-weight: var(--spectrum-button-primary-text-font-weight, var(--spectrum-global-font-weight-bold));
  border-style: solid;
}

.spectrum-Button_e2d99e.is-hovered_e2d99e, .spectrum-Button_e2d99e:active {
  box-shadow: none;
}

[dir="ltr"] .spectrum-Button_e2d99e .spectrum-Icon_e2d99e + .spectrum-Button-label_e2d99e {
  margin-left: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-Button_e2d99e .spectrum-Icon_e2d99e + .spectrum-Button-label_e2d99e, [dir="ltr"] .spectrum-Button_e2d99e .spectrum-Button-label_e2d99e + .spectrum-Icon_e2d99e {
  margin-right: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-Button_e2d99e .spectrum-Button-label_e2d99e + .spectrum-Icon_e2d99e {
  margin-left: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

a.spectrum-Button_e2d99e, a.spectrum-ActionButton_e2d99e {
  -webkit-appearance: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.spectrum-ActionButton_e2d99e {
  height: var(--spectrum-actionbutton-height, var(--spectrum-alias-single-line-height));
  min-width: var(--spectrum-actionbutton-min-width, var(--spectrum-global-dimension-size-400));
  border-width: var(--spectrum-actionbutton-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-actionbutton-border-radius, var(--spectrum-alias-border-radius-regular));
  font-size: var(--spectrum-actionbutton-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-actionbutton-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  padding: 0;
  position: relative;
}

[dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e {
  padding-right: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e {
  padding-left: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e + .spectrum-ActionButton-label_e2d99e {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e + .spectrum-ActionButton-label_e2d99e, [dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e:not([hidden]) + .spectrum-Icon_e2d99e {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e:not([hidden]) + .spectrum-Icon_e2d99e {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e:only-child, [dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-hold_e2d99e + .spectrum-ActionButton-label_e2d99e:last-child {
  padding-left: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-label_e2d99e:only-child, [dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-hold_e2d99e + .spectrum-ActionButton-label_e2d99e:last-child {
  padding-right: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e:only-child, [dir="ltr"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-hold_e2d99e + .spectrum-Icon_e2d99e:last-child {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e:only-child, [dir="rtl"] .spectrum-ActionButton_e2d99e .spectrum-ActionButton-hold_e2d99e + .spectrum-Icon_e2d99e:last-child {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="ltr"] .spectrum-ActionButton-hold_e2d99e {
  right: var(--spectrum-actionbutton-hold-icon-padding-right, var(--spectrum-global-dimension-size-40));
}

[dir="rtl"] .spectrum-ActionButton-hold_e2d99e {
  left: var(--spectrum-actionbutton-hold-icon-padding-right, var(--spectrum-global-dimension-size-40));
}

.spectrum-ActionButton-hold_e2d99e {
  bottom: var(--spectrum-actionbutton-hold-icon-padding-bottom, var(--spectrum-global-dimension-size-40));
  position: absolute;
}

[dir="rtl"] .spectrum-ActionButton-hold_e2d99e {
  transform: rotate(90deg);
}

.spectrum-ActionButton-label_e2d99e, .spectrum-Button-label_e2d99e {
  -ms-grid-row-align: center;
  text-align: center;
  width: 100%;
  order: 1;
  place-self: center;
}

.spectrum-ActionButton-label_e2d99e:empty, .spectrum-Button-label_e2d99e:empty {
  display: none;
}

.spectrum-ActionButton-label_e2d99e {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spectrum-ActionButton--quiet_e2d99e {
  border-width: var(--spectrum-actionbutton-quiet-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-actionbutton-quiet-border-radius, var(--spectrum-alias-border-radius-regular));
  font-size: var(--spectrum-actionbutton-quiet-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-actionbutton-quiet-text-font-weight, var(--spectrum-alias-body-text-font-weight));
}

.spectrum-ActionButton--emphasized_e2d99e:after, .spectrum-ActionButton--staticColor_e2d99e:after {
  border-radius: calc(var(--spectrum-actionbutton-border-radius, var(--spectrum-alias-border-radius-regular))  + var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)));
}

.spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e:after, .spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e:after {
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -1 - var(--spectrum-actionbutton-quiet-border-size, var(--spectrum-alias-border-size-thin)));
}

.spectrum-LogicButton_e2d99e {
  height: var(--spectrum-logicbutton-and-height, 24px);
  padding: var(--spectrum-logicbutton-and-padding-x, var(--spectrum-global-dimension-size-100));
  border-width: var(--spectrum-logicbutton-and-border-size, var(--spectrum-alias-border-size-thick));
  border-radius: var(--spectrum-logicbutton-and-border-radius, var(--spectrum-alias-border-radius-regular));
  font-size: var(--spectrum-logicbutton-and-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-logicbutton-and-text-font-weight, var(--spectrum-global-font-weight-bold));
  line-height: 0;
}

.spectrum-LogicButton_e2d99e:after {
  border-radius: calc(var(--spectrum-logicbutton-and-border-radius, var(--spectrum-alias-border-radius-regular))  + var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)));
}

.spectrum-FieldButton_e2d99e {
  height: var(--spectrum-dropdown-height, var(--spectrum-global-dimension-size-400));
  padding: 0 var(--spectrum-dropdown-padding-x, var(--spectrum-global-dimension-size-150));
  font-family: inherit;
  font-weight: 400;
  font-size: var(--spectrum-dropdown-text-size, var(--spectrum-alias-font-size-default));
  -webkit-font-smoothing: initial;
  cursor: default;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: var(--spectrum-dropdown-padding-x, var(--spectrum-global-dimension-size-150));
  padding-right: var(--spectrum-dropdown-padding-x, var(--spectrum-global-dimension-size-150));
  border-width: var(--spectrum-dropdown-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-global-dimension-size-50);
  transition: background-color var(--spectrum-global-animation-duration-100, 130ms), box-shadow var(--spectrum-global-animation-duration-100, 130ms), border-color var(--spectrum-global-animation-duration-100, 130ms);
  border-style: solid;
  outline: 0;
  margin: 0;
  line-height: normal;
}

.spectrum-FieldButton_e2d99e:disabled, .spectrum-FieldButton_e2d99e.is-disabled_e2d99e {
  cursor: default;
  border-width: 0;
}

.spectrum-FieldButton_e2d99e.is-open_e2d99e {
  border-width: var(--spectrum-dropdown-border-size, var(--spectrum-alias-border-size-thin));
}

.spectrum-FieldButton--quiet_e2d99e {
  border-radius: var(--spectrum-fieldbutton-quiet-border-radius, 0px);
  border-width: 0;
  margin: 0;
  padding: 0;
}

.spectrum-FieldButton--quiet_e2d99e:disabled.focus-ring_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-disabled_e2d99e.focus-ring_e2d99e {
  box-shadow: none;
}

.spectrum-ClearButton_e2d99e {
  width: var(--spectrum-clearbutton-medium-width, var(--spectrum-alias-single-line-height));
  height: var(--spectrum-clearbutton-medium-height, var(--spectrum-alias-single-line-height));
  border: 0;
  border-radius: 100%;
  margin: 0;
  padding: 0;
}

.spectrum-ClearButton_e2d99e > .spectrum-Icon_e2d99e {
  margin: 0 auto;
}

.spectrum-ClearButton--overBackground_e2d99e.focus-ring_e2d99e:after {
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -1);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .spectrum-ClearButton_e2d99e > .spectrum-Icon_e2d99e {
    margin: 0;
  }
}

.spectrum-ClearButton--small_e2d99e {
  width: var(--spectrum-clearbutton-small-width, var(--spectrum-global-dimension-size-300));
  height: var(--spectrum-clearbutton-small-height, var(--spectrum-global-dimension-size-300));
}

.spectrum-LogicButton_e2d99e.focus-ring_e2d99e:after, .spectrum-LogicButton_e2d99e.is-focused_e2d99e:after, .spectrum-Button_e2d99e.focus-ring_e2d99e:after, .spectrum-Button_e2d99e.is-focused_e2d99e:after, .spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e:after, .spectrum-ActionButton--emphasized_e2d99e.is-focused_e2d99e:after {
  box-shadow: 0 0 0 var(--spectrum-button-primary-focus-ring-size-key-focus, var(--spectrum-alias-focus-ring-size)) var(--spectrum-button-primary-focus-ring-color-key-focus, var(--spectrum-alias-focus-ring-color));
}

.spectrum-ClearButton_e2d99e {
  background-color: var(--spectrum-clearbutton-medium-background-color, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ClearButton_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-clearbutton-medium-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ClearButton_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-clearbutton-medium-background-color-hover, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ClearButton_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-clearbutton-medium-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ClearButton_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-clearbutton-medium-background-color-down, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-ClearButton_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-clearbutton-medium-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-ClearButton_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-clearbutton-medium-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.spectrum-ClearButton_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-clearbutton-medium-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.spectrum-ClearButton_e2d99e:disabled, .spectrum-ClearButton_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-clearbutton-medium-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-clearbutton-medium-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ClearButton_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ClearButton_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-clearbutton-medium-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-Button--cta_e2d99e {
  background-color: var(--spectrum-button-cta-background-color, var(--spectrum-semantic-cta-color-background-default));
  border-color: var(--spectrum-button-cta-border-color, var(--spectrum-semantic-cta-color-background-default));
  color: var(--spectrum-button-cta-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-Button--cta_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-cta-background-color-hover, var(--spectrum-semantic-cta-color-background-hover));
  border-color: var(--spectrum-button-cta-border-color-hover, var(--spectrum-semantic-cta-color-background-hover));
  color: var(--spectrum-button-cta-text-color-hover, var(--spectrum-global-color-static-white));
}

.spectrum-Button--cta_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-cta-background-color-key-focus, var(--spectrum-semantic-cta-color-background-hover));
  border-color: var(--spectrum-button-cta-border-color-key-focus, var(--spectrum-semantic-cta-color-background-hover));
  color: var(--spectrum-button-cta-text-color-key-focus, var(--spectrum-global-color-static-white));
}

.spectrum-Button--cta_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-cta-background-color-down, var(--spectrum-semantic-cta-color-background-down));
  border-color: var(--spectrum-button-cta-border-color-down, var(--spectrum-semantic-cta-color-background-down));
  color: var(--spectrum-button-cta-text-color-down, var(--spectrum-global-color-static-white));
}

.spectrum-Button--cta_e2d99e:disabled, .spectrum-Button--cta_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-cta-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-cta-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-cta-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Button--primary_e2d99e {
  background-color: var(--spectrum-button-primary-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-primary-border-color, var(--spectrum-global-color-gray-800));
  color: var(--spectrum-button-primary-text-color, var(--spectrum-global-color-gray-800));
}

.spectrum-Button--primary_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-primary-background-color-hover, var(--spectrum-global-color-gray-800));
  border-color: var(--spectrum-button-primary-border-color-hover, var(--spectrum-global-color-gray-800));
  color: var(--spectrum-button-primary-text-color-hover, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--primary_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-primary-background-color-key-focus, var(--spectrum-global-color-gray-800));
  border-color: var(--spectrum-button-primary-border-color-key-focus, var(--spectrum-global-color-gray-800));
  color: var(--spectrum-button-primary-text-color-key-focus, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--primary_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-primary-background-color-down, var(--spectrum-global-color-gray-900));
  border-color: var(--spectrum-button-primary-border-color-down, var(--spectrum-global-color-gray-900));
  color: var(--spectrum-button-primary-text-color-down, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--primary_e2d99e:disabled, .spectrum-Button--primary_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-primary-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-primary-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-primary-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Button--secondary_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-secondary-border-color, var(--spectrum-global-color-gray-700));
  color: var(--spectrum-button-secondary-text-color, var(--spectrum-global-color-gray-700));
}

.spectrum-Button--secondary_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color-hover, var(--spectrum-global-color-gray-700));
  border-color: var(--spectrum-button-secondary-border-color-hover, var(--spectrum-global-color-gray-700));
  color: var(--spectrum-button-secondary-text-color-hover, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--secondary_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color-key-focus, var(--spectrum-global-color-gray-700));
  border-color: var(--spectrum-button-secondary-border-color-key-focus, var(--spectrum-global-color-gray-700));
  color: var(--spectrum-button-secondary-text-color-key-focus, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--secondary_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color-down, var(--spectrum-global-color-gray-800));
  border-color: var(--spectrum-button-secondary-border-color-down, var(--spectrum-global-color-gray-800));
  color: var(--spectrum-button-secondary-text-color-down, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--secondary_e2d99e:disabled, .spectrum-Button--secondary_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-secondary-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-secondary-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Button--warning_e2d99e {
  background-color: var(--spectrum-button-warning-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-warning-border-color, var(--spectrum-semantic-negative-color-text-small));
  color: var(--spectrum-button-warning-text-color, var(--spectrum-semantic-negative-color-text-small));
}

.spectrum-Button--warning_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-warning-background-color-hover, var(--spectrum-semantic-negative-color-text-small));
  border-color: var(--spectrum-button-warning-border-color-hover, var(--spectrum-semantic-negative-color-text-small));
  color: var(--spectrum-button-warning-text-color-hover, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--warning_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-warning-background-color-key-focus, var(--spectrum-semantic-negative-color-text-small));
  border-color: var(--spectrum-button-warning-border-color-key-focus, var(--spectrum-semantic-negative-color-text-small));
  color: var(--spectrum-button-warning-text-color-key-focus, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--warning_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-warning-background-color-down, var(--spectrum-global-color-red-700));
  border-color: var(--spectrum-button-warning-border-color-down, var(--spectrum-global-color-red-700));
  color: var(--spectrum-button-warning-text-color-down, var(--spectrum-global-color-gray-50));
}

.spectrum-Button--warning_e2d99e:disabled, .spectrum-Button--warning_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-warning-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-warning-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-warning-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Button--overBackground_e2d99e {
  background-color: var(--spectrum-button-over-background-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-over-background-border-color, var(--spectrum-global-color-static-white));
  color: var(--spectrum-button-over-background-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.is-hovered_e2d99e, .spectrum-Button--overBackground_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-over-background-background-color-hover, var(--spectrum-global-color-static-white));
  border-color: var(--spectrum-button-over-background-border-color-hover, var(--spectrum-global-color-static-white));
  color: inherit;
}

.spectrum-Button--overBackground_e2d99e.focus-ring_e2d99e:after {
  box-shadow: 0 0 0 var(--spectrum-alias-focus-ring-size, var(--spectrum-global-dimension-static-size-25)) var(--spectrum-button-over-background-border-color-key-focus, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-over-background-background-color-down, var(--spectrum-global-color-static-white));
  border-color: var(--spectrum-button-over-background-border-color-down, var(--spectrum-global-color-static-white));
  color: inherit;
}

.spectrum-Button--overBackground_e2d99e:disabled, .spectrum-Button--overBackground_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-over-background-background-color-disabled, #ffffff1a);
  border-color: var(--spectrum-button-over-background-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-over-background-text-color-disabled, #ffffff59);
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e, .spectrum-ClearButton--overBackground_e2d99e {
  background-color: var(--spectrum-button-quiet-over-background-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-over-background-border-color, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-over-background-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e.is-hovered_e2d99e, .spectrum-ClearButton--overBackground_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-quiet-over-background-background-color-hover, #ffffff1a);
  border-color: var(--spectrum-button-quiet-over-background-border-color-hover, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-over-background-text-color-hover, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e.focus-ring_e2d99e, .spectrum-ClearButton--overBackground_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-quiet-over-background-background-color-hover, #ffffff1a);
  border-color: var(--spectrum-button-quiet-over-background-border-color-hover, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-over-background-text-color-hover, var(--spectrum-global-color-static-white));
  box-shadow: none;
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e.focus-ring_e2d99e:after, .spectrum-ClearButton--overBackground_e2d99e.focus-ring_e2d99e:after {
  box-shadow: 0 0 0 var(--spectrum-alias-focus-ring-size, var(--spectrum-global-dimension-static-size-25)) var(--spectrum-button-over-background-border-color-key-focus, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e.is-active_e2d99e, .spectrum-ClearButton--overBackground_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-quiet-over-background-background-color-down, #ffffff26);
  border-color: var(--spectrum-button-quiet-over-background-border-color-down, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-over-background-text-color-down, var(--spectrum-global-color-static-white));
}

.spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e:disabled, .spectrum-Button--overBackground_e2d99e.spectrum-Button--quiet_e2d99e.is-disabled_e2d99e, .spectrum-ClearButton--overBackground_e2d99e:disabled, .spectrum-ClearButton--overBackground_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-quiet-over-background-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-over-background-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-over-background-text-color-disabled, #ffffff26);
}

.spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e {
  background-color: var(--spectrum-button-quiet-primary-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-primary-border-color, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-primary-text-color, var(--spectrum-global-color-gray-800));
}

.spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-quiet-primary-background-color-hover, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-primary-border-color-hover, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-primary-text-color-hover, var(--spectrum-global-color-gray-900));
}

.spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-quiet-primary-background-color-key-focus, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-primary-border-color-key-focus, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-primary-text-color-key-focus, var(--spectrum-global-color-gray-900));
}

.spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-quiet-primary-background-color-down, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-button-quiet-primary-border-color-down, var(--spectrum-global-color-gray-300));
  color: var(--spectrum-button-quiet-primary-text-color-down, var(--spectrum-global-color-gray-900));
}

.spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e:disabled, .spectrum-Button--primary_e2d99e.spectrum-Button--quiet_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-quiet-primary-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-primary-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-primary-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e {
  background-color: var(--spectrum-button-quiet-secondary-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-secondary-border-color, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-secondary-text-color, var(--spectrum-global-color-gray-700));
}

.spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-quiet-secondary-background-color-hover, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-secondary-border-color-hover, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-secondary-text-color-hover, var(--spectrum-global-color-gray-800));
}

.spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-quiet-secondary-background-color-key-focus, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-secondary-border-color-key-focus, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-secondary-text-color-key-focus, var(--spectrum-global-color-gray-800));
}

.spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-quiet-secondary-background-color-down, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-button-quiet-secondary-border-color-down, var(--spectrum-global-color-gray-300));
  color: var(--spectrum-button-quiet-secondary-text-color-down, var(--spectrum-global-color-gray-800));
}

.spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e:disabled, .spectrum-Button--secondary_e2d99e.spectrum-Button--quiet_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-quiet-secondary-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-secondary-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-secondary-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-ActionButton_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color, var(--spectrum-global-color-gray-75));
  border-color: var(--spectrum-actionbutton-border-color, var(--spectrum-alias-border-color));
  color: var(--spectrum-actionbutton-text-color, var(--spectrum-alias-text-color));
}

.spectrum-ActionButton_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ActionButton_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-hold-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ActionButton_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-hover, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-actionbutton-border-color-hover, var(--spectrum-alias-border-color-hover));
  color: var(--spectrum-actionbutton-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton_e2d99e.is-hovered_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-hold-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-key-focus, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-actionbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 var(--spectrum-button-primary-border-size-increase-key-focus, 1px) var(--spectrum-actionbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-actionbutton-text-color-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  border-color: var(--spectrum-actionbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-ActionButton_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.spectrum-ActionButton_e2d99e.focus-ring_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-hold-icon-color-key-focus, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-down, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-down, var(--spectrum-alias-border-color-down));
  color: var(--spectrum-actionbutton-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-ActionButton_e2d99e.is-active_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-hold-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-ActionButton_e2d99e:disabled, .spectrum-ActionButton_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-disabled, var(--spectrum-alias-border-color-disabled));
  color: var(--spectrum-actionbutton-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton_e2d99e:disabled .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton_e2d99e.is-disabled_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-hold-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-selected, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-selected, var(--spectrum-alias-border-color));
  color: var(--spectrum-actionbutton-text-color-selected, var(--spectrum-alias-text-color));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-selected, var(--spectrum-alias-icon-color));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-selected-hover, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-selected-hover, var(--spectrum-alias-border-color-hover));
  color: var(--spectrum-actionbutton-text-color-selected-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-selected-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-selected-key-focus, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-selected-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-actionbutton-text-color-selected-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  border-color: var(--spectrum-actionbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-selected-key-focus, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-selected-down, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-selected-down, var(--spectrum-alias-border-color-down));
  color: var(--spectrum-actionbutton-text-color-selected-down, var(--spectrum-alias-text-color-down));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-selected-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-background-color-selected-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled));
  color: var(--spectrum-actionbutton-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton_e2d99e.is-selected_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-icon-color-selected-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton--emphasized_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color, var(--spectrum-global-color-gray-75));
  border-color: var(--spectrum-actionbutton-emphasized-border-color, var(--spectrum-alias-border-color));
  color: var(--spectrum-actionbutton-emphasized-text-color, var(--spectrum-alias-text-color));
}

.spectrum-ActionButton--emphasized_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ActionButton--emphasized_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color-selected, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-text-color-selected-hover, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-hover, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-hover, var(--spectrum-alias-border-color-hover));
  box-shadow: none;
  color: var(--spectrum-actionbutton-emphasized-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--emphasized_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton--emphasized_e2d99e.is-hovered_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-key-focus, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-actionbutton-emphasized-border-color, var(--spectrum-alias-border-color));
  box-shadow: none;
  color: var(--spectrum-actionbutton-emphasized-text-color-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  border-color: var(--spectrum-actionbutton-emphasized-border-color-down, var(--spectrum-alias-border-color-down));
}

.spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.spectrum-ActionButton--emphasized_e2d99e.focus-ring_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color-key-focus, var(--spectrum-alias-icon-color-hover));
}

.spectrum-ActionButton--emphasized_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-down, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-down, var(--spectrum-alias-border-color-down));
  color: var(--spectrum-actionbutton-emphasized-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-ActionButton--emphasized_e2d99e.is-active_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-ActionButton--emphasized_e2d99e:disabled, .spectrum-ActionButton--emphasized_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-disabled, var(--spectrum-alias-border-color-disabled));
  color: var(--spectrum-actionbutton-emphasized-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton--emphasized_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton--emphasized_e2d99e:disabled .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-disabled_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-hold-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.focus-ring_e2d99e {
  border-color: var(--spectrum-actionbutton-quiet-border-color, var(--spectrum-alias-border-color-transparent));
  box-shadow: none;
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-selected, var(--spectrum-semantic-cta-color-background-default));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected, var(--spectrum-semantic-cta-color-background-default));
  color: var(--spectrum-actionbutton-emphasized-text-color-selected, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-selected, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-selected-key-focus, var(--spectrum-semantic-cta-color-background-key-focus));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected, var(--spectrum-semantic-cta-color-background-default));
  box-shadow: none;
  color: var(--spectrum-actionbutton-emphasized-text-color-selected-key-focus, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e.is-active_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected-down, var(--spectrum-semantic-cta-color-background-down));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-selected-key-focus, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-hovered_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-selected-hover, var(--spectrum-semantic-cta-color-background-hover));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected-hover, var(--spectrum-semantic-cta-color-background-hover));
  color: var(--spectrum-actionbutton-emphasized-text-color-selected-hover, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-selected-hover, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-active_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-selected-down, var(--spectrum-semantic-cta-color-background-down));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected-down, var(--spectrum-semantic-cta-color-background-down));
  color: var(--spectrum-actionbutton-emphasized-text-color-selected-down, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-selected-down, var(--spectrum-global-color-static-white));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-emphasized-background-color-selected-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-emphasized-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled));
  color: var(--spectrum-actionbutton-emphasized-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--emphasized_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-emphasized-icon-color-selected-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-ActionButton--quiet_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-actionbutton-quiet-border-color, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-actionbutton-quiet-text-color, var(--spectrum-alias-text-color));
}

.spectrum-ActionButton--quiet_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-hover, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-actionbutton-quiet-border-color-hover, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-actionbutton-quiet-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--quiet_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
  box-shadow: 0 0 0 1px var(--spectrum-actionbutton-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  border-color: var(--spectrum-actionbutton-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-actionbutton-quiet-text-color-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--quiet_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-down, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-actionbutton-quiet-border-color-down, var(--spectrum-global-color-gray-300));
  color: var(--spectrum-actionbutton-quiet-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-ActionButton--quiet_e2d99e:disabled, .spectrum-ActionButton--quiet_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-actionbutton-quiet-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-actionbutton-quiet-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-selected, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-actionbutton-quiet-border-color-selected, var(--spectrum-alias-border-color));
  color: var(--spectrum-actionbutton-quiet-text-color-selected, var(--spectrum-alias-text-color));
}

.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-selected-hover, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-actionbutton-quiet-border-color-selected-hover, var(--spectrum-alias-border-color-hover));
  color: var(--spectrum-actionbutton-quiet-text-color-selected-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-selected-key-focus, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-actionbutton-quiet-border-color-selected-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-actionbutton-quiet-text-color-selected-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-selected-down, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-actionbutton-quiet-border-color-selected-down, var(--spectrum-alias-border-color-down));
  color: var(--spectrum-actionbutton-quiet-text-color-selected-down, var(--spectrum-alias-text-color-down));
}

.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-actionbutton-quiet-background-color-selected-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-actionbutton-quiet-border-color-selected-disabled, var(--spectrum-alias-border-color-disabled));
  color: var(--spectrum-actionbutton-quiet-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ActionButton--staticWhite_e2d99e {
  mix-blend-mode: screen;
  --spectrum-actionbutton-static-background-color-hover: #ffffff1a;
  --spectrum-actionbutton-static-background-color-focus: #ffffff1a;
  --spectrum-actionbutton-static-background-color-active: #fff3;
  --spectrum-actionbutton-static-border-color: #ffffff4d;
  --spectrum-actionbutton-static-border-color-hover: white;
  --spectrum-actionbutton-static-border-color-active: white;
  --spectrum-actionbutton-static-border-color-focus: white;
  --spectrum-actionbutton-static-border-disabled: #ffffff26;
  --spectrum-actionbutton-static-color: white;
  --spectrum-actionbutton-static-color-selected: black;
  --spectrum-actionbutton-static-color-disabled: #ffffff26;
}

.spectrum-ActionButton--staticWhite_e2d99e.spectrum-ActionButton--quiet_e2d99e {
  --spectrum-actionbutton-static-border-color: transparent;
  --spectrum-actionbutton-static-border-color-hover: transparent;
  --spectrum-actionbutton-static-border-color-active: transparent;
}

.spectrum-ActionButton--staticBlack_e2d99e {
  mix-blend-mode: multiply;
  --spectrum-actionbutton-static-background-color-hover: #0000001a;
  --spectrum-actionbutton-static-background-color-focus: #0000001a;
  --spectrum-actionbutton-static-background-color-active: #0003;
  --spectrum-actionbutton-static-border-color: #0000004d;
  --spectrum-actionbutton-static-border-color-hover: black;
  --spectrum-actionbutton-static-border-color-active: black;
  --spectrum-actionbutton-static-border-color-focus: black;
  --spectrum-actionbutton-static-border-disabled: #00000026;
  --spectrum-actionbutton-static-color: black;
  --spectrum-actionbutton-static-color-selected: white;
  --spectrum-actionbutton-static-color-disabled: #00000026;
}

.spectrum-ActionButton--staticBlack_e2d99e.spectrum-ActionButton--quiet_e2d99e {
  --spectrum-actionbutton-static-border-color: transparent;
  --spectrum-actionbutton-static-border-color-hover: transparent;
  --spectrum-actionbutton-static-border-color-active: transparent;
}

.spectrum-ActionButton--staticColor_e2d99e {
  border-color: var(--spectrum-actionbutton-static-border-color);
  color: var(--spectrum-actionbutton-static-color);
  background-color: #0000;
}

.spectrum-ActionButton--staticColor_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-actionbutton-static-background-color-hover);
  border-color: var(--spectrum-actionbutton-static-border-color-hover);
  color: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-hovered_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-static-background-color-focus);
  border-color: var(--spectrum-actionbutton-static-border-color-focus);
  box-shadow: none;
  color: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e.is-hovered_e2d99e {
  border-color: var(--spectrum-actionbutton-static-border-color-focus);
}

.spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  border-color: var(--spectrum-actionbutton-static-color-focus);
}

.spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.focus-ring_e2d99e:after {
  box-shadow: 0 0 0 var(--spectrum-button-primary-focus-ring-size-key-focus, var(--spectrum-alias-focus-ring-size)) var(--spectrum-actionbutton-static-border-color-focus);
}

.spectrum-ActionButton--staticColor_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-static-background-color-active);
  border-color: var(--spectrum-actionbutton-static-border-color-active);
  color: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.is-active_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e:disabled, .spectrum-ActionButton--staticColor_e2d99e.is-disabled_e2d99e {
  border-color: var(--spectrum-actionbutton-static-border-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
  background-color: #0000;
}

.spectrum-ActionButton--staticColor_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e:disabled .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-disabled_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-actionbutton-static-color);
  border-color: var(--spectrum-actionbutton-static-color);
  color: var(--spectrum-actionbutton-static-color-selected);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-actionbutton-static-color);
  border-color: var(--spectrum-actionbutton-static-color);
  color: var(--spectrum-actionbutton-static-color-selected);
  box-shadow: none;
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e.is-active_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.focus-ring_e2d99e.is-active_e2d99e {
  box-shadow: none;
  border-color: var(--spectrum-actionbutton-static-color);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.focus-ring_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-hovered_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-active_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-hovered_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-actionbutton-static-color);
  border-color: var(--spectrum-actionbutton-static-color);
  color: var(--spectrum-actionbutton-static-color-selected);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-hovered_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-active_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-actionbutton-static-color-selected);
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e:disabled, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-disabled_e2d99e {
  border-color: var(--spectrum-actionbutton-static-border-disabled);
  color: var(--spectrum-actionbutton-static-color-disabled);
  background-color: #0000;
}

.spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e:disabled .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e:disabled .spectrum-ActionButton-hold_e2d99e, .spectrum-ActionButton--staticColor_e2d99e.is-selected_e2d99e.is-disabled_e2d99e .spectrum-ActionButton-hold_e2d99e {
  fill: var(--spectrum-actionbutton-static-color-disabled);
}

.spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e {
  background-color: var(--spectrum-button-quiet-warning-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-warning-border-color, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-warning-text-color, var(--spectrum-semantic-negative-color-text-small));
}

.spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-button-quiet-warning-background-color-hover, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-warning-border-color-hover, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-warning-text-color-hover, var(--spectrum-global-color-red-700));
}

.spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e.focus-ring_e2d99e {
  background-color: var(--spectrum-button-quiet-warning-background-color-key-focus, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-quiet-warning-border-color-key-focus, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-button-quiet-warning-text-color-key-focus, var(--spectrum-global-color-red-700));
}

.spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e.is-active_e2d99e {
  background-color: var(--spectrum-button-quiet-warning-background-color-down, var(--spectrum-global-color-gray-300));
  border-color: var(--spectrum-button-quiet-warning-border-color-down, var(--spectrum-global-color-gray-300));
  color: var(--spectrum-button-quiet-warning-text-color-down, var(--spectrum-global-color-red-700));
}

.spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e:disabled, .spectrum-Button--warning_e2d99e.spectrum-Button--quiet_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-quiet-warning-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-button-quiet-warning-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-button-quiet-warning-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-LogicButton--and_e2d99e {
  background-color: var(--spectrum-logicbutton-and-background-color, var(--spectrum-global-color-blue-500));
  border-color: var(--spectrum-logicbutton-and-border-color, var(--spectrum-global-color-blue-500));
  color: var(--spectrum-logicbutton-and-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-LogicButton--and_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-logicbutton-and-background-color-hover, var(--spectrum-global-color-blue-700));
  border-color: var(--spectrum-logicbutton-and-border-color-hover, var(--spectrum-global-color-blue-700));
  color: var(--spectrum-logicbutton-and-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-LogicButton--and_e2d99e:disabled, .spectrum-LogicButton--and_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-logicbutton-and-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-logicbutton-and-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-logicbutton-and-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-LogicButton--or_e2d99e {
  background-color: var(--spectrum-logicbutton-or-background-color, var(--spectrum-global-color-magenta-500));
  border-color: var(--spectrum-logicbutton-or-border-color, var(--spectrum-global-color-magenta-500));
  color: var(--spectrum-logicbutton-or-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-LogicButton--or_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-logicbutton-or-background-color-hover, var(--spectrum-global-color-magenta-700));
  border-color: var(--spectrum-logicbutton-or-border-color-hover, var(--spectrum-global-color-magenta-700));
  color: var(--spectrum-logicbutton-or-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-LogicButton--or_e2d99e:disabled, .spectrum-LogicButton--or_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-button-secondary-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-button-secondary-border-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-logicbutton-and-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldButton_e2d99e {
  color: var(--spectrum-fieldbutton-text-color, var(--spectrum-alias-text-color));
  background-color: var(--spectrum-fieldbutton-background-color, var(--spectrum-global-color-gray-75));
  border-color: var(--spectrum-fieldbutton-border-color, var(--spectrum-alias-border-color));
}

.spectrum-FieldButton_e2d99e.is-hovered_e2d99e {
  color: var(--spectrum-fieldbutton-text-color-hover, var(--spectrum-alias-text-color-hover));
  background-color: var(--spectrum-fieldbutton-background-color-hover, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-fieldbutton-border-color-hover, var(--spectrum-alias-border-color-hover));
}

.spectrum-FieldButton_e2d99e.is-active_e2d99e, .spectrum-FieldButton_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-fieldbutton-background-color-down, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-fieldbutton-border-color-down, var(--spectrum-alias-border-color-down));
}

.spectrum-FieldButton_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton_e2d99e.is-focused_e2d99e {
  background-color: var(--spectrum-fieldbutton-background-color-key-focus, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-fieldbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 var(--spectrum-button-primary-border-size-increase-key-focus, 1px) var(--spectrum-fieldbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  color: var(--spectrum-fieldbutton-text-color-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-FieldButton_e2d99e.focus-ring_e2d99e.is-placeholder_e2d99e, .spectrum-FieldButton_e2d99e.is-focused_e2d99e.is-placeholder_e2d99e {
  color: var(--spectrum-fieldbutton-placeholder-text-color-key-focus, var(--spectrum-alias-placeholder-text-color-hover));
}

.spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e {
  border-color: var(--spectrum-fieldbutton-border-color-error, var(--spectrum-global-color-red-500));
}

.spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e.is-hovered_e2d99e {
  border-color: var(--spectrum-fieldbutton-border-color-error-hover, var(--spectrum-global-color-red-600));
}

.spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e.is-active_e2d99e, .spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e.is-selected_e2d99e {
  border-color: var(--spectrum-fieldbutton-border-color-error-down, var(--spectrum-global-color-red-600));
}

.spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton_e2d99e.spectrum-FieldButton--invalid_e2d99e.is-focused_e2d99e {
  border-color: var(--spectrum-fieldbutton-border-color-error-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 var(--spectrum-button-primary-border-size-increase-key-focus, 1px) var(--spectrum-fieldbutton-border-color-error-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-FieldButton_e2d99e:disabled, .spectrum-FieldButton_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-fieldbutton-background-color-disabled, var(--spectrum-global-color-gray-200));
  color: var(--spectrum-fieldbutton-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldButton_e2d99e:disabled .spectrum-Icon_e2d99e, .spectrum-FieldButton_e2d99e.is-disabled_e2d99e .spectrum-Icon_e2d99e {
  fill: var(--spectrum-fieldbutton-icon-color-disabled, var(--spectrum-alias-icon-color-disabled));
}

.spectrum-FieldButton--quiet_e2d99e {
  color: var(--spectrum-fieldbutton-text-color, var(--spectrum-alias-text-color));
  border-color: var(--spectrum-fieldbutton-quiet-border-color, var(--spectrum-alias-border-color-transparent));
  background-color: var(--spectrum-fieldbutton-quiet-background-color, var(--spectrum-alias-background-color-transparent));
}

.spectrum-FieldButton--quiet_e2d99e.is-hovered_e2d99e {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-hover, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-fieldbutton-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-FieldButton--quiet_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-focused_e2d99e {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
  box-shadow: 0 2px 0 0 var(--spectrum-fieldbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-FieldButton--quiet_e2d99e.focus-ring_e2d99e.is-placeholder_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-focused_e2d99e.is-placeholder_e2d99e {
  color: var(--spectrum-fieldbutton-quiet-placeholder-text-color-key-focus, var(--spectrum-alias-placeholder-text-color-hover));
}

.spectrum-FieldButton--quiet_e2d99e.is-active_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-selected_e2d99e {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-down, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-fieldbutton-quiet-border-color-down, var(--spectrum-alias-border-color-transparent));
}

.spectrum-FieldButton--quiet_e2d99e.is-active_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-active_e2d99e.is-focused_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-selected_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton--quiet_e2d99e.is-selected_e2d99e.is-focused_e2d99e {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-key-focus, var(--spectrum-alias-background-color-transparent));
  box-shadow: 0 2px 0 0 var(--spectrum-fieldbutton-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-FieldButton--quiet_e2d99e.spectrum-FieldButton--invalid_e2d99e.focus-ring_e2d99e, .spectrum-FieldButton--quiet_e2d99e.spectrum-FieldButton--invalid_e2d99e.is-focused_e2d99e {
  box-shadow: 0 2px 0 0 var(--spectrum-fieldbutton-border-color-error-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-FieldButton--quiet_e2d99e:disabled, .spectrum-FieldButton--quiet_e2d99e.is-disabled_e2d99e {
  background-color: var(--spectrum-fieldbutton-quiet-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-fieldbutton-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

@media (forced-colors: active) {
  .spectrum-ActionButton_e2d99e, .spectrum-Button_e2d99e, .spectrum-ClearButton_e2d99e, .spectrum-LogicButton_e2d99e, .spectrum-FieldButton_e2d99e {
    forced-color-adjust: none;
    --spectrum-actionbutton-background-color: ButtonFace;
    --spectrum-actionbutton-background-color-disabled: ButtonFace;
    --spectrum-actionbutton-background-color-down: ButtonFace;
    --spectrum-actionbutton-background-color-hover: ButtonFace;
    --spectrum-actionbutton-background-color-key-focus: ButtonFace;
    --spectrum-actionbutton-background-color-selected: Highlight;
    --spectrum-actionbutton-background-color-selected-disabled: ButtonFace;
    --spectrum-actionbutton-background-color-selected-down: Highlight;
    --spectrum-actionbutton-background-color-selected-hover: Highlight;
    --spectrum-actionbutton-background-color-selected-key-focus: Highlight;
    --spectrum-actionbutton-border-color: ButtonText;
    --spectrum-actionbutton-border-color-disabled: GrayText;
    --spectrum-actionbutton-border-color-down: Highlight;
    --spectrum-actionbutton-border-color-hover: Highlight;
    --spectrum-actionbutton-border-color-key-focus: CanvasText;
    --spectrum-actionbutton-border-color-selected: HighlightText;
    --spectrum-actionbutton-border-color-selected-disabled: GrayText;
    --spectrum-actionbutton-border-color-selected-down: HighlightText;
    --spectrum-actionbutton-border-color-selected-hover: HighlightText;
    --spectrum-actionbutton-border-color-selected-key-focus: CanvasText;
    --spectrum-actionbutton-emphasized-background-color: ButtonFace;
    --spectrum-actionbutton-emphasized-background-color-disabled: ButtonFace;
    --spectrum-actionbutton-emphasized-background-color-down: Highlight;
    --spectrum-actionbutton-emphasized-background-color-hover: Highlight;
    --spectrum-actionbutton-emphasized-background-color-key-focus: ButtonFace;
    --spectrum-actionbutton-emphasized-background-color-selected: Highlight;
    --spectrum-actionbutton-emphasized-background-color-selected-disabled: ButtonFace;
    --spectrum-actionbutton-emphasized-background-color-selected-down: Highlight;
    --spectrum-actionbutton-emphasized-background-color-selected-hover: Highlight;
    --spectrum-actionbutton-emphasized-background-color-selected-key-focus: Highlight;
    --spectrum-actionbutton-emphasized-border-color: ButtonText;
    --spectrum-actionbutton-emphasized-border-color-disabled: GrayText;
    --spectrum-actionbutton-emphasized-border-color-down: ButtonText;
    --spectrum-actionbutton-emphasized-border-color-hover: ButtonText;
    --spectrum-actionbutton-emphasized-border-color-selected: HighlightText;
    --spectrum-actionbutton-emphasized-border-color-selected-disabled: GrayText;
    --spectrum-actionbutton-emphasized-border-color-selected-downed: HighlightText;
    --spectrum-actionbutton-emphasized-border-color-selected-hover: HighlightText;
    --spectrum-actionbutton-emphasized-hold-icon-color: ButtonText;
    --spectrum-actionbutton-emphasized-hold-icon-color-disabled: GrayText;
    --spectrum-actionbutton-emphasized-hold-icon-color-down: HighlightText;
    --spectrum-actionbutton-emphasized-hold-icon-color-hover: HighlightText;
    --spectrum-actionbutton-emphasized-hold-icon-color-key-focus: ButtonText;
    --spectrum-actionbutton-emphasized-hold-icon-color-selected: ButtonFace;
    --spectrum-actionbutton-emphasized-icon-color: ButtonText;
    --spectrum-actionbutton-emphasized-icon-color-disabled: GrayText;
    --spectrum-actionbutton-emphasized-icon-color-hover: HighlightText;
    --spectrum-actionbutton-emphasized-icon-color-key-focus: ButtonText;
    --spectrum-actionbutton-emphasized-icon-color-selected: HighlightText;
    --spectrum-actionbutton-emphasized-icon-color-selected-disabled: GrayText;
    --spectrum-actionbutton-emphasized-icon-color-selected-down: HighlightText;
    --spectrum-actionbutton-emphasized-icon-color-selected-hover: HighlightText;
    --spectrum-actionbutton-emphasized-icon-color-selected-key-focus: HighlightText;
    --spectrum-actionbutton-emphasized-text-color: ButtonText;
    --spectrum-actionbutton-emphasized-text-color-disabled: GrayText;
    --spectrum-actionbutton-emphasized-text-color-down: HighlightText;
    --spectrum-actionbutton-emphasized-text-color-hover: HighlightText;
    --spectrum-actionbutton-emphasized-text-color-key-focus: ButtonText;
    --spectrum-actionbutton-emphasized-text-color-selected: HighlightText;
    --spectrum-actionbutton-emphasized-text-color-selected-disabled: GrayText;
    --spectrum-actionbutton-emphasized-text-color-selected-down: HighlightText;
    --spectrum-actionbutton-emphasized-text-color-selected-hover: HighlightText;
    --spectrum-actionbutton-emphasized-text-color-selected-key-focus: HighlightText;
    --spectrum-actionbutton-hold-icon-color: ButtonText;
    --spectrum-actionbutton-hold-icon-color-disabled: GrayText;
    --spectrum-actionbutton-hold-icon-color-down: ButtonText;
    --spectrum-actionbutton-hold-icon-color-hover: ButtonText;
    --spectrum-actionbutton-hold-icon-color-key-focus: ButtonText;
    --spectrum-actionbutton-icon-color: ButtonText;
    --spectrum-actionbutton-icon-color-disabled: GrayText;
    --spectrum-actionbutton-icon-color-hover: ButtonText;
    --spectrum-actionbutton-icon-color-key-focus: ButtonText;
    --spectrum-actionbutton-icon-color-selected: HighlightText;
    --spectrum-actionbutton-icon-color-selected-disabled: GrayText;
    --spectrum-actionbutton-icon-color-selected-down: HighlightText;
    --spectrum-actionbutton-icon-color-selected-hover: HighlightText;
    --spectrum-actionbutton-icon-color-selected-key-focus: HighlightText;
    --spectrum-actionbutton-quiet-background-color: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-disabled: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-down: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-hover: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-key-focus: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-selected: Highlight;
    --spectrum-actionbutton-quiet-background-color-selected-disabled: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-selected-down: Highlight;
    --spectrum-actionbutton-quiet-background-color-selected-hover: Highlight;
    --spectrum-actionbutton-quiet-background-color-selected-key-focus: Highlight;
    --spectrum-actionbutton-quiet-border-color: ButtonFace;
    --spectrum-actionbutton-quiet-border-color-disabled: ButtonFace;
    --spectrum-actionbutton-quiet-border-color-down: Highlight;
    --spectrum-actionbutton-quiet-border-color-hover: Highlight;
    --spectrum-actionbutton-quiet-border-color-key-focus: CanvasText;
    --spectrum-actionbutton-quiet-border-color-selected: HighlightText;
    --spectrum-actionbutton-quiet-border-color-selected-disabled: Canvas;
    --spectrum-actionbutton-quiet-border-color-selected-down: HighlightText;
    --spectrum-actionbutton-quiet-border-color-selected-hover: HighlightText;
    --spectrum-actionbutton-quiet-border-color-selected-key-focus: HighlightText;
    --spectrum-actionbutton-quiet-text-color: ButtonText;
    --spectrum-actionbutton-quiet-text-color-disabled: GrayText;
    --spectrum-actionbutton-quiet-text-color-down: ButtonText;
    --spectrum-actionbutton-quiet-text-color-hover: ButtonText;
    --spectrum-actionbutton-quiet-text-color-key-focus: ButtonText;
    --spectrum-actionbutton-quiet-text-color-selected: HighlightText;
    --spectrum-actionbutton-quiet-text-color-selected-disabled: GrayText;
    --spectrum-actionbutton-quiet-text-color-selected-down: HighlightText;
    --spectrum-actionbutton-quiet-text-color-selected-hover: HighlightText;
    --spectrum-actionbutton-quiet-text-color-selected-key-focus: HighlightText;
    --spectrum-actionbutton-static-background-color-active: ButtonFace;
    --spectrum-actionbutton-static-background-color-focus: ButtonFace;
    --spectrum-actionbutton-static-background-color-hover: ButtonFace;
    --spectrum-actionbutton-static-border-color: ButtonText;
    --spectrum-actionbutton-static-border-color-active: ButtonText;
    --spectrum-actionbutton-static-border-color-focus: Highlight;
    --spectrum-actionbutton-static-border-color-hover: ButtonText;
    --spectrum-actionbutton-static-color: Highlight;
    --spectrum-actionbutton-static-color-disabled: GrayText;
    --spectrum-actionbutton-static-color-focus: Highlight;
    --spectrum-actionbutton-static-color-selected: ButtonFace;
    --spectrum-actionbutton-text-color: ButtonText;
    --spectrum-actionbutton-text-color-disabled: GrayText;
    --spectrum-actionbutton-text-color-down: ButtonText;
    --spectrum-actionbutton-text-color-hover: ButtonText;
    --spectrum-actionbutton-text-color-key-focus: ButtonText;
    --spectrum-actionbutton-text-color-selected: HighlightText;
    --spectrum-actionbutton-text-color-selected-disabled: GrayText;
    --spectrum-actionbutton-text-color-selected-down: HighlightText;
    --spectrum-actionbutton-text-color-selected-hover: HighlightText;
    --spectrum-actionbutton-text-color-selected-key-focus: HighlightText;
    --spectrum-button-cta-background-color: ButtonText;
    --spectrum-button-cta-background-color-disabled: ButtonFace;
    --spectrum-button-cta-background-color-down: Highlight;
    --spectrum-button-cta-background-color-hover: Highlight;
    --spectrum-button-cta-background-color-key-focus: Highlight;
    --spectrum-button-cta-border-color: ButtonFace;
    --spectrum-button-cta-border-color-disabled: GrayText;
    --spectrum-button-cta-border-color-down: Highlight;
    --spectrum-button-cta-border-color-hover: Highlight;
    --spectrum-button-cta-border-color-key-focus: Highlight;
    --spectrum-button-cta-text-color: ButtonFace;
    --spectrum-button-cta-text-color-disabled: GrayText;
    --spectrum-button-cta-text-color-down: ButtonFace;
    --spectrum-button-cta-text-color-hover: ButtonFace;
    --spectrum-button-cta-text-color-key-focus: ButtonFace;
    --spectrum-button-over-background-background-color: ButtonFace;
    --spectrum-button-over-background-background-color-disabled: ButtonFace;
    --spectrum-button-over-background-background-color-down: ButtonFace;
    --spectrum-button-over-background-background-color-hover: ButtonFace;
    --spectrum-button-over-background-border-color: ButtonText;
    --spectrum-button-over-background-border-color-disabled: GrayText;
    --spectrum-button-over-background-border-color-down: Highlight;
    --spectrum-button-over-background-border-color-hover: Highlight;
    --spectrum-button-over-background-border-color-key-focus: Highlight;
    --spectrum-button-over-background-text-color: ButtonText;
    --spectrum-button-over-background-text-color-disabled: GrayText;
    --spectrum-button-primary-background-color: ButtonFace;
    --spectrum-button-primary-background-color-disabled: ButtonFace;
    --spectrum-button-primary-background-color-down: ButtonFace;
    --spectrum-button-primary-background-color-hover: ButtonFace;
    --spectrum-button-primary-background-color-key-focus: ButtonFace;
    --spectrum-button-primary-border-color: ButtonText;
    --spectrum-button-primary-border-color-disabled: GrayText;
    --spectrum-button-primary-border-color-down: Highlight;
    --spectrum-button-primary-border-color-hover: Highlight;
    --spectrum-button-primary-border-color-key-focus: Highlight;
    --spectrum-button-primary-text-color: ButtonText;
    --spectrum-button-primary-text-color-disabled: GrayText;
    --spectrum-button-primary-text-color-down: ButtonText;
    --spectrum-button-primary-text-color-hover: ButtonText;
    --spectrum-button-primary-text-color-key-focus: ButtonText;
    --spectrum-button-quiet-over-background-background-color: ButtonFace;
    --spectrum-button-quiet-over-background-background-color-disabled: ButtonFace;
    --spectrum-button-quiet-over-background-background-color-down: ButtonFace;
    --spectrum-button-quiet-over-background-background-color-hover: ButtonFace;
    --spectrum-button-quiet-over-background-border-color: ButtonFace;
    --spectrum-button-quiet-over-background-border-color-disabled: ButtonFace;
    --spectrum-button-quiet-over-background-border-color-down: Highlight;
    --spectrum-button-quiet-over-background-border-color-hover: Highlight;
    --spectrum-button-quiet-over-background-text-color: ButtonText;
    --spectrum-button-quiet-over-background-text-color-disabled: GrayText;
    --spectrum-button-quiet-over-background-text-color-down: ButtonText;
    --spectrum-button-quiet-over-background-text-color-hover: ButtonText;
    --spectrum-button-quiet-primary-background-color: ButtonFace;
    --spectrum-button-quiet-primary-background-color-disabled: ButtonFace;
    --spectrum-button-quiet-primary-background-color-down: ButtonFace;
    --spectrum-button-quiet-primary-background-color-hover: ButtonFace;
    --spectrum-button-quiet-primary-background-color-key-focus: ButtonFace;
    --spectrum-button-quiet-primary-border-color: ButtonFace;
    --spectrum-button-quiet-primary-border-color-disabled: ButtonFace;
    --spectrum-button-quiet-primary-border-color-down: Highlight;
    --spectrum-button-quiet-primary-border-color-hover: Highlight;
    --spectrum-button-quiet-primary-border-color-key-focus: Highlight;
    --spectrum-button-quiet-primary-text-color: ButtonText;
    --spectrum-button-quiet-primary-text-color-disabled: GrayText;
    --spectrum-button-quiet-primary-text-color-down: ButtonText;
    --spectrum-button-quiet-primary-text-color-hover: ButtonText;
    --spectrum-button-quiet-primary-text-color-key-focus: ButtonText;
    --spectrum-button-quiet-secondary-background-color: ButtonFace;
    --spectrum-button-quiet-secondary-background-color-disabled: ButtonFace;
    --spectrum-button-quiet-secondary-background-color-down: ButtonFace;
    --spectrum-button-quiet-secondary-background-color-hover: ButtonFace;
    --spectrum-button-quiet-secondary-background-color-key-focus: ButtonFace;
    --spectrum-button-quiet-secondary-border-color: ButtonFace;
    --spectrum-button-quiet-secondary-border-color-disabled: ButtonFace;
    --spectrum-button-quiet-secondary-border-color-down: Highlight;
    --spectrum-button-quiet-secondary-border-color-hover: Highlight;
    --spectrum-button-quiet-secondary-border-color-key-focus: Highlight;
    --spectrum-button-quiet-secondary-text-color: ButtonText;
    --spectrum-button-quiet-secondary-text-color-disabled: GrayText;
    --spectrum-button-quiet-secondary-text-color-down: ButtonText;
    --spectrum-button-quiet-secondary-text-color-hover: ButtonText;
    --spectrum-button-quiet-secondary-text-color-key-focus: ButtonText;
    --spectrum-button-quiet-warning-background-color: ButtonFace;
    --spectrum-button-quiet-warning-background-color-disabled: ButtonFace;
    --spectrum-button-quiet-warning-background-color-down: ButtonFace;
    --spectrum-button-quiet-warning-background-color-hover: ButtonFace;
    --spectrum-button-quiet-warning-background-color-key-focus: ButtonFace;
    --spectrum-button-quiet-warning-border-color: ButtonFace;
    --spectrum-button-quiet-warning-border-color-disabled: ButtonFace;
    --spectrum-button-quiet-warning-border-color-down: Highlight;
    --spectrum-button-quiet-warning-border-color-hover: Highlight;
    --spectrum-button-quiet-warning-border-color-key-focus: Highlight;
    --spectrum-button-quiet-warning-text-color: ButtonText;
    --spectrum-button-quiet-warning-text-color-disabled: GrayText;
    --spectrum-button-quiet-warning-text-color-down: ButtonText;
    --spectrum-button-quiet-warning-text-color-hover: ButtonText;
    --spectrum-button-quiet-warning-text-color-key-focus: ButtonText;
    --spectrum-button-secondary-background-color: ButtonFace;
    --spectrum-button-secondary-background-color-disabled: ButtonFace;
    --spectrum-button-secondary-background-color-down: ButtonFace;
    --spectrum-button-secondary-background-color-hover: ButtonFace;
    --spectrum-button-secondary-background-color-key-focus: ButtonFace;
    --spectrum-button-secondary-border-color: ButtonText;
    --spectrum-button-secondary-border-color-disabled: GrayText;
    --spectrum-button-secondary-border-color-down: Highlight;
    --spectrum-button-secondary-border-color-hover: Highlight;
    --spectrum-button-secondary-border-color-key-focus: Highlight;
    --spectrum-button-secondary-text-color: ButtonText;
    --spectrum-button-secondary-text-color-disabled: GrayText;
    --spectrum-button-secondary-text-color-down: ButtonText;
    --spectrum-button-secondary-text-color-hover: ButtonText;
    --spectrum-button-secondary-text-color-key-focus: ButtonText;
    --spectrum-button-warning-background-color: ButtonFace;
    --spectrum-button-warning-background-color-disabled: ButtonFace;
    --spectrum-button-warning-background-color-down: ButtonFace;
    --spectrum-button-warning-background-color-hover: ButtonFace;
    --spectrum-button-warning-background-color-key-focus: ButtonFace;
    --spectrum-button-warning-border-color: ButtonText;
    --spectrum-button-warning-border-color-disabled: GrayText;
    --spectrum-button-warning-border-color-down: Highlight;
    --spectrum-button-warning-border-color-hover: Highlight;
    --spectrum-button-warning-border-color-key-focus: Highlight;
    --spectrum-button-warning-text-color: ButtonText;
    --spectrum-button-warning-text-color-disabled: GrayText;
    --spectrum-button-warning-text-color-down: ButtonText;
    --spectrum-button-warning-text-color-hover: ButtonText;
    --spectrum-button-warning-text-color-key-focus: ButtonText;
    --spectrum-clearbutton-medium-background-color: ButtonFace;
    --spectrum-clearbutton-medium-background-color-disabled: ButtonFace;
    --spectrum-clearbutton-medium-background-color-down: ButtonFace;
    --spectrum-clearbutton-medium-background-color-hover: ButtonFace;
    --spectrum-clearbutton-medium-background-color-key-focus: ButtonFace;
    --spectrum-clearbutton-medium-icon-color: ButtonText;
    --spectrum-clearbutton-medium-icon-color-disabled: GrayText;
    --spectrum-clearbutton-medium-icon-color-down: Highlight;
    --spectrum-clearbutton-medium-icon-color-hover: Highlight;
    --spectrum-clearbutton-medium-icon-color-key-focus: Highlight;
    --spectrum-fieldbutton-background-color: ButtonFace;
    --spectrum-fieldbutton-background-color-disabled: ButtonFace;
    --spectrum-fieldbutton-background-color-down: ButtonFace;
    --spectrum-fieldbutton-background-color-hover: ButtonFace;
    --spectrum-fieldbutton-background-color-key-focus: ButtonFace;
    --spectrum-fieldbutton-border-color: ButtonText;
    --spectrum-fieldbutton-border-color-down: Highlight;
    --spectrum-fieldbutton-border-color-error: ButtonText;
    --spectrum-fieldbutton-border-color-error-down: Highlight;
    --spectrum-fieldbutton-border-color-error-hover: Highlight;
    --spectrum-fieldbutton-border-color-error-key-focus: Highlight;
    --spectrum-fieldbutton-border-color-hover: Highlight;
    --spectrum-fieldbutton-border-color-key-focus: Highlight;
    --spectrum-fieldbutton-icon-color-disabled: GrayText;
    --spectrum-fieldbutton-placeholder-text-color-key-focus: ButtonText;
    --spectrum-fieldbutton-quiet-background-color: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-disabled: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-down: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-hover: ButtonFace;
    --spectrum-fieldbutton-quiet-background-color-key-focus: ButtonFace;
    --spectrum-fieldbutton-quiet-border-color: ButtonFace;
    --spectrum-fieldbutton-quiet-border-color-down: Highlight;
    --spectrum-fieldbutton-quiet-placeholder-text-color-key-focus: ButtonText;
    --spectrum-fieldbutton-text-color: ButtonText;
    --spectrum-fieldbutton-text-color-disabled: GrayText;
    --spectrum-fieldbutton-text-color-hover: ButtonText;
    --spectrum-fieldbutton-text-color-key-focus: ButtonText;
    --spectrum-logicbutton-and-background-color: ButtonFace;
    --spectrum-logicbutton-and-background-color-disabled: ButtonFace;
    --spectrum-logicbutton-and-background-color-hover: ButtonFace;
    --spectrum-logicbutton-and-border-color: ButtonText;
    --spectrum-logicbutton-and-border-color-disabled: GrayText;
    --spectrum-logicbutton-and-border-color-hover: Highlight;
    --spectrum-logicbutton-and-text-color: ButtonText;
    --spectrum-logicbutton-and-text-color-disabled: GrayText;
    --spectrum-logicbutton-or-background-color: ButtonFace;
    --spectrum-logicbutton-or-background-color-hover: ButtonFace;
    --spectrum-logicbutton-or-border-color: ButtonText;
    --spectrum-logicbutton-or-border-color-hover: Highlight;
    --spectrum-logicbutton-or-text-color: ButtonText;
    --spectrum-button-primary-focus-ring-color-key-focus: CanvasText;
    --spectrum-button-primary-focus-ring-size-key-focus: 3px;
    --spectrum-dropdown-border-color-key-focus: Highlight;
  }

  .spectrum-Button--overBackground_e2d99e {
    --spectrum-button-over-background-color: ButtonText;
  }

  .spectrum-ActionButton--staticWhite_e2d99e, .spectrum-ActionButton--staticBlack_e2d99e {
    mix-blend-mode: normal;
    --spectrum-actionbutton-static-background-color-hover: ButtonFace;
    --spectrum-actionbutton-static-background-color-focus: ButtonFace;
    --spectrum-actionbutton-static-background-color-active: ButtonFace;
    --spectrum-actionbutton-static-border-color: ButtonText;
    --spectrum-actionbutton-static-border-color-hover: ButtonText;
    --spectrum-actionbutton-static-border-color-active: ButtonText;
    --spectrum-actionbutton-static-border-color-focus: CanvasText;
    --spectrum-actionbutton-static-border-disabled: GrayText;
    --spectrum-actionbutton-static-color: ButtonText;
    --spectrum-actionbutton-static-color-selected: HighlightText;
    --spectrum-actionbutton-static-color-disabled: GrayText;
  }

  .spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e {
    --spectrum-actionbutton-emphasized-border-color-selected-disabled: ButtonFace;
    --spectrum-actionbutton-quiet-background-color-hover: Highlight;
    --spectrum-actionbutton-quiet-text-color-hover: HighlightText;
    --spectrum-actionbutton-quiet-background-color-down: Highlight;
    --spectrum-actionbutton-quiet-text-color-down: HighlightText;
  }

  .spectrum-ActionButton--emphasized_e2d99e.spectrum-ActionButton--quiet_e2d99e.is-active_e2d99e {
    --spectrum-actionbutton-emphasized-icon-color-key-focus: HighlightText;
  }

  .spectrum-FieldButton_e2d99e.focus-ring_e2d99e:not(.spectrum-FieldButton--quiet_e2d99e), .spectrum-FieldButton_e2d99e.is-focused_e2d99e:not(.spectrum-FieldButton--quiet_e2d99e) {
    outline: 2px solid Highlight;
  }

  .spectrum-FieldButton_e2d99e.focus-ring_e2d99e.spectrum-FieldButton--quiet_e2d99e, .spectrum-FieldButton_e2d99e.is-focused_e2d99e.spectrum-FieldButton--quiet_e2d99e {
    forced-color-adjust: none;
    box-shadow: 0 2px 0 0 var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  }
}

[dir="ltr"] .spectrum-FieldLabel_d2db1f {
  text-align: left;
}

[dir="rtl"] .spectrum-FieldLabel_d2db1f {
  text-align: right;
}

.spectrum-FieldLabel_d2db1f {
  box-sizing: border-box;
  padding: var(--spectrum-fieldlabel-padding-top, var(--spectrum-global-dimension-size-50)) 0 var(--spectrum-fieldlabel-padding-bottom, var(--spectrum-global-dimension-size-65));
  font-size: var(--spectrum-fieldlabel-text-size, var(--spectrum-global-dimension-font-size-75));
  font-weight: var(--spectrum-fieldlabel-text-font-weight, var(--spectrum-global-font-weight-regular));
  line-height: var(--spectrum-fieldlabel-text-line-height, var(--spectrum-global-font-line-height-small));
  vertical-align: top;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  font-smoothing: subpixel-antialiased;
  cursor: default;
  flex: none;
  display: flex;
}

[dir="ltr"] .spectrum-FieldLabel--positionSide_d2db1f {
  padding-right: var(--spectrum-fieldlabel-side-padding-x, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-FieldLabel--positionSide_d2db1f {
  padding-left: var(--spectrum-fieldlabel-side-padding-x, var(--spectrum-global-dimension-size-100));
}

.spectrum-FieldLabel--positionSide_d2db1f {
  padding-top: var(--spectrum-fieldlabel-side-padding-top, var(--spectrum-global-dimension-size-100));
  display: inline-flex;
}

[dir="ltr"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-right: 0;
}

[dir="rtl"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-left: 0;
}

[dir="ltr"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-left: var(--spectrum-fieldlabel-asterisk-gap, var(--spectrum-global-dimension-size-25));
}

[dir="rtl"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-right: var(--spectrum-fieldlabel-asterisk-gap, var(--spectrum-global-dimension-size-25));
}

.spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-top: var(--spectrum-fieldlabel-asterisk-margin-y, var(--spectrum-global-dimension-size-50));
  margin-bottom: 0;
}

[dir="ltr"] .spectrum-FieldLabel--alignEnd_d2db1f {
  text-align: right;
}

[dir="rtl"] .spectrum-FieldLabel--alignEnd_d2db1f {
  text-align: left;
}

.spectrum-FieldLabel--alignEnd_d2db1f {
  justify-content: flex-end;
}

.spectrum-Field_d2db1f {
  --spectrum-field-default-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
}

.spectrum-Field_d2db1f.spectrum-Field--positionTop_d2db1f {
  width: var(--spectrum-field-default-width);
  flex-direction: column;
  display: inline-flex;
}

.spectrum-Field_d2db1f.spectrum-Field--positionTop_d2db1f .spectrum-Field-field_d2db1f {
  width: 100%;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f {
  align-items: flex-start;
  display: inline-flex;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f {
  min-width: 0;
  width: var(--spectrum-field-default-width);
  height: 100%;
  flex: 1;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f .spectrum-Field-field_d2db1f {
  width: 100%;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-field_d2db1f {
  min-width: 0;
  flex: 1;
}

[dir="ltr"] .spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  text-align: left;
}

[dir="rtl"] .spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  text-align: right;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  border-collapse: separate;
  border-spacing: 0 var(--spectrum-global-dimension-size-300);
  margin: calc(var(--spectrum-global-dimension-size-250) * -1) 0;
  display: table;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field_d2db1f {
  width: 100%;
  display: table-row;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-FieldLabel_d2db1f {
  display: table-cell;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f {
  width: 100%;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field-field_d2db1f {
  width: auto;
  min-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
  display: table-cell;
}

.spectrum-Form_d2db1f.spectrum-Form--positionTop_d2db1f {
  min-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
  flex-direction: column;
  margin: 0;
  display: flex;
}

.spectrum-Form_d2db1f.spectrum-Form--positionTop_d2db1f > * {
  margin-top: var(--spectrum-global-dimension-size-100);
  width: 100%;
}

.spectrum-StaticField_d2db1f.spectrum-Field--positionSide_d2db1f {
  -ms-flex-align: first baseline;
  align-items: baseline;
}

.spectrum-StaticField_d2db1f.spectrum-Field--positionTop_d2db1f {
  width: unset;
}

.spectrum-StaticField_d2db1f .spectrum-FieldLabel_d2db1f.spectrum-FieldLabel_d2db1f {
  padding-top: 0;
}

.spectrum-StaticField_d2db1f .spectrum-Field-wrapper_d2db1f.spectrum-Field-wrapper_d2db1f {
  width: unset;
}

.spectrum-StaticField_d2db1f .spectrum-Field-field_d2db1f {
  word-break: break-word;
  overflow: hidden;
}

.spectrum-FieldLabel_d2db1f, .spectrum-Form-itemLabel_d2db1f {
  color: var(--spectrum-fieldlabel-text-color, var(--spectrum-alias-label-text-color));
}

.spectrum-FieldLabel_d2db1f.is-disabled_d2db1f, .spectrum-Form-itemLabel_d2db1f.is-disabled_d2db1f {
  color: var(--spectrum-fieldlabel-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldLabel_d2db1f.is-disabled_d2db1f .spectrum-FieldLabel-requiredIcon_d2db1f, .spectrum-Form-itemLabel_d2db1f.is-disabled_d2db1f .spectrum-FieldLabel-requiredIcon_d2db1f {
  color: var(--spectrum-fieldlabel-asterisk-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldLabel-requiredIcon_d2db1f {
  color: var(--spectrum-fieldlabel-asterisk-color, var(--spectrum-global-color-gray-600));
}

.spectrum-Search_d3c0f3 {
  display: inline-block;
  position: relative;
}

[dir="ltr"] .spectrum-Search_d3c0f3 .spectrum-ClearButton_d3c0f3 {
  right: 0;
}

[dir="rtl"] .spectrum-Search_d3c0f3 .spectrum-ClearButton_d3c0f3 {
  left: 0;
}

.spectrum-Search_d3c0f3 .spectrum-ClearButton_d3c0f3 {
  position: absolute;
  top: 0;
}

[dir="ltr"] .spectrum-Search_d3c0f3.is-quiet_d3c0f3 .spectrum-Search-input_d3c0f3 {
  padding-right: var(--spectrum-global-dimension-size-350);
}

[dir="rtl"] .spectrum-Search_d3c0f3.is-quiet_d3c0f3 .spectrum-Search-input_d3c0f3 {
  padding-left: var(--spectrum-global-dimension-size-350);
}

[dir="ltr"] .spectrum-Search_d3c0f3.spectrum-Search--invalid_d3c0f3 .spectrum-ClearButton_d3c0f3 {
  right: calc(var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)));
}

[dir="rtl"] .spectrum-Search_d3c0f3.spectrum-Search--invalid_d3c0f3 .spectrum-ClearButton_d3c0f3 {
  left: calc(var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)));
}

[dir="ltr"] .spectrum-Search_d3c0f3.spectrum-Search--valid_d3c0f3 .spectrum-ClearButton_d3c0f3 {
  right: calc(var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)) / 2 + var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-Search_d3c0f3.spectrum-Search--valid_d3c0f3 .spectrum-ClearButton_d3c0f3 {
  left: calc(var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)) / 2 + var(--spectrum-global-dimension-size-150));
}

[dir="ltr"] .spectrum-Search_d3c0f3.spectrum-Search--loadable_d3c0f3 .spectrum-ClearButton_d3c0f3 {
  right: 0;
}

[dir="rtl"] .spectrum-Search_d3c0f3.spectrum-Search--loadable_d3c0f3 .spectrum-ClearButton_d3c0f3 {
  left: 0;
}

[dir="ltr"] .spectrum-Search_d3c0f3.spectrum-Search--loadable_d3c0f3 .spectrum-Search-circleLoader_d3c0f3 {
  right: calc(var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)) / 2 + var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-Search_d3c0f3.spectrum-Search--loadable_d3c0f3 .spectrum-Search-circleLoader_d3c0f3 {
  left: calc(var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)) / 2 + var(--spectrum-global-dimension-size-150));
}

[dir="ltr"] .spectrum-Search_d3c0f3.spectrum-Search--loadable_d3c0f3 .spectrum-Search-validationIcon_d3c0f3 {
  right: calc(var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)) / 2 + var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-Search_d3c0f3.spectrum-Search--loadable_d3c0f3 .spectrum-Search-validationIcon_d3c0f3 {
  left: calc(var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)) / 2 + var(--spectrum-global-dimension-size-150));
}

[dir="ltr"] .spectrum-Search_d3c0f3.spectrum-Search--loadable_d3c0f3 .spectrum-Search-validationIcon_d3c0f3 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Search_d3c0f3.spectrum-Search--loadable_d3c0f3 .spectrum-Search-validationIcon_d3c0f3 {
  padding-left: 0;
}

[dir="ltr"] .spectrum-Search-input_d3c0f3 {
  padding-right: var(--spectrum-global-dimension-size-350);
}

[dir="rtl"] .spectrum-Search-input_d3c0f3 {
  padding-left: var(--spectrum-global-dimension-size-350);
}

.spectrum-Search-input_d3c0f3 {
  -webkit-appearance: none;
  outline-offset: -2px;
  text-indent: 0;
  display: block;
}

.spectrum-Search-input_d3c0f3::-webkit-search-cancel-button, .spectrum-Search-input_d3c0f3::-webkit-search-decoration {
  -webkit-appearance: none;
}

[dir="ltr"] .spectrum-Textfield_d3c0f3.spectrum-Search--invalid_d3c0f3 .spectrum-Search-input_d3c0f3 {
  padding-right: calc(var(--spectrum-global-dimension-size-350)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="rtl"] .spectrum-Textfield_d3c0f3.spectrum-Search--invalid_d3c0f3 .spectrum-Search-input_d3c0f3 {
  padding-left: calc(var(--spectrum-global-dimension-size-350)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="ltr"] .spectrum-Textfield_d3c0f3.spectrum-Search--valid_d3c0f3 .spectrum-Search-input_d3c0f3 {
  padding-right: calc(var(--spectrum-global-dimension-size-350)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="rtl"] .spectrum-Textfield_d3c0f3.spectrum-Search--valid_d3c0f3 .spectrum-Search-input_d3c0f3 {
  padding-left: calc(var(--spectrum-global-dimension-size-350)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="ltr"] .spectrum-Textfield_d3c0f3.spectrum-Search--loadable_d3c0f3 .spectrum-Search-input_d3c0f3 {
  padding-right: calc(var(--spectrum-global-dimension-size-350)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="rtl"] .spectrum-Textfield_d3c0f3.spectrum-Search--loadable_d3c0f3 .spectrum-Search-input_d3c0f3 {
  padding-left: calc(var(--spectrum-global-dimension-size-350)  + var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

.spectrum-InputGroup_a0942f {
  min-width: calc(2.5 * var(--spectrum-dropdown-height, var(--spectrum-global-dimension-size-400)));
  border-radius: var(--spectrum-global-dimension-size-50);
  flex-flow: row;
  display: inline-flex;
  position: relative;
}

[dir="ltr"] .spectrum-InputGroup_a0942f .spectrum-FieldButton_a0942f {
  border-top-left-radius: var(--spectrum-combobox-fieldbutton-border-top-left-radius, 0);
}

[dir="rtl"] .spectrum-InputGroup_a0942f .spectrum-FieldButton_a0942f {
  border-top-right-radius: var(--spectrum-combobox-fieldbutton-border-top-left-radius, 0);
}

[dir="ltr"] .spectrum-InputGroup_a0942f .spectrum-FieldButton_a0942f {
  border-bottom-left-radius: var(--spectrum-combobox-fieldbutton-border-bottom-left-radius, 0);
}

[dir="rtl"] .spectrum-InputGroup_a0942f .spectrum-FieldButton_a0942f {
  border-bottom-right-radius: var(--spectrum-combobox-fieldbutton-border-bottom-left-radius, 0);
}

.spectrum-InputGroup_a0942f .spectrum-FieldButton_a0942f {
  width: var(--spectrum-global-dimension-size-400);
  border-width: 1px;
  flex-shrink: 0;
  padding: 0;
}

.spectrum-InputGroup_a0942f.is-disabled_a0942f:not(.spectrum-InputGroup--invalid_a0942f):not(.spectrum-InputGroup--quiet_a0942f) .spectrum-FieldButton_a0942f {
  border-width: 0;
}

[dir="ltr"] .spectrum-InputGroup_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-validationIcon_a0942f {
  padding-right: 0;
}

[dir="rtl"] .spectrum-InputGroup_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-validationIcon_a0942f {
  padding-left: 0;
}

[dir="ltr"] .spectrum-InputGroup_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-validationIcon_a0942f {
  right: var(--spectrum-global-dimension-size-100);
}

[dir="rtl"] .spectrum-InputGroup_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-validationIcon_a0942f {
  left: var(--spectrum-global-dimension-size-100);
}

[dir="ltr"] .spectrum-InputGroup_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-circleLoader_a0942f {
  padding-right: 0;
}

[dir="rtl"] .spectrum-InputGroup_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-circleLoader_a0942f {
  padding-left: 0;
}

[dir="ltr"] .spectrum-InputGroup_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-circleLoader_a0942f {
  right: var(--spectrum-global-dimension-size-100);
}

[dir="rtl"] .spectrum-InputGroup_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-circleLoader_a0942f {
  left: var(--spectrum-global-dimension-size-100);
}

.spectrum-InputGroup-field_a0942f {
  flex: auto;
}

[dir="ltr"] .spectrum-InputGroup-input_a0942f {
  border-top-right-radius: var(--spectrum-combobox-textfield-border-top-right-radius, 0);
}

[dir="rtl"] .spectrum-InputGroup-input_a0942f {
  border-top-left-radius: var(--spectrum-combobox-textfield-border-top-right-radius, 0);
}

[dir="ltr"] .spectrum-InputGroup-input_a0942f {
  border-bottom-right-radius: var(--spectrum-combobox-textfield-border-bottom-right-radius, 0);
}

[dir="rtl"] .spectrum-InputGroup-input_a0942f {
  border-bottom-left-radius: var(--spectrum-combobox-textfield-border-bottom-right-radius, 0);
}

[dir="ltr"] .spectrum-InputGroup-input_a0942f {
  border-right-style: none;
}

[dir="rtl"] .spectrum-InputGroup-input_a0942f {
  border-left-style: none;
}

.spectrum-InputGroup--quiet_a0942f {
  min-width: calc(2 * var(--spectrum-dropdown-height, var(--spectrum-global-dimension-size-400)));
  border-radius: 0;
}

[dir="ltr"] .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f {
  padding-left: var(--spectrum-global-dimension-size-130);
}

[dir="rtl"] .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f {
  padding-right: var(--spectrum-global-dimension-size-130);
}

[dir="ltr"] .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f {
  padding-right: 0;
}

[dir="rtl"] .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f {
  padding-left: 0;
}

.spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f {
  width: auto;
  border-width: 0;
  border-bottom: var(--spectrum-textfield-quiet-affixed-border-size, var(--spectrum-global-dimension-static-size-10)) solid;
  border-radius: 0;
  position: relative;
}

[dir="ltr"] .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f:after {
  right: -10px;
}

[dir="rtl"] .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f:after {
  left: -10px;
}

.spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f:after {
  content: "";
  height: 100%;
  width: 10px;
  position: absolute;
}

[dir="ltr"] .spectrum-InputGroup--quiet_a0942f .spectrum-InputGroup-icon_a0942f {
  right: 0;
}

[dir="rtl"] .spectrum-InputGroup--quiet_a0942f .spectrum-InputGroup-icon_a0942f {
  left: 0;
}

.spectrum-InputGroup--quiet_a0942f.is-disabled_a0942f .spectrum-FieldButton_a0942f {
  border-bottom-width: 1px;
}

[dir="ltr"] .spectrum-InputGroup--quiet_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-validationIcon_a0942f {
  padding-right: 0;
}

[dir="rtl"] .spectrum-InputGroup--quiet_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-validationIcon_a0942f {
  padding-left: 0;
}

[dir="ltr"] .spectrum-InputGroup--quiet_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-validationIcon_a0942f {
  right: 0;
}

[dir="rtl"] .spectrum-InputGroup--quiet_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-validationIcon_a0942f {
  left: 0;
}

[dir="ltr"] .spectrum-InputGroup--quiet_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-circleLoader_a0942f {
  padding-right: 0;
}

[dir="rtl"] .spectrum-InputGroup--quiet_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-circleLoader_a0942f {
  padding-left: 0;
}

[dir="ltr"] .spectrum-InputGroup--quiet_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-circleLoader_a0942f {
  right: var(--spectrum-global-dimension-size-10);
}

[dir="rtl"] .spectrum-InputGroup--quiet_a0942f .spectrum-InputGroup-field_a0942f .spectrum-InputGroup-input-circleLoader_a0942f {
  left: var(--spectrum-global-dimension-size-10);
}

[dir="ltr"] .spectrum-InputGroup-popover--quiet_a0942f {
  margin-right: calc(var(--spectrum-dropdown-quiet-offset) * -1);
}

[dir="rtl"] .spectrum-InputGroup-popover--quiet_a0942f {
  margin-left: calc(var(--spectrum-dropdown-quiet-offset) * -1);
}

.spectrum-InputGroup-popover--quiet_a0942f {
  --spectrum-dropdown-quiet-offset: calc(var(--spectrum-dropdown-quiet-popover-offset-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-popover-border-size, var(--spectrum-alias-border-size-thin)));
}

.spectrum-Datepicker--range_a0942f {
  border-radius: var(--spectrum-global-dimension-size-50);
}

.spectrum-Datepicker--range_a0942f.spectrum-InputGroup--quiet_a0942f, .spectrum-Datepicker--range_a0942f.spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f {
  border-radius: 0;
}

.spectrum-Datepicker--range_a0942f.spectrum-Datepicker--datetimeRange_a0942f .spectrum-InputGroup-field_a0942f {
  width: calc(var(--spectrum-global-dimension-size-1600)  - 2 * var(--spectrum-global-dimension-size-150)  + var(--spectrum-global-dimension-size-700)  - var(--spectrum-global-dimension-static-font-size-100, 14px) / 2);
  min-width: calc(var(--spectrum-global-dimension-size-1600)  - 2 * var(--spectrum-global-dimension-size-150)  + var(--spectrum-global-dimension-size-700)  - var(--spectrum-global-dimension-static-font-size-100, 14px) / 2);
}

.spectrum-Datepicker--range_a0942f .spectrum-InputGroup-field_a0942f {
  width: calc(var(--spectrum-global-dimension-size-1600)  - 2 * var(--spectrum-global-dimension-size-150));
  min-width: calc(var(--spectrum-global-dimension-size-1600)  - 2 * var(--spectrum-global-dimension-size-150));
  -ms-flex: initial;
  flex: initial;
}

[dir="ltr"] .spectrum-Datepicker--range_a0942f .spectrum-Datepicker-startField_a0942f .spectrum-InputGroup-field_a0942f {
  border-right: 0;
}

[dir="rtl"] .spectrum-Datepicker--range_a0942f .spectrum-Datepicker-startField_a0942f .spectrum-InputGroup-field_a0942f {
  border-left: 0;
}

[dir="ltr"] .spectrum-Datepicker--range_a0942f .spectrum-Datepicker-startField_a0942f .spectrum-InputGroup-field_a0942f {
  padding-right: var(--spectrum-global-dimension-size-150);
}

[dir="rtl"] .spectrum-Datepicker--range_a0942f .spectrum-Datepicker-startField_a0942f .spectrum-InputGroup-field_a0942f {
  padding-left: var(--spectrum-global-dimension-size-150);
}

.spectrum-Datepicker--range_a0942f .spectrum-Datepicker-startField_a0942f svg {
  display: none;
}

[dir="ltr"] .spectrum-Datepicker--range_a0942f .spectrum-Datepicker-endField_a0942f .spectrum-InputGroup-input_a0942f {
  border-left: 0;
}

[dir="rtl"] .spectrum-Datepicker--range_a0942f .spectrum-Datepicker-endField_a0942f .spectrum-InputGroup-input_a0942f {
  border-right: 0;
}

[dir="ltr"] .spectrum-Datepicker--range_a0942f .spectrum-Datepicker-endField_a0942f .spectrum-InputGroup-input_a0942f {
  padding-left: var(--spectrum-global-dimension-size-150);
}

[dir="rtl"] .spectrum-Datepicker--range_a0942f .spectrum-Datepicker-endField_a0942f .spectrum-InputGroup-input_a0942f {
  padding-right: var(--spectrum-global-dimension-size-150);
}

.spectrum-Datepicker--range_a0942f .spectrum-Datepicker-endField_a0942f .spectrum-InputGroup-input_a0942f {
  border-radius: 0;
  flex: 1;
}

.spectrum-Datepicker--range_a0942f .spectrum-Datepicker--rangeDash_a0942f {
  line-height: calc(var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height))  - var(--spectrum-global-dimension-size-100));
  -ms-flex: initial;
  flex: initial;
  width: 0;
  z-index: 1;
  padding-top: 0;
}

.spectrum-Datepicker--range_a0942f .spectrum-Datepicker--rangeDash_a0942f:before {
  content: "–";
  margin: 0 calc(-.5 * var(--spectrum-global-dimension-static-font-size-100, 14px));
  text-align: center;
  vertical-align: middle;
  width: var(--spectrum-global-dimension-static-font-size-100, 14px);
  display: inline-block;
  overflow: hidden;
}

.spectrum-Datepicker--range_a0942f.is-focused_a0942f .spectrum-Datepicker-focusRing_a0942f {
  border-radius: var(--spectrum-global-dimension-size-50);
  pointer-events: none;
  position: absolute;
  inset: 0;
}

.spectrum-Datepicker--range_a0942f.spectrum-InputGroup--quiet_a0942f.is-focused_a0942f .spectrum-Datepicker-focusRing_a0942f {
  border-radius: 0;
  top: auto;
}

[dir="ltr"] .spectrum-Datepicker--range_a0942f.spectrum-InputGroup--quiet_a0942f .spectrum-Datepicker--rangeDash_a0942f:before {
  margin-left: calc(-.5 * var(--spectrum-global-dimension-static-font-size-100, 14px));
}

[dir="rtl"] .spectrum-Datepicker--range_a0942f.spectrum-InputGroup--quiet_a0942f .spectrum-Datepicker--rangeDash_a0942f:before {
  margin-right: calc(-.5 * var(--spectrum-global-dimension-static-font-size-100, 14px));
}

.spectrum-InputGroup_a0942f.is-focused_a0942f:not(.spectrum-InputGroup--invalid_a0942f):not(.is-disabled_a0942f) .spectrum-InputGroup-input_a0942f, .spectrum-InputGroup_a0942f.is-focused_a0942f:not(.spectrum-InputGroup--invalid_a0942f):not(.is-disabled_a0942f) .spectrum-FieldButton_a0942f {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-InputGroup_a0942f.is-hovered_a0942f:not(.spectrum-InputGroup--invalid_a0942f):not(.is-focused_a0942f):not(.is-disabled_a0942f) .spectrum-InputGroup-input_a0942f, .spectrum-InputGroup_a0942f.is-hovered_a0942f:not(.spectrum-InputGroup--invalid_a0942f):not(.is-focused_a0942f):not(.is-disabled_a0942f) .spectrum-FieldButton_a0942f {
  border-color: var(--spectrum-textfield-border-color-hover, var(--spectrum-alias-border-color-hover));
}

.spectrum-InputGroup_a0942f.is-focused_a0942f .spectrum-FieldButton_a0942f, .spectrum-InputGroup_a0942f.is-focused_a0942f .spectrum-InputGroup-input_a0942f, .spectrum-InputGroup_a0942f.is-focused_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-FieldButton_a0942f, .spectrum-InputGroup_a0942f.is-focused_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-InputGroup-input_a0942f {
  border-color: var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-InputGroup_a0942f.focus-ring_a0942f:not(.spectrum-InputGroup--quiet_a0942f) .spectrum-FieldButton_a0942f {
  z-index: 1;
}

.spectrum-InputGroup_a0942f.focus-ring_a0942f:not(.spectrum-InputGroup--quiet_a0942f) .spectrum-FieldButton_a0942f, .spectrum-InputGroup_a0942f.focus-ring_a0942f:not(.spectrum-InputGroup--quiet_a0942f) .spectrum-InputGroup-input_a0942f, .spectrum-InputGroup_a0942f.focus-ring_a0942f:not(.spectrum-InputGroup--quiet_a0942f).spectrum-InputGroup--invalid_a0942f .spectrum-FieldButton_a0942f, .spectrum-InputGroup_a0942f.focus-ring_a0942f:not(.spectrum-InputGroup--quiet_a0942f).spectrum-InputGroup--invalid_a0942f .spectrum-InputGroup-input_a0942f {
  box-shadow: 0 0 0 1px var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-InputGroup--quiet_a0942f:not(.is-disabled_a0942f) .spectrum-FieldButton_a0942f:disabled, .spectrum-InputGroup--quiet_a0942f:not(.is-disabled_a0942f) .spectrum-FieldButton_a0942f:disabled.is-hovered_a0942f, .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f, .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f.is-hovered_a0942f, .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f:focus, .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f:active, .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f.is-selected_a0942f, .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f.spectrum-FieldButton--invalid_a0942f {
  border-color: var(--spectrum-textfield-quiet-border-color, var(--spectrum-alias-border-color));
}

.spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f:disabled, .spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f:disabled.is-hovered_a0942f {
  border-color: var(--spectrum-textfield-quiet-border-color-disabled, var(--spectrum-alias-border-color-mid));
}

.spectrum-InputGroup--quiet_a0942f.is-hovered_a0942f:not(.spectrum-InputGroup--invalid_a0942f):not(.is-focused_a0942f):not(.is-disabled_a0942f) .spectrum-FieldButton_a0942f {
  border-color: var(--spectrum-textfield-quiet-border-color-hover, var(--spectrum-alias-border-color-hover));
}

.spectrum-InputGroup--quiet_a0942f.spectrum-InputGroup_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-FieldButton_a0942f, .spectrum-InputGroup--quiet_a0942f.spectrum-InputGroup_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-InputGroup-input_a0942f {
  border-color: var(--spectrum-textfield-border-color-error, var(--spectrum-semantic-negative-color-default));
}

.spectrum-InputGroup--quiet_a0942f.spectrum-InputGroup_a0942f.is-focused_a0942f .spectrum-FieldButton_a0942f, .spectrum-InputGroup--quiet_a0942f.spectrum-InputGroup_a0942f.is-focused_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-FieldButton_a0942f, .spectrum-InputGroup--quiet_a0942f.spectrum-InputGroup_a0942f.is-focused_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-InputGroup-input_a0942f {
  border-color: var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-InputGroup--quiet_a0942f.spectrum-InputGroup_a0942f.focus-ring_a0942f .spectrum-InputGroup-input_a0942f {
  box-shadow: 0 1px 0 var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-InputGroup--quiet_a0942f.spectrum-InputGroup_a0942f.focus-ring_a0942f .spectrum-FieldButton_a0942f {
  box-shadow: 0 1px 0 var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  border-color: var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-InputGroup--quiet_a0942f.spectrum-InputGroup_a0942f.focus-ring_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-InputGroup-input_a0942f, .spectrum-InputGroup--quiet_a0942f.spectrum-InputGroup_a0942f.focus-ring_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-FieldButton_a0942f {
  box-shadow: 0 1px 0 var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Datepicker--range_a0942f.focus-ring_a0942f .spectrum-InputGroup-input_a0942f {
  box-shadow: none !important;
}

.spectrum-Datepicker--range_a0942f.is-disabled_a0942f .spectrum-Datepicker--rangeDash_a0942f {
  color: var(--spectrum-textfield-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-Datepicker--range_a0942f.focus-ring_a0942f {
  box-shadow: 0 0 0 1px var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Datepicker--range_a0942f.focus-ring_a0942f.spectrum-InputGroup--invalid_a0942f, .spectrum-Datepicker--range_a0942f.focus-ring_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-FieldButton_a0942f {
  box-shadow: 0 0 0 1px var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Datepicker--range_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-InputGroup-input_a0942f {
  border-color: var(--spectrum-dropdown-border-color-error, var(--spectrum-global-color-red-500));
}

.spectrum-Datepicker--range_a0942f.spectrum-InputGroup--invalid_a0942f.focus-ring_a0942f .spectrum-FieldButton_a0942f {
  border-color: var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 1px var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Datepicker--range_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-FieldButton_a0942f {
  border-color: var(--spectrum-dropdown-border-color-error, var(--spectrum-global-color-red-500));
}

.spectrum-Datepicker--range_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-FieldButton_a0942f.spectrum-FieldButton--invalid_a0942f.focus-ring_a0942f {
  border-color: var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 1px var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Datepicker--range_a0942f.spectrum-InputGroup--quiet_a0942f.is-focused_a0942f {
  box-shadow: 0 1px 0 0 var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Datepicker--range_a0942f.spectrum-InputGroup--quiet_a0942f.is-focused_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-FieldButton_a0942f {
  box-shadow: none;
  border-color: var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Datepicker--range_a0942f.spectrum-InputGroup--quiet_a0942f.is-focused_a0942f.spectrum-InputGroup--invalid_a0942f .spectrum-FieldButton_a0942f.spectrum-FieldButton--invalid_a0942f.focus-ring_a0942f {
  box-shadow: 0 2px 0 0 var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Datepicker--range_a0942f.spectrum-InputGroup--quiet_a0942f.is-focused_a0942f.spectrum-InputGroup--invalid_a0942f.focus-ring_a0942f {
  box-shadow: 0 0 0 1px var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

@media (forced-colors: active) {
  .spectrum-InputGroup_a0942f {
    --spectrum-dropdown-border-color-error: Highlight;
    --spectrum-dropdown-border-color-key-focus: Highlight;
    --spectrum-textfield-border-color-error: Highlight;
    --spectrum-textfield-border-color-hover: Highlight;
    --spectrum-textfield-border-color-key-focus: Highlight;
    --spectrum-textfield-quiet-border-color: CanvasText;
    --spectrum-textfield-quiet-border-color-disabled: GrayText;
    --spectrum-textfield-quiet-border-color-hover: Highlight;
    --spectrum-textfield-quiet-border-color-key-focus: Highlight;
    --spectrum-textfield-text-color-disabled: GrayText;
    forced-color-adjust: none;
  }

  .spectrum-InputGroup_a0942f .spectrum-InputGroup-input_a0942f, .spectrum-InputGroup_a0942f .spectrum-FieldButton_a0942f {
    forced-color-adjust: auto;
  }

  .spectrum-InputGroup_a0942f.is-disabled_a0942f:not(.spectrum-InputGroup--invalid_a0942f):not(.spectrum-InputGroup--quiet_a0942f) .spectrum-FieldButton_a0942f {
    border-color: GrayText;
    border-width: 1px;
  }

  .spectrum-InputGroup--quiet_a0942f:not(.is-disabled_a0942f) .spectrum-FieldButton_a0942f:disabled, .spectrum-InputGroup--quiet_a0942f:not(.is-disabled_a0942f) .spectrum-FieldButton_a0942f:disabled.is-hovered_a0942f {
    border-color: var(--spectrum-textfield-quiet-border-color, var(--spectrum-alias-border-color));
  }

  .spectrum-InputGroup--quiet_a0942f.is-disabled_a0942f .spectrum-FieldButton_a0942f {
    border-color: GrayText;
  }

  .spectrum-InputGroup--invalid_a0942f .spectrum-FieldButton_a0942f {
    border-color: Highlight;
  }

  .spectrum-InputGroup--invalid_a0942f.is-disabled_a0942f:not(.spectrum-InputGroup--quiet_a0942f) .spectrum-InputGroup-input_a0942f, .spectrum-InputGroup--invalid_a0942f.is-disabled_a0942f.spectrum-InputGroup--quiet_a0942f .spectrum-InputGroup-input_a0942f, .spectrum-InputGroup--invalid_a0942f.is-disabled_a0942f.spectrum-InputGroup--quiet_a0942f .spectrum-FieldButton_a0942f, .spectrum-InputGroup--invalid_a0942f.is-disabled_a0942f:not(.spectrum-InputGroup--quiet_a0942f) .spectrum-FieldButton_a0942f {
    border-color: GrayText;
  }

  .spectrum-InputGroup_a0942f.focus-ring_a0942f:not(.spectrum-InputGroup--quiet_a0942f) {
    outline: 2px solid Highlight;
  }

  .spectrum-InputGroup_a0942f.focus-ring_a0942f.spectrum-InputGroup--quiet_a0942f {
    box-shadow: 0 2px 0 0 var(--spectrum-dropdown-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  }
}

.spectrum-Textfield-input_73bc77 {
  font-family: adobe-clean-ux, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield-input_73bc77:lang(ar) {
  font-family: adobe-arabic, myriad-arabic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield-input_73bc77:lang(he) {
  font-family: adobe-hebrew, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield-input_73bc77:lang(zh-Hans), .spectrum-Textfield-input_73bc77:lang(zh) {
  font-family: adobe-clean-han-simplified-c, SimSun, Heiti SC Light, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield-input_73bc77:lang(ko) {
  font-family: adobe-clean-han-korean, Malgun Gothic, Apple Gothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield-input_73bc77:lang(ja) {
  font-family: adobe-clean-han-japanese, Yu Gothic, メイリオ, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro W3, Osaka, ＭＳＰゴシック, MS PGothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield_73bc77 {
  min-width: var(--spectrum-textfield-min-width, var(--spectrum-global-dimension-size-600));
  width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
  display: inline-flex;
  position: relative;
}

.spectrum-Textfield_73bc77.spectrum-Textfield--quiet_73bc77.spectrum-Textfield--multiline_73bc77 .spectrum-Textfield-input_73bc77 {
  height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  min-height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  overflow-x: hidden;
}

.spectrum-Textfield-input_73bc77 {
  box-sizing: border-box;
  border: var(--spectrum-textfield-border-size, var(--spectrum-alias-border-size-thin)) solid;
  border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  padding: 3px var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)) 5px calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  - 1px);
  text-indent: 0;
  width: 100%;
  height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  vertical-align: top;
  font-size: var(--spectrum-textfield-text-size, var(--spectrum-alias-font-size-default));
  line-height: var(--spectrum-textfield-text-line-height, var(--spectrum-alias-body-text-line-height));
  text-overflow: ellipsis;
  transition: border-color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  outline: 0;
  margin: 0;
  overflow: visible;
}

.spectrum-Textfield-input_73bc77::placeholder, .spectrum-Textfield-input_73bc77.is-placeholder_73bc77 {
  font-weight: var(--spectrum-textfield-placeholder-text-font-weight, var(--spectrum-global-font-weight-regular));
  font-style: var(--spectrum-textfield-placeholder-text-font-style, var(--spectrum-global-font-style-italic));
  font-synthesis: none;
  transition: color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  opacity: 1;
}

.spectrum-Textfield-input_73bc77::-moz-placeholder {
  font-weight: var(--spectrum-textfield-placeholder-text-font-weight, var(--spectrum-global-font-weight-regular));
  font-style: var(--spectrum-textfield-placeholder-text-font-style, var(--spectrum-global-font-style-italic));
  transition: color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  opacity: 1;
}

.spectrum-Textfield-input_73bc77:lang(ja)::placeholder, .spectrum-Textfield-input_73bc77:lang(ja).is-placeholder_73bc77, .spectrum-Textfield-input_73bc77:lang(zh)::placeholder, .spectrum-Textfield-input_73bc77:lang(zh).is-placeholder_73bc77, .spectrum-Textfield-input_73bc77:lang(ko)::placeholder, .spectrum-Textfield-input_73bc77:lang(ko).is-placeholder_73bc77, .spectrum-Textfield-input_73bc77:lang(ar)::placeholder, .spectrum-Textfield-input_73bc77:lang(ar).is-placeholder_73bc77, .spectrum-Textfield-input_73bc77:lang(he)::placeholder, .spectrum-Textfield-input_73bc77:lang(he).is-placeholder_73bc77 {
  font-style: normal;
}

.spectrum-Textfield-input_73bc77:lang(ja)::-moz-placeholder, .spectrum-Textfield-input_73bc77:lang(zh)::-moz-placeholder, .spectrum-Textfield-input_73bc77:lang(ko)::-moz-placeholder, .spectrum-Textfield-input_73bc77:lang(ar)::-moz-placeholder, .spectrum-Textfield-input_73bc77:lang(he)::-moz-placeholder {
  font-style: normal;
}

.spectrum-Textfield-input_73bc77.is-hovered_73bc77::placeholder {
  font-weight: var(--spectrum-textfield-placeholder-text-font-weight, var(--spectrum-global-font-weight-regular));
}

.spectrum-Textfield-input_73bc77:disabled {
  resize: none;
  opacity: 1;
}

.spectrum-Textfield-input_73bc77:disabled::placeholder, .spectrum-Textfield-input_73bc77:disabled.is-placeholder_73bc77 {
  font-weight: var(--spectrum-textfield-placeholder-text-font-weight, var(--spectrum-global-font-weight-regular));
}

.spectrum-Textfield-input_73bc77::-ms-clear {
  width: 0;
  height: 0;
}

.spectrum-Textfield-input_73bc77::-webkit-inner-spin-button, .spectrum-Textfield-input_73bc77::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spectrum-Textfield-input_73bc77:-moz-ui-invalid {
  box-shadow: none;
}

.spectrum-Textfield--multiline_73bc77 .spectrum-Textfield-input_73bc77 {
  height: auto;
  min-height: var(--spectrum-global-dimension-size-700);
  padding: 3px var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)) 5px calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  - 1px);
  resize: none;
  overflow: auto;
}

[dir="ltr"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-left: var(--spectrum-textfield-quiet-padding-x, 0);
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77, [dir="ltr"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-right: var(--spectrum-textfield-quiet-padding-x, 0);
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-left: var(--spectrum-textfield-quiet-padding-x, 0);
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  border-width: 0 0 var(--spectrum-textfield-quiet-border-size, var(--spectrum-alias-border-size-thin)) 0;
  border-radius: 0;
}

[dir="ltr"] .spectrum-Textfield--valid_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-right: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="rtl"] .spectrum-Textfield--valid_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-left: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="ltr"] .spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-right: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="rtl"] .spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-left: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="ltr"] .spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-right: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="rtl"] .spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-left: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

.spectrum-Textfield-wrapper_73bc77 .spectrum-Textfield--multiline_73bc77 {
  min-height: 0;
  flex: auto;
}

.spectrum-Textfield-wrapper_73bc77 .spectrum-Textfield--multiline_73bc77.spectrum-Textfield--quiet_73bc77 {
  max-height: 100%;
  flex: 0 auto;
}

.spectrum-Textfield-wrapper_73bc77 .spectrum-Textfield--multiline_73bc77.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  max-height: 100%;
}

[dir="ltr"] .spectrum-Textfield-validationIcon_73bc77 {
  padding-right: calc(var(--spectrum-global-dimension-size-150) / 2);
}

[dir="rtl"] .spectrum-Textfield-validationIcon_73bc77 {
  padding-left: calc(var(--spectrum-global-dimension-size-150) / 2);
}

.spectrum-Textfield-validationIcon_73bc77 {
  pointer-events: all;
  transition: color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  position: absolute;
}

[dir="ltr"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  padding-left: 0;
}

[dir="ltr"] .spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  right: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225)) / 2);
}

[dir="rtl"] .spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  left: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225)) / 2);
}

.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  width: var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225));
  height: var(--spectrum-icon-alert-medium-height, var(--spectrum-global-dimension-size-225));
  top: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-alert-medium-height, var(--spectrum-global-dimension-size-225)) / 2);
}

[dir="ltr"] .spectrum-Textfield--valid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  right: var(--spectrum-global-dimension-size-150);
}

[dir="rtl"] .spectrum-Textfield--valid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  left: var(--spectrum-global-dimension-size-150);
}

.spectrum-Textfield--valid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  width: var(--spectrum-icon-checkmark-medium-width);
  height: var(--spectrum-icon-checkmark-medium-height);
  top: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-checkmark-medium-height) / 2);
}

[dir="ltr"] .spectrum-Textfield-icon_73bc77 {
  left: var(--spectrum-global-dimension-size-150);
}

[dir="rtl"] .spectrum-Textfield-icon_73bc77 {
  right: var(--spectrum-global-dimension-size-150);
}

.spectrum-Textfield-icon_73bc77 {
  height: var(--spectrum-icon-info-medium-height, var(--spectrum-global-dimension-size-225));
  width: var(--spectrum-icon-info-medium-width, var(--spectrum-global-dimension-size-225));
  top: var(--spectrum-global-dimension-size-85);
  transition: fill var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  display: block;
  position: absolute;
}

[dir="ltr"] .spectrum-Textfield-inputIcon_73bc77 {
  padding-left: calc(var(--spectrum-global-dimension-size-150)  + var(--spectrum-global-dimension-size-225)  + var(--spectrum-global-dimension-size-65));
}

[dir="rtl"] .spectrum-Textfield-inputIcon_73bc77 {
  padding-right: calc(var(--spectrum-global-dimension-size-150)  + var(--spectrum-global-dimension-size-225)  + var(--spectrum-global-dimension-size-65));
}

[dir="ltr"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-inputIcon_73bc77 {
  padding-left: calc(var(--spectrum-global-dimension-size-100)  + var(--spectrum-icon-info-medium-width, var(--spectrum-global-dimension-size-225)));
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-inputIcon_73bc77 {
  padding-right: calc(var(--spectrum-global-dimension-size-100)  + var(--spectrum-icon-info-medium-width, var(--spectrum-global-dimension-size-225)));
}

[dir="ltr"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-inputIcon_73bc77 ~ .spectrum-Textfield-icon_73bc77 {
  left: 0;
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-inputIcon_73bc77 ~ .spectrum-Textfield-icon_73bc77 {
  right: 0;
}

.spectrum-Textfield-inputIcon_73bc77.spectrum-Textfield--multiline_73bc77 {
  height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  min-height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
}

[dir="ltr"] .spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-circleLoader_73bc77 {
  right: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225)) / 2 + var(--spectrum-global-dimension-size-150) / 2);
}

[dir="rtl"] .spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-circleLoader_73bc77 {
  left: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225)) / 2 + var(--spectrum-global-dimension-size-150) / 2);
}

.spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-circleLoader_73bc77 {
  top: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-loader-circle-small-width, var(--spectrum-global-dimension-static-size-200)) / 2);
  position: absolute;
}

[dir="ltr"] .spectrum-Textfield--quiet_73bc77.spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-circleLoader_73bc77 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77.spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-circleLoader_73bc77 {
  padding-left: 0;
}

.spectrum-Textfield-input_73bc77 {
  background-color: var(--spectrum-textfield-background-color, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-textfield-border-color, var(--spectrum-alias-border-color));
  color: var(--spectrum-textfield-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Textfield-input_73bc77::placeholder, .spectrum-Textfield-input_73bc77.is-placeholder_73bc77 {
  color: var(--spectrum-textfield-placeholder-text-color, var(--spectrum-alias-placeholder-text-color));
}

.spectrum-Textfield-input_73bc77.is-hovered_73bc77 {
  border-color: var(--spectrum-textfield-border-color-hover, var(--spectrum-alias-border-color-hover));
  box-shadow: none;
}

.spectrum-Textfield-input_73bc77.is-hovered_73bc77::placeholder, .spectrum-Textfield-input_73bc77.is-hovered_73bc77.is-placeholder_73bc77 {
  color: var(--spectrum-textfield-placeholder-text-color-hover, var(--spectrum-alias-placeholder-text-color-hover));
}

.spectrum-Textfield-input_73bc77.is-hovered_73bc77 ~ .spectrum-Textfield-icon_73bc77 {
  fill: var(--spectrum-textfield-icon-color-hover, var(--spectrum-global-color-gray-900));
}

.spectrum-Textfield-input_73bc77:active {
  background-color: var(--spectrum-textfield-background-color-down, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-textfield-border-color-down, var(--spectrum-alias-border-color-mouse-focus));
  color: var(--spectrum-textfield-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-Textfield-input_73bc77:active::placeholder, .spectrum-Textfield-input_73bc77:active.is-placeholder_73bc77 {
  color: var(--spectrum-textfield-placeholder-text-color-down, var(--spectrum-alias-placeholder-text-color-down));
}

.spectrum-Textfield-input_73bc77:active ~ .spectrum-Textfield-icon_73bc77 {
  fill: var(--spectrum-textfield-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-Textfield-input_73bc77:focus, .spectrum-Textfield-input_73bc77.is-focused_73bc77 {
  border-color: var(--spectrum-textfield-border-color-down, var(--spectrum-alias-border-color-mouse-focus));
}

.spectrum-Textfield-input_73bc77.focus-ring_73bc77:not(:active) {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 1px var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield-input_73bc77[disabled], .spectrum-Textfield-input_73bc77.is-disabled_73bc77 {
  background-color: var(--spectrum-textfield-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-textfield-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-textfield-text-color-disabled, var(--spectrum-alias-text-color-disabled));
  -webkit-text-fill-color: var(--spectrum-textfield-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-Textfield-input_73bc77[disabled]::placeholder, .spectrum-Textfield-input_73bc77[disabled].is-placeholder_73bc77, .spectrum-Textfield-input_73bc77.is-disabled_73bc77::placeholder, .spectrum-Textfield-input_73bc77.is-disabled_73bc77.is-placeholder_73bc77 {
  color: var(--spectrum-textfield-placeholder-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-Textfield-input_73bc77[disabled] ~ .spectrum-Textfield-icon_73bc77, .spectrum-Textfield-input_73bc77.is-disabled_73bc77 ~ .spectrum-Textfield-icon_73bc77 {
  fill: var(--spectrum-textfield-icon-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77 {
  border-color: var(--spectrum-textfield-border-color-error, var(--spectrum-semantic-negative-color-default));
}

.spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77.is-hovered_73bc77, .spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77:active, .spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77:focus, .spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77.is-focused_73bc77 {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77.focus-ring_73bc77:not(:active) {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 1px var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  background-color: var(--spectrum-textfield-quiet-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-textfield-quiet-border-color, var(--spectrum-alias-border-color));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.is-hovered_73bc77 {
  border-color: var(--spectrum-textfield-quiet-border-color-hover, var(--spectrum-alias-border-color-hover));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77:active {
  border-color: var(--spectrum-textfield-quiet-border-color-down, var(--spectrum-alias-border-color-mouse-focus));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77:focus, .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.is-focused_73bc77 {
  border-color: var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77:not(:active) {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 1px 0 var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77:disabled, .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.is-disabled_73bc77 {
  background-color: var(--spectrum-textfield-quiet-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-textfield-quiet-border-color-disabled, var(--spectrum-alias-border-color-mid));
}

.spectrum-Textfield_73bc77.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77 {
  border-color: var(--spectrum-textfield-border-color-error, var(--spectrum-semantic-negative-color-default));
}

.spectrum-Textfield_73bc77.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77:focus {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield_73bc77.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77:not(:active) {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 1px var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield--quiet_73bc77.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77:not(:active) {
  box-shadow: 0 1px 0 var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield-icon_73bc77 {
  fill: var(--spectrum-textfield-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-Textfield--valid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  fill: var(--spectrum-alert-success-icon-color, var(--spectrum-semantic-positive-color-icon));
}

.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  fill: var(--spectrum-alert-error-icon-color, var(--spectrum-semantic-negative-color-icon));
}

@media (forced-colors: active) {
  .spectrum-Textfield-input_73bc77 {
    --spectrum-textfield-text-color-disabled: GrayText;
    --spectrum-textfield-border-color-disabled: GrayText;
    --spectrum-textfield-quiet-border-color-disabled: GrayText;
    --spectrum-textfield-quiet-border-color-key-focus: Highlight;
    --spectrum-textfield-border-color-key-focus: Highlight;
    --spectrum-textfield-placeholder-text-color: GrayText;
    --spectrum-textfield-placeholder-text-color-hover: GrayText;
  }

  .spectrum-Textfield-input_73bc77.focus-ring_73bc77, .spectrum-Textfield-input_73bc77.focus-ring_73bc77 {
    outline: 2px solid Highlight;
  }

  .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77, .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77 {
    forced-color-adjust: none;
    box-shadow: 0 1px 0 0 var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
    outline: 0;
  }

  .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77 .spectrum-Textfield-input_73bc77, .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77 .spectrum-Textfield-input_73bc77 {
    border-color: var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  }
}

[dir="ltr"] .spectrum-HelpText_f395de {
  text-align: left;
}

[dir="rtl"] .spectrum-HelpText_f395de {
  text-align: right;
}

.spectrum-HelpText_f395de {
  font-size: var(--spectrum-global-dimension-font-size-75);
  display: flex;
}

[dir="ltr"] .spectrum-HelpText_f395de .spectrum-HelpText-validationIcon_f395de {
  margin-right: var(--spectrum-global-dimension-size-100);
}

[dir="rtl"] .spectrum-HelpText_f395de .spectrum-HelpText-validationIcon_f395de {
  margin-left: var(--spectrum-global-dimension-size-100);
}

.spectrum-HelpText_f395de .spectrum-HelpText-validationIcon_f395de {
  padding-top: var(--spectrum-global-dimension-size-40);
  padding-bottom: var(--spectrum-global-dimension-size-40);
  flex-shrink: 0;
}

[dir="ltr"] .spectrum-HelpText_f395de .spectrum-HelpText-text_f395de {
  margin-right: var(--spectrum-global-dimension-size-100);
}

[dir="rtl"] .spectrum-HelpText_f395de .spectrum-HelpText-text_f395de {
  margin-left: var(--spectrum-global-dimension-size-100);
}

.spectrum-HelpText_f395de .spectrum-HelpText-text_f395de {
  padding-top: var(--spectrum-global-dimension-static-size-50, 4px);
  padding-bottom: var(--spectrum-global-dimension-size-115);
  line-height: var(--spectrum-global-font-line-height-small, 1.3);
  text-transform: none;
  letter-spacing: var(--spectrum-global-font-letter-spacing-none, 0);
  flex: 1;
}

.spectrum-HelpText--neutral_f395de .spectrum-HelpText-text_f395de {
  color: var(--spectrum-alias-label-text-color, var(--spectrum-global-color-gray-700));
}

.spectrum-HelpText--neutral_f395de.is-disabled_f395de .spectrum-HelpText-text_f395de {
  color: var(--spectrum-alias-text-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-HelpText--negative_f395de .spectrum-HelpText-validationIcon_f395de, .spectrum-HelpText--negative_f395de .spectrum-HelpText-text_f395de {
  color: var(--spectrum-semantic-negative-color-text-small, var(--spectrum-global-color-red-600));
}

[dir="ltr"] .spectrum-FieldLabel_d2db1f {
  text-align: left;
}

[dir="rtl"] .spectrum-FieldLabel_d2db1f {
  text-align: right;
}

.spectrum-FieldLabel_d2db1f {
  box-sizing: border-box;
  padding: var(--spectrum-fieldlabel-padding-top, var(--spectrum-global-dimension-size-50)) 0 var(--spectrum-fieldlabel-padding-bottom, var(--spectrum-global-dimension-size-65));
  font-size: var(--spectrum-fieldlabel-text-size, var(--spectrum-global-dimension-font-size-75));
  font-weight: var(--spectrum-fieldlabel-text-font-weight, var(--spectrum-global-font-weight-regular));
  line-height: var(--spectrum-fieldlabel-text-line-height, var(--spectrum-global-font-line-height-small));
  vertical-align: top;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  font-smoothing: subpixel-antialiased;
  cursor: default;
  flex: none;
  display: flex;
}

[dir="ltr"] .spectrum-FieldLabel--positionSide_d2db1f {
  padding-right: var(--spectrum-fieldlabel-side-padding-x, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-FieldLabel--positionSide_d2db1f {
  padding-left: var(--spectrum-fieldlabel-side-padding-x, var(--spectrum-global-dimension-size-100));
}

.spectrum-FieldLabel--positionSide_d2db1f {
  padding-top: var(--spectrum-fieldlabel-side-padding-top, var(--spectrum-global-dimension-size-100));
  display: inline-flex;
}

[dir="ltr"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-right: 0;
}

[dir="rtl"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-left: 0;
}

[dir="ltr"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-left: var(--spectrum-fieldlabel-asterisk-gap, var(--spectrum-global-dimension-size-25));
}

[dir="rtl"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-right: var(--spectrum-fieldlabel-asterisk-gap, var(--spectrum-global-dimension-size-25));
}

.spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-top: var(--spectrum-fieldlabel-asterisk-margin-y, var(--spectrum-global-dimension-size-50));
  margin-bottom: 0;
}

[dir="ltr"] .spectrum-FieldLabel--alignEnd_d2db1f {
  text-align: right;
}

[dir="rtl"] .spectrum-FieldLabel--alignEnd_d2db1f {
  text-align: left;
}

.spectrum-FieldLabel--alignEnd_d2db1f {
  justify-content: flex-end;
}

.spectrum-Field_d2db1f {
  --spectrum-field-default-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
}

.spectrum-Field_d2db1f.spectrum-Field--positionTop_d2db1f {
  width: var(--spectrum-field-default-width);
  flex-direction: column;
  display: inline-flex;
}

.spectrum-Field_d2db1f.spectrum-Field--positionTop_d2db1f .spectrum-Field-field_d2db1f {
  width: 100%;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f {
  align-items: flex-start;
  display: inline-flex;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f {
  min-width: 0;
  width: var(--spectrum-field-default-width);
  height: 100%;
  flex: 1;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f .spectrum-Field-field_d2db1f {
  width: 100%;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-field_d2db1f {
  min-width: 0;
  flex: 1;
}

[dir="ltr"] .spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  text-align: left;
}

[dir="rtl"] .spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  text-align: right;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  border-collapse: separate;
  border-spacing: 0 var(--spectrum-global-dimension-size-300);
  margin: calc(var(--spectrum-global-dimension-size-250) * -1) 0;
  display: table;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field_d2db1f {
  width: 100%;
  display: table-row;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-FieldLabel_d2db1f {
  display: table-cell;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f {
  width: 100%;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field-field_d2db1f {
  width: auto;
  min-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
  display: table-cell;
}

.spectrum-Form_d2db1f.spectrum-Form--positionTop_d2db1f {
  min-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
  flex-direction: column;
  margin: 0;
  display: flex;
}

.spectrum-Form_d2db1f.spectrum-Form--positionTop_d2db1f > * {
  margin-top: var(--spectrum-global-dimension-size-100);
  width: 100%;
}

.spectrum-StaticField_d2db1f.spectrum-Field--positionSide_d2db1f {
  -ms-flex-align: first baseline;
  align-items: baseline;
}

.spectrum-StaticField_d2db1f.spectrum-Field--positionTop_d2db1f {
  width: unset;
}

.spectrum-StaticField_d2db1f .spectrum-FieldLabel_d2db1f.spectrum-FieldLabel_d2db1f {
  padding-top: 0;
}

.spectrum-StaticField_d2db1f .spectrum-Field-wrapper_d2db1f.spectrum-Field-wrapper_d2db1f {
  width: unset;
}

.spectrum-StaticField_d2db1f .spectrum-Field-field_d2db1f {
  word-break: break-word;
  overflow: hidden;
}

.spectrum-FieldLabel_d2db1f, .spectrum-Form-itemLabel_d2db1f {
  color: var(--spectrum-fieldlabel-text-color, var(--spectrum-alias-label-text-color));
}

.spectrum-FieldLabel_d2db1f.is-disabled_d2db1f, .spectrum-Form-itemLabel_d2db1f.is-disabled_d2db1f {
  color: var(--spectrum-fieldlabel-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldLabel_d2db1f.is-disabled_d2db1f .spectrum-FieldLabel-requiredIcon_d2db1f, .spectrum-Form-itemLabel_d2db1f.is-disabled_d2db1f .spectrum-FieldLabel-requiredIcon_d2db1f {
  color: var(--spectrum-fieldlabel-asterisk-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldLabel-requiredIcon_d2db1f {
  color: var(--spectrum-fieldlabel-asterisk-color, var(--spectrum-global-color-gray-600));
}

.flex-container_e15493, .flex_e15493 {
  display: flex;
}

.flex-gap_e15493 {
  --gap: 0px;
  --column-gap: var(--gap);
  --row-gap: var(--gap);
  margin: calc(var(--row-gap) / -2) calc(var(--column-gap) / -2);
  width: calc(100% + var(--column-gap)  + 1px);
  height: calc(100% + var(--row-gap));
}

.flex-container_e15493 .flex-gap_e15493 > * {
  margin: calc(var(--row-gap) / 2) calc(var(--column-gap) / 2);
}

[dir="ltr"] .spectrum-FieldLabel_d2db1f {
  text-align: left;
}

[dir="rtl"] .spectrum-FieldLabel_d2db1f {
  text-align: right;
}

.spectrum-FieldLabel_d2db1f {
  box-sizing: border-box;
  padding: var(--spectrum-fieldlabel-padding-top, var(--spectrum-global-dimension-size-50)) 0 var(--spectrum-fieldlabel-padding-bottom, var(--spectrum-global-dimension-size-65));
  font-size: var(--spectrum-fieldlabel-text-size, var(--spectrum-global-dimension-font-size-75));
  font-weight: var(--spectrum-fieldlabel-text-font-weight, var(--spectrum-global-font-weight-regular));
  line-height: var(--spectrum-fieldlabel-text-line-height, var(--spectrum-global-font-line-height-small));
  vertical-align: top;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  font-smoothing: subpixel-antialiased;
  cursor: default;
  flex: none;
  display: flex;
}

[dir="ltr"] .spectrum-FieldLabel--positionSide_d2db1f {
  padding-right: var(--spectrum-fieldlabel-side-padding-x, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-FieldLabel--positionSide_d2db1f {
  padding-left: var(--spectrum-fieldlabel-side-padding-x, var(--spectrum-global-dimension-size-100));
}

.spectrum-FieldLabel--positionSide_d2db1f {
  padding-top: var(--spectrum-fieldlabel-side-padding-top, var(--spectrum-global-dimension-size-100));
  display: inline-flex;
}

[dir="ltr"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-right: 0;
}

[dir="rtl"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-left: 0;
}

[dir="ltr"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-left: var(--spectrum-fieldlabel-asterisk-gap, var(--spectrum-global-dimension-size-25));
}

[dir="rtl"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-right: var(--spectrum-fieldlabel-asterisk-gap, var(--spectrum-global-dimension-size-25));
}

.spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-top: var(--spectrum-fieldlabel-asterisk-margin-y, var(--spectrum-global-dimension-size-50));
  margin-bottom: 0;
}

[dir="ltr"] .spectrum-FieldLabel--alignEnd_d2db1f {
  text-align: right;
}

[dir="rtl"] .spectrum-FieldLabel--alignEnd_d2db1f {
  text-align: left;
}

.spectrum-FieldLabel--alignEnd_d2db1f {
  justify-content: flex-end;
}

.spectrum-Field_d2db1f {
  --spectrum-field-default-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
}

.spectrum-Field_d2db1f.spectrum-Field--positionTop_d2db1f {
  width: var(--spectrum-field-default-width);
  flex-direction: column;
  display: inline-flex;
}

.spectrum-Field_d2db1f.spectrum-Field--positionTop_d2db1f .spectrum-Field-field_d2db1f {
  width: 100%;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f {
  align-items: flex-start;
  display: inline-flex;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f {
  min-width: 0;
  width: var(--spectrum-field-default-width);
  height: 100%;
  flex: 1;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f .spectrum-Field-field_d2db1f {
  width: 100%;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-field_d2db1f {
  min-width: 0;
  flex: 1;
}

[dir="ltr"] .spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  text-align: left;
}

[dir="rtl"] .spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  text-align: right;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  border-collapse: separate;
  border-spacing: 0 var(--spectrum-global-dimension-size-300);
  margin: calc(var(--spectrum-global-dimension-size-250) * -1) 0;
  display: table;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field_d2db1f {
  width: 100%;
  display: table-row;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-FieldLabel_d2db1f {
  display: table-cell;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f {
  width: 100%;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field-field_d2db1f {
  width: auto;
  min-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
  display: table-cell;
}

.spectrum-Form_d2db1f.spectrum-Form--positionTop_d2db1f {
  min-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
  flex-direction: column;
  margin: 0;
  display: flex;
}

.spectrum-Form_d2db1f.spectrum-Form--positionTop_d2db1f > * {
  margin-top: var(--spectrum-global-dimension-size-100);
  width: 100%;
}

.spectrum-StaticField_d2db1f.spectrum-Field--positionSide_d2db1f {
  -ms-flex-align: first baseline;
  align-items: baseline;
}

.spectrum-StaticField_d2db1f.spectrum-Field--positionTop_d2db1f {
  width: unset;
}

.spectrum-StaticField_d2db1f .spectrum-FieldLabel_d2db1f.spectrum-FieldLabel_d2db1f {
  padding-top: 0;
}

.spectrum-StaticField_d2db1f .spectrum-Field-wrapper_d2db1f.spectrum-Field-wrapper_d2db1f {
  width: unset;
}

.spectrum-StaticField_d2db1f .spectrum-Field-field_d2db1f {
  word-break: break-word;
  overflow: hidden;
}

.spectrum-FieldLabel_d2db1f, .spectrum-Form-itemLabel_d2db1f {
  color: var(--spectrum-fieldlabel-text-color, var(--spectrum-alias-label-text-color));
}

.spectrum-FieldLabel_d2db1f.is-disabled_d2db1f, .spectrum-Form-itemLabel_d2db1f.is-disabled_d2db1f {
  color: var(--spectrum-fieldlabel-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldLabel_d2db1f.is-disabled_d2db1f .spectrum-FieldLabel-requiredIcon_d2db1f, .spectrum-Form-itemLabel_d2db1f.is-disabled_d2db1f .spectrum-FieldLabel-requiredIcon_d2db1f {
  color: var(--spectrum-fieldlabel-asterisk-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldLabel-requiredIcon_d2db1f {
  color: var(--spectrum-fieldlabel-asterisk-color, var(--spectrum-global-color-gray-600));
}

.spectrum-Menu-popover_7ea47c {
  max-width: 320px;
}

[dir="ltr"] .spectrum-Menu_7ea47c {
  text-align: left;
}

[dir="rtl"] .spectrum-Menu_7ea47c {
  text-align: right;
}

.spectrum-Menu_7ea47c {
  box-sizing: border-box;
  padding: var(--spectrum-popover-padding-y, var(--spectrum-global-dimension-size-50)) 0;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  list-style-type: none;
  display: block;
  overflow-y: auto;
}

.spectrum-Menu_7ea47c .spectrum-Menu-sectionHeading_7ea47c {
  margin-top: var(--spectrum-global-dimension-size-40);
  margin-bottom: var(--spectrum-global-dimension-size-40);
}

.spectrum-Menu_7ea47c:focus {
  outline: 0;
}

.spectrum-Menu-checkmark_7ea47c {
  opacity: 1;
  padding-top: calc(var(--spectrum-selectlist-option-icon-padding-y)  - var(--spectrum-selectlist-option-padding-height));
  transform: scale(1);
}

[dir="ltr"] .spectrum-Menu-item_7ea47c {
  border-left: var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25)) solid transparent;
}

[dir="rtl"] .spectrum-Menu-item_7ea47c {
  border-right: var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25)) solid transparent;
}

.spectrum-Menu-item_7ea47c {
  cursor: default;
  box-sizing: border-box;
  font-size: var(--spectrum-selectlist-option-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-selectlist-option-text-font-weight, var(--spectrum-global-font-weight-regular));
  margin: 0;
  font-style: normal;
  text-decoration: none;
  position: relative;
}

.spectrum-Menu-item_7ea47c:focus {
  outline: 0;
}

.spectrum-Menu-item_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c {
  display: block;
}

[dir="ltr"] .spectrum-Menu-item_7ea47c .spectrum-Icon_7ea47c + .spectrum-Menu-itemLabel_7ea47c, [dir="ltr"] .spectrum-Menu-item_7ea47c .spectrum-Menu-itemIcon_7ea47c + .spectrum-Menu-itemLabel_7ea47c {
  margin-left: var(--spectrum-selectlist-thumbnail-image-padding-x, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-Menu-item_7ea47c .spectrum-Icon_7ea47c + .spectrum-Menu-itemLabel_7ea47c, [dir="rtl"] .spectrum-Menu-item_7ea47c .spectrum-Menu-itemIcon_7ea47c + .spectrum-Menu-itemLabel_7ea47c {
  margin-right: var(--spectrum-selectlist-thumbnail-image-padding-x, var(--spectrum-global-dimension-size-100));
}

.spectrum-Menu-itemLabel_7ea47c {
  line-height: var(--spectrum-global-font-line-height-small, 1.3);
  word-break: break-word;
  grid-area: text;
}

.spectrum-Menu-itemLabel--wrapping_7ea47c {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.spectrum-Menu-checkmark_7ea47c {
  grid-area: checkmark;
  place-self: flex-start end;
  display: none;
}

.spectrum-Menu-divider_7ea47c {
  box-sizing: content-box;
  height: var(--spectrum-selectlist-divider-size, var(--spectrum-alias-border-size-thick));
  margin: calc(var(--spectrum-selectlist-divider-padding, 3px) / 2) var(--spectrum-global-dimension-size-150);
  border: 0;
  padding: 0;
  overflow: visible;
}

[dir="ltr"] .spectrum-Menu-sectionHeading_7ea47c {
  padding-left: var(--spectrum-global-dimension-size-150);
}

[dir="rtl"] .spectrum-Menu-sectionHeading_7ea47c {
  padding-right: var(--spectrum-global-dimension-size-150);
}

[dir="ltr"] .spectrum-Menu-sectionHeading_7ea47c {
  padding-right: var(--spectrum-global-dimension-size-450);
}

[dir="rtl"] .spectrum-Menu-sectionHeading_7ea47c {
  padding-left: var(--spectrum-global-dimension-size-450);
}

.spectrum-Menu-sectionHeading_7ea47c {
  margin: var(--spectrum-global-dimension-size-75) 0 0 0;
  font-size: var(--spectrum-global-dimension-font-size-50);
  font-weight: 400;
  line-height: var(--spectrum-alias-line-height-medium, var(--spectrum-global-dimension-size-250));
  text-transform: uppercase;
  letter-spacing: .06em;
  padding-top: 0;
  padding-bottom: 0;
  display: block;
}

.spectrum-Menu_7ea47c .spectrum-Menu_7ea47c {
  margin: 0;
  display: block;
}

.spectrum-Menu-itemGrid_7ea47c {
  display: -ms-grid;
  -ms-grid-columns: calc(var(--spectrum-global-dimension-size-150)  - var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25))) auto 1fr auto auto auto var(--spectrum-global-dimension-size-150);
  grid-template-columns: calc(var(--spectrum-global-dimension-size-150)  - var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25))) auto 1fr auto auto auto var(--spectrum-global-dimension-size-150);
  -ms-grid-rows: var(--spectrum-selectlist-option-padding-height) 1fr auto var(--spectrum-selectlist-option-padding-height);
  grid-template-rows: var(--spectrum-selectlist-option-padding-height) 1fr auto var(--spectrum-selectlist-option-padding-height);
  grid-template-areas: ". . . . . . ."
                       ". icon text checkmark end keyboard ."
                       ". icon description checkmark end keyboard ."
                       ". . . . . . .";
  display: grid;
}

.spectrum-Menu-item_7ea47c.is-selectable_7ea47c .spectrum-Menu-itemGrid_7ea47c {
  -ms-grid-columns: calc(var(--spectrum-global-dimension-size-150)  - var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25))) auto 1fr calc(var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-selectlist-option-icon-padding-x, var(--spectrum-global-dimension-size-150))) auto auto var(--spectrum-global-dimension-size-150);
  grid-template-columns: calc(var(--spectrum-global-dimension-size-150)  - var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25))) auto 1fr calc(var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-selectlist-option-icon-padding-x, var(--spectrum-global-dimension-size-150))) auto auto var(--spectrum-global-dimension-size-150);
}

[dir="ltr"] .spectrum-Menu_7ea47c .spectrum-Menu-end_7ea47c {
  padding-left: var(--spectrum-global-dimension-size-125);
}

[dir="rtl"] .spectrum-Menu_7ea47c .spectrum-Menu-end_7ea47c {
  padding-right: var(--spectrum-global-dimension-size-125);
}

.spectrum-Menu_7ea47c .spectrum-Menu-end_7ea47c {
  grid-area: end;
  place-self: flex-start end;
}

[dir="ltr"] .spectrum-Menu-icon_7ea47c {
  padding-right: var(--spectrum-global-dimension-size-100);
}

[dir="rtl"] .spectrum-Menu-icon_7ea47c {
  padding-left: var(--spectrum-global-dimension-size-100);
}

.spectrum-Menu-icon_7ea47c {
  padding-top: var(--spectrum-global-dimension-size-10);
  grid-area: icon;
}

.spectrum-Menu-description_7ea47c {
  line-height: var(--spectrum-global-font-line-height-small, 1.3);
  font-size: var(--spectrum-global-dimension-size-150);
  grid-area: description;
}

[dir="ltr"] .spectrum-Menu-keyboard_7ea47c {
  padding-left: var(--spectrum-global-dimension-size-125);
}

[dir="rtl"] .spectrum-Menu-keyboard_7ea47c {
  padding-right: var(--spectrum-global-dimension-size-125);
}

.spectrum-Menu-keyboard_7ea47c {
  grid-area: keyboard;
  align-self: flex-start;
  font-family: adobe-clean-ux, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Menu_7ea47c {
  background-color: var(--spectrum-selectlist-background-color, var(--spectrum-alias-background-color-transparent));
}

.spectrum-Menu-item_7ea47c {
  background-color: var(--spectrum-selectlist-option-background-color, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-selectlist-option-text-color, var(--spectrum-alias-text-color));
}

[dir="ltr"] .spectrum-Menu-item_7ea47c.focus-ring_7ea47c, [dir="ltr"] .spectrum-Menu-item_7ea47c.is-focused_7ea47c {
  border-left-color: var(--spectrum-selectlist-option-focus-indicator-color, var(--spectrum-alias-border-color-focus));
}

[dir="rtl"] .spectrum-Menu-item_7ea47c.focus-ring_7ea47c, [dir="rtl"] .spectrum-Menu-item_7ea47c.is-focused_7ea47c {
  border-right-color: var(--spectrum-selectlist-option-focus-indicator-color, var(--spectrum-alias-border-color-focus));
}

.spectrum-Menu-item_7ea47c.focus-ring_7ea47c, .spectrum-Menu-item_7ea47c.is-focused_7ea47c {
  background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
}

.spectrum-Menu-item_7ea47c.is-hovered_7ea47c, .spectrum-Menu-item_7ea47c:focus, .spectrum-Menu-item_7ea47c.is-highlighted_7ea47c, .spectrum-Menu-item_7ea47c.is-open_7ea47c {
  background-color: var(--spectrum-selectlist-option-background-color-hover, var(--spectrum-alias-background-color-hover-overlay));
  color: var(--spectrum-selectlist-option-text-color-hover, var(--spectrum-alias-text-color));
}

.spectrum-Menu-item_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c {
  color: var(--spectrum-selectlist-option-icon-color-selected, var(--spectrum-alias-icon-color-selected));
}

.spectrum-Menu-item_7ea47c .is-active_7ea47c, .spectrum-Menu-item_7ea47c:active {
  background-color: var(--spectrum-selectlist-option-background-color-down, var(--spectrum-alias-background-color-hover-overlay));
}

.spectrum-Menu-item_7ea47c.is-disabled_7ea47c {
  background-color: var(--spectrum-selectlist-option-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-selectlist-option-text-color-disabled, var(--spectrum-alias-text-color-disabled));
  cursor: default;
  background-image: none;
}

.spectrum-Menu-sectionHeading_7ea47c {
  color: var(--spectrum-heading-subtitle3-text-color, var(--spectrum-global-color-gray-700));
}

.spectrum-Menu-divider_7ea47c {
  background-color: var(--spectrum-global-color-gray-300);
}

.spectrum-Menu-description_7ea47c {
  color: var(--spectrum-global-color-gray-700);
}

@media (forced-colors: active) {
  .spectrum-Menu-divider_7ea47c {
    background-color: CanvasText;
    forced-color-adjust: none;
  }

  .spectrum-Menu-item_7ea47c {
    forced-color-adjust: none;
    --spectrum-heading-subtitle3-text-color: ButtonText;
    --spectrum-selectlist-background-color: ButtonFace;
    --spectrum-selectlist-divider-color: ButtonText;
    --spectrum-selectlist-option-background-color: ButtonFace;
    --spectrum-selectlist-option-background-color-disabled: ButtonFace;
    --spectrum-selectlist-option-background-color-down: ButtonFace;
    --spectrum-selectlist-option-background-color-hover: Highlight;
    --spectrum-selectlist-option-background-color-key-focus: Highlight;
    --spectrum-selectlist-option-focus-indicator-color: Highlight;
    --spectrum-selectlist-option-icon-color-selected: Highlight;
    --spectrum-selectlist-option-text-color: ButtonText;
    --spectrum-selectlist-option-text-color-disabled: GrayText;
    --spectrum-selectlist-option-text-color-hover: HighlightText;
    --spectrum-selectlist-option-text-color-key-focus: HighlightText;
    --spectrum-alias-border-color-focus: Highlight;
  }

  .spectrum-Menu-item_7ea47c .spectrum-Menu-description_7ea47c {
    color: var(--spectrum-selectlist-option-text-color, var(--spectrum-alias-text-color));
  }

  .spectrum-Menu-item_7ea47c.is-hovered_7ea47c, .spectrum-Menu-item_7ea47c:focus, .spectrum-Menu-item_7ea47c.is-highlighted_7ea47c, .spectrum-Menu-item_7ea47c.is-open_7ea47c, .spectrum-Menu-item_7ea47c.focus-ring_7ea47c, .spectrum-Menu-item_7ea47c.is-focused_7ea47c {
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
  }

  .spectrum-Menu-item_7ea47c.is-hovered_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c, .spectrum-Menu-item_7ea47c:focus.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c, .spectrum-Menu-item_7ea47c.is-highlighted_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c, .spectrum-Menu-item_7ea47c.is-open_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c, .spectrum-Menu-item_7ea47c.focus-ring_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c, .spectrum-Menu-item_7ea47c.is-focused_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c {
    color: HighlightText;
  }

  .spectrum-Menu-item_7ea47c.is-hovered_7ea47c .spectrum-Menu-description_7ea47c, .spectrum-Menu-item_7ea47c:focus .spectrum-Menu-description_7ea47c, .spectrum-Menu-item_7ea47c.is-highlighted_7ea47c .spectrum-Menu-description_7ea47c, .spectrum-Menu-item_7ea47c.is-open_7ea47c .spectrum-Menu-description_7ea47c, .spectrum-Menu-item_7ea47c.focus-ring_7ea47c .spectrum-Menu-description_7ea47c, .spectrum-Menu-item_7ea47c.is-focused_7ea47c .spectrum-Menu-description_7ea47c {
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  }
}

.spectrum-BarLoader_48541f {
  min-width: var(--spectrum-global-dimension-static-size-600, 48px);
  width: var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400));
  vertical-align: top;
  isolation: isolate;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.spectrum-BarLoader_48541f.spectrum-BarLoader--indeterminate_48541f {
  max-width: var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400));
}

.spectrum-BarLoader_48541f.spectrum-BarLoader--indeterminate_48541f.spectrum-BarLoader--sideLabel_48541f .spectrum-BarLoader-track_48541f {
  max-width: var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400));
  width: 100%;
}

.spectrum-BarLoader-track_48541f {
  min-width: var(--spectrum-global-dimension-static-size-600, 48px);
  width: 100%;
  height: var(--spectrum-barloader-large-height, var(--spectrum-global-dimension-size-75));
  border-radius: var(--spectrum-barloader-large-border-radius);
  z-index: 1;
  overflow: hidden;
}

.spectrum-BarLoader-fill_48541f {
  height: var(--spectrum-barloader-large-height, var(--spectrum-global-dimension-size-75));
  border: 0;
  transition: width 1s;
}

[dir="ltr"] .spectrum-BarLoader-label_48541f, [dir="ltr"] .spectrum-BarLoader-percentage_48541f {
  text-align: left;
}

[dir="rtl"] .spectrum-BarLoader-label_48541f, [dir="rtl"] .spectrum-BarLoader-percentage_48541f {
  text-align: right;
}

.spectrum-BarLoader-label_48541f, .spectrum-BarLoader-percentage_48541f {
  font-size: var(--spectrum-fieldlabel-text-size, var(--spectrum-global-dimension-font-size-75));
  font-weight: var(--spectrum-fieldlabel-text-font-weight, var(--spectrum-global-font-weight-regular));
  line-height: var(--spectrum-fieldlabel-text-line-height, var(--spectrum-global-font-line-height-small));
  margin-bottom: var(--spectrum-barloader-large-label-gap-y, var(--spectrum-global-dimension-size-115));
}

.spectrum-BarLoader-label_48541f {
  flex: 1;
}

[dir="ltr"] .spectrum-BarLoader-percentage_48541f {
  margin-left: var(--spectrum-barloader-small-label-gap-x, var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-BarLoader-percentage_48541f {
  margin-right: var(--spectrum-barloader-small-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.spectrum-BarLoader-percentage_48541f {
  align-self: flex-start;
}

.spectrum-BarLoader--sideLabel_48541f {
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  flex-flow: row;
  justify-content: space-between;
  display: inline-flex;
}

.spectrum-BarLoader--sideLabel_48541f .spectrum-BarLoader-track_48541f {
  width: inherit;
}

[dir="ltr"] .spectrum-BarLoader--sideLabel_48541f .spectrum-BarLoader-label_48541f {
  margin-right: var(--spectrum-barloader-large-label-gap-x, var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-BarLoader--sideLabel_48541f .spectrum-BarLoader-label_48541f {
  margin-left: var(--spectrum-barloader-large-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.spectrum-BarLoader--sideLabel_48541f .spectrum-BarLoader-label_48541f {
  margin-bottom: 0;
}

[dir="ltr"] .spectrum-BarLoader--sideLabel_48541f .spectrum-BarLoader-percentage_48541f {
  text-align: right;
}

[dir="rtl"] .spectrum-BarLoader--sideLabel_48541f .spectrum-BarLoader-percentage_48541f {
  text-align: left;
}

[dir="ltr"] .spectrum-BarLoader--sideLabel_48541f .spectrum-BarLoader-percentage_48541f {
  margin-left: var(--spectrum-barloader-large-label-gap-x, var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-BarLoader--sideLabel_48541f .spectrum-BarLoader-percentage_48541f {
  margin-right: var(--spectrum-barloader-large-label-gap-x, var(--spectrum-global-dimension-size-150));
}

.spectrum-BarLoader--sideLabel_48541f .spectrum-BarLoader-percentage_48541f {
  order: 3;
  margin-bottom: 0;
}

.spectrum-BarLoader--small_48541f {
  min-width: var(--spectrum-global-dimension-static-size-600, 48px);
}

.spectrum-BarLoader--small_48541f .spectrum-BarLoader-fill_48541f {
  height: var(--spectrum-barloader-small-height, var(--spectrum-global-dimension-size-50));
}

.spectrum-BarLoader--small_48541f .spectrum-BarLoader-track_48541f {
  height: var(--spectrum-barloader-small-height, var(--spectrum-global-dimension-size-50));
  border-radius: var(--spectrum-barloader-small-border-radius);
}

.spectrum-BarLoader--indeterminate_48541f .spectrum-BarLoader-fill_48541f {
  width: var(--spectrum-barloader-large-indeterminate-fill-width, var(--spectrum-global-dimension-size-1700));
  animation-timing-function: var(--spectrum-barloader-large-indeterminate-animation-ease, var(--spectrum-global-animation-ease-in-out));
  will-change: transform;
  position: relative;
}

[dir="ltr"] .spectrum-BarLoader--indeterminate_48541f .spectrum-BarLoader-fill_48541f {
  animation: indeterminate-loop-ltr_48541f var(--spectrum-barloader-large-indeterminate-duration, var(--spectrum-global-animation-duration-2000)) infinite;
}

[dir="rtl"] .spectrum-BarLoader--indeterminate_48541f .spectrum-BarLoader-fill_48541f {
  animation: indeterminate-loop-rtl_48541f var(--spectrum-barloader-large-indeterminate-duration, var(--spectrum-global-animation-duration-2000)) infinite;
}

@keyframes indeterminate-loop-ltr_48541f {
  0% {
    transform: translate(calc(var(--spectrum-barloader-large-indeterminate-fill-width, var(--spectrum-global-dimension-size-1700)) * -1) );
  }

  to {
    transform: translate(var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400)));
  }
}

@keyframes indeterminate-loop-rtl_48541f {
  0% {
    transform: translate(var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400)));
  }

  to {
    transform: translate(calc(var(--spectrum-barloader-large-width, var(--spectrum-global-dimension-size-2400)) * -1) );
  }
}

.spectrum-BarLoader_48541f .spectrum-BarLoader-fill_48541f {
  background: var(--spectrum-barloader-large-track-fill-color, var(--spectrum-global-color-blue-500));
}

.spectrum-BarLoader_48541f .spectrum-BarLoader-track_48541f {
  background-color: var(--spectrum-barloader-large-track-color, var(--spectrum-alias-track-color-default));
}

.spectrum-BarLoader_48541f.spectrum-BarLoader--overBackground_48541f .spectrum-BarLoader-fill_48541f {
  background: var(--spectrum-barloader-large-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

.spectrum-BarLoader_48541f.spectrum-BarLoader--overBackground_48541f .spectrum-BarLoader-label_48541f, .spectrum-BarLoader_48541f.spectrum-BarLoader--overBackground_48541f .spectrum-BarLoader-percentage_48541f {
  color: var(--spectrum-barloader-large-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

.spectrum-BarLoader_48541f.spectrum-BarLoader--overBackground_48541f .spectrum-BarLoader-track_48541f {
  background-color: var(--spectrum-barloader-large-over-background-track-color, var(--spectrum-alias-track-color-over-background));
}

.spectrum-BarLoader_48541f.is-positive_48541f .spectrum-BarLoader-fill_48541f {
  background: var(--spectrum-meter-large-track-color-positive, var(--spectrum-semantic-positive-color-status));
}

.spectrum-BarLoader_48541f.is-warning_48541f .spectrum-BarLoader-fill_48541f {
  background: var(--spectrum-meter-large-track-color-warning, var(--spectrum-semantic-notice-color-status));
}

.spectrum-BarLoader_48541f.is-critical_48541f .spectrum-BarLoader-fill_48541f {
  background: var(--spectrum-meter-large-track-color-critical, var(--spectrum-semantic-negative-color-status));
}

.spectrum-BarLoader-label_48541f, .spectrum-BarLoader-percentage_48541f {
  color: var(--spectrum-fieldlabel-text-color, var(--spectrum-alias-label-text-color));
  color: var(--spectrum-fieldlabel-text-color, var(--spectrum-alias-label-text-color));
  color: var(--spectrum-fieldlabel-text-color, var(--spectrum-alias-label-text-color));
}

@media (forced-colors: active) {
  .spectrum-BarLoader-track_48541f {
    forced-color-adjust: none;
    --spectrum-barloader-large-track-fill-color: ButtonText;
    --spectrum-barloader-large-track-color: ButtonFace;
    border: 1px solid ButtonText;
  }
}

.spectrum-CircleLoader--indeterminate-fill-submask-2_7c1153 {
  animation: 1s linear infinite spectrum-fill-mask-2_7c1153;
}

@keyframes spectrum-fill-mask-1_7c1153 {
  0% {
    transform: rotate(90deg);
  }

  1.69% {
    transform: rotate(72.3deg);
  }

  3.39% {
    transform: rotate(55.5deg);
  }

  5.08% {
    transform: rotate(40.3deg);
  }

  6.78% {
    transform: rotate(25deg);
  }

  8.47% {
    transform: rotate(10.6deg);
  }

  10.17% {
    transform: rotate(0);
  }

  11.86% {
    transform: rotate(0);
  }

  13.56% {
    transform: rotate(0);
  }

  15.25% {
    transform: rotate(0);
  }

  16.95% {
    transform: rotate(0);
  }

  18.64% {
    transform: rotate(0);
  }

  20.34% {
    transform: rotate(0);
  }

  22.03% {
    transform: rotate(0);
  }

  23.73% {
    transform: rotate(0);
  }

  25.42% {
    transform: rotate(0);
  }

  27.12% {
    transform: rotate(0);
  }

  28.81% {
    transform: rotate(0);
  }

  30.51% {
    transform: rotate(0);
  }

  32.2% {
    transform: rotate(0);
  }

  33.9% {
    transform: rotate(0);
  }

  35.59% {
    transform: rotate(0);
  }

  37.29% {
    transform: rotate(0);
  }

  38.98% {
    transform: rotate(0);
  }

  40.68% {
    transform: rotate(0);
  }

  42.37% {
    transform: rotate(5.3deg);
  }

  44.07% {
    transform: rotate(13.4deg);
  }

  45.76% {
    transform: rotate(20.6deg);
  }

  47.46% {
    transform: rotate(29deg);
  }

  49.15% {
    transform: rotate(36.5deg);
  }

  50.85% {
    transform: rotate(42.6deg);
  }

  52.54% {
    transform: rotate(48.8deg);
  }

  54.24% {
    transform: rotate(54.2deg);
  }

  55.93% {
    transform: rotate(59.4deg);
  }

  57.63% {
    transform: rotate(63.2deg);
  }

  59.32% {
    transform: rotate(67.2deg);
  }

  61.02% {
    transform: rotate(70.8deg);
  }

  62.71% {
    transform: rotate(73.8deg);
  }

  64.41% {
    transform: rotate(76.2deg);
  }

  66.1% {
    transform: rotate(78.7deg);
  }

  67.8% {
    transform: rotate(80.6deg);
  }

  69.49% {
    transform: rotate(82.6deg);
  }

  71.19% {
    transform: rotate(83.7deg);
  }

  72.88% {
    transform: rotate(85deg);
  }

  74.58% {
    transform: rotate(86.3deg);
  }

  76.27% {
    transform: rotate(87deg);
  }

  77.97% {
    transform: rotate(87.7deg);
  }

  79.66% {
    transform: rotate(88.3deg);
  }

  81.36% {
    transform: rotate(88.6deg);
  }

  83.05% {
    transform: rotate(89.2deg);
  }

  84.75% {
    transform: rotate(89.2deg);
  }

  86.44% {
    transform: rotate(89.5deg);
  }

  88.14% {
    transform: rotate(89.9deg);
  }

  89.83% {
    transform: rotate(89.7deg);
  }

  91.53% {
    transform: rotate(90.1deg);
  }

  93.22% {
    transform: rotate(90.2deg);
  }

  94.92% {
    transform: rotate(90.1deg);
  }

  96.61% {
    transform: rotate(90deg);
  }

  98.31% {
    transform: rotate(89.8deg);
  }

  to {
    transform: rotate(90deg);
  }
}

@keyframes spectrum-fill-mask-2_7c1153 {
  0% {
    transform: rotate(180deg);
  }

  1.69% {
    transform: rotate(180deg);
  }

  3.39% {
    transform: rotate(180deg);
  }

  5.08% {
    transform: rotate(180deg);
  }

  6.78% {
    transform: rotate(180deg);
  }

  8.47% {
    transform: rotate(180deg);
  }

  10.17% {
    transform: rotate(179.2deg);
  }

  11.86% {
    transform: rotate(164deg);
  }

  13.56% {
    transform: rotate(151.8deg);
  }

  15.25% {
    transform: rotate(140.8deg);
  }

  16.95% {
    transform: rotate(130.3deg);
  }

  18.64% {
    transform: rotate(120.4deg);
  }

  20.34% {
    transform: rotate(110.8deg);
  }

  22.03% {
    transform: rotate(101.6deg);
  }

  23.73% {
    transform: rotate(93.5deg);
  }

  25.42% {
    transform: rotate(85.4deg);
  }

  27.12% {
    transform: rotate(78.1deg);
  }

  28.81% {
    transform: rotate(71.2deg);
  }

  30.51% {
    transform: rotate(89.1deg);
  }

  32.2% {
    transform: rotate(105.5deg);
  }

  33.9% {
    transform: rotate(121.3deg);
  }

  35.59% {
    transform: rotate(135.5deg);
  }

  37.29% {
    transform: rotate(148.4deg);
  }

  38.98% {
    transform: rotate(161deg);
  }

  40.68% {
    transform: rotate(173.5deg);
  }

  42.37% {
    transform: rotate(180deg);
  }

  44.07% {
    transform: rotate(180deg);
  }

  45.76% {
    transform: rotate(180deg);
  }

  47.46% {
    transform: rotate(180deg);
  }

  49.15% {
    transform: rotate(180deg);
  }

  50.85% {
    transform: rotate(180deg);
  }

  52.54% {
    transform: rotate(180deg);
  }

  54.24% {
    transform: rotate(180deg);
  }

  55.93% {
    transform: rotate(180deg);
  }

  57.63% {
    transform: rotate(180deg);
  }

  59.32% {
    transform: rotate(180deg);
  }

  61.02% {
    transform: rotate(180deg);
  }

  62.71% {
    transform: rotate(180deg);
  }

  64.41% {
    transform: rotate(180deg);
  }

  66.1% {
    transform: rotate(180deg);
  }

  67.8% {
    transform: rotate(180deg);
  }

  69.49% {
    transform: rotate(180deg);
  }

  71.19% {
    transform: rotate(180deg);
  }

  72.88% {
    transform: rotate(180deg);
  }

  74.58% {
    transform: rotate(180deg);
  }

  76.27% {
    transform: rotate(180deg);
  }

  77.97% {
    transform: rotate(180deg);
  }

  79.66% {
    transform: rotate(180deg);
  }

  81.36% {
    transform: rotate(180deg);
  }

  83.05% {
    transform: rotate(180deg);
  }

  84.75% {
    transform: rotate(180deg);
  }

  86.44% {
    transform: rotate(180deg);
  }

  88.14% {
    transform: rotate(180deg);
  }

  89.83% {
    transform: rotate(180deg);
  }

  91.53% {
    transform: rotate(180deg);
  }

  93.22% {
    transform: rotate(180deg);
  }

  94.92% {
    transform: rotate(180deg);
  }

  96.61% {
    transform: rotate(180deg);
  }

  98.31% {
    transform: rotate(180deg);
  }

  to {
    transform: rotate(180deg);
  }
}

@keyframes spectrum-fills-rotate_7c1153 {
  0% {
    transform: rotate(-90deg);
  }

  to {
    transform: rotate(270deg);
  }
}

.spectrum-CircleLoader_7c1153 {
  width: var(--spectrum-loader-circle-medium-width, var(--spectrum-global-dimension-static-size-400));
  height: var(--spectrum-loader-circle-medium-height, var(--spectrum-global-dimension-static-size-400));
  direction: ltr;
  display: inline-block;
  position: relative;
}

.spectrum-CircleLoader-track_7c1153 {
  box-sizing: border-box;
  width: var(--spectrum-loader-circle-medium-width, var(--spectrum-global-dimension-static-size-400));
  height: var(--spectrum-loader-circle-medium-height, var(--spectrum-global-dimension-static-size-400));
  border-style: solid;
  border-width: var(--spectrum-loader-circle-medium-border-size);
  border-radius: var(--spectrum-loader-circle-medium-width, var(--spectrum-global-dimension-static-size-400));
}

.spectrum-CircleLoader-fills_7c1153 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.spectrum-CircleLoader-fill_7c1153 {
  box-sizing: border-box;
  width: var(--spectrum-loader-circle-medium-width, var(--spectrum-global-dimension-static-size-400));
  height: var(--spectrum-loader-circle-medium-height, var(--spectrum-global-dimension-static-size-400));
  border-style: solid;
  border-width: var(--spectrum-loader-circle-medium-border-size);
  border-radius: var(--spectrum-loader-circle-medium-width, var(--spectrum-global-dimension-static-size-400));
}

.spectrum-CircleLoader-fillMask1_7c1153, .spectrum-CircleLoader-fillMask2_7c1153 {
  width: 50%;
  height: 100%;
  transform-origin: 100%;
  position: absolute;
  overflow: hidden;
  transform: rotate(180deg);
}

.spectrum-CircleLoader-fillSubMask1_7c1153, .spectrum-CircleLoader-fillSubMask2_7c1153 {
  width: 100%;
  height: 100%;
  transform-origin: 100%;
  overflow: hidden;
  transform: rotate(180deg);
}

.spectrum-CircleLoader-fillMask2_7c1153 {
  transform: rotate(0);
}

.spectrum-CircleLoader--small_7c1153 {
  width: var(--spectrum-loader-circle-small-width, var(--spectrum-global-dimension-static-size-200));
  height: var(--spectrum-loader-circle-small-height, var(--spectrum-global-dimension-static-size-200));
}

.spectrum-CircleLoader--small_7c1153 .spectrum-CircleLoader-track_7c1153, .spectrum-CircleLoader--small_7c1153 .spectrum-CircleLoader-fill_7c1153 {
  width: var(--spectrum-loader-circle-small-width, var(--spectrum-global-dimension-static-size-200));
  height: var(--spectrum-loader-circle-small-height, var(--spectrum-global-dimension-static-size-200));
  border-style: solid;
  border-width: var(--spectrum-loader-circle-small-border-size);
  border-radius: var(--spectrum-loader-circle-small-width, var(--spectrum-global-dimension-static-size-200));
}

.spectrum-CircleLoader--large_7c1153 {
  width: var(--spectrum-loader-circle-large-width, var(--spectrum-global-dimension-size-800));
  height: var(--spectrum-loader-circle-large-height, var(--spectrum-global-dimension-size-800));
}

.spectrum-CircleLoader--large_7c1153 .spectrum-CircleLoader-track_7c1153, .spectrum-CircleLoader--large_7c1153 .spectrum-CircleLoader-fill_7c1153 {
  width: var(--spectrum-loader-circle-large-width, var(--spectrum-global-dimension-size-800));
  height: var(--spectrum-loader-circle-large-height, var(--spectrum-global-dimension-size-800));
  border-style: solid;
  border-width: var(--spectrum-loader-circle-large-border-size, var(--spectrum-global-dimension-size-50));
  border-radius: var(--spectrum-loader-circle-large-width, var(--spectrum-global-dimension-size-800));
}

.spectrum-CircleLoader--indeterminate_7c1153 .spectrum-CircleLoader-fills_7c1153 {
  will-change: transform;
  transform-origin: 50%;
  animation: 1s cubic-bezier(.25, .78, .48, .89) infinite spectrum-fills-rotate_7c1153;
  transform: translateZ(0);
}

.spectrum-CircleLoader--indeterminate_7c1153 .spectrum-CircleLoader-fillSubMask1_7c1153 {
  will-change: transform;
  animation: 1s linear infinite spectrum-fill-mask-1_7c1153;
  transform: translateZ(0);
}

.spectrum-CircleLoader--indeterminate_7c1153 .spectrum-CircleLoader-fillSubMask2_7c1153 {
  will-change: transform;
  animation: 1s linear infinite spectrum-fill-mask-2_7c1153;
  transform: translateZ(0);
}

.spectrum-CircleLoader-track_7c1153 {
  border-color: var(--spectrum-loader-circle-medium-track-color, var(--spectrum-alias-track-color-default));
}

.spectrum-CircleLoader-fill_7c1153 {
  border-color: var(--spectrum-loader-circle-medium-track-fill-color, var(--spectrum-global-color-blue-500));
}

.spectrum-CircleLoader--overBackground_7c1153 .spectrum-CircleLoader-track_7c1153 {
  border-color: var(--spectrum-loader-circle-medium-over-background-track-color, var(--spectrum-alias-track-color-over-background));
}

.spectrum-CircleLoader--overBackground_7c1153 .spectrum-CircleLoader-fill_7c1153 {
  border-color: var(--spectrum-loader-circle-medium-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

.spectrum-CircleLoader--indeterminate_7c1153.spectrum-CircleLoader--overBackground_7c1153 .spectrum-CircleLoader-track_7c1153 {
  border-color: var(--spectrum-loader-circle-medium-over-background-track-color, var(--spectrum-alias-track-color-over-background));
}

.spectrum-CircleLoader--indeterminate_7c1153.spectrum-CircleLoader--overBackground_7c1153 .spectrum-CircleLoader-fill_7c1153 {
  border-color: var(--spectrum-loader-circle-medium-over-background-track-fill-color, var(--spectrum-global-color-static-white));
}

@media (forced-colors: active) {
  .spectrum-CircleLoader-fill_7c1153 {
    --spectrum-loader-circle-medium-track-fill-color: Highlight;
  }
}

.spectrum-Popover_38efea.react-spectrum-Popover_38efea {
  visibility: visible;
}

.spectrum-Popover_38efea .spectrum-Dialog-content_38efea {
  max-height: initial;
}

.spectrum-Modal-wrapper_38efea.react-spectrum-Modal-wrapper_38efea, .spectrum-Modal_38efea.react-spectrum-Modal_38efea, .spectrum-Tray_38efea.react-spectrum-Tray_38efea {
  visibility: visible;
}

.spectrum-Popover_6115b8 {
  visibility: hidden;
  opacity: 0;
  transition: transform var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, opacity var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, visibility 0ms linear var(--spectrum-global-animation-duration-100, 130ms);
  pointer-events: none;
}

.spectrum-Popover_6115b8.is-open_6115b8 {
  visibility: visible;
  opacity: .9999;
  pointer-events: auto;
  transition-delay: 0s;
}

.spectrum-Popover--bottom_6115b8.is-open_6115b8 {
  transform: translateY(var(--spectrum-overlay-positive-transform-distance));
}

.spectrum-Popover--top_6115b8.is-open_6115b8 {
  transform: translateY(var(--spectrum-overlay-negative-transform-distance));
}

.spectrum-Popover--right_6115b8.is-open_6115b8 {
  transform: translateX(var(--spectrum-overlay-positive-transform-distance));
}

.spectrum-Popover--left_6115b8.is-open_6115b8 {
  transform: translateX(var(--spectrum-overlay-negative-transform-distance));
}

.spectrum-Popover_6115b8 {
  box-sizing: border-box;
  min-width: var(--spectrum-global-dimension-size-400);
  min-height: var(--spectrum-global-dimension-size-400);
  border-style: solid;
  border-width: var(--spectrum-popover-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-popover-border-radius, var(--spectrum-alias-border-radius-regular));
  outline: 0;
  flex-direction: column;
  display: inline-flex;
  position: absolute;
}

.spectrum-Popover-tip_6115b8 {
  --spectrum-popover-tip-size: var(--spectrum-popover-tip-width, var(--spectrum-global-dimension-size-250));
  --spectrum-popover-tip-borderWidth: var(--spectrum-popover-border-size, var(--spectrum-alias-border-size-thin));
  position: absolute;
  -webkit-transform: translate(0);
}

.spectrum-Popover-tip_6115b8 .spectrum-Popover-tip-triangle_6115b8 {
  stroke-linecap: square;
  stroke-linejoin: miter;
  stroke-width: var(--spectrum-popover-border-size, var(--spectrum-alias-border-size-thin));
}

.spectrum-Popover--dialog_6115b8 {
  min-width: 270px;
  padding: 30px 29px;
}

.spectrum-Popover--left_6115b8.spectrum-Popover--withTip_6115b8 {
  margin-right: 13px;
}

.spectrum-Popover--left_6115b8 .spectrum-Popover-tip_6115b8 {
  left: 100%;
}

.spectrum-Popover--right_6115b8.spectrum-Popover--withTip_6115b8 {
  margin-left: 13px;
}

.spectrum-Popover--right_6115b8 .spectrum-Popover-tip_6115b8 {
  right: 100%;
  transform: scaleX(-1);
}

.spectrum-Popover--left_6115b8 .spectrum-Popover-tip_6115b8, .spectrum-Popover--right_6115b8 .spectrum-Popover-tip_6115b8 {
  margin-top: calc(var(--spectrum-global-dimension-size-150) * -1);
  top: 50%;
}

.spectrum-Popover--bottom_6115b8.spectrum-Popover--withTip_6115b8 {
  margin-top: 13px;
}

.spectrum-Popover--bottom_6115b8 .spectrum-Popover-tip_6115b8 {
  bottom: 100%;
  transform: scaleY(-1);
}

.spectrum-Popover--top_6115b8.spectrum-Popover--withTip_6115b8 {
  margin-bottom: 13px;
}

.spectrum-Popover--top_6115b8 .spectrum-Popover-tip_6115b8 {
  top: 100%;
}

.spectrum-Popover--bottom_6115b8 .spectrum-Popover-tip_6115b8, .spectrum-Popover--top_6115b8 .spectrum-Popover-tip_6115b8 {
  margin-left: calc(var(--spectrum-global-dimension-size-150) * -1);
  left: 50%;
}

.spectrum-Popover_6115b8 {
  background-color: var(--spectrum-popover-background-color, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-popover-border-color, var(--spectrum-alias-border-color-dark));
  filter: drop-shadow(0 1px 4px var(--spectrum-popover-shadow-color, var(--spectrum-alias-dropshadow-color)));
  -webkit-filter: drop-shadow(0 1px 4px var(--spectrum-popover-shadow-color, var(--spectrum-alias-dropshadow-color)));
  will-change: filter;
  clip-path: inset(-30px);
}

.spectrum-Popover_6115b8 .spectrum-Popover-tip_6115b8 .spectrum-Popover-tip-triangle_6115b8 {
  fill: var(--spectrum-popover-background-color, var(--spectrum-global-color-gray-50));
  stroke: var(--spectrum-popover-border-color, var(--spectrum-alias-border-color-dark));
}

.spectrum-Modal_f81956 {
  visibility: hidden;
  opacity: 0;
  transition: transform var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, opacity var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, visibility 0ms linear var(--spectrum-global-animation-duration-100, 130ms);
  pointer-events: none;
}

.spectrum-Modal_f81956.is-open_f81956 {
  visibility: visible;
  opacity: .9999;
  pointer-events: auto;
  transition-delay: 0s;
}

.spectrum-Modal-wrapper_f81956 {
  box-sizing: border-box;
  width: 100vw;
  height: var(--spectrum-visual-viewport-height);
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  transition: visibility 0ms linear var(--spectrum-global-animation-duration-100, 130ms);
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.spectrum-Modal-wrapper_f81956.is-open_f81956 {
  visibility: visible;
}

.spectrum-Modal_f81956 {
  transform: translateY(var(--spectrum-dialog-entry-animation-distance, var(--spectrum-global-dimension-size-250)));
  z-index: 2;
  max-height: calc(var(--spectrum-visual-viewport-height) * .9);
  max-width: 90vw;
  min-width: var(--spectrum-dialog-min-width, var(--spectrum-global-dimension-static-size-3600));
  border-radius: var(--spectrum-dialog-border-radius, var(--spectrum-global-dimension-size-50));
  pointer-events: auto;
  transition: opacity var(--spectrum-dialog-exit-animation-duration, var(--spectrum-global-animation-duration-100)) cubic-bezier(.5, 0, 1, 1) 0ms, visibility 0ms linear calc(0ms + var(--spectrum-dialog-exit-animation-duration, var(--spectrum-global-animation-duration-100))), transform 0ms linear calc(0ms + var(--spectrum-dialog-exit-animation-duration, var(--spectrum-global-animation-duration-100)));
  outline: 0;
}

.spectrum-Modal_f81956.is-open_f81956 {
  transition: transform var(--spectrum-dialog-entry-animation-duration, var(--spectrum-global-animation-duration-500)) cubic-bezier(0, 0, .4, 1) var(--spectrum-dialog-entry-animation-delay, var(--spectrum-global-animation-duration-200)), opacity var(--spectrum-dialog-entry-animation-duration, var(--spectrum-global-animation-duration-500)) cubic-bezier(0, 0, .4, 1) var(--spectrum-dialog-entry-animation-delay, var(--spectrum-global-animation-duration-200));
  transform: translateY(0);
}

@media only screen and (max-device-width: 400px), only screen and (max-device-height: 350px) {
  .spectrum-Modal--responsive_f81956 {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    border-radius: 0;
  }

  .spectrum-Modal-wrapper_f81956 .spectrum-Modal--responsive_f81956 {
    margin-top: 0;
  }
}

.spectrum-Modal--fullscreen_f81956 {
  max-width: none;
  max-height: none;
  width: calc(100% - 80px);
  height: calc(100% - 80px);
  position: fixed;
  inset: 40px;
}

.spectrum-Modal--fullscreenTakeover_f81956 {
  max-width: none;
  max-height: none;
  box-sizing: border-box;
  border: 0;
  border-radius: 0;
  position: fixed;
  inset: 0;
}

.spectrum-Modal--fullscreenTakeover_f81956, .spectrum-Modal--fullscreenTakeover_f81956.is-open_f81956 {
  transform: none;
}

.spectrum-Modal_f81956 {
  background: var(--spectrum-dialog-background-color, var(--spectrum-alias-background-color-default));
}

.spectrum-Underlay_eb7615 {
  visibility: hidden;
  opacity: 0;
  transition: transform var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, opacity var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, visibility 0ms linear var(--spectrum-global-animation-duration-100, 130ms);
  pointer-events: none;
}

.spectrum-Underlay_eb7615.is-open_eb7615 {
  visibility: visible;
  opacity: .9999;
  pointer-events: auto;
  transition-delay: 0s;
}

.spectrum-Underlay_eb7615 {
  z-index: 1;
  transition: opacity var(--spectrum-dialog-background-exit-animation-duration, var(--spectrum-global-animation-duration-300)) cubic-bezier(.5, 0, 1, 1) var(--spectrum-dialog-background-exit-animation-delay, var(--spectrum-global-animation-duration-200)), visibility 0ms linear calc(var(--spectrum-dialog-background-exit-animation-delay, var(--spectrum-global-animation-duration-200))  + var(--spectrum-dialog-background-exit-animation-duration, var(--spectrum-global-animation-duration-300)));
  position: fixed;
  inset: 0;
  overflow: hidden;
}

.spectrum-Underlay_eb7615.is-open_eb7615 {
  transition: opacity var(--spectrum-dialog-background-entry-animation-duration, var(--spectrum-global-animation-duration-600)) cubic-bezier(0, 0, .4, 1) 0ms;
}

.spectrum-Underlay_eb7615 {
  background: var(--spectrum-dialog-underlay-background-color, var(--spectrum-alias-background-color-modal-overlay));
}

.spectrum-Tray_23bf66 {
  visibility: hidden;
  opacity: 0;
  transition: transform var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, opacity var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, visibility 0ms linear var(--spectrum-global-animation-duration-100, 130ms);
  pointer-events: none;
}

.spectrum-Tray_23bf66.is-open_23bf66 {
  visibility: visible;
  opacity: .9999;
  pointer-events: auto;
  transition-delay: 0s;
}

[dir="ltr"] .spectrum-Tray-wrapper_23bf66 {
  left: 0;
}

[dir="rtl"] .spectrum-Tray-wrapper_23bf66 {
  right: 0;
}

.spectrum-Tray-wrapper_23bf66 {
  width: 100%;
  height: 100vh;
  pointer-events: none;
  z-index: 2;
  justify-content: center;
  display: flex;
  position: fixed;
  top: 0;
}

.spectrum-Tray_23bf66 {
  --spectrum-tray-margin-top: 64px;
  --spectrum-tray-max-width: 450px;
  width: var(--spectrum-tray-width, 100%);
  max-width: var(--spectrum-tray-max-width, 375px);
  min-height: var(--spectrum-tray-min-height, var(--spectrum-global-dimension-static-size-800));
  max-height: calc(var(--spectrum-visual-viewport-height)  - var(--spectrum-tray-margin-top));
  padding-bottom: max(calc(100vh - var(--spectrum-visual-viewport-height)), env(safe-area-inset-bottom) );
  border-radius: var(--spectrum-tray-full-width-border-radius, var(--spectrum-alias-border-radius-regular)) var(--spectrum-tray-full-width-border-radius, var(--spectrum-alias-border-radius-regular)) var(--spectrum-tray-border-radius, 0px) var(--spectrum-tray-border-radius, 0px);
  transition: opacity var(--spectrum-dialog-exit-animation-duration, var(--spectrum-global-animation-duration-100)) cubic-bezier(.5, 0, 1, 1) 0ms, transform var(--spectrum-dialog-exit-animation-duration, var(--spectrum-global-animation-duration-100)) cubic-bezier(.5, 0, 1, 1) 0ms;
  outline: 0;
  flex-direction: column;
  display: flex;
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
}

.spectrum-Tray_23bf66.is-open_23bf66 {
  transition: transform var(--spectrum-dialog-entry-animation-duration, var(--spectrum-global-animation-duration-500)) cubic-bezier(0, 0, .4, 1) var(--spectrum-dialog-entry-animation-delay, var(--spectrum-global-animation-duration-200)), opacity var(--spectrum-dialog-entry-animation-duration, var(--spectrum-global-animation-duration-500)) cubic-bezier(0, 0, .4, 1) var(--spectrum-dialog-entry-animation-delay, var(--spectrum-global-animation-duration-200));
  transform: translateY(0);
}

.spectrum-Tray--fixedHeight_23bf66 {
  height: calc(var(--spectrum-visual-viewport-height)  - var(--spectrum-tray-margin-top));
  top: var(--spectrum-tray-margin-top);
}

@media (max-width: 450px) {
  .spectrum-Tray_23bf66 {
    border-radius: var(--spectrum-tray-border-radius, 0px);
  }
}

.spectrum-Tray_23bf66 {
  background-color: var(--spectrum-tray-background-color);
}

.spectrum-Textfield-input_73bc77 {
  font-family: adobe-clean-ux, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield-input_73bc77:lang(ar) {
  font-family: adobe-arabic, myriad-arabic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield-input_73bc77:lang(he) {
  font-family: adobe-hebrew, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield-input_73bc77:lang(zh-Hans), .spectrum-Textfield-input_73bc77:lang(zh) {
  font-family: adobe-clean-han-simplified-c, SimSun, Heiti SC Light, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield-input_73bc77:lang(ko) {
  font-family: adobe-clean-han-korean, Malgun Gothic, Apple Gothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield-input_73bc77:lang(ja) {
  font-family: adobe-clean-han-japanese, Yu Gothic, メイリオ, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro W3, Osaka, ＭＳＰゴシック, MS PGothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Textfield_73bc77 {
  min-width: var(--spectrum-textfield-min-width, var(--spectrum-global-dimension-size-600));
  width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
  display: inline-flex;
  position: relative;
}

.spectrum-Textfield_73bc77.spectrum-Textfield--quiet_73bc77.spectrum-Textfield--multiline_73bc77 .spectrum-Textfield-input_73bc77 {
  height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  min-height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  overflow-x: hidden;
}

.spectrum-Textfield-input_73bc77 {
  box-sizing: border-box;
  border: var(--spectrum-textfield-border-size, var(--spectrum-alias-border-size-thin)) solid;
  border-radius: var(--spectrum-textfield-border-radius, var(--spectrum-alias-border-radius-regular));
  padding: 3px var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)) 5px calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  - 1px);
  text-indent: 0;
  width: 100%;
  height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  vertical-align: top;
  font-size: var(--spectrum-textfield-text-size, var(--spectrum-alias-font-size-default));
  line-height: var(--spectrum-textfield-text-line-height, var(--spectrum-alias-body-text-line-height));
  text-overflow: ellipsis;
  transition: border-color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: textfield;
  outline: 0;
  margin: 0;
  overflow: visible;
}

.spectrum-Textfield-input_73bc77::placeholder, .spectrum-Textfield-input_73bc77.is-placeholder_73bc77 {
  font-weight: var(--spectrum-textfield-placeholder-text-font-weight, var(--spectrum-global-font-weight-regular));
  font-style: var(--spectrum-textfield-placeholder-text-font-style, var(--spectrum-global-font-style-italic));
  font-synthesis: none;
  transition: color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  opacity: 1;
}

.spectrum-Textfield-input_73bc77::-moz-placeholder {
  font-weight: var(--spectrum-textfield-placeholder-text-font-weight, var(--spectrum-global-font-weight-regular));
  font-style: var(--spectrum-textfield-placeholder-text-font-style, var(--spectrum-global-font-style-italic));
  transition: color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  opacity: 1;
}

.spectrum-Textfield-input_73bc77:lang(ja)::placeholder, .spectrum-Textfield-input_73bc77:lang(ja).is-placeholder_73bc77, .spectrum-Textfield-input_73bc77:lang(zh)::placeholder, .spectrum-Textfield-input_73bc77:lang(zh).is-placeholder_73bc77, .spectrum-Textfield-input_73bc77:lang(ko)::placeholder, .spectrum-Textfield-input_73bc77:lang(ko).is-placeholder_73bc77, .spectrum-Textfield-input_73bc77:lang(ar)::placeholder, .spectrum-Textfield-input_73bc77:lang(ar).is-placeholder_73bc77, .spectrum-Textfield-input_73bc77:lang(he)::placeholder, .spectrum-Textfield-input_73bc77:lang(he).is-placeholder_73bc77 {
  font-style: normal;
}

.spectrum-Textfield-input_73bc77:lang(ja)::-moz-placeholder, .spectrum-Textfield-input_73bc77:lang(zh)::-moz-placeholder, .spectrum-Textfield-input_73bc77:lang(ko)::-moz-placeholder, .spectrum-Textfield-input_73bc77:lang(ar)::-moz-placeholder, .spectrum-Textfield-input_73bc77:lang(he)::-moz-placeholder {
  font-style: normal;
}

.spectrum-Textfield-input_73bc77.is-hovered_73bc77::placeholder {
  font-weight: var(--spectrum-textfield-placeholder-text-font-weight, var(--spectrum-global-font-weight-regular));
}

.spectrum-Textfield-input_73bc77:disabled {
  resize: none;
  opacity: 1;
}

.spectrum-Textfield-input_73bc77:disabled::placeholder, .spectrum-Textfield-input_73bc77:disabled.is-placeholder_73bc77 {
  font-weight: var(--spectrum-textfield-placeholder-text-font-weight, var(--spectrum-global-font-weight-regular));
}

.spectrum-Textfield-input_73bc77::-ms-clear {
  width: 0;
  height: 0;
}

.spectrum-Textfield-input_73bc77::-webkit-inner-spin-button, .spectrum-Textfield-input_73bc77::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spectrum-Textfield-input_73bc77:-moz-ui-invalid {
  box-shadow: none;
}

.spectrum-Textfield--multiline_73bc77 .spectrum-Textfield-input_73bc77 {
  height: auto;
  min-height: var(--spectrum-global-dimension-size-700);
  padding: 3px var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150)) 5px calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  - 1px);
  resize: none;
  overflow: auto;
}

[dir="ltr"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-left: var(--spectrum-textfield-quiet-padding-x, 0);
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77, [dir="ltr"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-right: var(--spectrum-textfield-quiet-padding-x, 0);
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-left: var(--spectrum-textfield-quiet-padding-x, 0);
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  border-width: 0 0 var(--spectrum-textfield-quiet-border-size, var(--spectrum-alias-border-size-thin)) 0;
  border-radius: 0;
}

[dir="ltr"] .spectrum-Textfield--valid_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-right: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="rtl"] .spectrum-Textfield--valid_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-left: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="ltr"] .spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-right: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="rtl"] .spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-left: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="ltr"] .spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-right: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

[dir="rtl"] .spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-input_73bc77 {
  padding-left: calc(var(--spectrum-textfield-padding-x, var(--spectrum-global-dimension-size-150))  + var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225))  + var(--spectrum-textfield-icon-margin-left, var(--spectrum-global-dimension-size-150)));
}

.spectrum-Textfield-wrapper_73bc77 .spectrum-Textfield--multiline_73bc77 {
  min-height: 0;
  flex: auto;
}

.spectrum-Textfield-wrapper_73bc77 .spectrum-Textfield--multiline_73bc77.spectrum-Textfield--quiet_73bc77 {
  max-height: 100%;
  flex: 0 auto;
}

.spectrum-Textfield-wrapper_73bc77 .spectrum-Textfield--multiline_73bc77.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  max-height: 100%;
}

[dir="ltr"] .spectrum-Textfield-validationIcon_73bc77 {
  padding-right: calc(var(--spectrum-global-dimension-size-150) / 2);
}

[dir="rtl"] .spectrum-Textfield-validationIcon_73bc77 {
  padding-left: calc(var(--spectrum-global-dimension-size-150) / 2);
}

.spectrum-Textfield-validationIcon_73bc77 {
  pointer-events: all;
  transition: color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  position: absolute;
}

[dir="ltr"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  padding-left: 0;
}

[dir="ltr"] .spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  right: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225)) / 2);
}

[dir="rtl"] .spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  left: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225)) / 2);
}

.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  width: var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225));
  height: var(--spectrum-icon-alert-medium-height, var(--spectrum-global-dimension-size-225));
  top: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-alert-medium-height, var(--spectrum-global-dimension-size-225)) / 2);
}

[dir="ltr"] .spectrum-Textfield--valid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  right: var(--spectrum-global-dimension-size-150);
}

[dir="rtl"] .spectrum-Textfield--valid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  left: var(--spectrum-global-dimension-size-150);
}

.spectrum-Textfield--valid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  width: var(--spectrum-icon-checkmark-medium-width);
  height: var(--spectrum-icon-checkmark-medium-height);
  top: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-checkmark-medium-height) / 2);
}

[dir="ltr"] .spectrum-Textfield-icon_73bc77 {
  left: var(--spectrum-global-dimension-size-150);
}

[dir="rtl"] .spectrum-Textfield-icon_73bc77 {
  right: var(--spectrum-global-dimension-size-150);
}

.spectrum-Textfield-icon_73bc77 {
  height: var(--spectrum-icon-info-medium-height, var(--spectrum-global-dimension-size-225));
  width: var(--spectrum-icon-info-medium-width, var(--spectrum-global-dimension-size-225));
  top: var(--spectrum-global-dimension-size-85);
  transition: fill var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  display: block;
  position: absolute;
}

[dir="ltr"] .spectrum-Textfield-inputIcon_73bc77 {
  padding-left: calc(var(--spectrum-global-dimension-size-150)  + var(--spectrum-global-dimension-size-225)  + var(--spectrum-global-dimension-size-65));
}

[dir="rtl"] .spectrum-Textfield-inputIcon_73bc77 {
  padding-right: calc(var(--spectrum-global-dimension-size-150)  + var(--spectrum-global-dimension-size-225)  + var(--spectrum-global-dimension-size-65));
}

[dir="ltr"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-inputIcon_73bc77 {
  padding-left: calc(var(--spectrum-global-dimension-size-100)  + var(--spectrum-icon-info-medium-width, var(--spectrum-global-dimension-size-225)));
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-inputIcon_73bc77 {
  padding-right: calc(var(--spectrum-global-dimension-size-100)  + var(--spectrum-icon-info-medium-width, var(--spectrum-global-dimension-size-225)));
}

[dir="ltr"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-inputIcon_73bc77 ~ .spectrum-Textfield-icon_73bc77 {
  left: 0;
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-inputIcon_73bc77 ~ .spectrum-Textfield-icon_73bc77 {
  right: 0;
}

.spectrum-Textfield-inputIcon_73bc77.spectrum-Textfield--multiline_73bc77 {
  height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  min-height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
}

[dir="ltr"] .spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-circleLoader_73bc77 {
  right: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225)) / 2 + var(--spectrum-global-dimension-size-150) / 2);
}

[dir="rtl"] .spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-circleLoader_73bc77 {
  left: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-icon-alert-medium-width, var(--spectrum-global-dimension-size-225)) / 2 + var(--spectrum-global-dimension-size-150) / 2);
}

.spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-circleLoader_73bc77 {
  top: calc(var(--spectrum-textfield-icon-frame) / 2 - var(--spectrum-loader-circle-small-width, var(--spectrum-global-dimension-static-size-200)) / 2);
  position: absolute;
}

[dir="ltr"] .spectrum-Textfield--quiet_73bc77.spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-circleLoader_73bc77 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Textfield--quiet_73bc77.spectrum-Textfield--loadable_73bc77 .spectrum-Textfield-circleLoader_73bc77 {
  padding-left: 0;
}

.spectrum-Textfield-input_73bc77 {
  background-color: var(--spectrum-textfield-background-color, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-textfield-border-color, var(--spectrum-alias-border-color));
  color: var(--spectrum-textfield-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Textfield-input_73bc77::placeholder, .spectrum-Textfield-input_73bc77.is-placeholder_73bc77 {
  color: var(--spectrum-textfield-placeholder-text-color, var(--spectrum-alias-placeholder-text-color));
}

.spectrum-Textfield-input_73bc77.is-hovered_73bc77 {
  border-color: var(--spectrum-textfield-border-color-hover, var(--spectrum-alias-border-color-hover));
  box-shadow: none;
}

.spectrum-Textfield-input_73bc77.is-hovered_73bc77::placeholder, .spectrum-Textfield-input_73bc77.is-hovered_73bc77.is-placeholder_73bc77 {
  color: var(--spectrum-textfield-placeholder-text-color-hover, var(--spectrum-alias-placeholder-text-color-hover));
}

.spectrum-Textfield-input_73bc77.is-hovered_73bc77 ~ .spectrum-Textfield-icon_73bc77 {
  fill: var(--spectrum-textfield-icon-color-hover, var(--spectrum-global-color-gray-900));
}

.spectrum-Textfield-input_73bc77:active {
  background-color: var(--spectrum-textfield-background-color-down, var(--spectrum-global-color-gray-50));
  border-color: var(--spectrum-textfield-border-color-down, var(--spectrum-alias-border-color-mouse-focus));
  color: var(--spectrum-textfield-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-Textfield-input_73bc77:active::placeholder, .spectrum-Textfield-input_73bc77:active.is-placeholder_73bc77 {
  color: var(--spectrum-textfield-placeholder-text-color-down, var(--spectrum-alias-placeholder-text-color-down));
}

.spectrum-Textfield-input_73bc77:active ~ .spectrum-Textfield-icon_73bc77 {
  fill: var(--spectrum-textfield-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-Textfield-input_73bc77:focus, .spectrum-Textfield-input_73bc77.is-focused_73bc77 {
  border-color: var(--spectrum-textfield-border-color-down, var(--spectrum-alias-border-color-mouse-focus));
}

.spectrum-Textfield-input_73bc77.focus-ring_73bc77:not(:active) {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 1px var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield-input_73bc77[disabled], .spectrum-Textfield-input_73bc77.is-disabled_73bc77 {
  background-color: var(--spectrum-textfield-background-color-disabled, var(--spectrum-global-color-gray-200));
  border-color: var(--spectrum-textfield-border-color-disabled, var(--spectrum-alias-border-color-transparent));
  color: var(--spectrum-textfield-text-color-disabled, var(--spectrum-alias-text-color-disabled));
  -webkit-text-fill-color: var(--spectrum-textfield-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-Textfield-input_73bc77[disabled]::placeholder, .spectrum-Textfield-input_73bc77[disabled].is-placeholder_73bc77, .spectrum-Textfield-input_73bc77.is-disabled_73bc77::placeholder, .spectrum-Textfield-input_73bc77.is-disabled_73bc77.is-placeholder_73bc77 {
  color: var(--spectrum-textfield-placeholder-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-Textfield-input_73bc77[disabled] ~ .spectrum-Textfield-icon_73bc77, .spectrum-Textfield-input_73bc77.is-disabled_73bc77 ~ .spectrum-Textfield-icon_73bc77 {
  fill: var(--spectrum-textfield-icon-color-disabled, var(--spectrum-global-color-gray-500));
}

.spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77 {
  border-color: var(--spectrum-textfield-border-color-error, var(--spectrum-semantic-negative-color-default));
}

.spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77.is-hovered_73bc77, .spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77:active, .spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77:focus, .spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77.is-focused_73bc77 {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield-input_73bc77.spectrum-Textfield--invalid_73bc77.focus-ring_73bc77:not(:active) {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 1px var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77 {
  background-color: var(--spectrum-textfield-quiet-background-color, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-textfield-quiet-border-color, var(--spectrum-alias-border-color));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.is-hovered_73bc77 {
  border-color: var(--spectrum-textfield-quiet-border-color-hover, var(--spectrum-alias-border-color-hover));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77:active {
  border-color: var(--spectrum-textfield-quiet-border-color-down, var(--spectrum-alias-border-color-mouse-focus));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77:focus, .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.is-focused_73bc77 {
  border-color: var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77:not(:active) {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 1px 0 var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77:disabled, .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.is-disabled_73bc77 {
  background-color: var(--spectrum-textfield-quiet-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  border-color: var(--spectrum-textfield-quiet-border-color-disabled, var(--spectrum-alias-border-color-mid));
}

.spectrum-Textfield_73bc77.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77 {
  border-color: var(--spectrum-textfield-border-color-error, var(--spectrum-semantic-negative-color-default));
}

.spectrum-Textfield_73bc77.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77:focus {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield_73bc77.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77:not(:active) {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  box-shadow: 0 0 0 1px var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield--quiet_73bc77.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77:not(:active) {
  box-shadow: 0 1px 0 var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Textfield-icon_73bc77 {
  fill: var(--spectrum-textfield-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-Textfield--valid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  fill: var(--spectrum-alert-success-icon-color, var(--spectrum-semantic-positive-color-icon));
}

.spectrum-Textfield--invalid_73bc77 .spectrum-Textfield-validationIcon_73bc77 {
  fill: var(--spectrum-alert-error-icon-color, var(--spectrum-semantic-negative-color-icon));
}

@media (forced-colors: active) {
  .spectrum-Textfield-input_73bc77 {
    --spectrum-textfield-text-color-disabled: GrayText;
    --spectrum-textfield-border-color-disabled: GrayText;
    --spectrum-textfield-quiet-border-color-disabled: GrayText;
    --spectrum-textfield-quiet-border-color-key-focus: Highlight;
    --spectrum-textfield-border-color-key-focus: Highlight;
    --spectrum-textfield-placeholder-text-color: GrayText;
    --spectrum-textfield-placeholder-text-color-hover: GrayText;
  }

  .spectrum-Textfield-input_73bc77.focus-ring_73bc77, .spectrum-Textfield-input_73bc77.focus-ring_73bc77 {
    outline: 2px solid Highlight;
  }

  .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77, .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77 {
    forced-color-adjust: none;
    box-shadow: 0 1px 0 0 var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
    outline: 0;
  }

  .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77 .spectrum-Textfield-input_73bc77, .spectrum-Textfield--quiet_73bc77 .spectrum-Textfield-input_73bc77.focus-ring_73bc77 .spectrum-Textfield-input_73bc77 {
    border-color: var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  }
}

.spectrum-Dialog_6d8b48 {
  box-sizing: border-box;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-width: var(--spectrum-dialog-min-width, var(--spectrum-global-dimension-static-size-3600));
  max-width: 100%;
  max-height: inherit;
  --spectrum-dialog-padding-x: var(--spectrum-dialog-padding);
  --spectrum-dialog-padding-y: var(--spectrum-dialog-padding);
  outline: 0;
  display: flex;
}

.spectrum-Dialog--small_6d8b48 {
  width: 400px;
}

.spectrum-Dialog--medium_6d8b48 {
  width: 480px;
}

.spectrum-Dialog--large_6d8b48 {
  width: 640px;
}

.spectrum-Dialog-hero_6d8b48 {
  height: var(--spectrum-global-dimension-size-1600);
  border-top-left-radius: var(--spectrum-dialog-border-radius, var(--spectrum-global-dimension-size-50));
  border-top-right-radius: var(--spectrum-dialog-border-radius, var(--spectrum-global-dimension-size-50));
  background-position: 50%;
  background-size: cover;
  grid-area: hero;
  overflow: hidden;
}

.spectrum-Dialog_6d8b48 .spectrum-Dialog-grid_6d8b48 {
  display: -ms-grid;
  -ms-grid-columns: var(--spectrum-dialog-padding-x) auto 1fr auto minmax(0, auto) var(--spectrum-dialog-padding-x);
  grid-template-columns: var(--spectrum-dialog-padding-x) auto 1fr auto minmax(0, auto) var(--spectrum-dialog-padding-x);
  -ms-grid-rows: auto var(--spectrum-dialog-padding-y) auto auto 1fr auto var(--spectrum-dialog-padding-y);
  grid-template-rows: auto var(--spectrum-dialog-padding-y) auto auto 1fr auto var(--spectrum-dialog-padding-y);
  width: 100%;
  grid-template-areas: "hero hero hero hero hero hero"
                       ". . . . . ."
                       ". heading header header typeIcon ."
                       ". divider divider divider divider ."
                       ". content content content content ."
                       ". footer footer buttonGroup buttonGroup ."
                       ". . . . . .";
  display: grid;
}

[dir="ltr"] .spectrum-Dialog-heading_6d8b48 {
  padding-right: var(--spectrum-global-dimension-size-200);
}

[dir="rtl"] .spectrum-Dialog-heading_6d8b48 {
  padding-left: var(--spectrum-global-dimension-size-200);
}

.spectrum-Dialog-heading_6d8b48 {
  font-size: var(--spectrum-dialog-title-text-size);
  font-weight: var(--spectrum-dialog-title-text-font-weight, var(--spectrum-global-font-weight-bold));
  line-height: var(--spectrum-dialog-title-text-line-height, var(--spectrum-alias-heading-text-line-height));
  outline: 0;
  grid-area: heading;
  margin: 0;
}

[dir="ltr"] .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48 {
  padding-left: 0;
}

.spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48 {
  grid-area: heading-start / heading-start / header-end / header-end;
}

[dir="ltr"] .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
  padding-left: 0;
}

.spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
  grid-area: heading-start / heading-start / typeIcon-end / typeIcon-end;
}

.spectrum-Dialog-header_6d8b48 {
  box-sizing: border-box;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  outline: 0;
  grid-area: header;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

[dir="ltr"] .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
  padding-left: 0;
}

.spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
  grid-area: header-start / header-start / typeIcon-end / typeIcon-end;
}

.spectrum-Dialog-typeIcon_6d8b48 {
  grid-area: typeIcon;
}

.spectrum-Dialog_6d8b48 .spectrum-Dialog-divider_6d8b48 {
  width: 100%;
  margin-top: var(--spectrum-dialog-rule-margin-top, var(--spectrum-global-dimension-static-size-150));
  margin-bottom: var(--spectrum-dialog-rule-margin-bottom, var(--spectrum-global-dimension-static-size-200));
  grid-area: divider;
}

.spectrum-Dialog--noDivider_6d8b48 .spectrum-Dialog-divider_6d8b48 {
  display: none;
}

.spectrum-Dialog-content_6d8b48 {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  font-size: var(--spectrum-dialog-content-text-size);
  font-weight: var(--spectrum-dialog-content-text-font-weight, var(--spectrum-global-font-weight-regular));
  line-height: var(--spectrum-dialog-content-text-line-height, var(--spectrum-alias-body-text-line-height));
  padding: calc(var(--spectrum-global-dimension-size-25) * 2);
  margin: calc(var(--spectrum-global-dimension-size-25) * -2);
  min-height: var(--spectrum-alias-single-line-height, var(--spectrum-global-dimension-size-400));
  outline: 0;
  grid-area: content;
  overflow-y: auto;
}

.spectrum-Dialog-footer_6d8b48 {
  padding-top: var(--spectrum-global-dimension-static-size-500, 40px);
  outline: 0;
  flex-wrap: wrap;
  grid-area: footer;
  display: flex;
}

.spectrum-Dialog-footer_6d8b48 > *, .spectrum-Dialog-footer_6d8b48 > .spectrum-Button_6d8b48 + .spectrum-Button_6d8b48 {
  margin-bottom: 0;
}

[dir="ltr"] .spectrum-Dialog-buttonGroup_6d8b48 {
  padding-left: var(--spectrum-global-dimension-size-200);
}

[dir="rtl"] .spectrum-Dialog-buttonGroup_6d8b48 {
  padding-right: var(--spectrum-global-dimension-size-200);
}

.spectrum-Dialog-buttonGroup_6d8b48 {
  padding-top: var(--spectrum-global-dimension-static-size-500, 40px);
  max-width: 100%;
  grid-area: buttonGroup;
  justify-content: flex-end;
  display: flex;
}

.spectrum-Dialog-buttonGroup_6d8b48.spectrum-Dialog-buttonGroup--noFooter_6d8b48 {
  grid-area: footer-start / footer-start / buttonGroup-end / buttonGroup-end;
}

.spectrum-Dialog_6d8b48.spectrum-Dialog--dismissable_6d8b48 .spectrum-Dialog-grid_6d8b48 {
  -ms-grid-columns: var(--spectrum-dialog-padding-x) auto 1fr auto minmax(0, auto) minmax(0, var(--spectrum-global-dimension-size-400)) var(--spectrum-dialog-padding-x);
  grid-template-columns: var(--spectrum-dialog-padding-x) auto 1fr auto minmax(0, auto) minmax(0, var(--spectrum-global-dimension-size-400)) var(--spectrum-dialog-padding-x);
  -ms-grid-rows: auto var(--spectrum-dialog-padding-y) auto auto 1fr auto var(--spectrum-dialog-padding-y);
  grid-template-rows: auto var(--spectrum-dialog-padding-y) auto auto 1fr auto var(--spectrum-dialog-padding-y);
  grid-template-areas: "hero hero hero hero hero hero hero"
                       ". . . . . closeButton closeButton"
                       ". heading header header typeIcon closeButton closeButton"
                       ". divider divider divider divider divider ."
                       ". content content content content content ."
                       ". footer footer buttonGroup buttonGroup buttonGroup ."
                       ". . . . . . .";
}

.spectrum-Dialog_6d8b48.spectrum-Dialog--dismissable_6d8b48 .spectrum-Dialog-grid_6d8b48 .spectrum-Dialog-buttonGroup_6d8b48 {
  display: none;
}

.spectrum-Dialog_6d8b48.spectrum-Dialog--dismissable_6d8b48 .spectrum-Dialog-grid_6d8b48 .spectrum-Dialog-footer_6d8b48 {
  grid-area: footer / footer / buttonGroup / buttonGroup;
}

[dir="ltr"] .spectrum-Dialog_6d8b48.spectrum-Dialog--dismissable_6d8b48 .spectrum-Dialog-closeButton_6d8b48 {
  margin-right: calc(26px - var(--spectrum-global-dimension-size-175));
}

[dir="rtl"] .spectrum-Dialog_6d8b48.spectrum-Dialog--dismissable_6d8b48 .spectrum-Dialog-closeButton_6d8b48 {
  margin-left: calc(26px - var(--spectrum-global-dimension-size-175));
}

.spectrum-Dialog_6d8b48.spectrum-Dialog--dismissable_6d8b48 .spectrum-Dialog-closeButton_6d8b48 {
  margin-top: calc(26px - var(--spectrum-global-dimension-size-175));
  grid-area: closeButton;
  place-self: flex-start end;
}

.spectrum-Dialog--error_6d8b48 {
  width: 480px;
}

.spectrum-Dialog--fullscreen_6d8b48 {
  width: 100%;
  height: 100%;
}

.spectrum-Dialog--fullscreenTakeover_6d8b48 {
  width: 100%;
  height: 100%;
  border-radius: 0;
}

.spectrum-Dialog--fullscreen_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 {
  max-height: none;
  max-width: none;
}

.spectrum-Dialog--fullscreen_6d8b48.spectrum-Dialog_6d8b48 .spectrum-Dialog-grid_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48.spectrum-Dialog_6d8b48 .spectrum-Dialog-grid_6d8b48 {
  display: -ms-grid;
  -ms-grid-columns: var(--spectrum-dialog-padding-x) 1fr auto auto var(--spectrum-dialog-padding-x);
  grid-template-columns: var(--spectrum-dialog-padding-x) 1fr auto auto var(--spectrum-dialog-padding-x);
  -ms-grid-rows: var(--spectrum-dialog-padding-y) auto auto 1fr var(--spectrum-dialog-padding-y);
  grid-template-rows: var(--spectrum-dialog-padding-y) auto auto 1fr var(--spectrum-dialog-padding-y);
  grid-template-areas: ". . . . ."
                       ". heading header buttonGroup ."
                       ". divider divider divider ."
                       ". content content content ."
                       ". . . . .";
  display: grid;
}

.spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48 {
  font-size: 28px;
}

[dir="ltr"] .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48, [dir="ltr"] .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48, [dir="rtl"] .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48 {
  padding-left: 0;
}

.spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48 {
  grid-area: heading-start / heading-start / header-end / header-end;
}

[dir="ltr"] .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48, [dir="ltr"] .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48, [dir="rtl"] .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
  padding-left: 0;
}

.spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
  grid-area: heading-start / heading-start / header-end / header-end;
}

[dir="ltr"] .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48, [dir="ltr"] .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48, [dir="rtl"] .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
  padding-left: 0;
}

.spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
  grid-area: header;
}

.spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-content_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-content_6d8b48 {
  max-height: none;
}

.spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-footer_6d8b48, .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-buttonGroup_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-footer_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-buttonGroup_6d8b48 {
  padding-top: 0;
}

.spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-footer_6d8b48, .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-typeIcon_6d8b48, .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-closeButton_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-footer_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-typeIcon_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-closeButton_6d8b48 {
  display: none;
}

.spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-buttonGroup_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-buttonGroup_6d8b48 {
  grid-area: buttonGroup;
}

@media screen and (max-width: 700px) {
  .spectrum-Dialog_6d8b48 {
    --spectrum-dialog-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  }

  .spectrum-Dialog_6d8b48 .spectrum-Dialog-grid_6d8b48 {
    -ms-grid-columns: var(--spectrum-dialog-padding-x) auto 1fr auto minmax(0, auto) var(--spectrum-dialog-padding-x);
    grid-template-columns: var(--spectrum-dialog-padding-x) auto 1fr auto minmax(0, auto) var(--spectrum-dialog-padding-x);
    -ms-grid-rows: auto var(--spectrum-dialog-padding-y) auto auto auto 1fr auto var(--spectrum-dialog-padding-y);
    grid-template-rows: auto var(--spectrum-dialog-padding-y) auto auto auto 1fr auto var(--spectrum-dialog-padding-y);
    grid-template-areas: "hero hero hero hero hero hero"
                         ". . . . . ."
                         ". heading heading heading typeIcon ."
                         ". header header header header ."
                         ". divider divider divider divider ."
                         ". content content content content ."
                         ". footer footer buttonGroup buttonGroup ."
                         ". . . . . .";
  }

  .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48 {
    grid-area: heading;
  }

  .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
    grid-area: heading-start / heading-start / typeIcon-end / typeIcon-end;
  }

  .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
    grid-area: header;
  }

  .spectrum-Dialog_6d8b48.spectrum-Dialog--dismissable_6d8b48 .spectrum-Dialog-grid_6d8b48 {
    -ms-grid-columns: var(--spectrum-dialog-padding-x) auto 1fr auto minmax(0, auto) minmax(0, var(--spectrum-global-dimension-size-400)) var(--spectrum-dialog-padding-x);
    grid-template-columns: var(--spectrum-dialog-padding-x) auto 1fr auto minmax(0, auto) minmax(0, var(--spectrum-global-dimension-size-400)) var(--spectrum-dialog-padding-x);
    -ms-grid-rows: auto var(--spectrum-dialog-padding-y) auto auto auto 1fr auto var(--spectrum-dialog-padding-y);
    grid-template-rows: auto var(--spectrum-dialog-padding-y) auto auto auto 1fr auto var(--spectrum-dialog-padding-y);
    grid-template-areas: "hero hero hero hero hero hero hero"
                         ". . . . . closeButton closeButton"
                         ". heading heading heading typeIcon closeButton closeButton"
                         ". header header header header header ."
                         ". divider divider divider divider divider ."
                         ". content content content content content ."
                         ". footer footer buttonGroup buttonGroup buttonGroup ."
                         ". . . . . . .";
  }

  .spectrum-Dialog_6d8b48 .spectrum-Dialog-header_6d8b48 {
    justify-content: flex-start;
  }

  .spectrum-Dialog-footer_6d8b48 {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
  }

  .spectrum-Dialog-buttonGroup_6d8b48 {
    min-width: 0;
  }

  .spectrum-Dialog--fullscreen_6d8b48.spectrum-Dialog_6d8b48 .spectrum-Dialog-grid_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48.spectrum-Dialog_6d8b48 .spectrum-Dialog-grid_6d8b48 {
    display: -ms-grid;
    -ms-grid-columns: var(--spectrum-dialog-padding-x) 1fr var(--spectrum-dialog-padding-x);
    grid-template-columns: var(--spectrum-dialog-padding-x) 1fr var(--spectrum-dialog-padding-x);
    -ms-grid-rows: var(--spectrum-dialog-padding-y) auto auto auto 1fr auto var(--spectrum-dialog-padding-y);
    grid-template-rows: var(--spectrum-dialog-padding-y) auto auto auto 1fr auto var(--spectrum-dialog-padding-y);
    grid-template-areas: ". . ."
                         ". heading ."
                         ". header ."
                         ". divider ."
                         ". content ."
                         ". buttonGroup ."
                         ". . .";
    display: grid;
  }

  .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48, .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48, .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
    grid-area: heading;
  }

  .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
    grid-area: header;
  }

  .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-buttonGroup_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-buttonGroup_6d8b48 {
    padding-top: var(--spectrum-global-dimension-static-size-500, 40px);
  }

  .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48 {
    font-size: var(--spectrum-dialog-title-text-size);
  }
}

@media screen and (max-height: 400px) {
  .spectrum-Dialog_6d8b48 .spectrum-Dialog-grid_6d8b48 {
    border-top-left-radius: var(--spectrum-dialog-border-radius, var(--spectrum-global-dimension-size-50));
    border-top-right-radius: var(--spectrum-dialog-border-radius, var(--spectrum-global-dimension-size-50));
    -ms-grid-columns: var(--spectrum-dialog-padding-x) auto 1fr auto minmax(0, auto) var(--spectrum-dialog-padding-x);
    grid-template-columns: var(--spectrum-dialog-padding-x) auto 1fr auto minmax(0, auto) var(--spectrum-dialog-padding-x);
    -ms-grid-rows: auto var(--spectrum-dialog-padding-y) auto auto auto 1fr auto auto var(--spectrum-dialog-padding-y);
    grid-template-rows: auto var(--spectrum-dialog-padding-y) auto auto auto 1fr auto auto var(--spectrum-dialog-padding-y);
    grid-template-areas: "hero hero hero hero hero hero"
                         ". . . . . ."
                         ". heading heading heading typeIcon ."
                         ". header header header header ."
                         ". divider divider divider divider ."
                         ". content content content content ."
                         ". footer footer footer footer ."
                         ". buttonGroup buttonGroup buttonGroup buttonGroup ."
                         ". . . . . .";
    overflow-y: auto;
  }

  [dir="ltr"] .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48 {
    padding-right: 0;
  }

  [dir="rtl"] .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48 {
    padding-left: 0;
  }

  .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48 {
    grid-area: heading;
  }

  [dir="ltr"] .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
    padding-right: 0;
  }

  [dir="rtl"] .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
    padding-left: 0;
  }

  .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
    grid-area: heading-start / heading-start / typeIcon-end / typeIcon-end;
  }

  [dir="ltr"] .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
    padding-right: 0;
  }

  [dir="rtl"] .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
    padding-left: 0;
  }

  .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
    grid-area: header;
  }

  .spectrum-Dialog-content_6d8b48 {
    height: -webkit-min-content;
    height: min-content;
    display: inline-table;
    overflow-y: visible;
  }

  .spectrum-Dialog-footer_6d8b48 + .spectrum-Dialog-buttonGroup_6d8b48 {
    padding-top: calc(var(--spectrum-global-dimension-size-25) * 2);
  }
}

@media screen and (max-height: 400px) and (max-width: 700px) {
  .spectrum-Dialog--fullscreen_6d8b48.spectrum-Dialog_6d8b48 .spectrum-Dialog-grid_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48.spectrum-Dialog_6d8b48 .spectrum-Dialog-grid_6d8b48 {
    display: -ms-grid;
    -ms-grid-columns: var(--spectrum-dialog-padding-x) 1fr var(--spectrum-dialog-padding-x);
    grid-template-columns: var(--spectrum-dialog-padding-x) 1fr var(--spectrum-dialog-padding-x);
    -ms-grid-rows: var(--spectrum-dialog-padding-y) auto auto auto 1fr auto var(--spectrum-dialog-padding-y);
    grid-template-rows: var(--spectrum-dialog-padding-y) auto auto auto 1fr auto var(--spectrum-dialog-padding-y);
    grid-template-areas: ". . ."
                         ". heading ."
                         ". header ."
                         ". divider ."
                         ". content ."
                         ". buttonGroup ."
                         ". . .";
    display: grid;
  }

  .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48, .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48, .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48.spectrum-Dialog-heading--noHeader_6d8b48.spectrum-Dialog-heading--noTypeIcon_6d8b48 {
    grid-area: heading;
  }

  .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-header_6d8b48.spectrum-Dialog-header--noTypeIcon_6d8b48 {
    grid-area: header;
  }

  .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-buttonGroup_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-buttonGroup_6d8b48 {
    padding-top: var(--spectrum-global-dimension-static-size-500, 40px);
  }

  .spectrum-Dialog--fullscreen_6d8b48 .spectrum-Dialog-heading_6d8b48, .spectrum-Dialog--fullscreenTakeover_6d8b48 .spectrum-Dialog-heading_6d8b48 {
    font-size: var(--spectrum-dialog-title-text-size);
  }
}

.spectrum-Dialog-heading_6d8b48 {
  color: var(--spectrum-dialog-title-text-color, var(--spectrum-global-color-gray-900));
}

.spectrum-Dialog-content_6d8b48 {
  color: var(--spectrum-dialog-content-text-color, var(--spectrum-global-color-gray-800));
}

.spectrum-Dialog-typeIcon_6d8b48 {
  color: var(--spectrum-dialog-icon-color, var(--spectrum-global-color-gray-900));
}

.spectrum-Dialog--error_6d8b48 .spectrum-Dialog-typeIcon_6d8b48 {
  color: var(--spectrum-dialog-error-icon-color, var(--spectrum-semantic-negative-color-icon));
}

.spectrum-Dialog--warning_6d8b48 .spectrum-Dialog-typeIcon_6d8b48 {
  color: var(--spectrum-semantic-notice-color-icon, var(--spectrum-global-color-orange-600));
}

@media (forced-colors: active) {
  .spectrum-Dialog_6d8b48 {
    border: 1px solid #0000;
  }
}

.spectrum-ButtonGroup_b10c2f {
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

.spectrum-ButtonGroup_b10c2f .spectrum-ButtonGroup-Button_b10c2f {
  flex-shrink: 0;
}

[dir="ltr"] .spectrum-ButtonGroup_b10c2f .spectrum-ButtonGroup-Button_b10c2f + .spectrum-ButtonGroup-Button_b10c2f {
  margin-left: var(--spectrum-buttongroup-button-gap-x, var(--spectrum-global-dimension-static-size-200));
}

[dir="rtl"] .spectrum-ButtonGroup_b10c2f .spectrum-ButtonGroup-Button_b10c2f + .spectrum-ButtonGroup-Button_b10c2f {
  margin-right: var(--spectrum-buttongroup-button-gap-x, var(--spectrum-global-dimension-static-size-200));
}

.spectrum-ButtonGroup_b10c2f.spectrum-ButtonGroup--alignEnd_b10c2f {
  justify-content: flex-end;
}

.spectrum-ButtonGroup_b10c2f.spectrum-ButtonGroup--alignCenter_b10c2f {
  justify-content: center;
}

.spectrum-ButtonGroup--vertical_b10c2f {
  flex-direction: column;
  display: inline-flex;
}

[dir="ltr"] .spectrum-ButtonGroup--vertical_b10c2f .spectrum-ButtonGroup-Button_b10c2f + .spectrum-ButtonGroup-Button_b10c2f {
  margin-left: 0;
}

[dir="rtl"] .spectrum-ButtonGroup--vertical_b10c2f .spectrum-ButtonGroup-Button_b10c2f + .spectrum-ButtonGroup-Button_b10c2f {
  margin-right: 0;
}

.spectrum-ButtonGroup--vertical_b10c2f .spectrum-ButtonGroup-Button_b10c2f + .spectrum-ButtonGroup-Button_b10c2f {
  margin-top: var(--spectrum-buttongroup-button-gap-y, var(--spectrum-global-dimension-static-size-200));
}

.spectrum-ButtonGroup--vertical_b10c2f.spectrum-ButtonGroup--alignEnd_b10c2f {
  align-items: flex-end;
}

.spectrum-ButtonGroup--vertical_b10c2f.spectrum-ButtonGroup--alignCenter_b10c2f {
  align-items: center;
}

[dir="ltr"] .spectrum-Rule_612bb8 {
  margin-left: 0;
}

[dir="rtl"] .spectrum-Rule_612bb8, [dir="ltr"] .spectrum-Rule_612bb8 {
  margin-right: 0;
}

[dir="rtl"] .spectrum-Rule_612bb8 {
  margin-left: 0;
}

.spectrum-Rule_612bb8 {
  border: 0;
  border-width: var(--spectrum-rule-medium-height, var(--spectrum-global-dimension-static-size-25));
  border-radius: var(--spectrum-rule-medium-height, var(--spectrum-global-dimension-static-size-25));
  -ms-grid-row-align: stretch;
  align-self: stretch;
  margin: 0;
  overflow: visible;
}

.spectrum-Rule--large_612bb8 {
  border-radius: var(--spectrum-rule-large-border-radius, var(--spectrum-global-dimension-static-size-25));
}

.spectrum-Rule--medium_612bb8 {
  border-radius: var(--spectrum-rule-medium-border-radius, var(--spectrum-global-dimension-static-size-10));
}

.spectrum-Rule--small_612bb8 {
  border-radius: var(--spectrum-rule-small-border-radius, var(--spectrum-global-dimension-static-size-10));
}

.spectrum-Rule--horizontal_612bb8.spectrum-Rule--large_612bb8 {
  height: var(--spectrum-rule-large-height, var(--spectrum-global-dimension-static-size-50));
}

.spectrum-Rule--horizontal_612bb8.spectrum-Rule--medium_612bb8 {
  height: var(--spectrum-rule-medium-height, var(--spectrum-global-dimension-static-size-25));
}

.spectrum-Rule--horizontal_612bb8.spectrum-Rule--small_612bb8 {
  height: var(--spectrum-rule-small-height, var(--spectrum-global-dimension-static-size-10));
}

.spectrum-Rule--vertical_612bb8 {
  height: auto;
}

.spectrum-Rule--vertical_612bb8.spectrum-Rule--large_612bb8 {
  width: var(--spectrum-rule-large-height, var(--spectrum-global-dimension-static-size-50));
}

.spectrum-Rule--vertical_612bb8.spectrum-Rule--medium_612bb8 {
  width: var(--spectrum-rule-medium-height, var(--spectrum-global-dimension-static-size-25));
}

.spectrum-Rule--vertical_612bb8.spectrum-Rule--small_612bb8 {
  width: var(--spectrum-rule-small-height, var(--spectrum-global-dimension-static-size-10));
}

.spectrum-Rule--large_612bb8 {
  background-color: var(--spectrum-rule-large-background-color, var(--spectrum-global-color-gray-800));
}

.spectrum-Rule--medium_612bb8 {
  background-color: var(--spectrum-rule-medium-background-color, var(--spectrum-global-color-gray-300));
}

.spectrum-Rule--small_612bb8 {
  background-color: var(--spectrum-rule-small-background-color, var(--spectrum-global-color-gray-300));
}

.spectrum-Link_5a03c7 {
  -webkit-text-decoration-skip: objects;
  transition: color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  cursor: pointer;
  background-color: #0000;
  outline: 0;
  text-decoration: underline;
}

.spectrum-Link_5a03c7.is-hovered_5a03c7, .spectrum-Link_5a03c7.focus-ring_5a03c7 {
  text-decoration: underline;
}

.spectrum-Link_5a03c7.focus-ring_5a03c7 {
  text-decoration: underline double;
}

.spectrum-Link_5a03c7.is-disabled_5a03c7 {
  cursor: default;
  pointer-events: none;
}

.spectrum-Link_5a03c7.is-disabled_5a03c7.is-hovered_5a03c7, .spectrum-Link_5a03c7.is-disabled_5a03c7:focus {
  text-decoration: none;
}

.spectrum-Link--secondary_5a03c7, .spectrum-Link--overBackground_5a03c7 {
  text-decoration: underline;
}

.spectrum-Link--quiet_5a03c7 {
  text-decoration: none;
}

.spectrum-Link_5a03c7 {
  color: var(--spectrum-link-text-color, var(--spectrum-global-color-blue-600));
}

.spectrum-Link_5a03c7.is-hovered_5a03c7 {
  color: var(--spectrum-link-text-color-hover, var(--spectrum-global-color-blue-600));
}

.spectrum-Link_5a03c7:active {
  color: var(--spectrum-link-text-color-down, var(--spectrum-global-color-blue-700));
}

.spectrum-Link_5a03c7.focus-ring_5a03c7 {
  color: var(--spectrum-link-text-color-key-focus, var(--spectrum-alias-text-color-key-focus));
}

.spectrum-Link_5a03c7.is-disabled_5a03c7 {
  color: var(--spectrum-link-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-Link--secondary_5a03c7, .spectrum-Link--secondary_5a03c7.is-hovered_5a03c7, .spectrum-Link--secondary_5a03c7:active, .spectrum-Link--secondary_5a03c7:focus {
  color: inherit;
}

.spectrum-Link--overBackground_5a03c7 {
  color: var(--spectrum-link-over-background-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-Link--overBackground_5a03c7.is-hovered_5a03c7 {
  color: var(--spectrum-link-over-background-text-color-hover, var(--spectrum-global-color-static-white));
}

.spectrum-Link--overBackground_5a03c7:active {
  color: var(--spectrum-link-over-background-text-color-down, var(--spectrum-global-color-static-white));
}

.spectrum-Link--overBackground_5a03c7:focus {
  color: var(--spectrum-link-over-background-text-color-key-focus, var(--spectrum-global-color-static-white));
}

.spectrum-Link--overBackground_5a03c7.is-disabled_5a03c7 {
  color: var(--spectrum-link-over-background-text-color-disabled, #ffffff80);
}

.spectrum-Button_8890ad, .spectrum-ActionButton_8890ad, .spectrum-LogicButton_8890ad, .spectrum-FieldButton_8890ad, .spectrum-ClearButton_8890ad, .spectrum-Stepper-button_8890ad {
  font-family: adobe-clean-ux, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_8890ad:lang(ar), .spectrum-ActionButton_8890ad:lang(ar), .spectrum-LogicButton_8890ad:lang(ar), .spectrum-FieldButton_8890ad:lang(ar), .spectrum-ClearButton_8890ad:lang(ar), .spectrum-Stepper-button_8890ad:lang(ar) {
  font-family: adobe-arabic, myriad-arabic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_8890ad:lang(he), .spectrum-ActionButton_8890ad:lang(he), .spectrum-LogicButton_8890ad:lang(he), .spectrum-FieldButton_8890ad:lang(he), .spectrum-ClearButton_8890ad:lang(he), .spectrum-Stepper-button_8890ad:lang(he) {
  font-family: adobe-hebrew, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_8890ad:lang(zh-Hans), .spectrum-ActionButton_8890ad:lang(zh-Hans), .spectrum-LogicButton_8890ad:lang(zh-Hans), .spectrum-FieldButton_8890ad:lang(zh-Hans), .spectrum-ClearButton_8890ad:lang(zh-Hans), .spectrum-Stepper-button_8890ad:lang(zh-Hans), .spectrum-Button_8890ad:lang(zh), .spectrum-ActionButton_8890ad:lang(zh), .spectrum-LogicButton_8890ad:lang(zh), .spectrum-FieldButton_8890ad:lang(zh), .spectrum-ClearButton_8890ad:lang(zh), .spectrum-Stepper-button_8890ad:lang(zh) {
  font-family: adobe-clean-han-simplified-c, SimSun, Heiti SC Light, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_8890ad:lang(ko), .spectrum-ActionButton_8890ad:lang(ko), .spectrum-LogicButton_8890ad:lang(ko), .spectrum-FieldButton_8890ad:lang(ko), .spectrum-ClearButton_8890ad:lang(ko), .spectrum-Stepper-button_8890ad:lang(ko) {
  font-family: adobe-clean-han-korean, Malgun Gothic, Apple Gothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_8890ad:lang(ja), .spectrum-ActionButton_8890ad:lang(ja), .spectrum-LogicButton_8890ad:lang(ja), .spectrum-FieldButton_8890ad:lang(ja), .spectrum-ClearButton_8890ad:lang(ja), .spectrum-Stepper-button_8890ad:lang(ja) {
  font-family: adobe-clean-han-japanese, Yu Gothic, メイリオ, ヒラギノ角ゴ Pro W3, Hiragino Kaku Gothic Pro W3, Osaka, ＭＳＰゴシック, MS PGothic, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Button_8890ad, .spectrum-ActionButton_8890ad, .spectrum-LogicButton_8890ad, .spectrum-FieldButton_8890ad, .spectrum-ClearButton_8890ad, .spectrum-Stepper-button_8890ad {
  box-sizing: border-box;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: top;
  transition: background var(--spectrum-global-animation-duration-100, 130ms) ease-out, border-color var(--spectrum-global-animation-duration-100, 130ms) ease-out, color var(--spectrum-global-animation-duration-100, 130ms) ease-out, box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  -webkit-user-select: none;
  user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  cursor: default;
  isolation: isolate;
  border-style: solid;
  justify-content: center;
  align-items: center;
  margin: 0;
  line-height: 1.3;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: visible;
}

button.spectrum-Button_8890ad, button.spectrum-ActionButton_8890ad, button.spectrum-LogicButton_8890ad, button.spectrum-FieldButton_8890ad, button.spectrum-ClearButton_8890ad, button.spectrum-Stepper-button_8890ad {
  -webkit-appearance: button;
}

.spectrum-Button_8890ad:focus, .spectrum-ActionButton_8890ad:focus, .spectrum-LogicButton_8890ad:focus, .spectrum-FieldButton_8890ad:focus, .spectrum-ClearButton_8890ad:focus, .spectrum-Stepper-button_8890ad:focus {
  outline: 0;
}

.spectrum-Button_8890ad.focus-ring_8890ad, .spectrum-ActionButton_8890ad.focus-ring_8890ad, .spectrum-LogicButton_8890ad.focus-ring_8890ad, .spectrum-FieldButton_8890ad.focus-ring_8890ad, .spectrum-ClearButton_8890ad.focus-ring_8890ad, .spectrum-Stepper-button_8890ad.focus-ring_8890ad {
  z-index: 3;
}

.spectrum-Button_8890ad::-moz-focus-inner, .spectrum-ActionButton_8890ad::-moz-focus-inner, .spectrum-LogicButton_8890ad::-moz-focus-inner, .spectrum-FieldButton_8890ad::-moz-focus-inner, .spectrum-ClearButton_8890ad::-moz-focus-inner, .spectrum-Stepper-button_8890ad::-moz-focus-inner {
  border: 0;
  margin-top: -2px;
  margin-bottom: -2px;
  padding: 0;
}

.spectrum-Button_8890ad:disabled, .spectrum-ActionButton_8890ad:disabled, .spectrum-LogicButton_8890ad:disabled, .spectrum-FieldButton_8890ad:disabled, .spectrum-ClearButton_8890ad:disabled, .spectrum-Stepper-button_8890ad:disabled {
  cursor: default;
}

.spectrum-Button_8890ad .spectrum-Icon_8890ad, .spectrum-ActionButton_8890ad .spectrum-Icon_8890ad, .spectrum-LogicButton_8890ad .spectrum-Icon_8890ad, .spectrum-FieldButton_8890ad .spectrum-Icon_8890ad, .spectrum-ClearButton_8890ad .spectrum-Icon_8890ad, .spectrum-Stepper-button_8890ad .spectrum-Icon_8890ad {
  max-height: 100%;
  transition: background var(--spectrum-global-animation-duration-100, 130ms) ease-out, fill var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  box-sizing: initial;
  flex-shrink: 0;
  order: 0;
}

.spectrum-Button_8890ad:after, .spectrum-ActionButton--emphasized_8890ad:after, .spectrum-ActionButton--staticColor_8890ad:after, .spectrum-LogicButton_8890ad:after, .spectrum-ClearButton_8890ad:after {
  border-radius: calc(var(--spectrum-button-primary-border-radius, var(--spectrum-alias-border-radius-large))  + var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)));
  content: "";
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -1);
  transition: box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-out, margin var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  display: block;
  position: absolute;
  inset: 0;
  transform: translate(0);
}

.spectrum-Button_8890ad.focus-ring_8890ad:after, .spectrum-ActionButton--emphasized_8890ad.focus-ring_8890ad:after, .spectrum-ActionButton--staticColor_8890ad.focus-ring_8890ad:after, .spectrum-LogicButton_8890ad.focus-ring_8890ad:after, .spectrum-ClearButton_8890ad.focus-ring_8890ad:after {
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -2);
}

.spectrum-Button_8890ad {
  border-width: var(--spectrum-button-primary-border-size, var(--spectrum-alias-border-size-thick));
  border-radius: var(--spectrum-button-primary-border-radius, var(--spectrum-alias-border-radius-large));
  min-height: var(--spectrum-button-primary-height, var(--spectrum-alias-single-line-height));
  height: 0%;
  min-width: var(--spectrum-button-primary-min-width);
  padding: var(--spectrum-global-dimension-size-50) calc(var(--spectrum-button-primary-padding-x, var(--spectrum-global-dimension-size-200))  - var(--spectrum-button-primary-border-size, var(--spectrum-alias-border-size-thick)));
  padding-bottom: calc(var(--spectrum-global-dimension-size-50)  + 1px);
  padding-top: calc(var(--spectrum-global-dimension-size-50)  - 1px);
  font-size: var(--spectrum-button-primary-text-size, var(--spectrum-alias-pill-button-text-size));
  font-weight: var(--spectrum-button-primary-text-font-weight, var(--spectrum-global-font-weight-bold));
  border-style: solid;
}

.spectrum-Button_8890ad.is-hovered_8890ad, .spectrum-Button_8890ad:active {
  box-shadow: none;
}

[dir="ltr"] .spectrum-Button_8890ad .spectrum-Icon_8890ad + .spectrum-Button-label_8890ad {
  margin-left: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-Button_8890ad .spectrum-Icon_8890ad + .spectrum-Button-label_8890ad, [dir="ltr"] .spectrum-Button_8890ad .spectrum-Button-label_8890ad + .spectrum-Icon_8890ad {
  margin-right: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-Button_8890ad .spectrum-Button-label_8890ad + .spectrum-Icon_8890ad {
  margin-left: var(--spectrum-button-primary-text-gap, var(--spectrum-global-dimension-size-100));
}

a.spectrum-Button_8890ad, a.spectrum-ActionButton_8890ad {
  -webkit-appearance: none;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
}

.spectrum-ActionButton_8890ad {
  height: var(--spectrum-actionbutton-height, var(--spectrum-alias-single-line-height));
  min-width: var(--spectrum-actionbutton-min-width, var(--spectrum-global-dimension-size-400));
  border-width: var(--spectrum-actionbutton-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-actionbutton-border-radius, var(--spectrum-alias-border-radius-regular));
  font-size: var(--spectrum-actionbutton-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-actionbutton-text-font-weight, var(--spectrum-alias-body-text-font-weight));
  padding: 0;
  position: relative;
}

[dir="ltr"] .spectrum-ActionButton_8890ad .spectrum-Icon_8890ad {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_8890ad .spectrum-Icon_8890ad {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="ltr"] .spectrum-ActionButton_8890ad .spectrum-ActionButton-label_8890ad {
  padding-right: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-ActionButton_8890ad .spectrum-ActionButton-label_8890ad {
  padding-left: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="ltr"] .spectrum-ActionButton_8890ad .spectrum-Icon_8890ad + .spectrum-ActionButton-label_8890ad {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_8890ad .spectrum-Icon_8890ad + .spectrum-ActionButton-label_8890ad, [dir="ltr"] .spectrum-ActionButton_8890ad .spectrum-ActionButton-label_8890ad:not([hidden]) + .spectrum-Icon_8890ad {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_8890ad .spectrum-ActionButton-label_8890ad:not([hidden]) + .spectrum-Icon_8890ad {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="ltr"] .spectrum-ActionButton_8890ad .spectrum-ActionButton-label_8890ad:only-child, [dir="ltr"] .spectrum-ActionButton_8890ad .spectrum-ActionButton-hold_8890ad + .spectrum-ActionButton-label_8890ad:last-child {
  padding-left: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="rtl"] .spectrum-ActionButton_8890ad .spectrum-ActionButton-label_8890ad:only-child, [dir="rtl"] .spectrum-ActionButton_8890ad .spectrum-ActionButton-hold_8890ad + .spectrum-ActionButton-label_8890ad:last-child {
  padding-right: var(--spectrum-actionbutton-text-padding-x, var(--spectrum-global-dimension-size-150));
}

[dir="ltr"] .spectrum-ActionButton_8890ad .spectrum-Icon_8890ad:only-child, [dir="ltr"] .spectrum-ActionButton_8890ad .spectrum-ActionButton-hold_8890ad + .spectrum-Icon_8890ad:last-child {
  padding-right: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="rtl"] .spectrum-ActionButton_8890ad .spectrum-Icon_8890ad:only-child, [dir="rtl"] .spectrum-ActionButton_8890ad .spectrum-ActionButton-hold_8890ad + .spectrum-Icon_8890ad:last-child {
  padding-left: var(--spectrum-actionbutton-icon-padding-x, var(--spectrum-global-dimension-size-85));
}

[dir="ltr"] .spectrum-ActionButton-hold_8890ad {
  right: var(--spectrum-actionbutton-hold-icon-padding-right, var(--spectrum-global-dimension-size-40));
}

[dir="rtl"] .spectrum-ActionButton-hold_8890ad {
  left: var(--spectrum-actionbutton-hold-icon-padding-right, var(--spectrum-global-dimension-size-40));
}

.spectrum-ActionButton-hold_8890ad {
  bottom: var(--spectrum-actionbutton-hold-icon-padding-bottom, var(--spectrum-global-dimension-size-40));
  position: absolute;
}

[dir="rtl"] .spectrum-ActionButton-hold_8890ad {
  transform: rotate(90deg);
}

.spectrum-ActionButton-label_8890ad, .spectrum-Button-label_8890ad {
  -ms-grid-row-align: center;
  text-align: center;
  width: 100%;
  order: 1;
  place-self: center;
}

.spectrum-ActionButton-label_8890ad:empty, .spectrum-Button-label_8890ad:empty {
  display: none;
}

.spectrum-ActionButton-label_8890ad {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.spectrum-ActionButton--quiet_8890ad {
  border-width: var(--spectrum-actionbutton-quiet-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-actionbutton-quiet-border-radius, var(--spectrum-alias-border-radius-regular));
  font-size: var(--spectrum-actionbutton-quiet-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-actionbutton-quiet-text-font-weight, var(--spectrum-alias-body-text-font-weight));
}

.spectrum-ActionButton--emphasized_8890ad:after, .spectrum-ActionButton--staticColor_8890ad:after {
  border-radius: calc(var(--spectrum-actionbutton-border-radius, var(--spectrum-alias-border-radius-regular))  + var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)));
}

.spectrum-ActionButton--emphasized_8890ad.focus-ring_8890ad:after, .spectrum-ActionButton--staticColor_8890ad.focus-ring_8890ad:after {
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -1 - var(--spectrum-actionbutton-quiet-border-size, var(--spectrum-alias-border-size-thin)));
}

.spectrum-LogicButton_8890ad {
  height: var(--spectrum-logicbutton-and-height, 24px);
  padding: var(--spectrum-logicbutton-and-padding-x, var(--spectrum-global-dimension-size-100));
  border-width: var(--spectrum-logicbutton-and-border-size, var(--spectrum-alias-border-size-thick));
  border-radius: var(--spectrum-logicbutton-and-border-radius, var(--spectrum-alias-border-radius-regular));
  font-size: var(--spectrum-logicbutton-and-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-logicbutton-and-text-font-weight, var(--spectrum-global-font-weight-bold));
  line-height: 0;
}

.spectrum-LogicButton_8890ad:after {
  border-radius: calc(var(--spectrum-logicbutton-and-border-radius, var(--spectrum-alias-border-radius-regular))  + var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)));
}

.spectrum-FieldButton_8890ad {
  height: var(--spectrum-dropdown-height, var(--spectrum-global-dimension-size-400));
  padding: 0 var(--spectrum-dropdown-padding-x, var(--spectrum-global-dimension-size-150));
  font-family: inherit;
  font-weight: 400;
  font-size: var(--spectrum-dropdown-text-size, var(--spectrum-alias-font-size-default));
  -webkit-font-smoothing: initial;
  cursor: default;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: var(--spectrum-dropdown-padding-x, var(--spectrum-global-dimension-size-150));
  padding-right: var(--spectrum-dropdown-padding-x, var(--spectrum-global-dimension-size-150));
  border-width: var(--spectrum-dropdown-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-global-dimension-size-50);
  transition: background-color var(--spectrum-global-animation-duration-100, 130ms), box-shadow var(--spectrum-global-animation-duration-100, 130ms), border-color var(--spectrum-global-animation-duration-100, 130ms);
  border-style: solid;
  outline: 0;
  margin: 0;
  line-height: normal;
}

.spectrum-FieldButton_8890ad:disabled, .spectrum-FieldButton_8890ad.is-disabled_8890ad {
  cursor: default;
  border-width: 0;
}

.spectrum-FieldButton_8890ad.is-open_8890ad {
  border-width: var(--spectrum-dropdown-border-size, var(--spectrum-alias-border-size-thin));
}

.spectrum-FieldButton--quiet_8890ad {
  border-radius: var(--spectrum-fieldbutton-quiet-border-radius, 0px);
  border-width: 0;
  margin: 0;
  padding: 0;
}

.spectrum-FieldButton--quiet_8890ad:disabled.focus-ring_8890ad, .spectrum-FieldButton--quiet_8890ad.is-disabled_8890ad.focus-ring_8890ad {
  box-shadow: none;
}

.spectrum-ClearButton_8890ad {
  width: var(--spectrum-clearbutton-medium-width, var(--spectrum-alias-single-line-height));
  height: var(--spectrum-clearbutton-medium-height, var(--spectrum-alias-single-line-height));
  border: 0;
  border-radius: 100%;
  margin: 0;
  padding: 0;
}

.spectrum-ClearButton_8890ad > .spectrum-Icon_8890ad {
  margin: 0 auto;
}

.spectrum-ClearButton--overBackground_8890ad.focus-ring_8890ad:after {
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -1);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .spectrum-ClearButton_8890ad > .spectrum-Icon_8890ad {
    margin: 0;
  }
}

.spectrum-ClearButton--small_8890ad {
  width: var(--spectrum-clearbutton-small-width, var(--spectrum-global-dimension-size-300));
  height: var(--spectrum-clearbutton-small-height, var(--spectrum-global-dimension-size-300));
}

.spectrum-Stepper_8890ad {
  display: -ms-inline-grid;
  width: calc(var(--spectrum-alias-single-line-height, var(--spectrum-global-dimension-size-400)) * 4);
  transition: border-color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  line-height: 0;
  display: inline-grid;
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) {
  -ms-grid-rows: auto auto;
  -ms-grid-columns: 1fr auto;
  grid-template: "field increment"
                 "field decrement"
                 / 1fr auto;
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepUp_8890ad {
  padding-top: calc(var(--spectrum-global-dimension-size-50)  + 1px);
  padding-bottom: calc(var(--spectrum-global-dimension-size-50)  - .5px);
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepDown_8890ad {
  padding-bottom: calc(var(--spectrum-global-dimension-size-50)  + 1px);
  padding-top: calc(var(--spectrum-global-dimension-size-50)  - .5px);
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isMobile_8890ad {
  width: calc(var(--spectrum-alias-single-line-height, var(--spectrum-global-dimension-size-400)) * 5);
  -ms-grid-rows: auto;
  -ms-grid-columns: auto 1fr auto;
  grid-template: "decrement field increment"
  / auto 1fr auto;
}

.spectrum-Stepper_8890ad .spectrum-Stepper-field_8890ad {
  width: unset;
  min-width: var(--spectrum-alias-single-line-height, var(--spectrum-global-dimension-size-400));
  grid-area: field;
}

[dir="ltr"] .spectrum-Stepper_8890ad .spectrum-Stepper-icon_8890ad {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Stepper_8890ad .spectrum-Stepper-icon_8890ad {
  padding-left: 0;
}

.spectrum-Stepper_8890ad .spectrum-Stepper-button--stepUp_8890ad {
  grid-area: increment;
}

.spectrum-Stepper_8890ad .spectrum-Stepper-button--stepDown_8890ad {
  grid-area: decrement;
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad) {
  border-radius: var(--spectrum-global-dimension-size-50);
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad) .spectrum-Stepper-icon_8890ad {
  right: var(--spectrum-global-dimension-size-100);
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad) .spectrum-Stepper-icon_8890ad {
  left: var(--spectrum-global-dimension-size-100);
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad):not(.spectrum-Stepper--showStepper_8890ad):not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button_8890ad {
  height: calc(var(--spectrum-alias-single-line-height, var(--spectrum-global-dimension-size-400)) / 2);
  width: 24px;
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad):not(.spectrum-Stepper--showStepper_8890ad).spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button_8890ad {
  height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  width: var(--spectrum-actionbutton-min-width, var(--spectrum-global-dimension-size-400));
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-input_8890ad {
  border-top-right-radius: 0;
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-input_8890ad {
  border-top-left-radius: 0;
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-input_8890ad {
  border-bottom-right-radius: 0;
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-input_8890ad {
  border-bottom-left-radius: 0;
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button_8890ad {
  border-left-width: 0;
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button_8890ad {
  border-right-width: 0;
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button_8890ad {
  height: calc(var(--spectrum-alias-single-line-height, var(--spectrum-global-dimension-size-400)) / 2);
  width: 24px;
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepDown_8890ad {
  border-bottom-right-radius: var(--spectrum-global-dimension-size-50);
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepDown_8890ad {
  border-bottom-left-radius: var(--spectrum-global-dimension-size-50);
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepDown_8890ad {
  border-right-width: 1px;
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepDown_8890ad {
  border-left-width: 1px;
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepDown_8890ad {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepUp_8890ad {
  border-right-width: 1px;
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepUp_8890ad {
  border-left-width: 1px;
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepUp_8890ad {
  border-top-right-radius: var(--spectrum-global-dimension-size-50);
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepUp_8890ad {
  border-top-left-radius: var(--spectrum-global-dimension-size-50);
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepUp_8890ad {
  border-top-width: 1px;
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-input_8890ad {
  border-radius: 0;
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button_8890ad {
  height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  width: var(--spectrum-actionbutton-min-width, var(--spectrum-global-dimension-size-400));
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepDown_8890ad {
  border-left-width: 1px;
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepDown_8890ad {
  border-right-width: 1px;
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepDown_8890ad {
  border-top-left-radius: var(--spectrum-global-dimension-size-50);
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepDown_8890ad {
  border-top-right-radius: var(--spectrum-global-dimension-size-50);
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepDown_8890ad {
  border-bottom-left-radius: var(--spectrum-global-dimension-size-50);
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepDown_8890ad {
  border-bottom-right-radius: var(--spectrum-global-dimension-size-50);
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepDown_8890ad {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepUp_8890ad {
  border-right-width: 1px;
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepUp_8890ad {
  border-left-width: 1px;
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepUp_8890ad {
  border-top-right-radius: var(--spectrum-global-dimension-size-50);
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepUp_8890ad {
  border-top-left-radius: var(--spectrum-global-dimension-size-50);
}

[dir="ltr"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepUp_8890ad {
  border-bottom-right-radius: var(--spectrum-global-dimension-size-50);
}

[dir="rtl"] .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepUp_8890ad {
  border-bottom-left-radius: var(--spectrum-global-dimension-size-50);
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button--stepUp_8890ad {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

[dir="ltr"] .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad .spectrum-Stepper-icon_8890ad {
  right: 0;
}

[dir="rtl"] .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad .spectrum-Stepper-icon_8890ad {
  left: 0;
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:not(.spectrum-Stepper--showStepper_8890ad):not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button_8890ad {
  height: calc(var(--spectrum-alias-single-line-height, var(--spectrum-global-dimension-size-400)) / 2);
  width: 24px;
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:not(.spectrum-Stepper--showStepper_8890ad):not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepDown_8890ad {
  border-bottom-width: 1px;
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:not(.spectrum-Stepper--showStepper_8890ad).spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button_8890ad {
  height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  width: var(--spectrum-actionbutton-min-width, var(--spectrum-global-dimension-size-400));
  border-bottom-width: 1px;
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button_8890ad {
  height: calc(var(--spectrum-alias-single-line-height, var(--spectrum-global-dimension-size-400)) / 2);
  width: 24px;
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.spectrum-Stepper--showStepper_8890ad:not(.spectrum-Stepper--isMobile_8890ad) .spectrum-Stepper-button--stepDown_8890ad {
  border-bottom-width: 1px;
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.spectrum-Stepper--showStepper_8890ad.spectrum-Stepper--isMobile_8890ad .spectrum-Stepper-button_8890ad {
  height: var(--spectrum-textfield-height, var(--spectrum-alias-single-line-height));
  width: var(--spectrum-actionbutton-min-width, var(--spectrum-global-dimension-size-400));
  border-bottom-width: 1px;
}

.spectrum-Stepper-container_8890ad.spectrum-Stepper-container_8890ad {
  --spectrum-field-default-width: calc(var(--spectrum-alias-single-line-height, var(--spectrum-global-dimension-size-400)) * 4);
}

.spectrum-Stepper-container_8890ad.spectrum-Stepper-container--isMobile_8890ad {
  --spectrum-field-default-width: calc(var(--spectrum-alias-single-line-height, var(--spectrum-global-dimension-size-400)) * 5);
}

.spectrum-Stepper-button_8890ad {
  border-width: 0;
}

.spectrum-Stepper-button_8890ad.is-hovered_8890ad, .spectrum-Stepper-button_8890ad:active {
  box-shadow: none;
}

.spectrum-Stepper-button_8890ad .spectrum-Stepper-button-icon_8890ad {
  max-height: 100%;
  transition: background var(--spectrum-global-animation-duration-100, 130ms) ease-out, fill var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  box-sizing: initial;
  flex-shrink: 0;
}

.spectrum-Stepper_8890ad .spectrum-Stepper-button-icon_8890ad {
  fill: var(--spectrum-actionbutton-icon-color, var(--spectrum-alias-icon-color));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad) .spectrum-Stepper-input_8890ad {
  border-color: var(--spectrum-textfield-border-color, var(--spectrum-alias-border-color));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad) .spectrum-Stepper-button_8890ad {
  background-color: var(--spectrum-actionbutton-background-color, var(--spectrum-global-color-gray-75));
  border-color: var(--spectrum-textfield-border-color, var(--spectrum-alias-border-color));
  color: var(--spectrum-actionbutton-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-hovered_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-hovered_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-hovered_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-hovered_8890ad .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-border-color-hover, var(--spectrum-alias-border-color-hover));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-active_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad):active .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-active_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad):active .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-focused_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad):focus .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-focused_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad):focus .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).focus-ring_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).focus-ring_8890ad {
  box-shadow: 0 0 0 1px var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).focus-ring_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).focus-ring_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).focus-ring_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).focus-ring_8890ad .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-disabled_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad):disabled .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-disabled_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad):disabled .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-border-color-disabled, var(--spectrum-alias-border-color-transparent));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-invalid_8890ad.focus-ring_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-invalid_8890ad.focus-ring_8890ad {
  box-shadow: 0 0 0 1px var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-invalid_8890ad.focus-ring_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-invalid_8890ad.focus-ring_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-invalid_8890ad.is-focused_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-invalid_8890ad.focus-ring_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-invalid_8890ad.focus-ring_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-invalid_8890ad.is-focused_8890ad .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-invalid_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-invalid_8890ad .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-border-color-error, var(--spectrum-semantic-negative-color-default));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad .spectrum-Stepper-input_8890ad {
  border-color: var(--spectrum-textfield-quiet-border-color, var(--spectrum-alias-border-color));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad .spectrum-Stepper-button_8890ad {
  background-color: unset;
  border-color: var(--spectrum-textfield-quiet-border-color, var(--spectrum-alias-border-color));
  color: var(--spectrum-actionbutton-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-hovered_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-hovered_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-hovered_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-hovered_8890ad .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-quiet-border-color-hover, var(--spectrum-alias-border-color-hover));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-active_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:active .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-active_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:active .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-focused_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:focus .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-focused_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:focus .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad {
  box-shadow: 0 1px 0 0 var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-disabled_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:disabled .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-disabled_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:disabled .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-quiet-border-color-disabled, var(--spectrum-alias-border-color-mid));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-invalid_8890ad.focus-ring_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-invalid_8890ad.focus-ring_8890ad {
  box-shadow: 0 1px 0 0 var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-invalid_8890ad.is-focused_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-invalid_8890ad.is-focused_8890ad .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-invalid_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-invalid_8890ad .spectrum-Stepper-button_8890ad {
  border-color: var(--spectrum-textfield-quiet-border-color-error, var(--spectrum-semantic-negative-color-default));
}

.spectrum-Stepper_8890ad .spectrum-Stepper-button_8890ad.is-hovered_8890ad, .spectrum-Stepper_8890ad .spectrum-Stepper-button_8890ad.is-hovered_8890ad {
  color: var(--spectrum-actionbutton-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-Stepper_8890ad .spectrum-Stepper-button_8890ad.is-hovered_8890ad .spectrum-Stepper-button-icon_8890ad, .spectrum-Stepper_8890ad .spectrum-Stepper-button_8890ad.is-hovered_8890ad .spectrum-Stepper-button-icon_8890ad {
  fill: var(--spectrum-actionbutton-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-Stepper_8890ad .spectrum-Stepper-button_8890ad.is-active_8890ad {
  color: var(--spectrum-actionbutton-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-Stepper_8890ad .spectrum-Stepper-button_8890ad.is-active_8890ad .spectrum-Stepper-button-icon_8890ad {
  fill: var(--spectrum-actionbutton-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-Stepper_8890ad .spectrum-Stepper-button_8890ad:disabled, .spectrum-Stepper_8890ad .spectrum-Stepper-button_8890ad.is-disabled_8890ad {
  color: var(--spectrum-textfield-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-Stepper_8890ad .spectrum-Stepper-button_8890ad:disabled .spectrum-Stepper-button-icon_8890ad, .spectrum-Stepper_8890ad .spectrum-Stepper-button_8890ad.is-disabled_8890ad .spectrum-Stepper-button-icon_8890ad {
  fill: var(--spectrum-textfield-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad) .spectrum-Stepper-button_8890ad.is-hovered_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad) .spectrum-Stepper-button_8890ad.is-hovered_8890ad {
  background-color: var(--spectrum-actionbutton-background-color-hover, var(--spectrum-global-color-gray-50));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad) .spectrum-Stepper-button_8890ad.is-active_8890ad {
  background-color: var(--spectrum-actionbutton-background-color-down, var(--spectrum-global-color-gray-200));
}

.spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad) .spectrum-Stepper-button_8890ad:disabled, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad) .spectrum-Stepper-button_8890ad.is-disabled_8890ad {
  background-color: var(--spectrum-textfield-background-color-disabled, var(--spectrum-global-color-gray-200));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad .spectrum-Stepper-button_8890ad:disabled, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad .spectrum-Stepper-button_8890ad.is-disabled_8890ad {
  color: var(--spectrum-textfield-quiet-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad .spectrum-Stepper-button_8890ad:disabled .spectrum-Stepper-button-icon_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad .spectrum-Stepper-button_8890ad.is-disabled_8890ad .spectrum-Stepper-button-icon_8890ad {
  fill: var(--spectrum-textfield-quiet-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

@media (forced-colors: active) {
  .spectrum-Stepper_8890ad {
    --spectrum-textfield-border-color-disabled: GrayText;
    --spectrum-textfield-border-color-error: Highlight;
    --spectrum-textfield-border-color-hover: Highlight;
    --spectrum-textfield-border-color-key-focus: Highlight;
    --spectrum-textfield-quiet-border-color-disabled: GrayText;
    --spectrum-textfield-quiet-border-color-error: Highlight;
    --spectrum-textfield-quiet-border-color-hover: Highlight;
    --spectrum-textfield-quiet-border-color-key-focus: Highlight;
    --spectrum-textfield-quiet-border-color: ButtonText;
    --spectrum-textfield-quiet-text-color-disabled: GrayText;
    --spectrum-textfield-text-color-disabled: GrayText;
  }

  .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad).is-focused_8890ad, .spectrum-Stepper_8890ad:not(.spectrum-Stepper--isQuiet_8890ad):focus {
    outline: 2px solid Highlight;
  }

  .spectrum-Stepper_8890ad.spectrum-Stepper--readonly_8890ad.spectrum-Stepper--isQuiet_8890ad .spectrum-Stepper-button_8890ad {
    border-color: var(--spectrum-textfield-quiet-border-color, var(--spectrum-alias-border-color));
  }

  .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-focused_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:focus, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad {
    forced-color-adjust: none;
    box-shadow: 0 1px 0 0 var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  }

  .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-focused_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:focus .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-focused_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad:focus .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.focus-ring_8890ad .spectrum-Stepper-button_8890ad {
    forced-color-adjust: auto;
    border-color: var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  }

  .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-hovered_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-hovered_8890ad .spectrum-Stepper-input_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-hovered_8890ad .spectrum-Stepper-button_8890ad, .spectrum-Stepper_8890ad.spectrum-Stepper--isQuiet_8890ad.is-hovered_8890ad .spectrum-Stepper-button_8890ad {
    border-color: var(--spectrum-textfield-quiet-border-color-key-focus, var(--spectrum-alias-border-color-focus));
  }
}

[dir="ltr"] .spectrum-ToggleSwitch_3526dd {
  margin-right: calc(var(--spectrum-switch-cursor-hit-x, var(--spectrum-global-dimension-size-100)) * 2);
}

[dir="rtl"] .spectrum-ToggleSwitch_3526dd {
  margin-left: calc(var(--spectrum-switch-cursor-hit-x, var(--spectrum-global-dimension-size-100)) * 2);
}

.spectrum-ToggleSwitch_3526dd {
  min-height: var(--spectrum-switch-height, var(--spectrum-global-dimension-size-400));
  max-width: 100%;
  vertical-align: top;
  isolation: isolate;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

[dir="ltr"] .spectrum-ToggleSwitch-input_3526dd {
  left: 0;
}

[dir="rtl"] .spectrum-ToggleSwitch-input_3526dd {
  right: 0;
}

.spectrum-ToggleSwitch-input_3526dd {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  opacity: .0001;
  z-index: 1;
  cursor: default;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
}

[dir="ltr"] .spectrum-ToggleSwitch-input_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd:before {
  transform: translateX(var(--spectrum-switch-handle-transform-x-ltr));
}

[dir="rtl"] .spectrum-ToggleSwitch-input_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd:before {
  transform: translateX(var(--spectrum-switch-handle-transform-x-rtl));
}

.spectrum-ToggleSwitch-input_3526dd:disabled, .spectrum-ToggleSwitch-input_3526dd[disabled] {
  cursor: default;
}

.spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd + .spectrum-ToggleSwitch-switch_3526dd:after {
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -1);
}

[dir="ltr"] .spectrum-ToggleSwitch-label_3526dd {
  text-align: left;
}

[dir="rtl"] .spectrum-ToggleSwitch-label_3526dd {
  text-align: right;
}

.spectrum-ToggleSwitch-label_3526dd {
  margin: 0 var(--spectrum-switch-text-gap, var(--spectrum-global-dimension-size-125));
  margin-top: var(--spectrum-global-dimension-size-65);
  font-size: var(--spectrum-switch-text-size, var(--spectrum-alias-font-size-default));
  transition: color var(--spectrum-global-animation-duration-200, 160ms) ease-in-out;
  line-height: 1.49;
}

[dir="ltr"] .spectrum-ToggleSwitch-switch_3526dd {
  left: 0;
}

[dir="rtl"] .spectrum-ToggleSwitch-switch_3526dd, [dir="ltr"] .spectrum-ToggleSwitch-switch_3526dd {
  right: 0;
}

[dir="rtl"] .spectrum-ToggleSwitch-switch_3526dd {
  left: 0;
}

.spectrum-ToggleSwitch-switch_3526dd {
  box-sizing: border-box;
  width: var(--spectrum-switch-track-width);
  margin: calc((var(--spectrum-switch-height, var(--spectrum-global-dimension-size-400))  - var(--spectrum-switch-track-height, var(--spectrum-global-dimension-size-175))) / 2) 0;
  vertical-align: middle;
  will-change: transform;
  transition: background var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, border var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  height: var(--spectrum-switch-track-height, var(--spectrum-global-dimension-size-175));
  border-radius: calc(var(--spectrum-switch-track-height, var(--spectrum-global-dimension-size-175)) / 2);
  flex-grow: 0;
  flex-shrink: 0;
  display: inline-block;
  position: relative;
}

.spectrum-ToggleSwitch-switch_3526dd:before {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
}

[dir="ltr"] .spectrum-ToggleSwitch-switch_3526dd:before {
  left: 0;
}

[dir="rtl"] .spectrum-ToggleSwitch-switch_3526dd:before {
  right: 0;
}

.spectrum-ToggleSwitch-switch_3526dd:before {
  transition: background var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, border var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, transform var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  width: var(--spectrum-switch-handle-size, var(--spectrum-global-dimension-size-175));
  height: var(--spectrum-switch-handle-size, var(--spectrum-global-dimension-size-175));
  border-width: var(--spectrum-switch-handle-border-size, var(--spectrum-alias-border-size-thick));
  border-radius: var(--spectrum-switch-handle-border-radius);
  border-style: solid;
  top: 0;
}

.spectrum-ToggleSwitch-switch_3526dd:after {
  border-radius: calc(var(--spectrum-switch-track-height, var(--spectrum-global-dimension-size-175))  + var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)));
  content: "";
  transition: box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-out, margin var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  margin: 0;
  display: block;
  position: absolute;
  inset: 0;
  transform: translate(0);
}

.spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-emphasized-track-color, var(--spectrum-global-color-gray-300));
}

.spectrum-ToggleSwitch-switch_3526dd:before {
  background-color: var(--spectrum-switch-emphasized-handle-background-color, var(--spectrum-global-color-gray-75));
  border-color: var(--spectrum-switch-emphasized-handle-border-color, var(--spectrum-global-color-gray-600));
}

.spectrum-ToggleSwitch-input_3526dd ~ .spectrum-ToggleSwitch-label_3526dd {
  color: var(--spectrum-switch-emphasized-text-color, var(--spectrum-alias-text-color));
}

.spectrum-ToggleSwitch-input_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-emphasized-track-color-selected, var(--spectrum-global-color-blue-500));
}

.spectrum-ToggleSwitch-input_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-selected, var(--spectrum-global-color-blue-500));
}

.spectrum-ToggleSwitch_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-hover, var(--spectrum-global-color-gray-700));
  box-shadow: none;
}

.spectrum-ToggleSwitch_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd ~ .spectrum-ToggleSwitch-label_3526dd {
  color: var(--spectrum-switch-emphasized-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-ToggleSwitch_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-emphasized-track-color-selected-hover, var(--spectrum-global-color-blue-600));
}

.spectrum-ToggleSwitch_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-selected-hover, var(--spectrum-global-color-blue-600));
}

.spectrum-ToggleSwitch_3526dd:active .spectrum-ToggleSwitch-input_3526dd + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-down, var(--spectrum-global-color-gray-800));
}

.spectrum-ToggleSwitch_3526dd:active .spectrum-ToggleSwitch-input_3526dd ~ .spectrum-ToggleSwitch-label_3526dd {
  color: var(--spectrum-switch-emphasized-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-ToggleSwitch_3526dd:active .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-emphasized-track-color-selected-down, var(--spectrum-global-color-blue-700));
}

.spectrum-ToggleSwitch_3526dd:active .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-selected-down, var(--spectrum-global-color-blue-700));
}

.spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd:disabled + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-emphasized-track-color-disabled, var(--spectrum-global-color-gray-300));
}

.spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd:disabled + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-disabled, var(--spectrum-global-color-gray-400));
}

.spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd:disabled ~ .spectrum-ToggleSwitch-label_3526dd {
  color: var(--spectrum-switch-emphasized-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd:disabled:checked + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-emphasized-track-color-selected-disabled, var(--spectrum-global-color-gray-400));
}

.spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd:disabled:checked + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-selected-disabled, var(--spectrum-global-color-gray-400));
}

.spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd:disabled:checked ~ .spectrum-ToggleSwitch-label_3526dd {
  color: var(--spectrum-switch-emphasized-text-color-selected-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-ToggleSwitch--quiet_3526dd .spectrum-ToggleSwitch-input_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-quiet-track-color-selected, var(--spectrum-global-color-gray-700));
}

.spectrum-ToggleSwitch--quiet_3526dd .spectrum-ToggleSwitch-input_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-quiet-handle-border-color-selected, var(--spectrum-global-color-gray-700));
}

.spectrum-ToggleSwitch--quiet_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-quiet-track-color-selected-hover, var(--spectrum-global-color-gray-800));
}

.spectrum-ToggleSwitch--quiet_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-quiet-handle-border-color-selected-hover, var(--spectrum-global-color-gray-800));
}

.spectrum-ToggleSwitch_3526dd.spectrum-ToggleSwitch--quiet_3526dd:active .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-quiet-track-color-selected-down, var(--spectrum-global-color-gray-900));
}

.spectrum-ToggleSwitch_3526dd.spectrum-ToggleSwitch--quiet_3526dd:active .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-quiet-handle-border-color-selected-down, var(--spectrum-global-color-gray-900));
}

.spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd + .spectrum-ToggleSwitch-switch_3526dd:after, .spectrum-ToggleSwitch_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd + .spectrum-ToggleSwitch-switch_3526dd:after {
  box-shadow: 0 0 0 var(--spectrum-switch-focus-ring-size-key-focus, var(--spectrum-alias-focus-ring-size)) var(--spectrum-switch-focus-ring-color-key-focus, var(--spectrum-alias-focus-ring-color));
}

.spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd + .spectrum-ToggleSwitch-switch_3526dd:before, .spectrum-ToggleSwitch_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-key-focus, var(--spectrum-global-color-gray-700));
}

.spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd, .spectrum-ToggleSwitch_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-emphasized-track-color-selected-key-focus, var(--spectrum-global-color-blue-600));
}

.spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd:before, .spectrum-ToggleSwitch_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-selected-key-focus, var(--spectrum-global-color-blue-600));
}

.spectrum-ToggleSwitch--quiet_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd, .spectrum-ToggleSwitch--quiet_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-quiet-track-color-selected-key-focus, var(--spectrum-global-color-gray-800));
}

.spectrum-ToggleSwitch--quiet_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd:before, .spectrum-ToggleSwitch--quiet_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-quiet-handle-border-color-selected-key-focus, var(--spectrum-global-color-gray-800));
}

.spectrum-ToggleSwitch--ab_3526dd .spectrum-ToggleSwitch-input_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-track-color, var(--spectrum-global-color-gray-300));
}

.spectrum-ToggleSwitch--ab_3526dd .spectrum-ToggleSwitch-input_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-handle-border-color, var(--spectrum-global-color-gray-600));
}

.spectrum-ToggleSwitch--ab_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-track-color, var(--spectrum-global-color-gray-300));
}

.spectrum-ToggleSwitch--ab_3526dd .spectrum-ToggleSwitch-input_3526dd.focus-ring_3526dd:checked + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-handle-border-color-selected, var(--spectrum-global-color-gray-700));
}

.spectrum-ToggleSwitch--ab_3526dd .spectrum-ToggleSwitch-input_3526dd:disabled:checked + .spectrum-ToggleSwitch-switch_3526dd, .spectrum-ToggleSwitch--ab_3526dd .spectrum-ToggleSwitch-input_3526dd:disabled + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-emphasized-track-color-disabled, var(--spectrum-global-color-gray-300));
}

.spectrum-ToggleSwitch--ab_3526dd .spectrum-ToggleSwitch-input_3526dd:disabled + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-disabled, var(--spectrum-global-color-gray-400));
}

.spectrum-ToggleSwitch--ab_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-emphasized-track-color, var(--spectrum-global-color-gray-300));
}

.spectrum-ToggleSwitch--ab_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-hover, var(--spectrum-global-color-gray-700));
}

.spectrum-ToggleSwitch--ab_3526dd:active .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd {
  background-color: var(--spectrum-switch-emphasized-track-color, var(--spectrum-global-color-gray-300));
}

.spectrum-ToggleSwitch--ab_3526dd:active .spectrum-ToggleSwitch-input_3526dd:checked:enabled + .spectrum-ToggleSwitch-switch_3526dd:before {
  border-color: var(--spectrum-switch-emphasized-handle-border-color-down, var(--spectrum-global-color-gray-800));
}

@media (forced-colors: active) {
  .spectrum-ToggleSwitch_3526dd {
    forced-color-adjust: none;
    --spectrum-switch-emphasized-handle-background-color: ButtonFace;
    --spectrum-switch-emphasized-handle-border-color: ButtonText;
    --spectrum-switch-emphasized-handle-border-color-disabled: GrayText;
    --spectrum-switch-emphasized-handle-border-color-down: Highlight;
    --spectrum-switch-emphasized-handle-border-color-hover: Highlight;
    --spectrum-switch-emphasized-handle-border-color-key-focus: ButtonText;
    --spectrum-switch-emphasized-handle-border-color-selected: Highlight;
    --spectrum-switch-emphasized-handle-border-color-selected-disabled: GrayText;
    --spectrum-switch-emphasized-handle-border-color-selected-down: Highlight;
    --spectrum-switch-emphasized-handle-border-color-selected-hover: Highlight;
    --spectrum-switch-emphasized-handle-border-color-selected-key-focus: Highlight;
    --spectrum-switch-emphasized-text-color: CanvasText;
    --spectrum-switch-emphasized-text-color-disabled: GrayText;
    --spectrum-switch-emphasized-text-color-down: CanvasText;
    --spectrum-switch-emphasized-text-color-hover: CanvasText;
    --spectrum-switch-emphasized-text-color-selected-disabled: GrayText;
    --spectrum-switch-emphasized-track-color: ButtonFace;
    --spectrum-switch-emphasized-track-color-disabled: ButtonFace;
    --spectrum-switch-emphasized-track-color-selected: Highlight;
    --spectrum-switch-emphasized-track-color-selected-disabled: GrayText;
    --spectrum-switch-emphasized-track-color-selected-down: Highlight;
    --spectrum-switch-emphasized-track-color-selected-hover: Highlight;
    --spectrum-switch-emphasized-track-color-selected-key-focus: Highlight;
    --spectrum-switch-focus-ring-color-key-focus: ButtonText;
    --spectrum-switch-handle-border-color: ButtonText;
    --spectrum-switch-handle-border-color-selected: Highlight;
    --spectrum-switch-quiet-handle-border-color-selected: Highlight;
    --spectrum-switch-quiet-handle-border-color-selected-down: Highlight;
    --spectrum-switch-quiet-handle-border-color-selected-hover: Highlight;
    --spectrum-switch-quiet-handle-border-color-selected-key-focus: Highlight;
    --spectrum-switch-quiet-track-color-selected: Highlight;
    --spectrum-switch-quiet-track-color-selected-down: Highlight;
    --spectrum-switch-quiet-track-color-selected-hover: Highlight;
    --spectrum-switch-quiet-track-color-selected-key-focus: Highlight;
    --spectrum-switch-track-color: ButtonFace;
    --spectrum-switch-track-color-disabled: ButtonFace;
  }

  .spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd:not(:checked) + .spectrum-ToggleSwitch-switch_3526dd {
    box-shadow: inset 0 0 0 1px var(--spectrum-switch-handle-border-color, var(--spectrum-global-color-gray-600));
  }

  .spectrum-ToggleSwitch_3526dd.is-hovered_3526dd .spectrum-ToggleSwitch-input_3526dd:not(:checked) + .spectrum-ToggleSwitch-switch_3526dd {
    box-shadow: inset 0 0 0 1px var(--spectrum-switch-emphasized-handle-border-color-hover, var(--spectrum-global-color-gray-700));
  }

  .spectrum-ToggleSwitch_3526dd .spectrum-ToggleSwitch-input_3526dd:disabled + .spectrum-ToggleSwitch-switch_3526dd {
    box-shadow: inset 0 0 0 1px var(--spectrum-switch-emphasized-handle-border-color-disabled, var(--spectrum-global-color-gray-400));
  }
}

.spectrum-Table_d0fc74 {
  border-collapse: separate;
  border-spacing: 0;
  isolation: isolate;
  outline: 0;
}

[dir="ltr"] svg.spectrum-Table-sortedIcon_d0fc74 {
  margin-left: var(--spectrum-table-header-sort-icon-gap, var(--spectrum-global-dimension-size-125));
}

[dir="rtl"] svg.spectrum-Table-sortedIcon_d0fc74 {
  margin-right: var(--spectrum-table-header-sort-icon-gap, var(--spectrum-global-dimension-size-125));
}

svg.spectrum-Table-sortedIcon_d0fc74 {
  min-width: var(--spectrum-icon-arrow-down-small-width, var(--spectrum-global-dimension-size-100));
  vertical-align: middle;
  transition: transform var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  flex: none;
  display: none;
}

.spectrum-Table-headWrapper_d0fc74 {
  border-left-style: solid;
  border-left-width: 1px;
  border-right-style: solid;
  border-right-width: 1px;
  flex: none;
}

.spectrum-Table-headCellContents_d0fc74 {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
}

.spectrum-Table-headCell_d0fc74 {
  box-sizing: border-box;
  font-size: var(--spectrum-table-header-text-size, var(--spectrum-global-dimension-font-size-50));
  font-weight: var(--spectrum-table-header-text-font-weight, var(--spectrum-global-font-weight-bold));
  line-height: var(--spectrum-table-header-text-line-height, var(--spectrum-alias-heading-text-line-height));
  min-height: var(--spectrum-table-header-min-height, var(--spectrum-global-dimension-size-150));
  letter-spacing: var(--spectrum-table-header-text-letter-spacing, var(--spectrum-global-font-letter-spacing-medium));
  text-transform: uppercase;
  padding: var(--spectrum-table-header-padding-y, var(--spectrum-global-dimension-static-size-125)) var(--spectrum-table-header-padding-x, var(--spectrum-global-dimension-size-200));
  transition: color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  border-radius: var(--spectrum-table-header-border-radius, 0px);
  outline: 0;
  display: flex;
}

.spectrum-Table-headCell_d0fc74.is-sortable_d0fc74 {
  cursor: default;
}

.spectrum-Table-headCell_d0fc74.is-sorted-asc_d0fc74 .spectrum-Table-sortedIcon_d0fc74, .spectrum-Table-headCell_d0fc74.is-sorted-desc_d0fc74 .spectrum-Table-sortedIcon_d0fc74 {
  margin-top: calc(var(--spectrum-global-dimension-size-25) * -1);
  display: inline-block;
}

.spectrum-Table-headCell_d0fc74.is-sorted-asc_d0fc74 .spectrum-Table-sortedIcon_d0fc74 {
  transform: rotate(180deg);
}

.spectrum-Table-headCell_d0fc74.is-resizable_d0fc74 {
  padding: 0;
}

.spectrum-Table-headCell_d0fc74.is-resizable_d0fc74 .spectrum-Table-headCellContents_d0fc74 {
  min-width: 0;
  flex: auto;
}

.spectrum-Table-headCell_d0fc74.is-resizable_d0fc74 .spectrum-Table-headCellButton_d0fc74 {
  box-sizing: border-box;
  padding: var(--spectrum-table-header-padding-y, var(--spectrum-global-dimension-static-size-125)) var(--spectrum-table-header-padding-x, var(--spectrum-global-dimension-size-200));
}

.spectrum-Table-columnResizer_d0fc74 {
  box-sizing: border-box;
  width: 10px;
  height: 100%;
  -webkit-user-select: none;
  user-select: none;
  flex: none;
  justify-content: flex-end;
  display: flex;
}

.spectrum-Table-columnResizer_d0fc74:after {
  content: "";
  box-sizing: border-box;
  width: 1px;
  height: 100%;
  display: block;
}

.spectrum-Table-columnResizer_d0fc74:active, .spectrum-Table-columnResizer_d0fc74.focus-ring_d0fc74 {
  outline: 0;
}

.spectrum-Table-columnResizer_d0fc74:active:after, .spectrum-Table-columnResizer_d0fc74.focus-ring_d0fc74:after {
  width: 2px;
}

.spectrum-Table-cell--alignCenter_d0fc74 {
  text-align: center;
}

[dir="ltr"] .spectrum-Table-cell--alignEnd_d0fc74 {
  text-align: right;
}

[dir="rtl"] .spectrum-Table-cell--alignEnd_d0fc74 {
  text-align: left;
}

.spectrum-Table-body_d0fc74.is-drop-target_d0fc74:before, .spectrum-Table-row_d0fc74.is-drop-target_d0fc74:before {
  content: "";
  z-index: 1;
  position: absolute;
  inset: 0;
}

.spectrum-Table-body_d0fc74 {
  border-width: var(--spectrum-table-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular));
  vertical-align: var(--spectrum-table-cell-vertical-alignment, top);
  position: relative;
  overflow: auto;
  transform: translate(0);
}

.spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74) tbody.spectrum-Table-body_d0fc74 {
  border-width: var(--spectrum-table-border-size, var(--spectrum-alias-border-size-thin));
  border-radius: var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular));
}

.spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74) tbody.spectrum-Table-body_d0fc74 .spectrum-Table-row_d0fc74:first-child .spectrum-Table-cell_d0fc74:first-child {
  border-top-left-radius: var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular));
}

.spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74) tbody.spectrum-Table-body_d0fc74 .spectrum-Table-row_d0fc74:first-child .spectrum-Table-cell_d0fc74:last-child {
  border-top-right-radius: var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular));
}

.spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74) tbody.spectrum-Table-body_d0fc74 .spectrum-Table-row_d0fc74:last-child .spectrum-Table-cell_d0fc74:first-child {
  border-bottom-left-radius: var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular));
}

.spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74) tbody.spectrum-Table-body_d0fc74 .spectrum-Table-row_d0fc74:last-child .spectrum-Table-cell_d0fc74:last-child {
  border-bottom-right-radius: var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular));
}

.spectrum-Table-cell_d0fc74 {
  box-sizing: border-box;
  font-size: var(--spectrum-table-cell-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-table-cell-text-font-weight, var(--spectrum-global-font-weight-regular));
  line-height: calc(var(--spectrum-table-cell-text-size, var(--spectrum-alias-font-size-default)) * var(--spectrum-table-cell-text-line-height, var(--spectrum-alias-body-text-line-height))  - 1px);
  padding: var(--spectrum-global-dimension-size-125) var(--spectrum-table-cell-padding-x, var(--spectrum-global-dimension-size-200));
}

.spectrum-Table--compact_d0fc74 .spectrum-Table-cell_d0fc74 {
  padding-top: var(--spectrum-global-dimension-size-75);
  padding-bottom: var(--spectrum-global-dimension-size-75);
}

.spectrum-Table--spacious_d0fc74 .spectrum-Table-cell_d0fc74 {
  padding-top: var(--spectrum-global-dimension-size-175);
  padding-bottom: var(--spectrum-global-dimension-size-175);
}

.spectrum-Table-cellContents_d0fc74 {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1;
  margin: -4px;
  padding: 4px;
  overflow: hidden;
}

.spectrum-Table-cell--hideHeader_d0fc74 {
  justify-content: center;
  padding: 0;
}

.spectrum-Table--wrap_d0fc74 .spectrum-Table-cellContents_d0fc74 {
  white-space: normal;
}

.spectrum-Table-cell_d0fc74, .spectrum-Table-headCell_d0fc74, .spectrum-Table-headCellButton_d0fc74 {
  position: relative;
}

.spectrum-Table-cell_d0fc74:focus, .spectrum-Table-headCell_d0fc74:focus, .spectrum-Table-headCellButton_d0fc74:focus, .spectrum-Table-cell_d0fc74.focus-ring_d0fc74, .spectrum-Table-cell_d0fc74.is-focused_d0fc74, .spectrum-Table-headCell_d0fc74.focus-ring_d0fc74, .spectrum-Table-headCell_d0fc74.is-focused_d0fc74, .spectrum-Table-headCellButton_d0fc74.focus-ring_d0fc74, .spectrum-Table-headCellButton_d0fc74.is-focused_d0fc74 {
  outline: 0;
}

.spectrum-Table-cell_d0fc74.focus-ring_d0fc74:before, .spectrum-Table-cell_d0fc74.is-focused_d0fc74:before, .spectrum-Table-headCell_d0fc74.focus-ring_d0fc74:before, .spectrum-Table-headCell_d0fc74.is-focused_d0fc74:before, .spectrum-Table-headCellButton_d0fc74.focus-ring_d0fc74:before, .spectrum-Table-headCellButton_d0fc74.is-focused_d0fc74:before {
  content: "";
  z-index: 1;
  border-radius: calc(var(--spectrum-table-cell-border-radius-key-focus, var(--spectrum-alias-border-radius-regular))  - 1px);
  position: absolute;
  inset: 0;
}

.spectrum-Table-headCell_d0fc74.focus-ring_d0fc74:before, .spectrum-Table-headCell_d0fc74.is-focused_d0fc74:before, .spectrum-Table-headCellButton_d0fc74.focus-ring_d0fc74:before, .spectrum-Table-headCellButton_d0fc74.is-focused_d0fc74:before {
  top: var(--spectrum-table-border-size, var(--spectrum-alias-border-size-thin));
  right: var(--spectrum-table-border-size, var(--spectrum-alias-border-size-thin));
  bottom: var(--spectrum-table-border-size, var(--spectrum-alias-border-size-thin));
  left: var(--spectrum-table-border-size, var(--spectrum-alias-border-size-thin));
}

[dir="ltr"] .spectrum-Table-cell--divider_d0fc74 {
  border-right-width: var(--spectrum-table-divider-border-size, var(--spectrum-alias-border-size-thin));
}

[dir="rtl"] .spectrum-Table-cell--divider_d0fc74 {
  border-left-width: var(--spectrum-table-divider-border-size, var(--spectrum-alias-border-size-thin));
}

.spectrum-Table-row_d0fc74 {
  cursor: default;
  transition: background-color var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  position: relative;
}

.spectrum-Table-row_d0fc74:focus {
  outline: 0;
}

[dir="ltr"] .spectrum-Table-row_d0fc74.focus-ring_d0fc74:before {
  left: 0;
}

[dir="rtl"] .spectrum-Table-row_d0fc74.focus-ring_d0fc74:before {
  right: 0;
}

[dir="ltr"] .spectrum-Table-row_d0fc74.focus-ring_d0fc74:before {
  margin-right: -3px;
}

[dir="rtl"] .spectrum-Table-row_d0fc74.focus-ring_d0fc74:before {
  margin-left: -3px;
}

.spectrum-Table-row_d0fc74.focus-ring_d0fc74:before {
  content: "";
  height: 100%;
  width: 3px;
  z-index: 4;
  display: inline-block;
  position: sticky;
}

.spectrum-Table-row_d0fc74.focus-ring_d0fc74 .spectrum-Table-cellWrapper_d0fc74:first-of-type {
  vertical-align: top;
  display: inline-block;
}

[dir="ltr"] .spectrum-Table_d0fc74:not(.spectrum-Table--loadingMore_d0fc74):not(.spectrum-Table--quiet_d0fc74) .spectrum-Table-row_d0fc74.spectrum-Table-row--lastRow_d0fc74.spectrum-Table-row--isFlushBottom_d0fc74.is-selected_d0fc74:after {
  border-bottom-left-radius: calc(var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular))  - 1px);
}

[dir="rtl"] .spectrum-Table_d0fc74:not(.spectrum-Table--loadingMore_d0fc74):not(.spectrum-Table--quiet_d0fc74) .spectrum-Table-row_d0fc74.spectrum-Table-row--lastRow_d0fc74.spectrum-Table-row--isFlushBottom_d0fc74.is-selected_d0fc74:after, [dir="ltr"] .spectrum-Table_d0fc74:not(.spectrum-Table--loadingMore_d0fc74):not(.spectrum-Table--quiet_d0fc74) .spectrum-Table-row_d0fc74.spectrum-Table-row--lastRow_d0fc74.spectrum-Table-row--isFlushBottom_d0fc74.is-selected_d0fc74:after {
  border-bottom-right-radius: calc(var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular))  - 1px);
}

[dir="rtl"] .spectrum-Table_d0fc74:not(.spectrum-Table--loadingMore_d0fc74):not(.spectrum-Table--quiet_d0fc74) .spectrum-Table-row_d0fc74.spectrum-Table-row--lastRow_d0fc74.spectrum-Table-row--isFlushBottom_d0fc74.is-selected_d0fc74:after {
  border-bottom-left-radius: calc(var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular))  - 1px);
}

[dir="ltr"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74) .spectrum-Table-row_d0fc74.spectrum-Table-row--firstRow_d0fc74.is-selected_d0fc74:after {
  border-top-left-radius: calc(var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular))  - 1px);
}

[dir="rtl"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74) .spectrum-Table-row_d0fc74.spectrum-Table-row--firstRow_d0fc74.is-selected_d0fc74:after, [dir="ltr"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74) .spectrum-Table-row_d0fc74.spectrum-Table-row--firstRow_d0fc74.is-selected_d0fc74:after {
  border-top-right-radius: calc(var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular))  - 1px);
}

[dir="rtl"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74) .spectrum-Table-row_d0fc74.spectrum-Table-row--firstRow_d0fc74.is-selected_d0fc74:after {
  border-top-left-radius: calc(var(--spectrum-table-border-radius, var(--spectrum-alias-border-radius-regular))  - 1px);
}

[dir="ltr"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74).spectrum-Table--isVerticalScrollbarVisible_d0fc74 .spectrum-Table-row_d0fc74.spectrum-Table-row--firstRow_d0fc74.is-selected_d0fc74:after {
  border-top-right-radius: 0;
}

[dir="rtl"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74).spectrum-Table--isVerticalScrollbarVisible_d0fc74 .spectrum-Table-row_d0fc74.spectrum-Table-row--firstRow_d0fc74.is-selected_d0fc74:after {
  border-top-left-radius: 0;
}

[dir="ltr"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74).spectrum-Table--isVerticalScrollbarVisible_d0fc74 .spectrum-Table-row_d0fc74.spectrum-Table-row--lastRow_d0fc74.is-selected_d0fc74.is-selected_d0fc74:after {
  border-bottom-right-radius: 0;
}

[dir="rtl"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74).spectrum-Table--isVerticalScrollbarVisible_d0fc74 .spectrum-Table-row_d0fc74.spectrum-Table-row--lastRow_d0fc74.is-selected_d0fc74.is-selected_d0fc74:after, [dir="ltr"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74).spectrum-Table--isHorizontalScrollbarVisible_d0fc74 .spectrum-Table-row_d0fc74.spectrum-Table-row--lastRow_d0fc74.is-selected_d0fc74.is-selected_d0fc74:after {
  border-bottom-left-radius: 0;
}

[dir="rtl"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74).spectrum-Table--isHorizontalScrollbarVisible_d0fc74 .spectrum-Table-row_d0fc74.spectrum-Table-row--lastRow_d0fc74.is-selected_d0fc74.is-selected_d0fc74:after, [dir="ltr"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74).spectrum-Table--isHorizontalScrollbarVisible_d0fc74 .spectrum-Table-row_d0fc74.spectrum-Table-row--lastRow_d0fc74.is-selected_d0fc74.is-selected_d0fc74:after {
  border-bottom-right-radius: 0;
}

[dir="rtl"] .spectrum-Table_d0fc74:not(.spectrum-Table--quiet_d0fc74).spectrum-Table--isHorizontalScrollbarVisible_d0fc74 .spectrum-Table-row_d0fc74.spectrum-Table-row--lastRow_d0fc74.is-selected_d0fc74.is-selected_d0fc74:after {
  border-bottom-left-radius: 0;
}

[dir="rtl"] .spectrum-Table-row_d0fc74.focus-ring_d0fc74:before {
  right: 0;
}

.spectrum-Table_d0fc74 > .spectrum-Table-body_d0fc74 > .spectrum-Table-row_d0fc74:last-of-type {
  border-bottom-style: none;
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-headWrapper_d0fc74 {
  border-left: 0;
  border-right: 0;
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-body_d0fc74 {
  border-radius: var(--spectrum-table-quiet-border-radius, 0px);
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-body_d0fc74.is-drop-target_d0fc74:before, .spectrum-Table--quiet_d0fc74 .spectrum-Table-row_d0fc74.is-drop-target_d0fc74:before {
  border-radius: var(--spectrum-global-dimension-size-50);
}

[dir="ltr"] .spectrum-Table-headCell_d0fc74.spectrum-Table-checkboxCell_d0fc74, [dir="ltr"] .spectrum-Table-cell_d0fc74.spectrum-Table-checkboxCell_d0fc74 {
  padding-right: var(--spectrum-table-cell-checkbox-padding-right, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-Table-headCell_d0fc74.spectrum-Table-checkboxCell_d0fc74, [dir="rtl"] .spectrum-Table-cell_d0fc74.spectrum-Table-checkboxCell_d0fc74 {
  padding-left: var(--spectrum-table-cell-checkbox-padding-right, var(--spectrum-global-dimension-size-100));
}

.spectrum-Table-headCell_d0fc74.spectrum-Table-checkboxCell_d0fc74, .spectrum-Table-cell_d0fc74.spectrum-Table-checkboxCell_d0fc74 {
  vertical-align: var(--spectrum-table-cell-checkbox-vertical-alignment, middle);
  padding-top: 0;
  padding-bottom: 0;
}

[dir="ltr"] .spectrum-Table-headCell_d0fc74.spectrum-Table-checkboxCell_d0fc74 .spectrum-Table-checkbox_d0fc74, [dir="ltr"] .spectrum-Table-cell_d0fc74.spectrum-Table-checkboxCell_d0fc74 .spectrum-Table-checkbox_d0fc74 {
  padding-right: 0;
}

[dir="rtl"] .spectrum-Table-headCell_d0fc74.spectrum-Table-checkboxCell_d0fc74 .spectrum-Table-checkbox_d0fc74, [dir="rtl"] .spectrum-Table-cell_d0fc74.spectrum-Table-checkboxCell_d0fc74 .spectrum-Table-checkbox_d0fc74 {
  padding-left: 0;
}

.spectrum-Table-checkbox_d0fc74 {
  vertical-align: super;
}

.spectrum-Table-headWrapper_d0fc74 {
  border-left-color: #0000;
  border-right-color: #0000;
}

.spectrum-Table-headCell_d0fc74 {
  color: var(--spectrum-table-header-text-color, var(--spectrum-alias-label-text-color));
  background-color: var(--spectrum-alias-background-color-default, var(--spectrum-global-color-gray-100));
}

.spectrum-Table-headCell_d0fc74.is-sortable_d0fc74 .spectrum-Table-sortedIcon_d0fc74 {
  color: var(--spectrum-table-header-sort-icon-color, var(--spectrum-global-color-gray-600));
}

.spectrum-Table-headCell_d0fc74.is-sortable_d0fc74.is-hovered_d0fc74 {
  color: var(--spectrum-table-header-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-Table-headCell_d0fc74.is-sortable_d0fc74.is-hovered_d0fc74 .spectrum-Table-sortedIcon_d0fc74 {
  color: var(--spectrum-table-header-sort-icon-color-hover, var(--spectrum-alias-icon-color-hover));
}

.spectrum-Table-headCell_d0fc74.is-sortable_d0fc74.focus-ring_d0fc74, .spectrum-Table-headCell_d0fc74.is-sortable_d0fc74.is-focused_d0fc74 {
  color: var(--spectrum-table-header-text-color-key-focus, var(--spectrum-alias-text-color-hover));
}

.spectrum-Table-headCell_d0fc74.is-sortable_d0fc74.focus-ring_d0fc74 .spectrum-Table-sortedIcon_d0fc74, .spectrum-Table-headCell_d0fc74.is-sortable_d0fc74.is-focused_d0fc74 .spectrum-Table-sortedIcon_d0fc74 {
  color: var(--spectrum-table-header-sort-icon-color-key-focus, var(--spectrum-alias-icon-color-focus));
}

.spectrum-Table-headCell_d0fc74.is-sortable_d0fc74:active {
  color: var(--spectrum-table-header-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-Table-headCell_d0fc74.is-sortable_d0fc74:active .spectrum-Table-sortedIcon_d0fc74 {
  color: var(--spectrum-table-header-sort-icon-color-down, var(--spectrum-alias-icon-color-down));
}

.spectrum-Table-body_d0fc74.is-drop-target_d0fc74, .spectrum-Table-row_d0fc74.is-drop-target_d0fc74 {
  border-color: var(--spectrum-alias-border-color-focus, var(--spectrum-global-color-blue-400));
  box-shadow: 0 0 0 1px var(--spectrum-alias-border-color-focus, var(--spectrum-global-color-blue-400));
}

.spectrum-Table-body_d0fc74.is-drop-target_d0fc74:before, .spectrum-Table-row_d0fc74.is-drop-target_d0fc74:before {
  background-color: var(--spectrum-alias-highlight-selected);
}

.spectrum-Table-cell_d0fc74.focus-ring_d0fc74:before, .spectrum-Table-cell_d0fc74.is-focused_d0fc74:before, .spectrum-Table-headCell_d0fc74.focus-ring_d0fc74:before, .spectrum-Table-headCell_d0fc74.is-focused_d0fc74:before, .spectrum-Table-headCellButton_d0fc74.focus-ring_d0fc74:before, .spectrum-Table-headCellButton_d0fc74.is-focused_d0fc74:before {
  box-shadow: inset 0 0 0 2px var(--spectrum-table-cell-border-color-key-focus, var(--spectrum-alias-border-color-focus));
}

.spectrum-Table-body_d0fc74 {
  border-style: solid;
  border-color: var(--spectrum-table-border-color, var(--spectrum-alias-border-color-mid));
  background-color: var(--spectrum-table-background-color, var(--spectrum-global-color-gray-50));
}

.spectrum-Table-body_d0fc74.is-drop-target_d0fc74 {
  border-color: var(--spectrum-alias-border-color-focus, var(--spectrum-global-color-blue-400));
  box-shadow: 0 0 0 1px var(--spectrum-alias-border-color-focus, var(--spectrum-global-color-blue-400));
}

.spectrum-Table-body_d0fc74.is-drop-target_d0fc74:before {
  background-color: var(--spectrum-alias-highlight-selected);
}

tbody.spectrum-Table-body_d0fc74 {
  border: 0;
}

tbody.spectrum-Table-body_d0fc74 .spectrum-Table-row_d0fc74 {
  border-top: 0;
}

tbody.spectrum-Table-body_d0fc74 .spectrum-Table-cell_d0fc74 {
  border-top: 1px solid var(--spectrum-table-cell-border-color, var(--spectrum-global-color-gray-300));
}

tbody.spectrum-Table-body_d0fc74 .spectrum-Table-row_d0fc74 .spectrum-Table-cell_d0fc74:first-child {
  border-left: 1px solid var(--spectrum-table-cell-border-color, var(--spectrum-global-color-gray-300));
}

tbody.spectrum-Table-body_d0fc74 .spectrum-Table-row_d0fc74 .spectrum-Table-cell_d0fc74:last-child {
  border-right: 1px solid var(--spectrum-table-cell-border-color, var(--spectrum-global-color-gray-300));
}

tbody.spectrum-Table-body_d0fc74 .spectrum-Table-row_d0fc74:last-child .spectrum-Table-cell_d0fc74 {
  border-bottom: 1px solid var(--spectrum-table-cell-border-color, var(--spectrum-global-color-gray-300));
}

.spectrum-Table-row_d0fc74 {
  box-sizing: border-box;
}

.spectrum-Table-row_d0fc74 .spectrum-Table-cellWrapper_d0fc74 {
  background-color: var(--spectrum-table-background-color, var(--spectrum-global-color-gray-50));
}

.spectrum-Table-row_d0fc74.is-hovered_d0fc74 .spectrum-Table-cell_d0fc74, .spectrum-Table-row_d0fc74.focus-ring_d0fc74 .spectrum-Table-cell_d0fc74, .spectrum-Table-row_d0fc74.is-focused_d0fc74 .spectrum-Table-cell_d0fc74 {
  background-color: var(--spectrum-table-row-background-color-hover, var(--spectrum-alias-highlight-hover));
}

.spectrum-Table-row_d0fc74.focus-ring_d0fc74:before {
  background: var(--spectrum-selectlist-option-focus-indicator-color, var(--spectrum-alias-border-color-focus));
}

.spectrum-Table-row_d0fc74.is-active_d0fc74 .spectrum-Table-cell_d0fc74:not(.is-disabled_d0fc74) {
  background-color: var(--spectrum-table-row-background-color-down, var(--spectrum-alias-highlight-active));
}

[dir="ltr"] .spectrum-Table-row_d0fc74:after {
  left: 0;
}

[dir="rtl"] .spectrum-Table-row_d0fc74:after, [dir="ltr"] .spectrum-Table-row_d0fc74:after {
  right: 0;
}

[dir="rtl"] .spectrum-Table-row_d0fc74:after {
  left: 0;
}

.spectrum-Table-row_d0fc74:after {
  box-shadow: inset 0 -1px 0 0 var(--spectrum-table-cell-border-color, var(--spectrum-global-color-gray-300));
  content: "";
  z-index: 3;
  pointer-events: none;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
}

.spectrum-Table-row_d0fc74.is-next-selected_d0fc74:after {
  box-shadow: inset 0 -1px 0 0 var(--spectrum-global-color-blue-500);
}

.spectrum-Table-row_d0fc74.is-selected_d0fc74 .spectrum-Table-cell_d0fc74 {
  background-color: var(--spectrum-table-row-background-color-selected, var(--spectrum-alias-highlight-selected));
}

.spectrum-Table-row_d0fc74.is-selected_d0fc74.is-hovered_d0fc74 .spectrum-Table-cell_d0fc74, .spectrum-Table-row_d0fc74.is-selected_d0fc74.is-active_d0fc74 .spectrum-Table-cell_d0fc74:not(.is-disabled_d0fc74) {
  background-color: var(--spectrum-table-row-background-color-selected-hover, var(--spectrum-alias-highlight-selected-hover));
}

.spectrum-Table-row_d0fc74.is-selected_d0fc74.focus-ring_d0fc74 .spectrum-Table-cell_d0fc74, .spectrum-Table-row_d0fc74.is-selected_d0fc74.is-focused_d0fc74 .spectrum-Table-cell_d0fc74 {
  background-color: var(--spectrum-table-row-background-color-selected-key-focus, var(--spectrum-alias-highlight-selected-hover));
}

.spectrum-Table-row_d0fc74.is-selected_d0fc74:after {
  box-shadow: inset 1px 0 0 0 var(--spectrum-global-color-blue-500), inset -1px 0 0 0 var(--spectrum-global-color-blue-500), inset 0 -1px 0 0 var(--spectrum-global-color-blue-500);
}

.spectrum-Table-row_d0fc74.spectrum-Table-row--firstRow_d0fc74.is-selected_d0fc74:after {
  box-shadow: inset 1px 0 0 0 var(--spectrum-global-color-blue-500), inset -1px 0 0 0 var(--spectrum-global-color-blue-500), inset 0 1px 0 0 var(--spectrum-global-color-blue-500), inset 0 -1px 0 0 var(--spectrum-global-color-blue-500);
}

.spectrum-Table-row_d0fc74.is-drop-target_d0fc74:before {
  box-shadow: inset 0 0 0 2px var(--spectrum-alias-border-color-focus, var(--spectrum-global-color-blue-400));
  background-color: var(--spectrum-alias-highlight-selected);
}

.spectrum-Table-cell_d0fc74 {
  color: var(--spectrum-table-cell-text-color, var(--spectrum-alias-text-color));
  background-color: var(--spectrum-table-cell-background-color, var(--spectrum-alias-background-color-transparent));
}

[dir="ltr"] .spectrum-Table-cell--divider_d0fc74 {
  border-right-style: solid;
}

[dir="rtl"] .spectrum-Table-cell--divider_d0fc74 {
  border-left-style: solid;
}

[dir="ltr"] .spectrum-Table-cell--divider_d0fc74 {
  border-right-color: var(--spectrum-table-divider-border-color, var(--spectrum-alias-border-color-mid));
}

[dir="rtl"] .spectrum-Table-cell--divider_d0fc74 {
  border-left-color: var(--spectrum-table-divider-border-color, var(--spectrum-alias-border-color-mid));
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-body_d0fc74 {
  background-color: var(--spectrum-table-quiet-cell-background-color, var(--spectrum-alias-background-color-transparent));
  border-width: 1px 0;
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-body_d0fc74.is-drop-target_d0fc74 {
  box-shadow: none;
  border-color: #0000;
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-body_d0fc74.is-drop-target_d0fc74:before {
  box-shadow: inset 0 0 0 2px var(--spectrum-alias-border-color-focus, var(--spectrum-global-color-blue-400));
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-row_d0fc74 .spectrum-Table-cellWrapper_d0fc74 {
  background-color: var(--spectrum-alias-background-color-default, var(--spectrum-global-color-gray-100));
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-row_d0fc74.is-hovered_d0fc74 .spectrum-Table-cell_d0fc74, .spectrum-Table--quiet_d0fc74 .spectrum-Table-row_d0fc74.focus-ring_d0fc74 .spectrum-Table-cell_d0fc74, .spectrum-Table--quiet_d0fc74 .spectrum-Table-row_d0fc74.is-focused_d0fc74 .spectrum-Table-cell_d0fc74 {
  background-color: var(--spectrum-table-quiet-row-background-color-hover, var(--spectrum-alias-highlight-hover));
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-row_d0fc74.is-active_d0fc74 .spectrum-Table-cell_d0fc74:not(.is-disabled_d0fc74) {
  background-color: var(--spectrum-table-quiet-row-background-color-down, var(--spectrum-alias-highlight-active));
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-row_d0fc74.is-selected_d0fc74 .spectrum-Table-cell_d0fc74 {
  background-color: var(--spectrum-table-quiet-row-background-color-selected, var(--spectrum-alias-highlight-selected));
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-row_d0fc74.is-selected_d0fc74.is-hovered_d0fc74 .spectrum-Table-cell_d0fc74 {
  background-color: var(--spectrum-table-quiet-row-background-color-selected-hover, var(--spectrum-alias-highlight-selected-hover));
}

.spectrum-Table--quiet_d0fc74 .spectrum-Table-row_d0fc74.is-selected_d0fc74.focus-ring_d0fc74 .spectrum-Table-cell_d0fc74, .spectrum-Table--quiet_d0fc74 .spectrum-Table-row_d0fc74.is-selected_d0fc74.is-focused_d0fc74 .spectrum-Table-cell_d0fc74 {
  background-color: var(--spectrum-table-quiet-row-background-color-selected-key-focus, var(--spectrum-alias-highlight-selected-hover));
}

.spectrum-Table--quiet_d0fc74 tbody.spectrum-Table-body_d0fc74 .spectrum-Table-row_d0fc74 .spectrum-Table-cell_d0fc74:first-child, .spectrum-Table--quiet_d0fc74 tbody.spectrum-Table-body_d0fc74 .spectrum-Table-row_d0fc74 .spectrum-Table-cell_d0fc74:last-child {
  border-left: 0;
  border-right: 0;
}

.spectrum-Table-columnResizer_d0fc74:after {
  background-color: var(--spectrum-table-divider-border-color, var(--spectrum-alias-border-color-mid));
}

.spectrum-Table-columnResizer_d0fc74:active:after, .spectrum-Table-columnResizer_d0fc74.focus-ring_d0fc74:after {
  background-color: var(--spectrum-global-color-blue-400);
}

.react-spectrum-Table_e844a7 {
  -webkit-user-select: none;
  user-select: none;
  min-height: 0;
  min-width: 0;
  flex-direction: column;
  display: flex;
}

.react-spectrum-Table-cell_e844a7 {
  align-items: center;
  display: flex;
}

.react-spectrum-Table-cellWrapper_e844a7 > .react-spectrum-Table-cell_e844a7 {
  height: 100%;
}

[dir="ltr"] .react-spectrum-Table-cell--alignStart_e844a7 {
  text-align: left;
}

[dir="rtl"] .react-spectrum-Table-cell--alignStart_e844a7 {
  text-align: right;
}

.react-spectrum-Table-cell--alignStart_e844a7 {
  justify-content: flex-start;
}

.react-spectrum-Table-cell--alignCenter_e844a7 {
  text-align: center;
  justify-content: center;
}

[dir="ltr"] .react-spectrum-Table-cell--alignEnd_e844a7 {
  text-align: right;
}

[dir="rtl"] .react-spectrum-Table-cell--alignEnd_e844a7 {
  text-align: left;
}

.react-spectrum-Table-cell--alignEnd_e844a7 {
  justify-content: flex-end;
}

.react-spectrum-Table-centeredWrapper_e844a7 {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

[dir="ltr"] .spectrum-Checkbox_4870fc {
  padding-right: calc(var(--spectrum-checkbox-cursor-hit-x, var(--spectrum-global-dimension-size-100)) * 2);
}

[dir="rtl"] .spectrum-Checkbox_4870fc {
  padding-left: calc(var(--spectrum-checkbox-cursor-hit-x, var(--spectrum-global-dimension-size-100)) * 2);
}

.spectrum-Checkbox_4870fc {
  box-sizing: border-box;
  min-height: var(--spectrum-checkbox-height, var(--spectrum-global-dimension-size-400));
  max-width: 100%;
  vertical-align: top;
  isolation: isolate;
  align-items: flex-start;
  display: inline-flex;
  position: relative;
}

[dir="ltr"] .spectrum-Checkbox-input_4870fc {
  left: calc(var(--spectrum-checkbox-cursor-hit-x, var(--spectrum-global-dimension-size-100)) * -1);
}

[dir="rtl"] .spectrum-Checkbox-input_4870fc {
  right: calc(var(--spectrum-checkbox-cursor-hit-x, var(--spectrum-global-dimension-size-100)) * -1);
}

.spectrum-Checkbox-input_4870fc {
  box-sizing: border-box;
  width: calc(100% + var(--spectrum-checkbox-cursor-hit-x, var(--spectrum-global-dimension-size-100)));
  height: 100%;
  opacity: .0001;
  z-index: 1;
  cursor: default;
  margin: 0;
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  position: absolute;
  top: 0;
  overflow: visible;
}

.spectrum-Checkbox-input_4870fc:disabled {
  cursor: default;
}

.spectrum-Checkbox-input_4870fc.focus-ring_4870fc + .spectrum-Checkbox-box_4870fc:after {
  margin: calc(var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)) * -1);
}

.spectrum-Checkbox_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-box_4870fc:before {
  border-width: calc(var(--spectrum-checkbox-box-size, var(--spectrum-global-dimension-size-175)) / 2);
}

.spectrum-Checkbox_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-box_4870fc .spectrum-Checkbox-checkmark_4870fc {
  display: none;
}

.spectrum-Checkbox_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-box_4870fc .spectrum-Checkbox-partialCheckmark_4870fc {
  opacity: 1;
  display: block;
  transform: scale(1);
}

.spectrum-Checkbox_4870fc.is-checked_4870fc .spectrum-Checkbox-box_4870fc:before {
  border-width: calc(var(--spectrum-checkbox-box-size, var(--spectrum-global-dimension-size-175)) / 2);
}

.spectrum-Checkbox_4870fc.is-checked_4870fc .spectrum-Checkbox-box_4870fc .spectrum-Checkbox-checkmark_4870fc {
  opacity: 1;
  transform: scale(1);
}

[dir="ltr"] .spectrum-Checkbox-label_4870fc {
  text-align: left;
}

[dir="rtl"] .spectrum-Checkbox-label_4870fc {
  text-align: right;
}

[dir="ltr"] .spectrum-Checkbox-label_4870fc {
  margin-left: var(--spectrum-checkbox-text-gap, var(--spectrum-global-dimension-size-125));
}

[dir="rtl"] .spectrum-Checkbox-label_4870fc {
  margin-right: var(--spectrum-checkbox-text-gap, var(--spectrum-global-dimension-size-125));
}

.spectrum-Checkbox-label_4870fc {
  margin-top: var(--spectrum-global-dimension-size-65);
  font-size: var(--spectrum-checkbox-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-checkbox-text-font-weight, var(--spectrum-global-font-weight-regular));
  line-height: 1.49;
}

.spectrum-Checkbox-box_4870fc {
  box-sizing: border-box;
  width: var(--spectrum-checkbox-box-size, var(--spectrum-global-dimension-size-175));
  height: var(--spectrum-checkbox-box-size, var(--spectrum-global-dimension-size-175));
  margin: calc((var(--spectrum-checkbox-height, var(--spectrum-global-dimension-size-400))  - var(--spectrum-checkbox-box-size, var(--spectrum-global-dimension-size-175))) / 2) 0;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.spectrum-Checkbox-box_4870fc:before {
  z-index: 0;
  content: "";
  box-sizing: border-box;
  width: var(--spectrum-checkbox-box-size, var(--spectrum-global-dimension-size-175));
  height: var(--spectrum-checkbox-box-size, var(--spectrum-global-dimension-size-175));
  border-radius: var(--spectrum-checkbox-box-border-radius, var(--spectrum-alias-border-radius-small));
  border-width: var(--spectrum-checkbox-box-border-size, var(--spectrum-alias-border-size-thick));
  transition: border var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, box-shadow var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  border-style: solid;
  display: block;
  position: absolute;
}

.spectrum-Checkbox-box_4870fc:after {
  border-radius: calc(var(--spectrum-checkbox-box-border-radius, var(--spectrum-alias-border-radius-small))  + var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25)));
  content: "";
  margin: var(--spectrum-alias-focus-ring-gap, var(--spectrum-global-dimension-static-size-25));
  transition: opacity var(--spectrum-global-animation-duration-100, 130ms) ease-out, margin var(--spectrum-global-animation-duration-100, 130ms) ease-out;
  display: block;
  position: absolute;
  inset: 0;
  transform: translate(0);
}

[dir="ltr"] .spectrum-Checkbox-checkmark_4870fc, [dir="ltr"] .spectrum-Checkbox-partialCheckmark_4870fc {
  left: 50%;
}

[dir="rtl"] .spectrum-Checkbox-checkmark_4870fc, [dir="rtl"] .spectrum-Checkbox-partialCheckmark_4870fc {
  right: 50%;
}

[dir="ltr"] .spectrum-Checkbox-checkmark_4870fc, [dir="ltr"] .spectrum-Checkbox-partialCheckmark_4870fc {
  margin-left: calc(var(--spectrum-icon-checkmark-small-width) / -2);
}

[dir="rtl"] .spectrum-Checkbox-checkmark_4870fc, [dir="rtl"] .spectrum-Checkbox-partialCheckmark_4870fc {
  margin-right: calc(var(--spectrum-icon-checkmark-small-width) / -2);
}

.spectrum-Checkbox-checkmark_4870fc, .spectrum-Checkbox-partialCheckmark_4870fc {
  margin-top: calc(var(--spectrum-icon-checkmark-small-height) / -2);
  opacity: 0;
  transition: opacity var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, transform var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  position: absolute;
  top: 50%;
  transform: scale(0);
}

.spectrum-Checkbox-partialCheckmark_4870fc {
  display: none;
}

.spectrum-Checkbox_4870fc {
  color: var(--spectrum-checkbox-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Checkbox_4870fc .spectrum-Checkbox-checkmark_4870fc, .spectrum-Checkbox_4870fc .spectrum-Checkbox-partialCheckmark_4870fc {
  color: var(--spectrum-checkbox-checkmark-color, var(--spectrum-global-color-gray-75));
}

.spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-emphasized-box-border-color, var(--spectrum-global-color-gray-600));
  background-color: var(--spectrum-checkbox-emphasized-box-background-color, var(--spectrum-global-color-gray-75));
}

.spectrum-Checkbox-label_4870fc {
  color: var(--spectrum-checkbox-emphasized-text-color, var(--spectrum-alias-text-color));
}

.spectrum-Checkbox_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox_4870fc.is-checked_4870fc .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-emphasized-box-border-color-selected, var(--spectrum-global-color-blue-500));
}

.spectrum-Checkbox_4870fc.is-hovered_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox_4870fc.is-hovered_4870fc .spectrum-Checkbox-input_4870fc:checked + .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-emphasized-box-border-color-selected-hover, var(--spectrum-global-color-blue-600));
}

.spectrum-Checkbox_4870fc:active.is-indeterminate_4870fc .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox_4870fc:active .spectrum-Checkbox-input_4870fc:checked + .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-emphasized-box-border-color-selected-down, var(--spectrum-global-color-blue-700));
}

.spectrum-Checkbox_4870fc {
  border-color: var(--spectrum-checkbox-emphasized-box-border-color, var(--spectrum-global-color-gray-600));
}

.spectrum-Checkbox_4870fc.is-hovered_4870fc .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-emphasized-box-border-color-hover, var(--spectrum-global-color-gray-700));
}

.spectrum-Checkbox_4870fc.is-hovered_4870fc .spectrum-Checkbox-label_4870fc {
  color: var(--spectrum-checkbox-emphasized-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-Checkbox_4870fc:active .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-emphasized-box-border-color-down, var(--spectrum-global-color-gray-800));
}

.spectrum-Checkbox_4870fc:active .spectrum-Checkbox-label_4870fc {
  color: var(--spectrum-checkbox-emphasized-text-color-down, var(--spectrum-alias-text-color-down));
}

.spectrum-Checkbox_4870fc .spectrum-Checkbox-input_4870fc:disabled + .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox_4870fc .spectrum-Checkbox-input_4870fc:disabled:active + .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox_4870fc .spectrum-Checkbox-input_4870fc:checked:disabled + .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox_4870fc .spectrum-Checkbox-input_4870fc:checked:disabled:active + .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-emphasized-box-border-color-disabled, var(--spectrum-global-color-gray-400));
  background-color: var(--spectrum-checkbox-emphasized-box-background-color-disabled, var(--spectrum-global-color-gray-75));
}

.spectrum-Checkbox_4870fc .spectrum-Checkbox-input_4870fc:disabled ~ .spectrum-Checkbox-label_4870fc, .spectrum-Checkbox_4870fc .spectrum-Checkbox-input_4870fc:checked:disabled ~ .spectrum-Checkbox-label_4870fc {
  color: var(--spectrum-checkbox-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-Checkbox-input_4870fc + .spectrum-Checkbox-box_4870fc:after {
  box-shadow: 0 0 0 var(--spectrum-checkbox-focus-ring-size-key-focus, var(--spectrum-alias-focus-ring-size)) var(--spectrum-checkbox-focus-ring-color-key-focus, var(--spectrum-alias-focus-ring-color));
  opacity: 0;
}

.spectrum-Checkbox-input_4870fc.focus-ring_4870fc + .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-box-border-color-key-focus, var(--spectrum-global-color-gray-700));
}

.spectrum-Checkbox-input_4870fc.focus-ring_4870fc + .spectrum-Checkbox-box_4870fc:after {
  opacity: 1;
}

.spectrum-Checkbox_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-input_4870fc.focus-ring_4870fc + .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox-input_4870fc:checked.focus-ring_4870fc + .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-emphasized-box-border-color-selected-hover, var(--spectrum-global-color-blue-600));
}

.spectrum-Checkbox-input_4870fc.focus-ring_4870fc ~ .spectrum-Checkbox-label_4870fc {
  color: var(--spectrum-checkbox-emphasized-text-color-hover, var(--spectrum-alias-text-color-hover));
}

.spectrum-Checkbox--quiet_4870fc .spectrum-Checkbox-input_4870fc:checked + .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox--quiet_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox--quiet_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-input_4870fc.focus-ring_4870fc + .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-quiet-box-border-color-selected, var(--spectrum-global-color-gray-700));
}

.spectrum-Checkbox--quiet_4870fc.is-hovered_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox--quiet_4870fc.is-hovered_4870fc .spectrum-Checkbox-input_4870fc:checked + .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-quiet-box-border-color-selected-hover, var(--spectrum-global-color-gray-800));
}

.spectrum-Checkbox--quiet_4870fc:active.is-indeterminate_4870fc .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox--quiet_4870fc:active .spectrum-Checkbox-input_4870fc:checked + .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-quiet-box-border-color-selected-down, var(--spectrum-global-color-gray-900));
}

.spectrum-Checkbox_4870fc.is-invalid_4870fc .spectrum-Checkbox-input_4870fc:checked + .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox_4870fc.is-invalid_4870fc .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-box-border-color-error, var(--spectrum-global-color-red-500));
}

.spectrum-Checkbox_4870fc.is-invalid_4870fc .spectrum-Checkbox-label_4870fc {
  color: var(--spectrum-checkbox-text-color-error, var(--spectrum-global-color-red-600));
}

.spectrum-Checkbox_4870fc.is-invalid_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-input_4870fc.focus-ring_4870fc + .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox_4870fc.is-invalid_4870fc .spectrum-Checkbox-input_4870fc.focus-ring_4870fc + .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-box-border-color-error-hover, var(--spectrum-global-color-red-600));
}

.spectrum-Checkbox_4870fc.is-invalid_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-input_4870fc.focus-ring_4870fc ~ .spectrum-Checkbox-label_4870fc, .spectrum-Checkbox_4870fc.is-invalid_4870fc .spectrum-Checkbox-input_4870fc.focus-ring_4870fc ~ .spectrum-Checkbox-label_4870fc {
  color: var(--spectrum-checkbox-text-color-error-hover, var(--spectrum-global-color-red-700));
}

.spectrum-Checkbox_4870fc.is-invalid_4870fc.is-hovered_4870fc .spectrum-Checkbox-input_4870fc:checked + .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox_4870fc.is-invalid_4870fc.is-hovered_4870fc .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-box-border-color-error-hover, var(--spectrum-global-color-red-600));
}

.spectrum-Checkbox_4870fc.is-invalid_4870fc.is-hovered_4870fc .spectrum-Checkbox-label_4870fc {
  color: var(--spectrum-checkbox-text-color-error-hover, var(--spectrum-global-color-red-700));
}

.spectrum-Checkbox_4870fc.is-invalid_4870fc:active .spectrum-Checkbox-input_4870fc:checked + .spectrum-Checkbox-box_4870fc:before, .spectrum-Checkbox_4870fc.is-invalid_4870fc:active .spectrum-Checkbox-box_4870fc:before {
  border-color: var(--spectrum-checkbox-box-border-color-error-down, var(--spectrum-global-color-red-700));
}

.spectrum-Checkbox_4870fc.is-invalid_4870fc:active .spectrum-Checkbox-label_4870fc {
  color: var(--spectrum-checkbox-text-color-error-down, var(--spectrum-global-color-red-700));
}

@media (forced-colors: active) {
  .spectrum-Checkbox_4870fc {
    forced-color-adjust: none;
    --spectrum-checkbox-box-border-color: ButtonText;
    --spectrum-checkbox-box-border-color-error: Highlight;
    --spectrum-checkbox-box-border-color-error-down: Highlight;
    --spectrum-checkbox-box-border-color-error-hover: Highlight;
    --spectrum-checkbox-box-border-color-key-focus: Highlight;
    --spectrum-checkbox-checkmark-color: HighlightText;
    --spectrum-checkbox-emphasized-box-background-color: ButtonFace;
    --spectrum-checkbox-emphasized-box-background-color-disabled: ButtonFace;
    --spectrum-checkbox-emphasized-box-border-color: ButtonText;
    --spectrum-checkbox-emphasized-box-border-color-disabled: GrayText;
    --spectrum-checkbox-emphasized-box-border-color-down: Highlight;
    --spectrum-checkbox-emphasized-box-border-color-hover: Highlight;
    --spectrum-checkbox-emphasized-box-border-color-selected: Highlight;
    --spectrum-checkbox-emphasized-box-border-color-selected-down: Highlight;
    --spectrum-checkbox-emphasized-box-border-color-selected-hover: Highlight;
    --spectrum-checkbox-emphasized-box-border-color-selected-key-focus: Highlight;
    --spectrum-checkbox-emphasized-text-color: FieldText;
    --spectrum-checkbox-emphasized-text-color-down: FieldText;
    --spectrum-checkbox-emphasized-text-color-hover: FieldText;
    --spectrum-checkbox-emphasized-text-color-key-focus: FieldText;
    --spectrum-checkbox-focus-ring-color-key-focus: ButtonText;
    --spectrum-checkbox-quiet-box-border-color-selected: Highlight;
    --spectrum-checkbox-quiet-box-border-color-selected-down: Highlight;
    --spectrum-checkbox-quiet-box-border-color-selected-hover: Highlight;
    --spectrum-checkbox-text-color: FieldText;
    --spectrum-checkbox-text-color-disabled: GrayText;
    --spectrum-checkbox-text-color-error: FieldText;
    --spectrum-checkbox-text-color-error-down: FieldText;
    --spectrum-checkbox-text-color-error-hover: FieldText;
  }

  .spectrum-Checkbox_4870fc.is-invalid_4870fc .spectrum-Checkbox-box_4870fc:before {
    border-color: var(--spectrum-checkbox-box-border-color, var(--spectrum-global-color-gray-600));
  }

  .spectrum-Checkbox_4870fc.is-invalid_4870fc.is-indeterminate_4870fc .spectrum-Checkbox-box_4870fc:before {
    border-color: var(--spectrum-checkbox-box-border-color-error, var(--spectrum-global-color-red-500));
  }

  .spectrum-Checkbox_4870fc.is-invalid_4870fc.is-disabled_4870fc .spectrum-Checkbox-input_4870fc:checked + .spectrum-Checkbox-box_4870fc:before {
    border-color: GrayText;
  }

  .spectrum-Checkbox_4870fc.is-invalid_4870fc .spectrum-Checkbox-input_4870fc:checked + .spectrum-Checkbox-box_4870fc:before {
    border-color: var(--spectrum-checkbox-box-border-color-error, var(--spectrum-global-color-red-500));
  }
}

[dir="ltr"] .spectrum-FieldLabel_d2db1f {
  text-align: left;
}

[dir="rtl"] .spectrum-FieldLabel_d2db1f {
  text-align: right;
}

.spectrum-FieldLabel_d2db1f {
  box-sizing: border-box;
  padding: var(--spectrum-fieldlabel-padding-top, var(--spectrum-global-dimension-size-50)) 0 var(--spectrum-fieldlabel-padding-bottom, var(--spectrum-global-dimension-size-65));
  font-size: var(--spectrum-fieldlabel-text-size, var(--spectrum-global-dimension-font-size-75));
  font-weight: var(--spectrum-fieldlabel-text-font-weight, var(--spectrum-global-font-weight-regular));
  line-height: var(--spectrum-fieldlabel-text-line-height, var(--spectrum-global-font-line-height-small));
  vertical-align: top;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: auto;
  font-smoothing: subpixel-antialiased;
  cursor: default;
  flex: none;
  display: flex;
}

[dir="ltr"] .spectrum-FieldLabel--positionSide_d2db1f {
  padding-right: var(--spectrum-fieldlabel-side-padding-x, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-FieldLabel--positionSide_d2db1f {
  padding-left: var(--spectrum-fieldlabel-side-padding-x, var(--spectrum-global-dimension-size-100));
}

.spectrum-FieldLabel--positionSide_d2db1f {
  padding-top: var(--spectrum-fieldlabel-side-padding-top, var(--spectrum-global-dimension-size-100));
  display: inline-flex;
}

[dir="ltr"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-right: 0;
}

[dir="rtl"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-left: 0;
}

[dir="ltr"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-left: var(--spectrum-fieldlabel-asterisk-gap, var(--spectrum-global-dimension-size-25));
}

[dir="rtl"] .spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-right: var(--spectrum-fieldlabel-asterisk-gap, var(--spectrum-global-dimension-size-25));
}

.spectrum-FieldLabel-requiredIcon_d2db1f {
  margin-top: var(--spectrum-fieldlabel-asterisk-margin-y, var(--spectrum-global-dimension-size-50));
  margin-bottom: 0;
}

[dir="ltr"] .spectrum-FieldLabel--alignEnd_d2db1f {
  text-align: right;
}

[dir="rtl"] .spectrum-FieldLabel--alignEnd_d2db1f {
  text-align: left;
}

.spectrum-FieldLabel--alignEnd_d2db1f {
  justify-content: flex-end;
}

.spectrum-Field_d2db1f {
  --spectrum-field-default-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
}

.spectrum-Field_d2db1f.spectrum-Field--positionTop_d2db1f {
  width: var(--spectrum-field-default-width);
  flex-direction: column;
  display: inline-flex;
}

.spectrum-Field_d2db1f.spectrum-Field--positionTop_d2db1f .spectrum-Field-field_d2db1f {
  width: 100%;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f {
  align-items: flex-start;
  display: inline-flex;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f {
  min-width: 0;
  width: var(--spectrum-field-default-width);
  height: 100%;
  flex: 1;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f .spectrum-Field-field_d2db1f {
  width: 100%;
}

.spectrum-Field_d2db1f.spectrum-Field--positionSide_d2db1f .spectrum-Field-field_d2db1f {
  min-width: 0;
  flex: 1;
}

[dir="ltr"] .spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  text-align: left;
}

[dir="rtl"] .spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  text-align: right;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f {
  border-collapse: separate;
  border-spacing: 0 var(--spectrum-global-dimension-size-300);
  margin: calc(var(--spectrum-global-dimension-size-250) * -1) 0;
  display: table;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field_d2db1f {
  width: 100%;
  display: table-row;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-FieldLabel_d2db1f {
  display: table-cell;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field-wrapper_d2db1f {
  width: 100%;
}

.spectrum-Form_d2db1f.spectrum-Form--positionSide_d2db1f .spectrum-Field-field_d2db1f {
  width: auto;
  min-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
  display: table-cell;
}

.spectrum-Form_d2db1f.spectrum-Form--positionTop_d2db1f {
  min-width: var(--spectrum-alias-single-line-width, var(--spectrum-global-dimension-size-2400));
  flex-direction: column;
  margin: 0;
  display: flex;
}

.spectrum-Form_d2db1f.spectrum-Form--positionTop_d2db1f > * {
  margin-top: var(--spectrum-global-dimension-size-100);
  width: 100%;
}

.spectrum-StaticField_d2db1f.spectrum-Field--positionSide_d2db1f {
  -ms-flex-align: first baseline;
  align-items: baseline;
}

.spectrum-StaticField_d2db1f.spectrum-Field--positionTop_d2db1f {
  width: unset;
}

.spectrum-StaticField_d2db1f .spectrum-FieldLabel_d2db1f.spectrum-FieldLabel_d2db1f {
  padding-top: 0;
}

.spectrum-StaticField_d2db1f .spectrum-Field-wrapper_d2db1f.spectrum-Field-wrapper_d2db1f {
  width: unset;
}

.spectrum-StaticField_d2db1f .spectrum-Field-field_d2db1f {
  word-break: break-word;
  overflow: hidden;
}

.spectrum-FieldLabel_d2db1f, .spectrum-Form-itemLabel_d2db1f {
  color: var(--spectrum-fieldlabel-text-color, var(--spectrum-alias-label-text-color));
}

.spectrum-FieldLabel_d2db1f.is-disabled_d2db1f, .spectrum-Form-itemLabel_d2db1f.is-disabled_d2db1f {
  color: var(--spectrum-fieldlabel-text-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldLabel_d2db1f.is-disabled_d2db1f .spectrum-FieldLabel-requiredIcon_d2db1f, .spectrum-Form-itemLabel_d2db1f.is-disabled_d2db1f .spectrum-FieldLabel-requiredIcon_d2db1f {
  color: var(--spectrum-fieldlabel-asterisk-color-disabled, var(--spectrum-alias-text-color-disabled));
}

.spectrum-FieldLabel-requiredIcon_d2db1f {
  color: var(--spectrum-fieldlabel-asterisk-color, var(--spectrum-global-color-gray-600));
}

.spectrum-FieldGroup_41d350 {
  flex-direction: column;
  display: flex;
}

.spectrum-FieldGroup--positionSide_41d350 {
  flex-direction: row;
}

.spectrum-FieldGroup-group_41d350 {
  flex-direction: column;
  display: flex;
}

.spectrum-FieldGroup-group--horizontal_41d350 {
  flex-flow: wrap;
}

.spectrum-Menu-popover_7ea47c {
  max-width: 320px;
}

[dir="ltr"] .spectrum-Menu_7ea47c {
  text-align: left;
}

[dir="rtl"] .spectrum-Menu_7ea47c {
  text-align: right;
}

.spectrum-Menu_7ea47c {
  box-sizing: border-box;
  padding: var(--spectrum-popover-padding-y, var(--spectrum-global-dimension-size-50)) 0;
  -webkit-user-select: none;
  user-select: none;
  margin: 0;
  list-style-type: none;
  display: block;
  overflow-y: auto;
}

.spectrum-Menu_7ea47c .spectrum-Menu-sectionHeading_7ea47c {
  margin-top: var(--spectrum-global-dimension-size-40);
  margin-bottom: var(--spectrum-global-dimension-size-40);
}

.spectrum-Menu_7ea47c:focus {
  outline: 0;
}

.spectrum-Menu-checkmark_7ea47c {
  opacity: 1;
  padding-top: calc(var(--spectrum-selectlist-option-icon-padding-y)  - var(--spectrum-selectlist-option-padding-height));
  transform: scale(1);
}

[dir="ltr"] .spectrum-Menu-item_7ea47c {
  border-left: var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25)) solid transparent;
}

[dir="rtl"] .spectrum-Menu-item_7ea47c {
  border-right: var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25)) solid transparent;
}

.spectrum-Menu-item_7ea47c {
  cursor: default;
  box-sizing: border-box;
  font-size: var(--spectrum-selectlist-option-text-size, var(--spectrum-alias-font-size-default));
  font-weight: var(--spectrum-selectlist-option-text-font-weight, var(--spectrum-global-font-weight-regular));
  margin: 0;
  font-style: normal;
  text-decoration: none;
  position: relative;
}

.spectrum-Menu-item_7ea47c:focus {
  outline: 0;
}

.spectrum-Menu-item_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c {
  display: block;
}

[dir="ltr"] .spectrum-Menu-item_7ea47c .spectrum-Icon_7ea47c + .spectrum-Menu-itemLabel_7ea47c, [dir="ltr"] .spectrum-Menu-item_7ea47c .spectrum-Menu-itemIcon_7ea47c + .spectrum-Menu-itemLabel_7ea47c {
  margin-left: var(--spectrum-selectlist-thumbnail-image-padding-x, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-Menu-item_7ea47c .spectrum-Icon_7ea47c + .spectrum-Menu-itemLabel_7ea47c, [dir="rtl"] .spectrum-Menu-item_7ea47c .spectrum-Menu-itemIcon_7ea47c + .spectrum-Menu-itemLabel_7ea47c {
  margin-right: var(--spectrum-selectlist-thumbnail-image-padding-x, var(--spectrum-global-dimension-size-100));
}

.spectrum-Menu-itemLabel_7ea47c {
  line-height: var(--spectrum-global-font-line-height-small, 1.3);
  word-break: break-word;
  grid-area: text;
}

.spectrum-Menu-itemLabel--wrapping_7ea47c {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.spectrum-Menu-checkmark_7ea47c {
  grid-area: checkmark;
  place-self: flex-start end;
  display: none;
}

.spectrum-Menu-divider_7ea47c {
  box-sizing: content-box;
  height: var(--spectrum-selectlist-divider-size, var(--spectrum-alias-border-size-thick));
  margin: calc(var(--spectrum-selectlist-divider-padding, 3px) / 2) var(--spectrum-global-dimension-size-150);
  border: 0;
  padding: 0;
  overflow: visible;
}

[dir="ltr"] .spectrum-Menu-sectionHeading_7ea47c {
  padding-left: var(--spectrum-global-dimension-size-150);
}

[dir="rtl"] .spectrum-Menu-sectionHeading_7ea47c {
  padding-right: var(--spectrum-global-dimension-size-150);
}

[dir="ltr"] .spectrum-Menu-sectionHeading_7ea47c {
  padding-right: var(--spectrum-global-dimension-size-450);
}

[dir="rtl"] .spectrum-Menu-sectionHeading_7ea47c {
  padding-left: var(--spectrum-global-dimension-size-450);
}

.spectrum-Menu-sectionHeading_7ea47c {
  margin: var(--spectrum-global-dimension-size-75) 0 0 0;
  font-size: var(--spectrum-global-dimension-font-size-50);
  font-weight: 400;
  line-height: var(--spectrum-alias-line-height-medium, var(--spectrum-global-dimension-size-250));
  text-transform: uppercase;
  letter-spacing: .06em;
  padding-top: 0;
  padding-bottom: 0;
  display: block;
}

.spectrum-Menu_7ea47c .spectrum-Menu_7ea47c {
  margin: 0;
  display: block;
}

.spectrum-Menu-itemGrid_7ea47c {
  display: -ms-grid;
  -ms-grid-columns: calc(var(--spectrum-global-dimension-size-150)  - var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25))) auto 1fr auto auto auto var(--spectrum-global-dimension-size-150);
  grid-template-columns: calc(var(--spectrum-global-dimension-size-150)  - var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25))) auto 1fr auto auto auto var(--spectrum-global-dimension-size-150);
  -ms-grid-rows: var(--spectrum-selectlist-option-padding-height) 1fr auto var(--spectrum-selectlist-option-padding-height);
  grid-template-rows: var(--spectrum-selectlist-option-padding-height) 1fr auto var(--spectrum-selectlist-option-padding-height);
  grid-template-areas: ". . . . . . ."
                       ". icon text checkmark end keyboard ."
                       ". icon description checkmark end keyboard ."
                       ". . . . . . .";
  display: grid;
}

.spectrum-Menu-item_7ea47c.is-selectable_7ea47c .spectrum-Menu-itemGrid_7ea47c {
  -ms-grid-columns: calc(var(--spectrum-global-dimension-size-150)  - var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25))) auto 1fr calc(var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-selectlist-option-icon-padding-x, var(--spectrum-global-dimension-size-150))) auto auto var(--spectrum-global-dimension-size-150);
  grid-template-columns: calc(var(--spectrum-global-dimension-size-150)  - var(--spectrum-selectlist-border-size-key-focus, var(--spectrum-global-dimension-static-size-25))) auto 1fr calc(var(--spectrum-icon-checkmark-medium-width)  + var(--spectrum-selectlist-option-icon-padding-x, var(--spectrum-global-dimension-size-150))) auto auto var(--spectrum-global-dimension-size-150);
}

[dir="ltr"] .spectrum-Menu_7ea47c .spectrum-Menu-end_7ea47c {
  padding-left: var(--spectrum-global-dimension-size-125);
}

[dir="rtl"] .spectrum-Menu_7ea47c .spectrum-Menu-end_7ea47c {
  padding-right: var(--spectrum-global-dimension-size-125);
}

.spectrum-Menu_7ea47c .spectrum-Menu-end_7ea47c {
  grid-area: end;
  place-self: flex-start end;
}

[dir="ltr"] .spectrum-Menu-icon_7ea47c {
  padding-right: var(--spectrum-global-dimension-size-100);
}

[dir="rtl"] .spectrum-Menu-icon_7ea47c {
  padding-left: var(--spectrum-global-dimension-size-100);
}

.spectrum-Menu-icon_7ea47c {
  padding-top: var(--spectrum-global-dimension-size-10);
  grid-area: icon;
}

.spectrum-Menu-description_7ea47c {
  line-height: var(--spectrum-global-font-line-height-small, 1.3);
  font-size: var(--spectrum-global-dimension-size-150);
  grid-area: description;
}

[dir="ltr"] .spectrum-Menu-keyboard_7ea47c {
  padding-left: var(--spectrum-global-dimension-size-125);
}

[dir="rtl"] .spectrum-Menu-keyboard_7ea47c {
  padding-right: var(--spectrum-global-dimension-size-125);
}

.spectrum-Menu-keyboard_7ea47c {
  grid-area: keyboard;
  align-self: flex-start;
  font-family: adobe-clean-ux, adobe-clean, Source Sans Pro, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif;
}

.spectrum-Menu_7ea47c {
  background-color: var(--spectrum-selectlist-background-color, var(--spectrum-alias-background-color-transparent));
}

.spectrum-Menu-item_7ea47c {
  background-color: var(--spectrum-selectlist-option-background-color, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-selectlist-option-text-color, var(--spectrum-alias-text-color));
}

[dir="ltr"] .spectrum-Menu-item_7ea47c.focus-ring_7ea47c, [dir="ltr"] .spectrum-Menu-item_7ea47c.is-focused_7ea47c {
  border-left-color: var(--spectrum-selectlist-option-focus-indicator-color, var(--spectrum-alias-border-color-focus));
}

[dir="rtl"] .spectrum-Menu-item_7ea47c.focus-ring_7ea47c, [dir="rtl"] .spectrum-Menu-item_7ea47c.is-focused_7ea47c {
  border-right-color: var(--spectrum-selectlist-option-focus-indicator-color, var(--spectrum-alias-border-color-focus));
}

.spectrum-Menu-item_7ea47c.focus-ring_7ea47c, .spectrum-Menu-item_7ea47c.is-focused_7ea47c {
  background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
}

.spectrum-Menu-item_7ea47c.is-hovered_7ea47c, .spectrum-Menu-item_7ea47c:focus, .spectrum-Menu-item_7ea47c.is-highlighted_7ea47c, .spectrum-Menu-item_7ea47c.is-open_7ea47c {
  background-color: var(--spectrum-selectlist-option-background-color-hover, var(--spectrum-alias-background-color-hover-overlay));
  color: var(--spectrum-selectlist-option-text-color-hover, var(--spectrum-alias-text-color));
}

.spectrum-Menu-item_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c {
  color: var(--spectrum-selectlist-option-icon-color-selected, var(--spectrum-alias-icon-color-selected));
}

.spectrum-Menu-item_7ea47c .is-active_7ea47c, .spectrum-Menu-item_7ea47c:active {
  background-color: var(--spectrum-selectlist-option-background-color-down, var(--spectrum-alias-background-color-hover-overlay));
}

.spectrum-Menu-item_7ea47c.is-disabled_7ea47c {
  background-color: var(--spectrum-selectlist-option-background-color-disabled, var(--spectrum-alias-background-color-transparent));
  color: var(--spectrum-selectlist-option-text-color-disabled, var(--spectrum-alias-text-color-disabled));
  cursor: default;
  background-image: none;
}

.spectrum-Menu-sectionHeading_7ea47c {
  color: var(--spectrum-heading-subtitle3-text-color, var(--spectrum-global-color-gray-700));
}

.spectrum-Menu-divider_7ea47c {
  background-color: var(--spectrum-global-color-gray-300);
}

.spectrum-Menu-description_7ea47c {
  color: var(--spectrum-global-color-gray-700);
}

@media (forced-colors: active) {
  .spectrum-Menu-divider_7ea47c {
    background-color: CanvasText;
    forced-color-adjust: none;
  }

  .spectrum-Menu-item_7ea47c {
    forced-color-adjust: none;
    --spectrum-heading-subtitle3-text-color: ButtonText;
    --spectrum-selectlist-background-color: ButtonFace;
    --spectrum-selectlist-divider-color: ButtonText;
    --spectrum-selectlist-option-background-color: ButtonFace;
    --spectrum-selectlist-option-background-color-disabled: ButtonFace;
    --spectrum-selectlist-option-background-color-down: ButtonFace;
    --spectrum-selectlist-option-background-color-hover: Highlight;
    --spectrum-selectlist-option-background-color-key-focus: Highlight;
    --spectrum-selectlist-option-focus-indicator-color: Highlight;
    --spectrum-selectlist-option-icon-color-selected: Highlight;
    --spectrum-selectlist-option-text-color: ButtonText;
    --spectrum-selectlist-option-text-color-disabled: GrayText;
    --spectrum-selectlist-option-text-color-hover: HighlightText;
    --spectrum-selectlist-option-text-color-key-focus: HighlightText;
    --spectrum-alias-border-color-focus: Highlight;
  }

  .spectrum-Menu-item_7ea47c .spectrum-Menu-description_7ea47c {
    color: var(--spectrum-selectlist-option-text-color, var(--spectrum-alias-text-color));
  }

  .spectrum-Menu-item_7ea47c.is-hovered_7ea47c, .spectrum-Menu-item_7ea47c:focus, .spectrum-Menu-item_7ea47c.is-highlighted_7ea47c, .spectrum-Menu-item_7ea47c.is-open_7ea47c, .spectrum-Menu-item_7ea47c.focus-ring_7ea47c, .spectrum-Menu-item_7ea47c.is-focused_7ea47c {
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
  }

  .spectrum-Menu-item_7ea47c.is-hovered_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c, .spectrum-Menu-item_7ea47c:focus.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c, .spectrum-Menu-item_7ea47c.is-highlighted_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c, .spectrum-Menu-item_7ea47c.is-open_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c, .spectrum-Menu-item_7ea47c.focus-ring_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c, .spectrum-Menu-item_7ea47c.is-focused_7ea47c.is-selected_7ea47c .spectrum-Menu-checkmark_7ea47c {
    color: HighlightText;
  }

  .spectrum-Menu-item_7ea47c.is-hovered_7ea47c .spectrum-Menu-description_7ea47c, .spectrum-Menu-item_7ea47c:focus .spectrum-Menu-description_7ea47c, .spectrum-Menu-item_7ea47c.is-highlighted_7ea47c .spectrum-Menu-description_7ea47c, .spectrum-Menu-item_7ea47c.is-open_7ea47c .spectrum-Menu-description_7ea47c, .spectrum-Menu-item_7ea47c.focus-ring_7ea47c .spectrum-Menu-description_7ea47c, .spectrum-Menu-item_7ea47c.is-focused_7ea47c .spectrum-Menu-description_7ea47c {
    color: var(--spectrum-selectlist-option-text-color-key-focus, var(--spectrum-alias-text-color));
    background-color: var(--spectrum-selectlist-option-background-color-key-focus, var(--spectrum-alias-background-color-hover-overlay));
  }
}

.spectrum-Tooltip_22ae94 {
  visibility: hidden;
  opacity: 0;
  transition: transform var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, opacity var(--spectrum-global-animation-duration-100, 130ms) ease-in-out, visibility 0ms linear var(--spectrum-global-animation-duration-100, 130ms);
  pointer-events: none;
}

.spectrum-Tooltip_22ae94.is-open_22ae94 {
  visibility: visible;
  opacity: .9999;
  pointer-events: auto;
  transition-delay: 0s;
}

.spectrum-Tooltip--bottom_22ae94.is-open_22ae94 {
  transform: translateY(var(--spectrum-overlay-positive-transform-distance));
}

.spectrum-Tooltip--top_22ae94.is-open_22ae94 {
  transform: translateY(var(--spectrum-overlay-negative-transform-distance));
}

.spectrum-Tooltip--right_22ae94.is-open_22ae94 {
  transform: translateX(var(--spectrum-overlay-positive-transform-distance));
}

.spectrum-Tooltip--left_22ae94.is-open_22ae94 {
  transform: translateX(var(--spectrum-overlay-negative-transform-distance));
}

.spectrum-Tooltip_22ae94 {
  box-sizing: border-box;
  vertical-align: top;
  width: auto;
  padding: var(--spectrum-tooltip-padding-top, var(--spectrum-global-dimension-size-50)) var(--spectrum-tooltip-padding-x, var(--spectrum-global-dimension-size-125));
  border-radius: var(--spectrum-tooltip-border-radius, var(--spectrum-global-dimension-size-50));
  min-height: var(--spectrum-tooltip-min-height, var(--spectrum-global-dimension-size-300));
  font-size: var(--spectrum-tooltip-text-size, var(--spectrum-global-dimension-font-size-75));
  font-weight: var(--spectrum-tooltip-text-font-weight, var(--spectrum-global-font-weight-regular));
  line-height: var(--spectrum-tooltip-text-line-height, var(--spectrum-global-dimension-font-size-200));
  word-break: break-word;
  -webkit-font-smoothing: antialiased;
  cursor: default;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  align-items: center;
  display: inline-flex;
  position: relative;
}

.spectrum-Tooltip_22ae94 p {
  margin: 0;
}

.spectrum-Tooltip-tip_22ae94 {
  height: 0;
  width: 0;
  border-width: var(--spectrum-tooltip-tip-height, var(--spectrum-global-dimension-size-50));
  border-style: solid;
  border-bottom-color: #0000;
  border-left-color: #0000;
  border-right-color: #0000;
  position: absolute;
}

.spectrum-Tooltip--right_22ae94 .spectrum-Tooltip-tip_22ae94, .spectrum-Tooltip--left_22ae94 .spectrum-Tooltip-tip_22ae94 {
  margin-top: calc(var(--spectrum-tooltip-tip-height, var(--spectrum-global-dimension-size-50)) * -1);
  top: 50%;
}

.spectrum-Tooltip--right_22ae94 {
  margin-left: var(--spectrum-global-dimension-size-40);
}

.spectrum-Tooltip--right_22ae94 .spectrum-Tooltip-tip_22ae94 {
  right: 100%;
  transform: rotate(90deg);
}

.spectrum-Tooltip--left_22ae94 {
  margin-right: var(--spectrum-global-dimension-size-40);
}

.spectrum-Tooltip--left_22ae94 .spectrum-Tooltip-tip_22ae94 {
  left: 100%;
  transform: rotate(-90deg);
}

.spectrum-Tooltip--top_22ae94 {
  margin-bottom: var(--spectrum-global-dimension-size-40);
}

.spectrum-Tooltip--top_22ae94 .spectrum-Tooltip-tip_22ae94 {
  top: 100%;
}

.spectrum-Tooltip--bottom_22ae94 {
  margin-top: var(--spectrum-global-dimension-size-40);
}

.spectrum-Tooltip--bottom_22ae94 .spectrum-Tooltip-tip_22ae94 {
  bottom: 100%;
  transform: rotate(180deg);
}

.spectrum-Tooltip--bottom_22ae94 .spectrum-Tooltip-tip_22ae94, .spectrum-Tooltip--top_22ae94 .spectrum-Tooltip-tip_22ae94 {
  margin-left: calc(var(--spectrum-tooltip-tip-height, var(--spectrum-global-dimension-size-50)) * -1);
  left: 50%;
}

[dir="ltr"] .spectrum-Tooltip-typeIcon_22ae94 {
  margin-left: calc(var(--spectrum-tooltip-icon-margin-x, var(--spectrum-global-dimension-size-100))  - var(--spectrum-tooltip-padding-x, var(--spectrum-global-dimension-size-125)));
}

[dir="rtl"] .spectrum-Tooltip-typeIcon_22ae94 {
  margin-right: calc(var(--spectrum-tooltip-icon-margin-x, var(--spectrum-global-dimension-size-100))  - var(--spectrum-tooltip-padding-x, var(--spectrum-global-dimension-size-125)));
}

[dir="ltr"] .spectrum-Tooltip-typeIcon_22ae94 {
  margin-right: var(--spectrum-tooltip-icon-margin-x, var(--spectrum-global-dimension-size-100));
}

[dir="rtl"] .spectrum-Tooltip-typeIcon_22ae94 {
  margin-left: var(--spectrum-tooltip-icon-margin-x, var(--spectrum-global-dimension-size-100));
}

.spectrum-Tooltip-typeIcon_22ae94 {
  width: var(--spectrum-tooltip-icon-size, var(--spectrum-global-dimension-size-175));
  height: var(--spectrum-tooltip-icon-size, var(--spectrum-global-dimension-size-175));
  align-self: flex-start;
  margin-top: 1px;
}

.spectrum-Tooltip-label_22ae94 {
  max-width: var(--spectrum-tooltip-content-max-width);
  line-height: var(--spectrum-tooltip-text-line-height, var(--spectrum-global-dimension-font-size-200));
}

.u-tooltip-showOnHover_22ae94 {
  display: inline-block;
  position: relative;
}

.u-tooltip-showOnHover_22ae94 .spectrum-Tooltip_22ae94 {
  white-space: nowrap;
  transition: transform var(--spectrum-global-animation-duration-100, 130ms) ease-in-out;
  position: absolute;
  top: -100%;
  visibility: visible !important;
}

.u-tooltip-showOnHover_22ae94 .spectrum-Tooltip-label_22ae94 {
  max-width: none;
}

.u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--right_22ae94, .u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--left_22ae94, .u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--right_22ae94 .spectrum-Tooltip-tip_22ae94, .u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--left_22ae94 .spectrum-Tooltip-tip_22ae94 {
  top: 50%;
}

.u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--right_22ae94 {
  left: 100%;
  transform: translateY(-50%);
}

.u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--left_22ae94 {
  transform: translate(-100%, -50%);
}

.u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--bottom_22ae94, .u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--top_22ae94, .u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--bottom_22ae94 .spectrum-Tooltip-tip_22ae94, .u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--top_22ae94 .spectrum-Tooltip-tip_22ae94 {
  left: 50%;
}

.u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--bottom_22ae94 {
  transform: translate(-50%, calc(var(--spectrum-tooltip-tip-margin, var(--spectrum-global-dimension-size-50)) * -1) );
  top: 100%;
}

.u-tooltip-showOnHover_22ae94 .spectrum-Tooltip--top_22ae94 {
  transform: translate(-50%, var(--spectrum-tooltip-tip-margin, var(--spectrum-global-dimension-size-50)));
}

.u-tooltip-showOnHover_22ae94.is-hovered_22ae94 .spectrum-Tooltip_22ae94, .u-tooltip-showOnHover_22ae94:focus .spectrum-Tooltip_22ae94, .u-tooltip-showOnHover_22ae94.is-focused_22ae94 .spectrum-Tooltip_22ae94, .u-tooltip-showOnHover_22ae94 :focus .spectrum-Tooltip_22ae94 {
  opacity: 1;
}

.u-tooltip-showOnHover_22ae94.is-hovered_22ae94 .spectrum-Tooltip_22ae94.spectrum-Tooltip--bottom_22ae94, .u-tooltip-showOnHover_22ae94:focus .spectrum-Tooltip_22ae94.spectrum-Tooltip--bottom_22ae94, .u-tooltip-showOnHover_22ae94.is-focused_22ae94 .spectrum-Tooltip_22ae94.spectrum-Tooltip--bottom_22ae94, .u-tooltip-showOnHover_22ae94 :focus .spectrum-Tooltip_22ae94.spectrum-Tooltip--bottom_22ae94 {
  transform: translate(-50%);
}

.u-tooltip-showOnHover_22ae94.is-hovered_22ae94 .spectrum-Tooltip_22ae94.spectrum-Tooltip--top_22ae94, .u-tooltip-showOnHover_22ae94:focus .spectrum-Tooltip_22ae94.spectrum-Tooltip--top_22ae94, .u-tooltip-showOnHover_22ae94.is-focused_22ae94 .spectrum-Tooltip_22ae94.spectrum-Tooltip--top_22ae94, .u-tooltip-showOnHover_22ae94 :focus .spectrum-Tooltip_22ae94.spectrum-Tooltip--top_22ae94 {
  transform: translate(-50%, calc(var(--spectrum-tooltip-tip-margin, var(--spectrum-global-dimension-size-50)) * -1) );
}

.u-tooltip-showOnHover_22ae94.is-hovered_22ae94 .spectrum-Tooltip_22ae94.spectrum-Tooltip--left_22ae94, .u-tooltip-showOnHover_22ae94:focus .spectrum-Tooltip_22ae94.spectrum-Tooltip--left_22ae94, .u-tooltip-showOnHover_22ae94.is-focused_22ae94 .spectrum-Tooltip_22ae94.spectrum-Tooltip--left_22ae94, .u-tooltip-showOnHover_22ae94 :focus .spectrum-Tooltip_22ae94.spectrum-Tooltip--left_22ae94 {
  transform: translate(calc(-100% - var(--spectrum-tooltip-tip-margin, var(--spectrum-global-dimension-size-50))), -50%);
}

.u-tooltip-showOnHover_22ae94.is-hovered_22ae94 .spectrum-Tooltip_22ae94.spectrum-Tooltip--right_22ae94, .u-tooltip-showOnHover_22ae94:focus .spectrum-Tooltip_22ae94.spectrum-Tooltip--right_22ae94, .u-tooltip-showOnHover_22ae94.is-focused_22ae94 .spectrum-Tooltip_22ae94.spectrum-Tooltip--right_22ae94, .u-tooltip-showOnHover_22ae94 :focus .spectrum-Tooltip_22ae94.spectrum-Tooltip--right_22ae94 {
  transform: translate(var(--spectrum-tooltip-tip-margin, var(--spectrum-global-dimension-size-50)), -50%);
}

.spectrum-Tooltip_22ae94 {
  background-color: var(--spectrum-tooltip-background-color, var(--spectrum-global-color-static-gray-700));
  color: var(--spectrum-tooltip-text-color, var(--spectrum-global-color-static-white));
}

.spectrum-Tooltip-tip_22ae94 {
  border-top-color: var(--spectrum-tooltip-background-color, var(--spectrum-global-color-static-gray-700));
}

.spectrum-Tooltip--negative_22ae94, .spectrum-Tooltip--error_22ae94 {
  background-color: var(--spectrum-tooltip-negative-background-color, var(--spectrum-global-color-static-red-700));
}

.spectrum-Tooltip--negative_22ae94 .spectrum-Tooltip-tip_22ae94, .spectrum-Tooltip--error_22ae94 .spectrum-Tooltip-tip_22ae94 {
  border-top-color: var(--spectrum-tooltip-negative-background-color, var(--spectrum-global-color-static-red-700));
}

.spectrum-Tooltip--info_22ae94, .spectrum-Tooltip--help_22ae94 {
  background-color: var(--spectrum-tooltip-info-background-color, var(--spectrum-global-color-static-blue-700));
}

.spectrum-Tooltip--info_22ae94 .spectrum-Tooltip-tip_22ae94, .spectrum-Tooltip--help_22ae94 .spectrum-Tooltip-tip_22ae94 {
  border-top-color: var(--spectrum-tooltip-info-background-color, var(--spectrum-global-color-static-blue-700));
}

.spectrum-Tooltip--positive_22ae94, .spectrum-Tooltip--success_22ae94 {
  background-color: var(--spectrum-tooltip-positive-background-color, var(--spectrum-global-color-static-green-700));
}

.spectrum-Tooltip--positive_22ae94 .spectrum-Tooltip-tip_22ae94, .spectrum-Tooltip--success_22ae94 .spectrum-Tooltip-tip_22ae94 {
  border-top-color: var(--spectrum-tooltip-positive-background-color, var(--spectrum-global-color-static-green-700));
}

@media (forced-colors: active) {
  .spectrum-Tooltip_22ae94 {
    border: 1px solid #0000;
  }

  .spectrum-Tooltip-tip_22ae94 {
    forced-color-adjust: none;
    --spectrum-tooltip-background-color: CanvasText;
    --spectrum-tooltip-negative-background-color: Highlight;
    --spectrum-tooltip-info-background-color: Highlight;
    --spectrum-tooltip-positive-background-color: Highlight;
  }
}

.spectrum--darkest_256eeb {
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.1;
  --spectrum-global-color-celery-400: #3da74e;
  --spectrum-global-color-celery-500: #44b556;
  --spectrum-global-color-celery-600: #4bc35f;
  --spectrum-global-color-celery-700: #51d267;
  --spectrum-global-color-chartreuse-400: #7cc33f;
  --spectrum-global-color-chartreuse-500: #85d044;
  --spectrum-global-color-chartreuse-600: #8ede49;
  --spectrum-global-color-chartreuse-700: #9bec54;
  --spectrum-global-color-yellow-400: #d2b200;
  --spectrum-global-color-yellow-500: #dfbf00;
  --spectrum-global-color-yellow-600: #edcc00;
  --spectrum-global-color-yellow-700: #fad900;
  --spectrum-global-color-magenta-400: #ca2996;
  --spectrum-global-color-magenta-500: #d83790;
  --spectrum-global-color-magenta-600: #e2499d;
  --spectrum-global-color-magenta-700: #ec5aaa;
  --spectrum-global-color-fuchsia-400: #b130bd;
  --spectrum-global-color-fuchsia-500: #c038cc;
  --spectrum-global-color-fuchsia-600: #cf3edc;
  --spectrum-global-color-fuchsia-700: #d951e5;
  --spectrum-global-color-purple-400: #864ccc;
  --spectrum-global-color-purple-500: #9256d9;
  --spectrum-global-color-purple-600: #9d64e1;
  --spectrum-global-color-purple-700: #a873df;
  --spectrum-global-color-indigo-400: #5c5ce0;
  --spectrum-global-color-indigo-500: #6767ec;
  --spectrum-global-color-indigo-600: #7575f1;
  --spectrum-global-color-indigo-700: #8282f6;
  --spectrum-global-color-seafoam-400: #16878c;
  --spectrum-global-color-seafoam-500: #1b959a;
  --spectrum-global-color-seafoam-600: #20a3a8;
  --spectrum-global-color-seafoam-700: #23b2b8;
  --spectrum-global-color-red-400: #d7373f;
  --spectrum-global-color-red-500: #e34850;
  --spectrum-global-color-red-600: #ec5b62;
  --spectrum-global-color-red-700: #f76d74;
  --spectrum-global-color-orange-400: #da7b11;
  --spectrum-global-color-orange-500: #e68619;
  --spectrum-global-color-orange-600: #f29423;
  --spectrum-global-color-orange-700: #f9a43f;
  --spectrum-global-color-green-400: #268e6c;
  --spectrum-global-color-green-500: #2d9d78;
  --spectrum-global-color-green-600: #33ab84;
  --spectrum-global-color-green-700: #39b990;
  --spectrum-global-color-blue-400: #1473e6;
  --spectrum-global-color-blue-500: #2680eb;
  --spectrum-global-color-blue-600: #378ef0;
  --spectrum-global-color-blue-700: #4b9cf5;
  --spectrum-global-color-gray-50: #080808;
  --spectrum-global-color-gray-75: #1a1a1a;
  --spectrum-global-color-gray-100: #1e1e1e;
  --spectrum-global-color-gray-200: #2c2c2c;
  --spectrum-global-color-gray-300: #393939;
  --spectrum-global-color-gray-400: #494949;
  --spectrum-global-color-gray-500: #5c5c5c;
  --spectrum-global-color-gray-600: #7c7c7c;
  --spectrum-global-color-gray-700: #a2a2a2;
  --spectrum-global-color-gray-800: #c8c8c8;
  --spectrum-global-color-gray-900: #efefef;
  --spectrum-alias-background-color-modal-overlay: #0009;
  --spectrum-alias-dropshadow-color: #000c;
  --spectrum-alias-background-color-hover-overlay: #efefef14;
  --spectrum-alias-highlight-hover: #efefef14;
  --spectrum-alias-highlight-active: #efefef26;
  --spectrum-alias-highlight-selected: #2680eb33;
  --spectrum-alias-highlight-selected-hover: #2680eb4d;
  --spectrum-alias-text-highlight-color: #2680eb4d;
  --spectrum-alias-background-color-quickactions: #1e1e1ee6;
  --spectrum-alias-radial-reaction-color-default: #c8c8c899;
  --spectrum-alias-pasteboard-background-color: var(--spectrum-global-color-gray-50);
  --spectrum-alias-appframe-border-color: var(--spectrum-global-color-gray-50);
  --spectrum-alias-appframe-separator-color: var(--spectrum-global-color-gray-50);
  --spectrum-colorarea-border-color: #efefef1a;
  --spectrum-colorarea-border-color-hover: #efefef1a;
  --spectrum-colorarea-border-color-down: #efefef1a;
  --spectrum-colorarea-border-color-key-focus: #efefef1a;
  --spectrum-colorslider-border-color: #efefef1a;
  --spectrum-colorslider-border-color-hover: #efefef1a;
  --spectrum-colorslider-border-color-down: #efefef1a;
  --spectrum-colorslider-border-color-key-focus: #efefef1a;
  --spectrum-colorslider-vertical-border-color: #efefef1a;
  --spectrum-colorslider-vertical-border-color-hover: #efefef1a;
  --spectrum-colorslider-vertical-border-color-down: #efefef1a;
  --spectrum-colorslider-vertical-border-color-key-focus: #efefef1a;
  --spectrum-colorwheel-border-color: #efefef1a;
  --spectrum-colorwheel-border-color-hover: #efefef1a;
  --spectrum-colorwheel-border-color-down: #efefef1a;
  --spectrum-colorwheel-border-color-key-focus: #efefef1a;
  --spectrum-miller-column-item-background-color-selected: #2680eb1a;
  --spectrum-miller-column-item-background-color-selected-hover: #2680eb33;
  --spectrum-tabs-compact-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-emphasized-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-well-background-color: #c8c8c805;
  --spectrum-well-border-color: #efefef0d;
  --spectrum-tray-background-color: var(--spectrum-global-color-gray-100);
  --react-spectrum-datepicker-placeholder-color: var(--spectrum-global-color-gray-600);
}

.spectrum_9e130c {
  --spectrum-global-animation-duration-0: 0ms;
  --spectrum-global-animation-duration-100: 130ms;
  --spectrum-global-animation-duration-200: 160ms;
  --spectrum-global-animation-duration-300: 190ms;
  --spectrum-global-animation-duration-400: 220ms;
  --spectrum-global-animation-duration-500: 250ms;
  --spectrum-global-animation-duration-600: 300ms;
  --spectrum-global-animation-duration-700: 350ms;
  --spectrum-global-animation-duration-800: 400ms;
  --spectrum-global-animation-duration-900: 450ms;
  --spectrum-global-animation-duration-1000: 500ms;
  --spectrum-global-animation-duration-2000: 1000ms;
  --spectrum-global-animation-duration-4000: 2000ms;
  --spectrum-global-animation-ease-in-out: cubic-bezier(.45, 0, .4, 1);
  --spectrum-global-animation-ease-in: cubic-bezier(.5, 0, 1, 1);
  --spectrum-global-animation-ease-out: cubic-bezier(0, 0, .4, 1);
  --spectrum-global-animation-linear: cubic-bezier(0, 0, 1, 1);
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.1;
  --spectrum-global-color-static-black: #000;
  --spectrum-global-color-static-white: #fff;
  --spectrum-global-color-static-blue: #1473e6;
  --spectrum-global-color-static-gray-50: #fff;
  --spectrum-global-color-static-gray-75: #fff;
  --spectrum-global-color-static-gray-100: #fff;
  --spectrum-global-color-static-gray-200: #f4f4f4;
  --spectrum-global-color-static-gray-300: #eaeaea;
  --spectrum-global-color-static-gray-400: #d3d3d3;
  --spectrum-global-color-static-gray-500: #bcbcbc;
  --spectrum-global-color-static-gray-600: #959595;
  --spectrum-global-color-static-gray-700: #747474;
  --spectrum-global-color-static-gray-800: #505050;
  --spectrum-global-color-static-gray-900: #323232;
  --spectrum-global-color-static-blue-200: #5aa9fa;
  --spectrum-global-color-static-blue-300: #4b9cf5;
  --spectrum-global-color-static-blue-400: #378ef0;
  --spectrum-global-color-static-blue-500: #2680eb;
  --spectrum-global-color-static-blue-600: #1473e6;
  --spectrum-global-color-static-blue-700: #0d66d0;
  --spectrum-global-color-static-blue-800: #095aba;
  --spectrum-global-color-static-red-400: #ec5b62;
  --spectrum-global-color-static-red-500: #e34850;
  --spectrum-global-color-static-red-600: #d7373f;
  --spectrum-global-color-static-red-700: #c9252d;
  --spectrum-global-color-static-orange-400: #f29423;
  --spectrum-global-color-static-orange-500: #e68619;
  --spectrum-global-color-static-orange-600: #da7b11;
  --spectrum-global-color-static-orange-700: #cb6f10;
  --spectrum-global-color-static-green-400: #33ab84;
  --spectrum-global-color-static-green-500: #2d9d78;
  --spectrum-global-color-static-green-600: #268e6c;
  --spectrum-global-color-static-green-700: #12805c;
  --spectrum-global-color-static-celery-200: #58e06f;
  --spectrum-global-color-static-celery-300: #51d267;
  --spectrum-global-color-static-celery-400: #4bc35f;
  --spectrum-global-color-static-celery-500: #44b556;
  --spectrum-global-color-static-celery-600: #3da74e;
  --spectrum-global-color-static-celery-700: #379947;
  --spectrum-global-color-static-chartreuse-300: #9bec54;
  --spectrum-global-color-static-chartreuse-400: #8ede49;
  --spectrum-global-color-static-chartreuse-500: #85d044;
  --spectrum-global-color-static-chartreuse-600: #7cc33f;
  --spectrum-global-color-static-chartreuse-700: #73b53a;
  --spectrum-global-color-static-yellow-200: #ffe22e;
  --spectrum-global-color-static-yellow-300: #fad900;
  --spectrum-global-color-static-yellow-400: #edcc00;
  --spectrum-global-color-static-yellow-500: #dfbf00;
  --spectrum-global-color-static-yellow-600: #d2b200;
  --spectrum-global-color-static-yellow-700: #c4a600;
  --spectrum-global-color-static-magenta-200: #f56bb7;
  --spectrum-global-color-static-magenta-300: #ec5aaa;
  --spectrum-global-color-static-magenta-400: #e2499d;
  --spectrum-global-color-static-magenta-500: #d83790;
  --spectrum-global-color-static-magenta-600: #ca2982;
  --spectrum-global-color-static-magenta-700: #bc1c74;
  --spectrum-global-color-static-fuchsia-400: #cf3edc;
  --spectrum-global-color-static-fuchsia-500: #c038cc;
  --spectrum-global-color-static-fuchsia-600: #b130bd;
  --spectrum-global-color-static-fuchsia-700: #a228ad;
  --spectrum-global-color-static-purple-400: #9d64e1;
  --spectrum-global-color-static-purple-500: #9256d9;
  --spectrum-global-color-static-purple-600: #864ccc;
  --spectrum-global-color-static-purple-700: #7a42bf;
  --spectrum-global-color-static-purple-800: #6f38b1;
  --spectrum-global-color-static-indigo-200: #9090fa;
  --spectrum-global-color-static-indigo-300: #8282f6;
  --spectrum-global-color-static-indigo-400: #7575f1;
  --spectrum-global-color-static-indigo-500: #6767ec;
  --spectrum-global-color-static-indigo-600: #5c5ce0;
  --spectrum-global-color-static-indigo-700: #5151d3;
  --spectrum-global-color-static-seafoam-200: #26c0c7;
  --spectrum-global-color-static-seafoam-300: #23b2b8;
  --spectrum-global-color-static-seafoam-400: #20a3a8;
  --spectrum-global-color-static-seafoam-500: #1b959a;
  --spectrum-global-color-static-seafoam-600: #16878c;
  --spectrum-global-color-static-seafoam-700: #0f797d;
  --spectrum-global-color-opacity-100: 1;
  --spectrum-global-color-opacity-90: .9;
  --spectrum-global-color-opacity-80: .8;
  --spectrum-global-color-opacity-60: .6;
  --spectrum-global-color-opacity-50: .5;
  --spectrum-global-color-opacity-42: .42;
  --spectrum-global-color-opacity-40: .4;
  --spectrum-global-color-opacity-30: .3;
  --spectrum-global-color-opacity-25: .25;
  --spectrum-global-color-opacity-20: .2;
  --spectrum-global-color-opacity-15: .15;
  --spectrum-global-color-opacity-10: .1;
  --spectrum-global-color-opacity-8: .08;
  --spectrum-global-color-opacity-7: .07;
  --spectrum-global-color-opacity-6: .06;
  --spectrum-global-color-opacity-5: .05;
  --spectrum-global-color-opacity-4: .04;
  --spectrum-semantic-negative-color-background: var(--spectrum-global-color-static-red-700, #c9252d);
  --spectrum-semantic-negative-color-default: var(--spectrum-global-color-red-500);
  --spectrum-semantic-negative-color-state-hover: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-color-dark: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-color-border: var(--spectrum-global-color-red-400);
  --spectrum-semantic-negative-color-icon: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-color-status: var(--spectrum-global-color-red-400);
  --spectrum-semantic-negative-color-text-large: var(--spectrum-global-color-red-500);
  --spectrum-semantic-negative-color-text-small: var(--spectrum-global-color-red-600);
  --spectrum-semantic-negative-color-state-down: var(--spectrum-global-color-red-700);
  --spectrum-semantic-negative-color-state-focus: var(--spectrum-global-color-red-400);
  --spectrum-semantic-notice-color-background: var(--spectrum-global-color-static-orange-700, #cb6f10);
  --spectrum-semantic-notice-color-default: var(--spectrum-global-color-orange-500);
  --spectrum-semantic-notice-color-dark: var(--spectrum-global-color-orange-600);
  --spectrum-semantic-notice-color-border: var(--spectrum-global-color-orange-400);
  --spectrum-semantic-notice-color-icon: var(--spectrum-global-color-orange-600);
  --spectrum-semantic-notice-color-status: var(--spectrum-global-color-orange-400);
  --spectrum-semantic-notice-color-text-large: var(--spectrum-global-color-orange-500);
  --spectrum-semantic-notice-color-text-small: var(--spectrum-global-color-orange-600);
  --spectrum-semantic-notice-color-state-down: var(--spectrum-global-color-orange-700);
  --spectrum-semantic-notice-color-state-focus: var(--spectrum-global-color-orange-400);
  --spectrum-semantic-positive-color-background: var(--spectrum-global-color-static-green-700, #12805c);
  --spectrum-semantic-positive-color-default: var(--spectrum-global-color-green-500);
  --spectrum-semantic-positive-color-dark: var(--spectrum-global-color-green-600);
  --spectrum-semantic-positive-color-border: var(--spectrum-global-color-green-400);
  --spectrum-semantic-positive-color-icon: var(--spectrum-global-color-green-600);
  --spectrum-semantic-positive-color-status: var(--spectrum-global-color-green-400);
  --spectrum-semantic-positive-color-text-large: var(--spectrum-global-color-green-500);
  --spectrum-semantic-positive-color-text-small: var(--spectrum-global-color-green-600);
  --spectrum-semantic-positive-color-state-down: var(--spectrum-global-color-green-700);
  --spectrum-semantic-positive-color-state-focus: var(--spectrum-global-color-green-400);
  --spectrum-semantic-informative-color-background: var(--spectrum-global-color-static-blue-700, #0d66d0);
  --spectrum-semantic-informative-color-default: var(--spectrum-global-color-blue-500);
  --spectrum-semantic-informative-color-dark: var(--spectrum-global-color-blue-600);
  --spectrum-semantic-informative-color-border: var(--spectrum-global-color-blue-400);
  --spectrum-semantic-informative-color-icon: var(--spectrum-global-color-blue-600);
  --spectrum-semantic-informative-color-status: var(--spectrum-global-color-blue-400);
  --spectrum-semantic-informative-color-text-large: var(--spectrum-global-color-blue-500);
  --spectrum-semantic-informative-color-text-small: var(--spectrum-global-color-blue-600);
  --spectrum-semantic-informative-color-state-down: var(--spectrum-global-color-blue-700);
  --spectrum-semantic-informative-color-state-focus: var(--spectrum-global-color-blue-400);
  --spectrum-semantic-cta-color-background-default: var(--spectrum-global-color-static-blue-600, #1473e6);
  --spectrum-semantic-cta-color-background-hover: var(--spectrum-global-color-static-blue-700, #0d66d0);
  --spectrum-semantic-cta-color-background-down: var(--spectrum-global-color-static-blue-800, #095aba);
  --spectrum-semantic-cta-color-background-key-focus: var(--spectrum-global-color-static-blue-600, #1473e6);
  --spectrum-semantic-background-color-key-focus: var(--spectrum-global-color-static-blue-600, #1473e6);
  --spectrum-semantic-neutral-color-background: var(--spectrum-global-color-static-gray-700, #747474);
  --spectrum-semantic-presence-color-1: var(--spectrum-global-color-static-red-500, #e34850);
  --spectrum-semantic-presence-color-2: var(--spectrum-global-color-static-orange-400, #f29423);
  --spectrum-semantic-presence-color-3: var(--spectrum-global-color-static-yellow-400, #edcc00);
  --spectrum-semantic-presence-color-4: #4bcca2;
  --spectrum-semantic-presence-color-5: #00c7ff;
  --spectrum-semantic-presence-color-6: #008cb8;
  --spectrum-semantic-presence-color-7: #7e4bf3;
  --spectrum-semantic-presence-color-8: var(--spectrum-global-color-static-fuchsia-600, #b130bd);
  --spectrum-global-dimension-static-size-0: 0px;
  --spectrum-global-dimension-static-size-10: 1px;
  --spectrum-global-dimension-static-size-25: 2px;
  --spectrum-global-dimension-static-size-50: 4px;
  --spectrum-global-dimension-static-size-40: 3px;
  --spectrum-global-dimension-static-size-65: 5px;
  --spectrum-global-dimension-static-size-100: 8px;
  --spectrum-global-dimension-static-size-115: 9px;
  --spectrum-global-dimension-static-size-125: 10px;
  --spectrum-global-dimension-static-size-130: 11px;
  --spectrum-global-dimension-static-size-150: 12px;
  --spectrum-global-dimension-static-size-160: 13px;
  --spectrum-global-dimension-static-size-175: 14px;
  --spectrum-global-dimension-static-size-200: 16px;
  --spectrum-global-dimension-static-size-225: 18px;
  --spectrum-global-dimension-static-size-250: 20px;
  --spectrum-global-dimension-static-size-300: 24px;
  --spectrum-global-dimension-static-size-400: 32px;
  --spectrum-global-dimension-static-size-450: 36px;
  --spectrum-global-dimension-static-size-500: 40px;
  --spectrum-global-dimension-static-size-550: 44px;
  --spectrum-global-dimension-static-size-600: 48px;
  --spectrum-global-dimension-static-size-700: 56px;
  --spectrum-global-dimension-static-size-800: 64px;
  --spectrum-global-dimension-static-size-900: 72px;
  --spectrum-global-dimension-static-size-1000: 80px;
  --spectrum-global-dimension-static-size-1200: 96px;
  --spectrum-global-dimension-static-size-1700: 136px;
  --spectrum-global-dimension-static-size-2400: 192px;
  --spectrum-global-dimension-static-size-2600: 208px;
  --spectrum-global-dimension-static-size-3400: 272px;
  --spectrum-global-dimension-static-size-3600: 288px;
  --spectrum-global-dimension-static-size-4600: 368px;
  --spectrum-global-dimension-static-size-5000: 400px;
  --spectrum-global-dimension-static-size-6000: 480px;
  --spectrum-global-dimension-static-font-size-50: 11px;
  --spectrum-global-dimension-static-font-size-75: 12px;
  --spectrum-global-dimension-static-font-size-100: 14px;
  --spectrum-global-dimension-static-font-size-150: 15px;
  --spectrum-global-dimension-static-font-size-200: 16px;
  --spectrum-global-dimension-static-font-size-300: 18px;
  --spectrum-global-dimension-static-font-size-400: 20px;
  --spectrum-global-dimension-static-font-size-500: 22px;
  --spectrum-global-dimension-static-font-size-600: 25px;
  --spectrum-global-dimension-static-font-size-700: 28px;
  --spectrum-global-dimension-static-font-size-800: 32px;
  --spectrum-global-dimension-static-font-size-900: 36px;
  --spectrum-global-dimension-static-font-size-1000: 40px;
  --spectrum-global-dimension-static-percent-50: 50%;
  --spectrum-global-dimension-static-percent-100: 100%;
  --spectrum-global-dimension-static-breakpoint-xsmall: 304px;
  --spectrum-global-dimension-static-breakpoint-small: 768px;
  --spectrum-global-dimension-static-breakpoint-medium: 1280px;
  --spectrum-global-dimension-static-breakpoint-large: 1768px;
  --spectrum-global-dimension-static-breakpoint-xlarge: 2160px;
  --spectrum-global-dimension-static-grid-columns: 12;
  --spectrum-global-dimension-static-grid-fluid-width: 100%;
  --spectrum-global-dimension-static-grid-fixed-max-width: 1280px;
  --spectrum-global-font-family-base: adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-global-font-family-serif: adobe-clean-serif, "Source Serif Pro", Georgia, serif;
  --spectrum-global-font-family-code: "Source Code Pro", Monaco, monospace;
  --spectrum-global-font-weight-thin: 100;
  --spectrum-global-font-weight-ultra-light: 200;
  --spectrum-global-font-weight-light: 300;
  --spectrum-global-font-weight-regular: 400;
  --spectrum-global-font-weight-medium: 500;
  --spectrum-global-font-weight-semi-bold: 600;
  --spectrum-global-font-weight-bold: 700;
  --spectrum-global-font-weight-extra-bold: 800;
  --spectrum-global-font-weight-black: 900;
  --spectrum-global-font-style-regular: normal;
  --spectrum-global-font-style-italic: italic;
  --spectrum-global-font-letter-spacing-none: 0;
  --spectrum-global-font-letter-spacing-small: .0125em;
  --spectrum-global-font-letter-spacing-han: .05em;
  --spectrum-global-font-letter-spacing-medium: .06em;
  --spectrum-global-font-line-height-large: 1.7;
  --spectrum-global-font-line-height-medium: 1.5;
  --spectrum-global-font-line-height-small: 1.3;
  --spectrum-global-font-multiplier-25: .25em;
  --spectrum-global-font-multiplier-75: .75em;
  --spectrum-alias-border-size-thin: var(--spectrum-global-dimension-static-size-10, 1px);
  --spectrum-alias-border-size-thick: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-border-size-thicker: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-alias-border-size-thickest: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-alias-border-offset-thin: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-border-offset-thick: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-alias-border-offset-thicker: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-alias-border-offset-thickest: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-baseline: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-alias-grid-gutter-xsmall: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-gutter-small: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-alias-grid-gutter-medium: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-alias-grid-gutter-large: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-alias-grid-gutter-xlarge: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-alias-grid-margin-xsmall: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-margin-small: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-alias-grid-margin-medium: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-alias-grid-margin-large: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-alias-grid-margin-xlarge: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-alias-grid-layout-region-margin-bottom-xsmall: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-alias-grid-layout-region-margin-bottom-small: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-alias-grid-layout-region-margin-bottom-medium: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-alias-grid-layout-region-margin-bottom-large: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-alias-grid-layout-region-margin-bottom-xlarge: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-alias-radial-reaction-size-default: var(--spectrum-global-dimension-static-size-550, 44px);
  --spectrum-alias-font-family-ar: myriad-arabic, adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-alias-font-family-he: myriad-hebrew, adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-alias-font-family-zh: adobe-clean-han-traditional, source-han-traditional, "MingLiu", "Heiti TC Light", "sans-serif";
  --spectrum-alias-font-family-zhhans: adobe-clean-han-simplified-c, source-han-simplified-c, "SimSun", "Heiti SC Light", "sans-serif";
  --spectrum-alias-font-family-ko: adobe-clean-han-korean, source-han-korean, "Malgun Gothic", "Apple Gothic", "sans-serif";
  --spectrum-alias-font-family-ja: adobe-clean-han-japanese, source-han-japanese, "Yu Gothic", "メイリオ", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro W3", "Osaka", "ＭＳＰゴシック", "MS PGothic", "sans-serif";
  --spectrum-alias-font-family-condensed: adobe-clean-han-traditional, source-han-traditional, "MingLiu", "Heiti TC Light", adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif;
  --spectrum-alias-line-height-body: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-line-height-title: var(--spectrum-global-font-line-height-small, 1.3);
  --spectrum-alias-body-han-text-line-height: var(--spectrum-global-font-line-height-large, 1.7);
  --spectrum-alias-body-text-font-family: var(--spectrum-global-font-family-base, adobe-clean, "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Trebuchet MS", "Lucida Grande", sans-serif);
  --spectrum-alias-body-text-line-height: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-body-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-body-text-font-weight-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-button-text-line-height: var(--spectrum-global-font-line-height-small, 1.3);
  --spectrum-alias-heading-han-text-line-height: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-heading-text-line-height: var(--spectrum-global-font-line-height-small, 1.3);
  --spectrum-alias-heading-text-font-weight-regular: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-heading-text-font-weight-regular-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-heading-text-font-weight-quiet: var(--spectrum-global-font-weight-light, 300);
  --spectrum-alias-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-heading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-heading-text-font-weight-strong-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-subheading-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-detail-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-detail-text-font-weight-light: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-detail-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-serif-text-font-family: var(--spectrum-global-font-family-serif, adobe-clean-serif, "Source Serif Pro", Georgia, serif);
  --spectrum-alias-article-text-font-family: var(--spectrum-global-font-family-serif, adobe-clean-serif, "Source Serif Pro", Georgia, serif);
  --spectrum-alias-article-body-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-article-body-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-article-heading-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-article-heading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-article-heading-text-font-weight-quiet: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-article-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-article-subheading-text-font-weight: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-article-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-article-detail-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-article-detail-text-font-weight-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-code-text-font-family: var(--spectrum-global-font-family-code, "Source Code Pro", Monaco, monospace);
  --spectrum-alias-han-heading-text-font-weight-regular: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-heading-text-font-weight-regular-emphasis: var(--spectrum-global-font-weight-extra-bold, 800);
  --spectrum-alias-han-heading-text-font-weight-regular-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-quiet: var(--spectrum-global-font-weight-light, 300);
  --spectrum-alias-han-heading-text-font-weight-quiet-emphasis: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-heading-text-font-weight-quiet-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-heading-text-font-weight-light: var(--spectrum-global-font-weight-light, 300);
  --spectrum-alias-han-heading-text-font-weight-light-emphasis: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-heading-text-font-weight-light-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-heading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-strong-emphasis: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-strong-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-heavy: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-heavy-emphasis: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-heading-text-font-weight-heavy-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-body-text-font-weight-regular: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-body-text-font-weight-emphasis: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-body-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-subheading-text-font-weight-regular: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-subheading-text-font-weight-emphasis: var(--spectrum-global-font-weight-extra-bold, 800);
  --spectrum-alias-han-subheading-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-han-detail-text-font-weight: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-han-detail-text-font-weight-emphasis: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-han-detail-text-font-weight-strong: var(--spectrum-global-font-weight-black, 900);
  --spectrum-alias-code-text-font-weight-regular: var(--spectrum-global-font-weight-regular, 400);
  --spectrum-alias-code-text-font-weight-strong: var(--spectrum-global-font-weight-bold, 700);
  --spectrum-alias-code-text-line-height: var(--spectrum-global-font-line-height-medium, 1.5);
  --spectrum-alias-heading-margin-bottom: var(--spectrum-global-font-multiplier-25, .25em);
  --spectrum-alias-body-margin-bottom: var(--spectrum-global-font-multiplier-75, .75em);
  --spectrum-alias-focus-ring-gap: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-focus-ring-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-alias-loupe-entry-animation-duration: var(--spectrum-global-animation-duration-300, 190ms);
  --spectrum-alias-loupe-exit-animation-duration: var(--spectrum-global-animation-duration-300, 190ms);
}

.spectrum--medium_9e130c, .spectrum--large_9e130c {
  --spectrum-alias-dropshadow-blur: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-dropshadow-offset-y: var(--spectrum-global-dimension-size-10);
  --spectrum-alias-font-size-default: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-line-height-small: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-line-height-medium: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-line-height-large: var(--spectrum-global-dimension-size-300);
  --spectrum-alias-line-height-xlarge: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-line-height-xxlarge: var(--spectrum-global-dimension-size-600);
  --spectrum-alias-layout-label-gap-size: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-pill-button-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-pill-button-text-baseline: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-alias-border-radius-xsmall: var(--spectrum-global-dimension-size-10);
  --spectrum-alias-border-radius-small: var(--spectrum-global-dimension-size-25);
  --spectrum-alias-border-radius-regular: var(--spectrum-global-dimension-size-50);
  --spectrum-alias-border-radius-medium: var(--spectrum-global-dimension-size-100);
  --spectrum-alias-border-radius-large: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-single-line-height: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-single-line-width: var(--spectrum-global-dimension-size-2400);
  --spectrum-alias-workflow-icon-size: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-heading-display1-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-xxxl-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-han-display1-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-han-xxxl-text-size: var(--spectrum-global-dimension-font-size-1300);
  --spectrum-alias-heading-han-display1-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-han-xxxl-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-display1-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-xxxl-margin-top: var(--spectrum-global-dimension-font-size-1200);
  --spectrum-alias-heading-display2-text-size: var(--spectrum-global-dimension-font-size-1100);
  --spectrum-alias-heading-xxl-text-size: var(--spectrum-global-dimension-font-size-1100);
  --spectrum-alias-heading-display2-margin-top: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-xxl-margin-top: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-han-display2-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-han-xxl-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-han-display2-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-han-xxl-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading1-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading-xl-text-size: var(--spectrum-global-dimension-font-size-900);
  --spectrum-alias-heading1-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-xl-margin-top: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading1-han-text-size: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading-han-xl-text-size: var(--spectrum-global-dimension-font-size-800);
  --spectrum-alias-heading1-han-margin-top: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading-han-xl-margin-top: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading2-text-size: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading-l-text-size: var(--spectrum-global-dimension-font-size-700);
  --spectrum-alias-heading2-margin-top: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading-l-margin-top: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading2-han-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading-han-l-text-size: var(--spectrum-global-dimension-font-size-600);
  --spectrum-alias-heading2-han-margin-top: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading-han-l-margin-top: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading3-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading-m-text-size: var(--spectrum-global-dimension-font-size-500);
  --spectrum-alias-heading3-margin-top: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading-m-margin-top: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading3-han-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading-han-m-text-size: var(--spectrum-global-dimension-font-size-400);
  --spectrum-alias-heading3-han-margin-top: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading-han-m-margin-top: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading4-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading-s-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-alias-heading4-margin-top: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading-s-margin-top: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading5-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading-xs-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-alias-heading5-margin-top: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading-xs-margin-top: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading6-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading-xxs-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-alias-heading6-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-alias-heading-xxs-margin-top: var(--spectrum-global-dimension-font-size-75);
  --spectrum-alias-avatar-size-50: var(--spectrum-global-dimension-size-200);
  --spectrum-alias-avatar-size-75: var(--spectrum-global-dimension-size-225);
  --spectrum-alias-avatar-size-200: var(--spectrum-global-dimension-size-275);
  --spectrum-alias-avatar-size-300: var(--spectrum-global-dimension-size-325);
  --spectrum-alias-avatar-size-500: var(--spectrum-global-dimension-size-400);
  --spectrum-alias-avatar-size-700: var(--spectrum-global-dimension-size-500);
}

.spectrum--darkest_9e130c, .spectrum--dark_9e130c, .spectrum--light_9e130c, .spectrum--lightest_9e130c {
  --spectrum-alias-background-color-default: var(--spectrum-global-color-gray-100);
  --spectrum-alias-background-color-transparent: transparent;
  --spectrum-alias-background-color-label-gray: #707070;
  --spectrum-alias-background-color-quickactions-overlay: #0003;
  --spectrum-alias-placeholder-text-color: var(--spectrum-global-color-gray-600);
  --spectrum-alias-placeholder-text-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-alias-placeholder-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-placeholder-text-color-selected: var(--spectrum-global-color-gray-800);
  --spectrum-alias-label-text-color: var(--spectrum-global-color-gray-700);
  --spectrum-alias-text-color: var(--spectrum-global-color-gray-800);
  --spectrum-alias-text-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-alias-text-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-text-color-key-focus: var(--spectrum-global-color-blue-600);
  --spectrum-alias-text-color-mouse-focus: var(--spectrum-global-color-blue-600);
  --spectrum-alias-text-color-disabled: var(--spectrum-global-color-gray-500);
  --spectrum-alias-text-color-invalid: var(--spectrum-global-color-red-500);
  --spectrum-alias-text-color-selected: var(--spectrum-global-color-blue-600);
  --spectrum-alias-text-color-selected-neutral: var(--spectrum-global-color-gray-900);
  --spectrum-alias-title-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-alias-heading-text-color: var(--spectrum-global-color-gray-900);
  --spectrum-alias-border-color: var(--spectrum-global-color-gray-400);
  --spectrum-alias-border-color-hover: var(--spectrum-global-color-gray-500);
  --spectrum-alias-border-color-down: var(--spectrum-global-color-gray-500);
  --spectrum-alias-border-color-focus: var(--spectrum-global-color-blue-400);
  --spectrum-alias-border-color-mouse-focus: var(--spectrum-global-color-blue-500);
  --spectrum-alias-border-color-disabled: var(--spectrum-global-color-gray-200);
  --spectrum-alias-border-color-extralight: var(--spectrum-global-color-gray-100);
  --spectrum-alias-border-color-light: var(--spectrum-global-color-gray-200);
  --spectrum-alias-border-color-mid: var(--spectrum-global-color-gray-300);
  --spectrum-alias-border-color-dark: var(--spectrum-global-color-gray-400);
  --spectrum-alias-border-color-transparent: transparent;
  --spectrum-alias-border-color-translucent-dark: #0000000d;
  --spectrum-alias-border-color-translucent-darker: #0000001a;
  --spectrum-alias-focus-color: var(--spectrum-global-color-blue-400);
  --spectrum-alias-focus-ring-color: var(--spectrum-alias-focus-color, var(--spectrum-global-color-blue-400));
  --spectrum-alias-track-color-default: var(--spectrum-global-color-gray-300);
  --spectrum-alias-track-color-disabled: var(--spectrum-global-color-gray-300);
  --spectrum-alias-track-color-over-background: #fff3;
  --spectrum-alias-icon-color: var(--spectrum-global-color-gray-700);
  --spectrum-alias-icon-color-over-background: var(--spectrum-global-color-static-white, #fff);
  --spectrum-alias-icon-color-hover: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-down: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-focus: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-disabled: var(--spectrum-global-color-gray-400);
  --spectrum-alias-icon-color-selected-neutral: var(--spectrum-global-color-gray-900);
  --spectrum-alias-icon-color-selected: var(--spectrum-global-color-blue-500);
  --spectrum-alias-icon-color-selected-hover: var(--spectrum-global-color-blue-600);
  --spectrum-alias-icon-color-selected-down: var(--spectrum-global-color-blue-700);
  --spectrum-alias-icon-color-selected-focus: var(--spectrum-global-color-blue-600);
  --spectrum-alias-icon-color-error: var(--spectrum-global-color-red-400);
  --spectrum-alias-toolbar-background-color: var(--spectrum-global-color-gray-100);
  --spectrum-alias-colorhandle-outer-border-color: #0000006b;
  --spectrum-alias-categorical-color-1: var(--spectrum-global-color-static-seafoam-200, #26c0c7);
  --spectrum-alias-categorical-color-2: var(--spectrum-global-color-static-indigo-700, #5151d3);
  --spectrum-alias-categorical-color-3: var(--spectrum-global-color-static-orange-500, #e68619);
  --spectrum-alias-categorical-color-4: var(--spectrum-global-color-static-magenta-500, #d83790);
  --spectrum-alias-categorical-color-5: var(--spectrum-global-color-static-indigo-200, #9090fa);
  --spectrum-alias-categorical-color-6: var(--spectrum-global-color-static-celery-200, #58e06f);
  --spectrum-alias-categorical-color-7: var(--spectrum-global-color-static-blue-500, #2680eb);
  --spectrum-alias-categorical-color-8: var(--spectrum-global-color-static-purple-800, #6f38b1);
  --spectrum-alias-categorical-color-9: var(--spectrum-global-color-static-yellow-500, #dfbf00);
  --spectrum-alias-categorical-color-10: var(--spectrum-global-color-static-orange-700, #cb6f10);
  --spectrum-alias-categorical-color-11: var(--spectrum-global-color-static-green-600, #268e6c);
  --spectrum-alias-categorical-color-12: var(--spectrum-global-color-static-chartreuse-300, #9bec54);
  --spectrum-alias-categorical-color-13: var(--spectrum-global-color-static-blue-200, #5aa9fa);
  --spectrum-alias-categorical-color-14: var(--spectrum-global-color-static-fuchsia-500, #c038cc);
  --spectrum-alias-categorical-color-15: var(--spectrum-global-color-static-magenta-200, #f56bb7);
  --spectrum-alias-categorical-color-16: var(--spectrum-global-color-static-yellow-200, #ffe22e);
}

.spectrum--large_c40598 {
  --spectrum-global-dimension-scale-factor: 1.25;
  --spectrum-global-dimension-size-0: 0px;
  --spectrum-global-dimension-size-10: 1px;
  --spectrum-global-dimension-size-25: 2px;
  --spectrum-global-dimension-size-40: 4px;
  --spectrum-global-dimension-size-50: 5px;
  --spectrum-global-dimension-size-65: 6px;
  --spectrum-global-dimension-size-75: 8px;
  --spectrum-global-dimension-size-85: 9px;
  --spectrum-global-dimension-size-100: 10px;
  --spectrum-global-dimension-size-115: 11px;
  --spectrum-global-dimension-size-125: 13px;
  --spectrum-global-dimension-size-130: 14px;
  --spectrum-global-dimension-size-150: 15px;
  --spectrum-global-dimension-size-160: 16px;
  --spectrum-global-dimension-size-175: 18px;
  --spectrum-global-dimension-size-200: 20px;
  --spectrum-global-dimension-size-225: 22px;
  --spectrum-global-dimension-size-250: 25px;
  --spectrum-global-dimension-size-275: 28px;
  --spectrum-global-dimension-size-300: 30px;
  --spectrum-global-dimension-size-325: 32px;
  --spectrum-global-dimension-size-350: 35px;
  --spectrum-global-dimension-size-400: 40px;
  --spectrum-global-dimension-size-450: 45px;
  --spectrum-global-dimension-size-500: 50px;
  --spectrum-global-dimension-size-550: 56px;
  --spectrum-global-dimension-size-600: 60px;
  --spectrum-global-dimension-size-675: 68px;
  --spectrum-global-dimension-size-700: 70px;
  --spectrum-global-dimension-size-800: 80px;
  --spectrum-global-dimension-size-900: 90px;
  --spectrum-global-dimension-size-1000: 100px;
  --spectrum-global-dimension-size-1200: 120px;
  --spectrum-global-dimension-size-1250: 125px;
  --spectrum-global-dimension-size-1600: 160px;
  --spectrum-global-dimension-size-1700: 170px;
  --spectrum-global-dimension-size-2000: 200px;
  --spectrum-global-dimension-size-2400: 240px;
  --spectrum-global-dimension-size-3000: 300px;
  --spectrum-global-dimension-size-3400: 340px;
  --spectrum-global-dimension-size-3600: 360px;
  --spectrum-global-dimension-size-4600: 460px;
  --spectrum-global-dimension-size-5000: 500px;
  --spectrum-global-dimension-size-6000: 600px;
  --spectrum-global-dimension-font-size-25: 12px;
  --spectrum-global-dimension-font-size-50: 13px;
  --spectrum-global-dimension-font-size-75: 15px;
  --spectrum-global-dimension-font-size-100: 17px;
  --spectrum-global-dimension-font-size-150: 18px;
  --spectrum-global-dimension-font-size-200: 19px;
  --spectrum-global-dimension-font-size-300: 22px;
  --spectrum-global-dimension-font-size-400: 24px;
  --spectrum-global-dimension-font-size-500: 27px;
  --spectrum-global-dimension-font-size-600: 31px;
  --spectrum-global-dimension-font-size-700: 34px;
  --spectrum-global-dimension-font-size-800: 39px;
  --spectrum-global-dimension-font-size-900: 44px;
  --spectrum-global-dimension-font-size-1000: 49px;
  --spectrum-global-dimension-font-size-1100: 55px;
  --spectrum-global-dimension-font-size-1200: 62px;
  --spectrum-global-dimension-font-size-1300: 70px;
  --spectrum-alias-avatar-size-100: 26px;
  --spectrum-alias-avatar-size-400: 36px;
  --spectrum-alias-avatar-size-600: 46px;
  --spectrum-actionbutton-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-barloader-large-border-radius: 4px;
  --spectrum-barloader-large-indeterminate-border-radius: 4px;
  --spectrum-barloader-large-over-background-border-radius: 4px;
  --spectrum-barloader-small-border-radius: 3px;
  --spectrum-barloader-small-indeterminate-border-radius: 3px;
  --spectrum-barloader-small-over-background-border-radius: 3px;
  --spectrum-breadcrumb-compact-item-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-compact-button-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-item-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-button-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-multiline-item-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-breadcrumb-multiline-button-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-cta-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-cta-min-width: 90px;
  --spectrum-button-cta-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-cta-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-over-background-min-width: 90px;
  --spectrum-button-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-primary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-primary-min-width: 90px;
  --spectrum-button-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-over-background-min-width: 90px;
  --spectrum-button-quiet-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-over-background-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-primary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-primary-min-width: 90px;
  --spectrum-button-quiet-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-primary-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-secondary-min-width: 90px;
  --spectrum-button-quiet-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-secondary-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-warning-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-quiet-warning-min-width: 90px;
  --spectrum-button-quiet-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-quiet-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-quiet-warning-cursor-hit-x: var(--spectrum-global-dimension-static-size-250, 20px);
  --spectrum-button-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-secondary-min-width: 90px;
  --spectrum-button-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-warning-text-padding-bottom: var(--spectrum-global-dimension-size-100);
  --spectrum-button-warning-min-width: 90px;
  --spectrum-button-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-button-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-checkbox-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-checkbox-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-checkbox-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-checkbox-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-circleloader-medium-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-circleloader-medium-over-background-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-circleloader-small-border-size: 3px;
  --spectrum-circleloader-small-over-background-border-size: 3px;
  --spectrum-colorhandle-loupe-margin: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-colorloupe-colorhandle-gap: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-colorslider-touch-hit-y: var(--spectrum-global-dimension-size-85);
  --spectrum-colorslider-vertical-touch-hit-x: var(--spectrum-global-dimension-size-85);
  --spectrum-colorwheel-min-size: var(--spectrum-global-dimension-static-size-2600, 208px);
  --spectrum-colorwheel-touch-hit-outer: var(--spectrum-global-dimension-size-85);
  --spectrum-colorwheel-touch-hit-inner: var(--spectrum-global-dimension-size-85);
  --spectrum-cyclebutton-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-cyclebutton-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dialog-confirm-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-confirm-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-confirm-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-confirm-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-confirm-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-content-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-content-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-destructive-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-destructive-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-destructive-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-destructive-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-destructive-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-error-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-error-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-error-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-error-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-error-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-info-max-width: var(--spectrum-global-dimension-static-size-5000, 400px);
  --spectrum-dialog-info-title-text-size: var(--spectrum-global-dimension-font-size-200);
  --spectrum-dialog-info-description-text-size: var(--spectrum-global-dimension-font-size-75);
  --spectrum-dialog-info-padding: var(--spectrum-global-dimension-static-size-300, 24px);
  --spectrum-dialog-info-description-margin-bottom: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dropdown-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-dropdown-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-fieldbutton-quiet-min-width: var(--spectrum-global-dimension-size-200);
  --spectrum-icon-arrow-down-small-height: 12px;
  --spectrum-icon-arrow-left-medium-height: 12px;
  --spectrum-icon-checkmark-medium-width: 16px;
  --spectrum-icon-checkmark-medium-height: 16px;
  --spectrum-icon-checkmark-small-width: 12px;
  --spectrum-icon-checkmark-small-height: 12px;
  --spectrum-icon-chevron-down-medium-width: 12px;
  --spectrum-icon-chevron-left-large-width: 16px;
  --spectrum-icon-chevron-left-medium-height: 12px;
  --spectrum-icon-chevron-right-large-width: 16px;
  --spectrum-icon-chevron-right-medium-height: 12px;
  --spectrum-icon-cross-large-width: 16px;
  --spectrum-icon-cross-large-height: 16px;
  --spectrum-icon-dash-small-width: 12px;
  --spectrum-icon-dash-small-height: 12px;
  --spectrum-icon-skip-left-width: 10px;
  --spectrum-icon-skip-left-height: 12px;
  --spectrum-icon-skip-right-width: 10px;
  --spectrum-icon-skip-right-height: 12px;
  --spectrum-icon-triplegripper-width: 12px;
  --spectrum-listitem-option-icon-size: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-listitem-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-listitem-option-height: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-listitem-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-listitem-thumbnail-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-listitem-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-thumbnail-small-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-selectlist-thumbnail-small-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-option-icon-size: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-selectlist-option-icon-margin-top: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-selectlist-option-height: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-selectlist-option-padding-height: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-selectlist-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-loader-bar-large-border-radius: 4px;
  --spectrum-loader-bar-large-over-background-border-radius: 4px;
  --spectrum-loader-bar-small-border-radius: 3px;
  --spectrum-loader-bar-small-over-background-border-radius: 3px;
  --spectrum-loader-circle-medium-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-loader-circle-medium-over-background-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-loader-circle-small-border-size: 3px;
  --spectrum-loader-circle-small-over-background-border-size: 3px;
  --spectrum-meter-large-border-radius: 4px;
  --spectrum-meter-small-border-radius: 3px;
  --spectrum-pagination-page-button-line-height: 32px;
  --spectrum-overlay-positive-transform-distance: 8px;
  --spectrum-overlay-negative-transform-distance: -8px;
  --spectrum-picker-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-quiet-min-width: var(--spectrum-global-dimension-size-200);
  --spectrum-picker-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-picker-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-progressbar-large-border-radius: 4px;
  --spectrum-progressbar-large-indeterminate-border-radius: 4px;
  --spectrum-progressbar-large-over-background-border-radius: 4px;
  --spectrum-progressbar-small-border-radius: 3px;
  --spectrum-progressbar-small-indeterminate-border-radius: 3px;
  --spectrum-progressbar-small-over-background-border-radius: 3px;
  --spectrum-progresscircle-medium-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-progresscircle-medium-over-background-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-progresscircle-small-border-size: 3px;
  --spectrum-progresscircle-small-indeterminate-border-size: 3px;
  --spectrum-progresscircle-small-over-background-border-size: 3px;
  --spectrum-progresscircle-medium-indeterminate-border-size: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-radio-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-margin-bottom: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-radio-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-emphasized-margin-bottom: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-radio-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-radio-quiet-margin-bottom: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-rating-icon-width: 30px;
  --spectrum-rating-indicator-width: 20px;
  --spectrum-rating-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-rating-emphasized-icon-width: 30px;
  --spectrum-rating-emphasized-indicator-width: 20px;
  --spectrum-rating-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-rating-quiet-icon-width: 30px;
  --spectrum-rating-quiet-indicator-width: 20px;
  --spectrum-rating-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-search-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-search-quiet-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-sidenav-item-touch-hit-bottom: 3px;
  --spectrum-sidenav-multilevel-item-touch-hit-bottom: 3px;
  --spectrum-slider-track-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-slider-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-editable-track-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-slider-editable-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-editable-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-fill-track-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-slider-fill-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-slider-fill-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-175, 14px);
  --spectrum-switch-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-switch-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-track-width: var(--spectrum-global-dimension-static-size-450, 36px);
  --spectrum-switch-handle-border-radius: 9px;
  --spectrum-switch-handle-transform-x-ltr: 18px;
  --spectrum-switch-handle-transform-x-rtl: -18px;
  --spectrum-switch-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-switch-emphasized-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-switch-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-emphasized-track-width: var(--spectrum-global-dimension-static-size-450, 36px);
  --spectrum-switch-emphasized-handle-border-radius: 9px;
  --spectrum-switch-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-switch-quiet-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-160, 13px);
  --spectrum-switch-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-switch-quiet-track-width: var(--spectrum-global-dimension-static-size-450, 36px);
  --spectrum-switch-quiet-handle-border-radius: 9px;
  --spectrum-switch-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tabs-compact-focus-ring-border-radius: 6px;
  --spectrum-tabs-compact-margin-left: -11px;
  --spectrum-tabs-compact-margin-right: -11px;
  --spectrum-tabs-compact-vertical-focus-ring-border-radius: 6px;
  --spectrum-tabs-compact-vertical-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-focus-ring-border-radius: 6px;
  --spectrum-tabs-margin-left: -11px;
  --spectrum-tabs-margin-right: -11px;
  --spectrum-tabs-emphasized-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-emphasized-margin-left: -11px;
  --spectrum-tabs-emphasized-margin-right: -11px;
  --spectrum-tabs-quiet-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-margin-left: -11px;
  --spectrum-tabs-quiet-margin-right: -11px;
  --spectrum-tabs-quiet-compact-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-compact-margin-left: -11px;
  --spectrum-tabs-quiet-compact-margin-right: -11px;
  --spectrum-tabs-quiet-compact-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-compact-emphasized-margin-left: -11px;
  --spectrum-tabs-quiet-compact-emphasized-margin-right: -11px;
  --spectrum-tabs-quiet-compact-vertical-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-compact-vertical-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-emphasized-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-emphasized-margin-left: -11px;
  --spectrum-tabs-quiet-emphasized-margin-right: -11px;
  --spectrum-tabs-quiet-vertical-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-vertical-focus-ring-border-radius: 6px;
  --spectrum-tabs-quiet-vertical-emphasized-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-quiet-vertical-emphasized-focus-ring-border-radius: 6px;
  --spectrum-tabs-vertical-baseline: var(--spectrum-global-dimension-size-250);
  --spectrum-tabs-vertical-focus-ring-border-radius: 6px;
  --spectrum-textarea-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-textarea-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textarea-quiet-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-textfield-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-textfield-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-textfield-quiet-icon-frame: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-tool-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tool-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tool-high-emphasis-touch-hit-x: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tool-high-emphasis-touch-hit-y: var(--spectrum-global-dimension-static-size-50, 4px);
  --spectrum-tooltip-padding-bottom: 6px;
  --spectrum-tooltip-content-max-width: var(--spectrum-global-dimension-size-2000);
  --spectrum-tooltip-info-padding-bottom: 6px;
  --spectrum-tooltip-info-content-max-width: 126px;
  --spectrum-tooltip-negative-padding-bottom: 6px;
  --spectrum-tooltip-negative-content-max-width: 126px;
  --spectrum-tooltip-positive-padding-bottom: 6px;
  --spectrum-tooltip-positive-content-max-width: 126px;
}

.spectrum--light_a40724 {
  --spectrum-global-color-status: Verified;
  --spectrum-global-color-version: 5.1;
  --spectrum-global-color-celery-400: #44b556;
  --spectrum-global-color-celery-500: #3da74e;
  --spectrum-global-color-celery-600: #379947;
  --spectrum-global-color-celery-700: #318b40;
  --spectrum-global-color-chartreuse-400: #85d044;
  --spectrum-global-color-chartreuse-500: #7cc33f;
  --spectrum-global-color-chartreuse-600: #73b53a;
  --spectrum-global-color-chartreuse-700: #6aa834;
  --spectrum-global-color-yellow-400: #dfbf00;
  --spectrum-global-color-yellow-500: #d2b200;
  --spectrum-global-color-yellow-600: #c4a600;
  --spectrum-global-color-yellow-700: #b79900;
  --spectrum-global-color-magenta-400: #d83790;
  --spectrum-global-color-magenta-500: #ce2783;
  --spectrum-global-color-magenta-600: #bc1c74;
  --spectrum-global-color-magenta-700: #ae0e66;
  --spectrum-global-color-fuchsia-400: #c038cc;
  --spectrum-global-color-fuchsia-500: #b130bd;
  --spectrum-global-color-fuchsia-600: #a228ad;
  --spectrum-global-color-fuchsia-700: #93219e;
  --spectrum-global-color-purple-400: #9256d9;
  --spectrum-global-color-purple-500: #864ccc;
  --spectrum-global-color-purple-600: #7a42bf;
  --spectrum-global-color-purple-700: #6f38b1;
  --spectrum-global-color-indigo-400: #6767ec;
  --spectrum-global-color-indigo-500: #5c5ce0;
  --spectrum-global-color-indigo-600: #5151d3;
  --spectrum-global-color-indigo-700: #4646c6;
  --spectrum-global-color-seafoam-400: #1b959a;
  --spectrum-global-color-seafoam-500: #16878c;
  --spectrum-global-color-seafoam-600: #0f797d;
  --spectrum-global-color-seafoam-700: #096c6f;
  --spectrum-global-color-red-400: #e34850;
  --spectrum-global-color-red-500: #d7373f;
  --spectrum-global-color-red-600: #c9252d;
  --spectrum-global-color-red-700: #bb121a;
  --spectrum-global-color-orange-400: #e68619;
  --spectrum-global-color-orange-500: #da7b11;
  --spectrum-global-color-orange-600: #cb6f10;
  --spectrum-global-color-orange-700: #bd640d;
  --spectrum-global-color-green-400: #2d9d78;
  --spectrum-global-color-green-500: #268e6c;
  --spectrum-global-color-green-600: #12805c;
  --spectrum-global-color-green-700: #107154;
  --spectrum-global-color-blue-400: #2680eb;
  --spectrum-global-color-blue-500: #1473e6;
  --spectrum-global-color-blue-600: #0d66d0;
  --spectrum-global-color-blue-700: #095aba;
  --spectrum-global-color-gray-50: #fff;
  --spectrum-global-color-gray-75: #fafafa;
  --spectrum-global-color-gray-100: #f5f5f5;
  --spectrum-global-color-gray-200: #eaeaea;
  --spectrum-global-color-gray-300: #e1e1e1;
  --spectrum-global-color-gray-400: #cacaca;
  --spectrum-global-color-gray-500: #b3b3b3;
  --spectrum-global-color-gray-600: #8e8e8e;
  --spectrum-global-color-gray-700: #6e6e6e;
  --spectrum-global-color-gray-800: #4b4b4b;
  --spectrum-global-color-gray-900: #2c2c2c;
  --spectrum-alias-background-color-modal-overlay: #0006;
  --spectrum-alias-dropshadow-color: #00000026;
  --spectrum-alias-background-color-hover-overlay: #2c2c2c0a;
  --spectrum-alias-highlight-hover: #2c2c2c0f;
  --spectrum-alias-highlight-active: #2c2c2c1a;
  --spectrum-alias-highlight-selected: #1473e61a;
  --spectrum-alias-highlight-selected-hover: #1473e633;
  --spectrum-alias-text-highlight-color: #1473e633;
  --spectrum-alias-background-color-quickactions: #f5f5f5e6;
  --spectrum-alias-radial-reaction-color-default: #4b4b4b99;
  --spectrum-alias-pasteboard-background-color: var(--spectrum-global-color-gray-300);
  --spectrum-alias-appframe-border-color: var(--spectrum-global-color-gray-300);
  --spectrum-alias-appframe-separator-color: var(--spectrum-global-color-gray-300);
  --spectrum-colorarea-border-color: #2c2c2c1a;
  --spectrum-colorarea-border-color-hover: #2c2c2c1a;
  --spectrum-colorarea-border-color-down: #2c2c2c1a;
  --spectrum-colorarea-border-color-key-focus: #2c2c2c1a;
  --spectrum-colorslider-border-color: #2c2c2c1a;
  --spectrum-colorslider-border-color-hover: #2c2c2c1a;
  --spectrum-colorslider-border-color-down: #2c2c2c1a;
  --spectrum-colorslider-border-color-key-focus: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color-hover: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color-down: #2c2c2c1a;
  --spectrum-colorslider-vertical-border-color-key-focus: #2c2c2c1a;
  --spectrum-colorwheel-border-color: #2c2c2c1a;
  --spectrum-colorwheel-border-color-hover: #2c2c2c1a;
  --spectrum-colorwheel-border-color-down: #2c2c2c1a;
  --spectrum-colorwheel-border-color-key-focus: #2c2c2c1a;
  --spectrum-miller-column-item-background-color-selected: #1473e61a;
  --spectrum-miller-column-item-background-color-selected-hover: #1473e633;
  --spectrum-tabs-compact-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-compact-vertical-emphasized-rule-color: var(--spectrum-global-color-gray-200);
  --spectrum-tabs-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-compact-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-tabs-quiet-vertical-emphasized-selection-indicator-color: var(--spectrum-global-color-blue-500);
  --spectrum-well-background-color: #4b4b4b05;
  --spectrum-well-border-color: #2c2c2c0d;
  --spectrum-tray-background-color: var(--spectrum-global-color-gray-50);
  --react-spectrum-datepicker-placeholder-color: #767676;
}

.spectrum--medium_4b172c {
  --spectrum-global-dimension-scale-factor: 1;
  --spectrum-global-dimension-size-0: 0px;
  --spectrum-global-dimension-size-10: 1px;
  --spectrum-global-dimension-size-25: 2px;
  --spectrum-global-dimension-size-40: 3px;
  --spectrum-global-dimension-size-50: 4px;
  --spectrum-global-dimension-size-65: 5px;
  --spectrum-global-dimension-size-75: 6px;
  --spectrum-global-dimension-size-85: 7px;
  --spectrum-global-dimension-size-100: 8px;
  --spectrum-global-dimension-size-115: 9px;
  --spectrum-global-dimension-size-125: 10px;
  --spectrum-global-dimension-size-130: 11px;
  --spectrum-global-dimension-size-150: 12px;
  --spectrum-global-dimension-size-160: 13px;
  --spectrum-global-dimension-size-175: 14px;
  --spectrum-global-dimension-size-200: 16px;
  --spectrum-global-dimension-size-225: 18px;
  --spectrum-global-dimension-size-250: 20px;
  --spectrum-global-dimension-size-275: 22px;
  --spectrum-global-dimension-size-300: 24px;
  --spectrum-global-dimension-size-325: 26px;
  --spectrum-global-dimension-size-350: 28px;
  --spectrum-global-dimension-size-400: 32px;
  --spectrum-global-dimension-size-450: 36px;
  --spectrum-global-dimension-size-500: 40px;
  --spectrum-global-dimension-size-550: 44px;
  --spectrum-global-dimension-size-600: 48px;
  --spectrum-global-dimension-size-675: 54px;
  --spectrum-global-dimension-size-700: 56px;
  --spectrum-global-dimension-size-800: 64px;
  --spectrum-global-dimension-size-900: 72px;
  --spectrum-global-dimension-size-1000: 80px;
  --spectrum-global-dimension-size-1200: 96px;
  --spectrum-global-dimension-size-1250: 100px;
  --spectrum-global-dimension-size-1600: 128px;
  --spectrum-global-dimension-size-1700: 136px;
  --spectrum-global-dimension-size-2000: 160px;
  --spectrum-global-dimension-size-2400: 192px;
  --spectrum-global-dimension-size-3000: 240px;
  --spectrum-global-dimension-size-3400: 272px;
  --spectrum-global-dimension-size-3600: 288px;
  --spectrum-global-dimension-size-4600: 368px;
  --spectrum-global-dimension-size-5000: 400px;
  --spectrum-global-dimension-size-6000: 480px;
  --spectrum-global-dimension-font-size-25: 10px;
  --spectrum-global-dimension-font-size-50: 11px;
  --spectrum-global-dimension-font-size-75: 12px;
  --spectrum-global-dimension-font-size-100: 14px;
  --spectrum-global-dimension-font-size-150: 15px;
  --spectrum-global-dimension-font-size-200: 16px;
  --spectrum-global-dimension-font-size-300: 18px;
  --spectrum-global-dimension-font-size-400: 20px;
  --spectrum-global-dimension-font-size-500: 22px;
  --spectrum-global-dimension-font-size-600: 25px;
  --spectrum-global-dimension-font-size-700: 28px;
  --spectrum-global-dimension-font-size-800: 32px;
  --spectrum-global-dimension-font-size-900: 36px;
  --spectrum-global-dimension-font-size-1000: 40px;
  --spectrum-global-dimension-font-size-1100: 45px;
  --spectrum-global-dimension-font-size-1200: 50px;
  --spectrum-global-dimension-font-size-1300: 60px;
  --spectrum-alias-avatar-size-100: var(--spectrum-global-dimension-size-250);
  --spectrum-alias-avatar-size-400: var(--spectrum-global-dimension-size-350);
  --spectrum-alias-avatar-size-600: var(--spectrum-global-dimension-size-450);
  --spectrum-actionbutton-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-actionbutton-quiet-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-barloader-large-border-radius: 3px;
  --spectrum-barloader-large-indeterminate-border-radius: 3px;
  --spectrum-barloader-large-over-background-border-radius: 3px;
  --spectrum-barloader-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-barloader-small-indeterminate-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-barloader-small-over-background-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-breadcrumb-compact-item-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-compact-button-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-item-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-button-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-multiline-item-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-breadcrumb-multiline-button-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-cta-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-cta-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-cta-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-cta-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-over-background-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-primary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-primary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-over-background-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-over-background-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-over-background-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-over-background-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-over-background-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-primary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-primary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-primary-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-primary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-primary-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-secondary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-secondary-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-warning-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-quiet-warning-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-quiet-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-quiet-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-quiet-warning-cursor-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-button-secondary-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-secondary-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-secondary-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-secondary-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-warning-text-padding-bottom: var(--spectrum-global-dimension-size-85);
  --spectrum-button-warning-min-width: var(--spectrum-global-dimension-size-900);
  --spectrum-button-warning-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-button-warning-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-checkbox-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-checkbox-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-checkbox-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-text-gap-error-indeterminate-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-checkbox-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-circleloader-medium-border-size: 3px;
  --spectrum-circleloader-medium-over-background-border-size: 3px;
  --spectrum-circleloader-small-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-circleloader-small-over-background-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-colorhandle-loupe-margin: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-colorloupe-colorhandle-gap: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-colorslider-touch-hit-y: var(--spectrum-global-dimension-size-150);
  --spectrum-colorslider-vertical-touch-hit-x: var(--spectrum-global-dimension-size-150);
  --spectrum-colorwheel-min-size: var(--spectrum-global-dimension-size-2400);
  --spectrum-colorwheel-touch-hit-outer: var(--spectrum-global-dimension-size-150);
  --spectrum-colorwheel-touch-hit-inner: var(--spectrum-global-dimension-size-150);
  --spectrum-cyclebutton-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-cyclebutton-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dialog-confirm-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-confirm-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-confirm-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-confirm-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-confirm-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-content-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-content-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-destructive-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-destructive-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-destructive-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-destructive-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-destructive-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-error-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-error-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-error-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-error-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-error-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dialog-info-max-width: var(--spectrum-global-dimension-static-size-6000, 480px);
  --spectrum-dialog-info-title-text-size: var(--spectrum-global-dimension-font-size-300);
  --spectrum-dialog-info-description-text-size: var(--spectrum-global-dimension-font-size-100);
  --spectrum-dialog-info-padding: var(--spectrum-global-dimension-static-size-500, 40px);
  --spectrum-dialog-info-description-margin-bottom: var(--spectrum-global-dimension-static-size-600, 48px);
  --spectrum-dropdown-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dropdown-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dropdown-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-dropdown-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-dropdown-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-fieldbutton-quiet-min-width: var(--spectrum-global-dimension-size-225);
  --spectrum-icon-arrow-down-small-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-arrow-left-medium-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-checkmark-medium-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-checkmark-medium-height: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-checkmark-small-width: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-checkmark-small-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-chevron-down-medium-width: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-chevron-left-large-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-chevron-left-medium-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-chevron-right-large-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-chevron-right-medium-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-cross-large-width: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-cross-large-height: var(--spectrum-global-dimension-size-150);
  --spectrum-icon-dash-small-width: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-dash-small-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-skip-left-width: 9px;
  --spectrum-icon-skip-left-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-skip-right-width: 9px;
  --spectrum-icon-skip-right-height: var(--spectrum-global-dimension-size-125);
  --spectrum-icon-triplegripper-width: var(--spectrum-global-dimension-size-125);
  --spectrum-listitem-option-icon-size: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-listitem-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-listitem-option-height: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-listitem-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-listitem-thumbnail-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-listitem-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-selectlist-thumbnail-small-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-selectlist-thumbnail-small-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-selectlist-option-icon-size: var(--spectrum-global-dimension-static-size-150, 12px);
  --spectrum-selectlist-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-selectlist-option-icon-margin-top: var(--spectrum-global-dimension-static-size-65, 5px);
  --spectrum-selectlist-option-height: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-selectlist-option-padding-height: 7px;
  --spectrum-selectlist-thumbnail-option-icon-padding-y: var(--spectrum-global-dimension-static-size-125, 10px);
  --spectrum-loader-bar-large-border-radius: 3px;
  --spectrum-loader-bar-large-over-background-border-radius: 3px;
  --spectrum-loader-bar-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-loader-bar-small-over-background-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-loader-circle-medium-border-size: 3px;
  --spectrum-loader-circle-medium-over-background-border-size: 3px;
  --spectrum-loader-circle-small-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-loader-circle-small-over-background-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-meter-large-border-radius: 3px;
  --spectrum-meter-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-pagination-page-button-line-height: 26px;
  --spectrum-overlay-positive-transform-distance: 6px;
  --spectrum-overlay-negative-transform-distance: -6px;
  --spectrum-picker-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-picker-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-picker-quiet-min-width: var(--spectrum-global-dimension-size-225);
  --spectrum-picker-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-picker-thumbnail-small-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-picker-thumbnail-small-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-progressbar-large-border-radius: 3px;
  --spectrum-progressbar-large-indeterminate-border-radius: 3px;
  --spectrum-progressbar-large-over-background-border-radius: 3px;
  --spectrum-progressbar-small-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progressbar-small-indeterminate-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progressbar-small-over-background-border-radius: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-medium-border-size: 3px;
  --spectrum-progresscircle-medium-over-background-border-size: 3px;
  --spectrum-progresscircle-small-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-small-indeterminate-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-small-over-background-border-size: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-progresscircle-medium-indeterminate-border-size: 3px;
  --spectrum-radio-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-margin-bottom: 0px;
  --spectrum-radio-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-emphasized-margin-bottom: 0px;
  --spectrum-radio-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-radio-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-radio-quiet-margin-bottom: 0px;
  --spectrum-radio-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-rating-icon-width: 24px;
  --spectrum-rating-indicator-width: 16px;
  --spectrum-rating-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-rating-emphasized-icon-width: 24px;
  --spectrum-rating-emphasized-indicator-width: 16px;
  --spectrum-rating-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-rating-quiet-icon-width: 24px;
  --spectrum-rating-quiet-indicator-width: 16px;
  --spectrum-rating-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-search-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-search-quiet-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-sidenav-item-touch-hit-bottom: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-sidenav-multilevel-item-touch-hit-bottom: var(--spectrum-global-dimension-static-size-25, 2px);
  --spectrum-slider-track-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-slider-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-editable-track-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-slider-editable-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-editable-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-fill-track-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-slider-fill-handle-touch-hit-x: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-slider-fill-handle-touch-hit-y: var(--spectrum-global-dimension-static-size-200, 16px);
  --spectrum-switch-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-130, 11px);
  --spectrum-switch-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-track-width: 26px;
  --spectrum-switch-handle-border-radius: 7px;
  --spectrum-switch-handle-transform-x-ltr: 12px;
  --spectrum-switch-handle-transform-x-rtl: -12px;
  --spectrum-switch-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-switch-emphasized-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-130, 11px);
  --spectrum-switch-emphasized-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-emphasized-track-width: 26px;
  --spectrum-switch-emphasized-handle-border-radius: 7px;
  --spectrum-switch-emphasized-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-switch-quiet-focus-ring-border-radius-key-focus: var(--spectrum-global-dimension-static-size-130, 11px);
  --spectrum-switch-quiet-text-gap-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-text-gap-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-text-gap-error-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-text-gap-error-selected-key-focus: var(--spectrum-global-dimension-static-size-115, 9px);
  --spectrum-switch-quiet-track-width: 26px;
  --spectrum-switch-quiet-handle-border-radius: 7px;
  --spectrum-switch-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tabs-compact-focus-ring-border-radius: 5px;
  --spectrum-tabs-compact-margin-left: -8px;
  --spectrum-tabs-compact-margin-right: -8px;
  --spectrum-tabs-compact-vertical-focus-ring-border-radius: 5px;
  --spectrum-tabs-compact-vertical-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-focus-ring-border-radius: 5px;
  --spectrum-tabs-margin-left: -8px;
  --spectrum-tabs-margin-right: -8px;
  --spectrum-tabs-emphasized-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-emphasized-margin-left: -8px;
  --spectrum-tabs-emphasized-margin-right: -8px;
  --spectrum-tabs-quiet-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-margin-left: -8px;
  --spectrum-tabs-quiet-margin-right: -8px;
  --spectrum-tabs-quiet-compact-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-compact-margin-left: -8px;
  --spectrum-tabs-quiet-compact-margin-right: -8px;
  --spectrum-tabs-quiet-compact-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-compact-emphasized-margin-left: -8px;
  --spectrum-tabs-quiet-compact-emphasized-margin-right: -8px;
  --spectrum-tabs-quiet-compact-vertical-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-compact-vertical-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-emphasized-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-emphasized-margin-left: -8px;
  --spectrum-tabs-quiet-emphasized-margin-right: -8px;
  --spectrum-tabs-quiet-vertical-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-vertical-focus-ring-border-radius: 5px;
  --spectrum-tabs-quiet-vertical-emphasized-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-quiet-vertical-emphasized-focus-ring-border-radius: 5px;
  --spectrum-tabs-vertical-baseline: var(--spectrum-global-dimension-size-225);
  --spectrum-tabs-vertical-focus-ring-border-radius: 5px;
  --spectrum-textarea-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-textarea-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textarea-quiet-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-textfield-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-textfield-quiet-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-quiet-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-textfield-quiet-icon-frame: var(--spectrum-global-dimension-static-size-400, 32px);
  --spectrum-tool-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tool-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tool-high-emphasis-touch-hit-x: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tool-high-emphasis-touch-hit-y: var(--spectrum-global-dimension-static-size-100, 8px);
  --spectrum-tooltip-padding-bottom: 5px;
  --spectrum-tooltip-content-max-width: var(--spectrum-global-dimension-size-2000);
  --spectrum-tooltip-info-padding-bottom: 5px;
  --spectrum-tooltip-info-content-max-width: 101px;
  --spectrum-tooltip-negative-padding-bottom: 5px;
  --spectrum-tooltip-negative-content-max-width: 101px;
  --spectrum-tooltip-positive-padding-bottom: 5px;
  --spectrum-tooltip-positive-content-max-width: 101px;
}

._0io6xW_playbackControlsContainer {
  position: absolute;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
}

body.window-controls-overlay ._0io6xW_playbackControlsContainer {
  top: env(titlebar-area-height, 12px);
}

.Z_9upq_container {
  -webkit-user-select: none;
  user-select: none;
  justify-content: center;
  align-items: center;
  gap: 14px;
  display: flex;
}

.Z_9upq_actions {
  border-radius: 6px;
}

.Z_9upq_actions:hover {
  background: var(--spectrum-global-color-gray-100);
}

.Z_9upq_actions button {
  border-radius: inherit;
  border: 0;
  padding: 0 .6em;
  transition: none;
  color: var(--spectrum-global-color-gray-700) !important;
  background: none !important;
}

.Z_9upq_actions button svg {
  width: 1.5em;
  fill: currentColor;
}

.Z_9upq_actions button:active {
  background: var(--spectrum-global-color-gray-200) !important;
}

.Z_9upq_actions button.Z_9upq_play {
  padding-left: .7em;
}

.Z_9upq_actions button.Z_9upq_play[aria-pressed="true"] {
  color: #fff !important;
  background-color: #43883b !important;
  border-color: #43883b !important;
}

.Z_9upq_position {
  background: var(--spectrum-global-color-gray-200);
  border-radius: 6px;
  align-items: stretch;
  gap: 1px;
  display: flex;
  overflow: hidden;
}

.Z_9upq_position .Z_9upq_field {
  text-align: center;
  color: var(--spectrum-global-color-gray-900);
  background: var(--spectrum-global-color-gray-100);
  flex-direction: column-reverse;
  padding: 2px 14px;
  font-size: 18px;
  font-weight: 500;
  display: flex;
}

.Z_9upq_position .Z_9upq_field input {
  margin: auto;
}

.Z_9upq_position .Z_9upq_field .Z_9upq_fieldName {
  color: var(--spectrum-global-color-gray-700);
  text-transform: uppercase;
  font-size: 10px;
}

.Z_9upq_tempo {
  min-width: 3em;
}

._8MPW2W_input {
  color: inherit;
  font: inherit;
  text-align: center;
  cursor: row-resize;
  appearance: textfield;
  background: none;
  border: 0;
}

._8MPW2W_input:active, ._8MPW2W_input:focus {
  color: var(--spectrum-global-color-gray-900, #fff);
  outline: none;
}

._8MPW2W_input::-webkit-outer-spin-button, ._8MPW2W_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body:has(._8MPW2W_input:active) * {
  cursor: row-resize !important;
}

.RFs23q_button {
  color: var(--pink);
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  gap: 8px;
  padding: 6px;
  font-weight: 500;
  text-decoration: none;
  display: flex;
}

.RFs23q_button:active {
  transform: translateY(1px);
}

.RFs23q_button:hover {
  text-decoration: underline;
}

.RFs23q_button:hover svg {
  fill: currentColor;
}

body.window-controls-overlay .RFs23q_button {
  display: none;
}

.Header {
  -webkit-app-region: drag;
}

.Header h1 {
  margin: 0;
}

.window-controls-overlay .Header h1 {
  display: none;
}

.Header h1, .Header h1 img {
  width: 24px;
  height: 24px;
  vertical-align: top;
}

.Header h2 {
  color: var(--spectrum-global-color-gray-900);
  -webkit-user-select: none;
  user-select: none;
  font-size: 12px;
  font-weight: 400;
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.window-controls-overlay .Header h2 {
  display: block;
}

._9il3RW_container {
  height: 100%;
  display: grid;
  overflow: hidden;
}

.yXlQQq_table {
  border-collapse: collapse;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
}

.yXlQQq_table td {
  padding: 0;
}

.yXlQQq_track {
  height: 70px;
}

.yXlQQq_track:nth-child(2n) {
  background: var(--spectrum-global-color-gray-75);
}

td.yXlQQq_trackHead {
  width: 8em;
  border-right: 1px solid var(--spectrum-global-color-gray-100);
  height: 100%;
  padding: 4px 16px;
}

td.yXlQQq_trackHead .yXlQQq_trackName {
  color: var(--spectrum-global-color-gray-900);
  margin-bottom: 4px;
}

td.yXlQQq_loop {
  color: #000;
  background: var(--yellow);
  padding: 0 4px;
  font-weight: 600;
}

.yXlQQq_pianoRollThumbnail {
  --color-800: #fffa;
  --color-500: var(--spectrum-global-color-blue-500);
  --color-400: var(--spectrum-global-color-blue-400);
  --color-200: #000c;
  border: 1px solid var(--spectrum-global-color-gray-300);
  background: linear-gradient(var(--color-500) 0%, var(--color-400) 100%);
  color: #fffe;
  height: 70px;
  border-radius: 6px;
  overflow: hidden;
}

.yXlQQq_pianoRollThumbnail:focus-visible {
  outline: 3px solid var(--spectrum-global-color-yellow-500);
}

.yXlQQq_pianoRollThumbnail .yXlQQq_segmentName {
  padding: 6px 10px;
}

.yXlQQq_pianoRollThumbnail.yXlQQq_selected {
  border-color: var(--color-800);
}

.yXlQQq_pianoRollThumbnail.yXlQQq_selected .yXlQQq_segmentName {
  color: var(--color-200);
  background: var(--color-800);
}

.yXlQQq_pianoRollThumbnail.yXlQQq_hasInterestingParentTrack {
  --color-500: var(--spectrum-global-color-indigo-500);
  --color-400: var(--spectrum-global-color-indigo-400);
}

.yXlQQq_pianoRollThumbnail.yXlQQq_drumRegion {
  --color-500: var(--spectrum-global-color-seafoam-500);
  --color-400: var(--spectrum-global-color-seafoam-400);
}

.yXlQQq_pianoRollThumbnail.yXlQQq_disabledRegion {
  --color-800: var(--spectrum-global-color-gray-800);
  --color-500: var(--spectrum-global-color-gray-500);
  --color-400: var(--spectrum-global-color-gray-400);
  --color-200: var(--spectrum-global-color-gray-200);
}

.s4vNKG_controls {
  border: 1px solid var(--spectrum-global-color-gray-200);
  border-radius: 6px;
  display: inline-block;
}

.s4vNKG_controls button {
  --spectrum-actionbutton-border-size: 0;
  --spectrum-actionbutton-min-width: 1.7em;
  --spectrum-actionbutton-height: 1.5em;
  --spectrum-actionbutton-text-color: var(--spectrum-global-color-gray-700);
}

.s4vNKG_mute {
  --spectrum-actionbutton-background-color-selected: var(--spectrum-global-color-blue-400);
  --spectrum-actionbutton-background-color-selected-hover: var(--spectrum-global-color-blue-400);
  --spectrum-actionbutton-text-color-selected: white;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.s4vNKG_solo {
  --spectrum-actionbutton-background-color-selected: var(--spectrum-global-color-orange-400);
  --spectrum-actionbutton-background-color-selected-hover: var(--spectrum-global-color-orange-400);
  --spectrum-actionbutton-text-color-selected: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid var(--spectrum-global-color-gray-200) !important;
}

.sM1YTa_regionName {
  margin: 0;
}

._5GBmQa_container {
  height: 100%;
  overflow: overlay;
  background: var(--spectrum-global-color-gray-300);
  box-shadow: inset 0 0 16px var(--spectrum-global-color-gray-200);
}

._5GBmQa_container ul, ._5GBmQa_container li {
  margin: 0;
  padding: 0;
  list-style: none;
}

._5GBmQa_lineNumber {
  height: 27px;
  text-align: center;
  color: var(--spectrum-global-color-gray-500);
  -webkit-user-select: none;
  user-select: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 27px;
  display: inline-block;
  position: absolute;
}

._5GBmQa_command {
  height: 27px;
  color: #fffc;
  background: #636363;
  border: 2px solid #ffffff20;
  border-radius: 4px;
  padding: 2px 6px;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  display: inline-block;
  box-shadow: 0 1px 2px #00000040;
}

._5GBmQa_command._5GBmQa_control {
  background: #e68619;
}

._5GBmQa_command._5GBmQa_playback {
  background: #9256d9;
}

._5GBmQa_command._5GBmQa_master {
  background: #00a9e0;
}

._5GBmQa_command._5GBmQa_track {
  background: #b93ea6;
}

._5GBmQa_command._5GBmQa_seg {
  background: #22833f;
}

._5GBmQa_inputBox {
  color: #fffd;
  background: #00000020;
  border: 1px solid #00000020;
  border-radius: 3px;
  margin: 0 6px;
  padding: 0;
}

._5GBmQa_inputBox:focus {
  color: #0008;
}

._5GBmQa_inputBox:has(input[type="number"]) {
  border-radius: 99px;
}

.hSKY4q_actionButton {
  all: unset;
  cursor: pointer;
  background: unset !important;
}

.hSKY4q_actionButton:hover {
  background: #00000020 !important;
}

.Lu5GOa_input {
  color: inherit;
  font: inherit;
  text-align: center;
  cursor: text;
  background: none;
  border: 0;
}

.Lu5GOa_input:active, .Lu5GOa_input:focus {
  color: #fff;
  outline: none;
}

._2DKHnq_input {
  color: inherit;
  font: inherit;
  text-align: center;
  cursor: text;
  background: none;
  border: 0;
}

._2DKHnq_input:active, ._2DKHnq_input:focus {
  color: var(--yellow);
  outline: none;
}

.BgmFromSbnPicker div[role="row"]:not([aria-rowindex="1"]) {
  cursor: pointer;
}

.DocTabs_container {
  padding: 0 8px;
}

.window-controls-overlay .DocTabs_container {
  margin-top: 24px;
}

.DocTabs_main_content {
  border-top: 1px solid var(--grey-2);
  overflow: hidden;
}

.DocTab {
  width: 160px;
  color: var(--spectrum-global-color-gray-600);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  border-bottom: 2px solid #0000;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0 9px 0 12px;
  display: flex;
  overflow: hidden;
}

.DocTab:not(:last-child) {
  border-right: 1px solid var(--spectrum-global-color-gray-100);
}

.DocTab.active-true {
  color: var(--spectrum-global-color-gray-900);
  border-bottom-color: var(--spectrum-global-color-gray-900);
}

.DocTab > span {
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  flex: 1;
  overflow: hidden;
}

.DocTab .DocTab_Close {
  width: 18px;
  height: 18px;
  color: var(--spectrum-global-color-gray-600);
  cursor: pointer;
  background: none;
  border: 0;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.DocTab .DocTab_Close:hover {
  color: var(--spectrum-global-color-gray-900);
  background: var(--spectrum-global-color-gray-200);
}

.DocTab .DocTab_Close:hover .DocTab_Close_UnsavedIcon, .DocTab .DocTab_Close:not(:hover):has(.DocTab_Close_UnsavedIcon) .DocTab_Close_CloseIcon {
  display: none;
}

.DocTab .DocTab_Close .DocTab_Close_CloseIcon {
  width: 14px;
  height: 14px;
  opacity: 0;
}

.DocTab .DocTab_Close .DocTab_Close_UnsavedIcon {
  width: 12px;
  height: 12px;
  color: var(--spectrum-global-color-gray-800);
}

.DocTab:hover .DocTab_Close_CloseIcon, .DocTab.active-true .DocTab_Close_CloseIcon {
  opacity: 1;
}

/*# sourceMappingURL=index.1cfb2a83.css.map */
